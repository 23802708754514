import {gql} from '@apollo/client';

const GET_VEHICLE_TYPES = gql`
  query busTypes {
    busTypes {
      id
      name
      fuelType
      dieselConsumption
      adblueConsumption
      lngConsumption
      doubleDecker
      numberOfTires
    }
  }
`;

export default GET_VEHICLE_TYPES;
