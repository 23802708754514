import {PartnerCostsPath} from './routePaths';

const navHorizontalOptions = [
  {
    name: 'Vehicle costs',
    path: PartnerCostsPath.vehicleCosts
  },
  {
    name: 'Driver costs',
    path: PartnerCostsPath.driverCosts
  },
  {
    name: 'Other costs',
    path: PartnerCostsPath.otherCosts
  }
];

export default navHorizontalOptions;
