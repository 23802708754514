import {gql} from '@apollo/client';

const UPDATE_PARTNER_DRIVER_COST = gql`
  mutation updateBusPartnerDriverCostEntry(
    $partnerId: ID!
    $year: Int!
    $daytimeWeekdaysWorkingHourCost: Float
    $nighttimeWeekdaysWorkingHourCost: Float
    $daytimeWeekendsWorkingHourCost: Float
    $nighttimeWeekendsWorkingHourCost: Float
    $replacementCostPerKm: CostPerDistance
    $replacementCostPerHr: Float
    $accommodationCostPerNight: Float
  ) {
    updateBusPartnerDriverCostEntry(
      input: {
        busPartnerId: $partnerId
        year: $year
        daytimeWeekdaysWorkingHourCost: $daytimeWeekdaysWorkingHourCost
        nighttimeWeekdaysWorkingHourCost: $nighttimeWeekdaysWorkingHourCost
        daytimeWeekendsWorkingHourCost: $daytimeWeekendsWorkingHourCost
        nighttimeWeekendsWorkingHourCost: $nighttimeWeekendsWorkingHourCost
        replacementCostPerKm: $replacementCostPerKm
        replacementCostPerHr: $replacementCostPerHr
        accommodationCostPerNight: $accommodationCostPerNight
      }
    ) {
      year
      daytimeWeekdaysWorkingHourCost
      nighttimeWeekdaysWorkingHourCost
      daytimeWeekendsWorkingHourCost
      nighttimeWeekendsWorkingHourCost
      replacementCostPerKm
      replacementCostPerHr
      accommodationCostPerNight
    }
  }
`;

export default UPDATE_PARTNER_DRIVER_COST;
