export const getFilters = (oldState, newFilter) => {
  const filterExists =
    oldState.length > 0 &&
    oldState.some(filter => newFilter.title === filter.title);

  if (!filterExists) {
    return [...oldState, newFilter];
  } else {
    return oldState;
  }
};
