import React from 'react';
import {Divider, Grid, GridCol, Text} from '@flixbus/honeycomb-react';
import {useVehicleCurrency} from 'Pages/ProductionCost/components/DataImportStepTwo/hooks/useVehicleCurrency';
import {LineScheduleVehicleRelatedCostsProps} from './LineScheduleVehicleRelatedCosts.types';
import EmptyDistancePerOperatingDay from './EmptyDistancePerOperatingDay/EmptyDistancePerOperatingDay';
import AdditionalInfrastructureCost from './AdditionalInfrastructureCost/AdditionalInfrastructureCost';
import VehicleUtilizationFactor from './VehicleUtilizationFactor/VehicleUtilizationFactor';
import FlixStandardCostOverwrite from './FlixStandardCostOverwrite/FlixStandardCostOverwrite';
import styles from './LineScheduleVehicleRelatedCosts.module.scss';

const LineScheduleVehicleRelatedCosts: React.FC<
  LineScheduleVehicleRelatedCostsProps
> = ({vehicleEntry, errors}) => {
  const currency = useVehicleCurrency(vehicleEntry.vehicleId) ?? '???';

  return (
    <div className={styles.container}>
      <Text>
        <b>1. Vehicle related costs</b>
      </Text>

      <Divider />

      <div className={styles.inputs}>
        <Grid>
          <GridCol size={4}>
            <EmptyDistancePerOperatingDay
              value={vehicleEntry.emptyKmPerDay}
              entryId={vehicleEntry.id}
            />
          </GridCol>

          <GridCol size={4}>
            <AdditionalInfrastructureCost
              value={vehicleEntry.additionalCostPerTrip}
              entryId={vehicleEntry.id}
              currency={currency}
            />
          </GridCol>
        </Grid>

        <Grid>
          <GridCol size={4}>
            <VehicleUtilizationFactor
              value={vehicleEntry.busUtilizationFactor}
              entryId={vehicleEntry.id}
              errors={errors?.busUtilizationFactor}
            />
          </GridCol>
          <GridCol size={4}>
            <FlixStandardCostOverwrite
              value={vehicleEntry.flixStandardCostOverwrite}
              entryId={vehicleEntry.id}
              currency={currency}
              disabled={!vehicleEntry.busUtilizationFactor}
              errors={errors?.flixStandardCostOverwrite}
            />
          </GridCol>
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(LineScheduleVehicleRelatedCosts);
