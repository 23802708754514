import React from 'react';
import IconButton from '../../Common/IconButton';
import {IconCopy, IconDelete, IconEdit} from '@flixbus/honeycomb-icons-react';
import constants from '../constants';
import InlineEdit from '../components/InlineEdit';
import {
  formatDateString,
  getPlanningPeriod,
  roundCostValues,
  getOverviewRowId
} from './index';

export const getStandardRows = (
  statePartners,
  stateCountries,
  standardEntriesAreReady,
  entries,
  handleEditClick,
  setEntryToDelete,
  setEntryToClone
) => {
  if (!standardEntriesAreReady) return [];

  return entries.map(entry => {
    const roundedEntry = roundCostValues(entry);
    const editButton = (
      <IconButton
        aria-label="edit-entry"
        onClick={() => handleEditClick(entry)}
        InlineIcon={IconEdit}
      />
    );
    const copyButton = (
      <IconButton
        title={'Copy production cost'}
        aria-label="copy-entry"
        onClick={() => setEntryToClone(entry)}
        InlineIcon={IconCopy}
      />
    );
    const deleteButton = (
      <IconButton
        aria-label="delete-entry"
        onClick={() => setEntryToDelete(entry)}
        InlineIcon={IconDelete}
        appearance="danger"
      />
    );

    const editEntryName = (
      <InlineEdit
        key={`key-inline-edit-${roundedEntry.id}`}
        id={roundedEntry.id}
        value={roundedEntry.name}
      />
    );

    const getList = (list, field) => {
      const data = field === 'countries' ? stateCountries : statePartners;
      return list
        .reduce((acc, curr) => {
          const name =
            data.list && data.list.find(item => item.id === curr).name;

          if (name && acc.includes(name)) return acc;
          else return [...acc, name];
        }, [])
        .join(', ');
    };

    const countries =
      entry.countryIds && getList(entry.countryIds, 'countries');
    const partners =
      entry.busPartnerIds && getList(entry.busPartnerIds, 'partners');
    const status = constants.calculationStatusOptions.find(
      option => option.value === entry.status
    ).label;

    const rowId = getOverviewRowId(roundedEntry);

    return {
      rowId,
      rowIdStringified: JSON.stringify(rowId),
      name: editEntryName,
      countries,
      partners,
      status,
      planningPeriod: getPlanningPeriod(
        roundedEntry.startDate,
        roundedEntry.endDate
      ),
      lineNumbers: roundedEntry.lineNumbers.join(', '),
      vehicleCount: roundedEntry.vehicleCount,
      operatingDays: roundedEntry.operatingDays,
      totalKm: roundedEntry.totalKm,
      totalHr: roundedEntry.totalHr,
      totalWorkingHr: roundedEntry.totalWorkingHr,
      productionCostPerKm: roundedEntry.productionCostPerKm,
      currency: roundedEntry.usedCurrencies.join(', '),
      version: roundedEntry.calculationVersion,
      createdAt: formatDateString(roundedEntry.createdAt),
      lastEditedAt: formatDateString(roundedEntry.lastEditedAt),
      editButton,
      copyButton,
      deleteButton
    };
  });
};
