import React, {useMemo} from 'react';
import {Input} from '@flixbus/honeycomb-react';
import {Icon, IconArrowDown} from '@flixbus/honeycomb-icons-react';
import {InputWithSelectionProps} from './InputWithSelection.types';
import styles from './InputWithSelection.module.scss';

const InputWithSelection: React.FC<InputWithSelectionProps> = ({
  selectorValue,
  selectorOptions,
  extraSelectorConfig,
  inputValue,
  extraInputConfig,
  label,
  onChange
}) => {
  const options = useMemo(
    () =>
      selectorOptions.map(({key, value}) => (
        <option key={key} value={key}>
          {value}
        </option>
      )),
    [selectorOptions]
  );

  const selectorId = useMemo(() => {
    const uniqueId = label.toLowerCase().replace(' ', '-');
    return `input-with-selection-selector-${uniqueId}`;
  }, [label]);

  const inputId = useMemo(() => {
    const uniqueId = label.toLowerCase().replace(' ', '-');
    return `input-with-selection-input-${uniqueId}`;
  }, [label]);

  return (
    <Input
      id={inputId}
      label={label}
      value={inputValue}
      type={'number'}
      placeholder={'0.1'}
      step={'0.1'}
      onChange={e =>
        onChange({
          selectorValue: selectorValue,
          inputValue: e.target.value
        })
      }
      {...extraInputConfig}
      inlineLabelLeft={
        <>
          <select
            id={selectorId}
            data-testid={selectorId}
            data-test
            className={styles.selector}
            value={selectorValue}
            onChange={e =>
              onChange({inputValue: inputValue, selectorValue: e.target.value})
            }
            {...extraSelectorConfig}
          >
            {options}
          </select>

          <Icon InlineIcon={IconArrowDown} className={styles.selectorIcon} />
        </>
      }
    />
  );
};

export default React.memo(InputWithSelection);
