import {gql} from '@apollo/client';

const GET_PLANR_VEHICLES = gql`
  query GetPlanrVehicles($scheduleIds: [ID!]!) {
    getPlanrVehicles(scheduleIds: $scheduleIds) {
      id
      name
      planrBusPartner {
        busPartnerBackendId
        busPartnerName
        busPartnerShortName
      }
    }
  }
`;

export default GET_PLANR_VEHICLES;
