import {gql} from '@apollo/client';

const GET_PARTNERS = gql`
  query busPartners($countryIds: [ID]) {
    busPartners(queryParams: {countries: $countryIds}) {
      id
      name
      backendId
      countryId
      depots {
        city
        postCode
        street
      }
      currency
    }
  }
`;

export default GET_PARTNERS;
