import {gql} from '@apollo/client';

const GET_COUNTRY_DRIVER_CONCEPT_TYPES = gql`
  query countryDriverConceptTypes($countryId: ID!) {
    countryDriverConceptTypes(countryId: $countryId) {
      id
      name
      description
      multiplicationFactor
      dailyAllowance
    }
  }
`;

export default GET_COUNTRY_DRIVER_CONCEPT_TYPES;
