import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  ChangeEvent
} from 'react';
import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOptions,
  AutocompleteOptionType,
  Tooltip
} from '@flixbus/honeycomb-react';
import {Icon, IconAttention} from '@flixbus/honeycomb-icons-react';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {PartnerNameAutocompleteFieldProps} from './PartnerNameAutocompleteFieldProps.types';

const PartnerNameAutocompleteField: React.FC<
  PartnerNameAutocompleteFieldProps
> = ({partners, busPartner, vehicleId, errors, onSelectPartnerName}) => {
  const [partnerTitle, setPartnerTitle] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    AutocompleteOptionType[] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(false);

  const partnerOptions = useMemo(
    () =>
      partners.map(partner => ({
        title: partner.name,
        key: partner.id
      })),
    [partners]
  );

  const partnerOption = useMemo(() => {
    if (!busPartner?.id) return null;
    return partnerOptions.find(partner => partner.key === busPartner?.id);
  }, [busPartner?.id, partnerOptions]);

  useEffect(() => {
    setPartnerTitle(partnerOption?.title ?? '');
  }, [partnerOption?.title]);

  const filterAutocompleteOptions = useCallback(
    (searchQuery: string) => {
      const filteredOptions = partnerOptions.filter(option =>
        option.title.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setAutocompleteOptions(filteredOptions);
      setLoading(false);
    },
    [partnerOptions]
  );

  const TooltipInlineIcon = useCallback(() => {
    const iconProps = {
      'aria-label': 'Tooltip Info',
      onMouseOver: () => setActiveTooltip(true),
      onMouseOut: () => setActiveTooltip(false),
      onBlur: () => setActiveTooltip(false),
      style: {
        cursor: 'pointer',
        pointerEvents: 'auto' as any,
        background: 'none',
        outline: 'none'
      }
    };

    return <IconAttention {...iconProps} />;
  }, []);

  const handleMouseOut = useCallback(() => setActiveTooltip(false), []);

  const handleAutocompleteDebounce = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      filterAutocompleteOptions(e.target.value);
    },
    [filterAutocompleteOptions]
  );

  const handleAutocompleteChange = useCallback(
    ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      setPartnerTitle(value);
    },
    []
  );

  const handleAutocompleteSelect = useCallback(
    item => {
      setAutocompleteOptions(null);
      setPartnerTitle(item.title);
      onSelectPartnerName(item);
    },
    [onSelectPartnerName]
  );

  const id = `partner-name-for-calculation-${vehicleId}`;

  return (
    <Tooltip
      id={`tooltip-${id}`}
      content={fieldInfoContent.partnerNameForCalculation}
      position="bottom"
      active={activeTooltip}
      onMouseOut={handleMouseOut}
    >
      <Autocomplete
        options={autocompleteOptions ?? []}
        value={partnerTitle ?? ''}
        debounce={200}
        onDebounce={handleAutocompleteDebounce}
        onChange={handleAutocompleteChange}
        onSelect={handleAutocompleteSelect}
      >
        <AutocompleteInput
          id={id}
          label="Partner name for calculation*"
          placeholder="Select a partner name"
          loading={loading}
          iconLeft={<Icon InlineIcon={TooltipInlineIcon} />}
          valid={!errors}
          infoError={errors?.optionality ?? errors?.nullable}
        />
        <AutocompleteOptions
          label="Partner name for calculation*"
          optionsToDisplay={4}
        />
      </Autocomplete>
    </Tooltip>
  );
};

export default React.memo(PartnerNameAutocompleteField);
