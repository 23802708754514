import React, {useCallback} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import VehicleCostsPanel from 'components/VehicleCosts/VehicleCostsPanel/VehicleCostsPanel';
import {Nullable} from 'Pages/Common/types';
import {
  createCountryVehicleTypeCost,
  updateCountryVehicleTypeCost
} from 'Pages/RegionalCosts/store/thunks';
import {RegionalCostsBusTypeCost} from 'Pages/RegionalCosts/store/types';
import {RegionalCostsVehicleCostPanelProps} from './RegionalCostsVehicleCostPanel.data';

const RegionalCostsVehicleCostPanel: React.FC<
  RegionalCostsVehicleCostPanelProps
> = ({
  country,
  year,
  currency,
  vehicleTypes,
  localUnits,
  panelState,
  setPanelState
}) => {
  const dispatch = useAppDispatch();

  const onSaveCompleted = useCallback(
    (vehicleTypeCost: Nullable<RegionalCostsBusTypeCost>) =>
      (error?: string) => {
        if (error) {
          setPanelState({
            data: vehicleTypeCost,
            loading: false,
            error
          });
        } else {
          setPanelState({
            data: null,
            loading: false,
            error: null
          });
        }
      },
    [setPanelState]
  );

  const onCreateVehicleTypeCost = useCallback(
    (vehicleTypeCost: Nullable<RegionalCostsBusTypeCost>) => {
      dispatch(
        createCountryVehicleTypeCost(
          vehicleTypeCost,
          country?.id,
          year,
          onSaveCompleted(vehicleTypeCost)
        )
      );
    },
    [country?.id, year, dispatch, onSaveCompleted]
  );

  const onUpdateVehicleTypeCost = useCallback(
    (vehicleTypeCost: Nullable<RegionalCostsBusTypeCost>) => {
      dispatch(
        updateCountryVehicleTypeCost(
          vehicleTypeCost,
          country?.id,
          year,
          onSaveCompleted(vehicleTypeCost)
        )
      );
    },
    [country?.id, year, dispatch, onSaveCompleted]
  );

  return (
    <VehicleCostsPanel
      country={country}
      currency={currency}
      vehicleTypes={vehicleTypes}
      localUnits={localUnits}
      state={panelState}
      onSuccess={vehicleTypeCost => {
        setPanelState({
          data: vehicleTypeCost,
          loading: true,
          error: null
        });

        vehicleTypeCost.id
          ? onUpdateVehicleTypeCost(vehicleTypeCost)
          : onCreateVehicleTypeCost(vehicleTypeCost);
      }}
      onCancel={() => {
        setPanelState({data: null, loading: false, error: null});
      }}
    />
  );
};

export default React.memo(RegionalCostsVehicleCostPanel);
