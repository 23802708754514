import {gql} from '@apollo/client';

const CREATE_COUNTRY = gql`
  mutation createCountry(
    $name: String!
    $businessRegion: String!
    $currency: Currency!
    $repairCostDimension: ValueDimension!
    $maintenanceCostDimension: ValueDimension!
  ) {
    createCountry(
      input: {
        name: $name
        businessRegion: $businessRegion
        currency: $currency
        repairCostDimension: $repairCostDimension
        maintenanceCostDimension: $maintenanceCostDimension
      }
    ) {
      id
      name
      businessRegion
      currency
      repairCostDimension
      maintenanceCostDimension
    }
  }
`;

export default CREATE_COUNTRY;
