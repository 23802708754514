import {Toast} from '../toasts-container/ToastsProvider';

export const handleError = (
  error: {message: string},
  addToast: (toast: Toast) => void,
  action: string
) => {
  console.error('Error on request ', action, error.message);
  addToast({
    type: 'danger',
    id: 0,
    content: `Error on request ${action}`,
    description: error.message
  });
};
