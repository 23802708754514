import {useState, useEffect} from 'react';
import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {RootState} from 'app/store';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';

export const useVehicleCurrency = (vehicleId: string | null) => {
  const [currency, setCurrency] = useState<string | null>(null);

  const selectBusPartner = createDraftSafeSelector(
    [
      ({productionCost}: RootState) => productionCost.busValues,
      ({partners}: RootState) => partners.list
    ],
    (busValues, partners) => {
      const busPartnerId = busValues?.find(
        vehicle => vehicle.vehicleId === vehicleId
      )?.busPartnerId;

      return partners?.find(partner => partner.id === busPartnerId);
    }
  );

  const busPartner = useAppSelector(selectBusPartner);

  useEffect(() => {
    setCurrency(busPartner?.currency ?? '???');
  }, [busPartner]);

  return currency;
};
