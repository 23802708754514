import {RegionalCostsPath} from './routePaths';

const navHorizontalOptions = [
  {
    name: 'Vehicle costs',
    path: RegionalCostsPath.vehicleCosts
  },
  {
    name: 'Driver costs',
    path: RegionalCostsPath.driverCosts
  },
  {
    name: 'Other costs',
    path: RegionalCostsPath.otherCosts
  },
  {
    name: 'Empty driving defaults',
    path: RegionalCostsPath.emptyDrivingDefaults
  },
  {
    name: 'Driver concept types',
    path: RegionalCostsPath.driverConceptTypes
  },
  {
    name: 'Driver salary periods',
    path: RegionalCostsPath.driverSalaryPeriods
  }
];

export default navHorizontalOptions;
