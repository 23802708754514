import React, {useCallback, useMemo, useState} from 'react';
import {Table, TableAction, TableHeader, TableRow} from 'components/Table';
import TablePanel from 'components/TableWithPanel/TablePanel/TablePanel';
import {
  TableWithPanelProps,
  TableWithPanelPanelTrigger,
  TableWithPanelActiveRow
} from './TableWithPanel.types';

const TableWithPanel: React.FC<TableWithPanelProps> = ({
  headers,
  rows,
  actions
}) => {
  const [activeRow, setActiveRow] = useState<TableWithPanelActiveRow | null>(
    null
  );

  // used for panel actions
  const rowClickHandler = useCallback(
    (rowIndex: number, actionId: string) => {
      const activeRow = rows[rowIndex].reduce(
        (acc, cell) => ({
          ...acc,
          [cell.key]: cell.value
        }),
        {}
      );
      setActiveRow({row: activeRow, actionId});
    },
    [rows]
  );

  const tableHeaders: TableHeader[] = useMemo(
    () =>
      headers.map(header => ({
        ...header,
        name: header.displayName ?? header.name
      })),
    [headers]
  );

  // used for table rows
  const tableRows: TableRow[] = useMemo(
    () =>
      rows.map(row =>
        row.reduce(
          (acc, cell) => ({
            ...acc,
            [cell.key]: cell.displayValue ?? cell.value
          }),
          {}
        )
      ),
    [rows]
  );

  const tableActions: TableAction[] = useMemo(
    () =>
      actions.map(action => {
        return action.type === 'panel'
          ? {
              icon: action.icon,
              onClick: (_, rowIndex) =>
                rowClickHandler(rowIndex, action.icon.name)
            }
          : {
              icon: action.icon,
              onClick: action.onClick
            };
      }),
    [actions, rowClickHandler]
  );

  return (
    <>
      <Table headers={tableHeaders} rows={tableRows} actions={tableActions} />
      {actions
        .filter(({type}) => type === 'panel')
        .map(action => {
          const {panel} = action as TableWithPanelPanelTrigger;
          return (
            <TablePanel
              key={panel.title}
              title={panel.title}
              fields={headers}
              data={
                activeRow?.actionId === action.icon.name ? activeRow.row : null
              }
              onSuccess={panel.onSuccess}
              onCancel={() => {
                setActiveRow(null);
              }}
            />
          );
        })}
    </>
  );
};

export default React.memo(TableWithPanel);
