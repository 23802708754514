import {gql} from '@apollo/client';

const SAVE_COUNTRY_PREFILLED_EMPTY_VALUES = gql`
  mutation saveCountryPrefilledEmptyValues(
    $countryId: ID!
    $prefilledEmptyKm: Distance
    $prefilledEmptyHr: Float
  ) {
    saveCountryPrefilledEmptyValues(
      input: {
        countryId: $countryId
        prefilledEmptyKm: $prefilledEmptyKm
        prefilledEmptyHr: $prefilledEmptyHr
      }
    ) {
      prefilledEmptyKm
      prefilledEmptyHr
    }
  }
`;

export default SAVE_COUNTRY_PREFILLED_EMPTY_VALUES;
