import React, {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {IconArrowLeft, IconArrowRight} from '@flixbus/honeycomb-icons-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {getPartners} from 'Pages/Partners/store/thunks';
import {getCountries} from 'Pages/Countries/store/thunks';
import {getVehicleTypes} from 'Pages/VehicleTypes/store/thunks';
import {getProductionCostBuses} from 'Pages/ProductionCost/store/thunks';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {
  backToFirstDataImportScreen,
  resetProductionCostState
} from 'Pages/ProductionCost/store/slice';
import {resetDataTableEntries} from 'Pages/Home/store/slice';
import ProductionCostHeader from '../ProductionCostHeader';
import ProductionCostFooter from '../ProductionCostFooter';
import {ProductionCostHeaderStepOption} from '../ProductionCostHeader/ProductionCostHeaderSteps/ProductionCostHeaderSteps.types';
import {ProductionCostFooterButton} from '../ProductionCostFooter/ProductionCostFooter.types';
import useCalculateProductionCost from './hooks/useCalculateProductionCost';
import ProductionCostErrorNotification from './ProductionCostErrorNotification';
import CalculationLogicSettings from './CalculationLogicSettings';
import Lines from './Lines';
import InvalidCostFactorsPopup from './InvalidCostFactorsPopup';
import LineVehicles from './LineVehicles';
import styles from './index.module.scss';

const ProductionCostStepTwo: React.FC = () => {
  const dispatch = useAppDispatch();
  const partners = useAppSelector(({partners}) => partners.list);
  const countries = useAppSelector(({countries}) => countries.list);
  const vehicleTypes = useAppSelector(({vehicleTypes}) => vehicleTypes.list);
  const calculationUserJourneyMode = useAppSelector(
    ({productionCost}) => productionCost.calculationUserJourneyMode
  );
  const loading = useAppSelector(({productionCost}) => productionCost.loading);

  const formIsInvalid = useAppSelector(
    ({productionCost}) =>
      Object.keys(productionCost.validationErrors.busValues).length > 0 ||
      Object.keys(productionCost.validationErrors.busScheduleValues).length > 0
  );

  useEffect(() => {
    if (!partners) dispatch(getPartners());
    if (!countries) dispatch(getCountries());
    if (!vehicleTypes) dispatch(getVehicleTypes());
    dispatch(getProductionCostBuses());
  }, [partners, countries, vehicleTypes, dispatch]);

  const calculateProductionCost = useCalculateProductionCost();
  const navigate = useNavigate();

  const backButton: ProductionCostFooterButton = useMemo(
    () => ({
      label: 'Back',
      leftSideIcon: IconArrowLeft,
      onClick: () => {
        if (
          calculationUserJourneyMode ===
          CalculationUserJourneyMode.EditCalculation
        ) {
          dispatch(resetProductionCostState());
          dispatch(resetDataTableEntries());
          navigate(-1); // go back to previous screen
        } else {
          dispatch(backToFirstDataImportScreen());
        }
      }
    }),
    [calculationUserJourneyMode, dispatch, navigate]
  );

  const nextButton: ProductionCostFooterButton = useMemo(
    () => ({
      label:
        calculationUserJourneyMode ===
        CalculationUserJourneyMode.EditCalculation
          ? 'Calculate & Save'
          : 'Next',
      rightSideIcon: IconArrowRight,
      appearance: 'primary',
      onClick: () => calculateProductionCost(),
      disabled: formIsInvalid || loading
    }),
    [
      calculationUserJourneyMode,
      formIsInvalid,
      loading,
      calculateProductionCost
    ]
  );

  return (
    <>
      <div className={styles.header}>
        <ProductionCostHeader
          mode={calculationUserJourneyMode}
          step={ProductionCostHeaderStepOption.DataEntry}
        />
      </div>

      <div className={styles.body}>
        <ProductionCostErrorNotification />
        <LineVehicles />
        <CalculationLogicSettings />
        <Lines />
      </div>

      <ProductionCostFooter
        leftSideButtons={[backButton]}
        rightSideButtons={[nextButton]}
      />

      <InvalidCostFactorsPopup />
    </>
  );
};

export default React.memo(ProductionCostStepTwo);
