import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'app/store';
import {partnerCostsInitialState, initPartnerCostData} from './data';
import {
  PartnerCostsBusCost,
  PartnerCostsBusTypeCost,
  PartnerCostsDriverCost,
  PartnerCostsOtherCost
} from './types';

const partnerCostsSlice = createSlice({
  name: 'partner-costs',
  initialState: partnerCostsInitialState,
  reducers: {
    selectPartner(state, {payload}: PayloadAction<string>) {
      return {
        ...partnerCostsInitialState,
        partnerId: payload
      };
    },
    selectYear(state, {payload}: PayloadAction<number>) {
      return {
        ...state,
        ...initPartnerCostData,
        year: payload
      };
    },
    qualityCheckedBusPartnerCostEntryYear(
      state,
      {payload: {qualityChecked}}: PayloadAction<{qualityChecked: boolean}>
    ) {
      return {
        ...state,
        qualityChecked
      };
    },
    receivedPartnerCostYears(state, {payload}: PayloadAction<number[]>) {
      return {...state, ...initPartnerCostData, years: [...payload].sort()};
    },
    receivedBusPartnerCostEntryYear(state, {payload}: PayloadAction<any>) {
      const newState = {
        ...state,
        qualityChecked: payload.qualityChecked,
        vehicleCost: payload?.busCostEntry,
        vehicleTypeCosts: {
          ...state.vehicleTypeCosts,
          list: payload?.busTypeCostEntries
        },
        driverCost: payload?.driverCostEntry,
        otherCost: payload?.otherCostEntry
      };

      newState.countryCost = {
        vehicleCost: payload?.countryCostEntry?.busCostEntry,
        vehicleTypeCosts: payload?.countryCostEntry?.busTypeCostEntries,
        otherCost: payload?.countryCostEntry?.otherCostEntry,
        driverCost: payload?.countryCostEntry?.driverCostEntry
      };

      return newState;
    },
    addedNewYear(state, {payload}: PayloadAction<any>) {
      return {
        ...state,
        ...initPartnerCostData,
        year: payload.year,
        years: [...(state.years ?? []), ...[payload.year]].sort(),
        vehicleCost: payload.busCostEntry,
        vehicleTypeCosts: {
          list: payload.busTypeCostEntries,
          vehicleTypeCost: null
        },
        driverCost: payload.driverCostEntry,
        otherCost: payload.otherCostEntry
      };
    },
    deletedNewYear(state, {payload}: PayloadAction<number>) {
      return {
        ...state,
        ...initPartnerCostData,
        year: null,
        years: (state.years ?? [])
          .filter(year => year?.toString() !== payload.toString())
          .sort()
      };
    },

    // Other cost
    receivedPartnerOtherCost(
      state,
      {payload}: PayloadAction<PartnerCostsOtherCost>
    ) {
      return {
        ...state,
        otherCost: payload
      };
    },

    // Driver cost
    receivedPartnerDriverCost(
      state,
      {payload}: PayloadAction<PartnerCostsDriverCost>
    ) {
      return {
        ...state,
        driverCost: payload
      };
    },

    // Vehicle cost
    receivedPartnerVehicleCostAction(
      state,
      {payload}: PayloadAction<PartnerCostsBusCost>
    ) {
      return {
        ...state,
        vehicleCost: payload
      };
    },
    addToVehicleTypeCostAction(
      state,
      {payload}: PayloadAction<PartnerCostsBusTypeCost>
    ) {
      const list = [...(state.vehicleTypeCosts?.list ?? []), payload];

      return {
        ...state,
        vehicleTypeCosts: {
          ...state.vehicleTypeCosts,
          list
        }
      };
    },
    updateVehicleTypeCostAction(
      state,
      {payload}: PayloadAction<PartnerCostsBusTypeCost>
    ) {
      const list =
        state.vehicleTypeCosts?.list?.map(vehicleTypeCost => {
          return payload.id === vehicleTypeCost?.id ? payload : vehicleTypeCost;
        }) ?? [];

      return {
        ...state,
        vehicleTypeCosts: {
          ...state.vehicleTypeCosts,
          list
        }
      };
    },
    deleteFromVehicleTypeCostAction(
      state,
      {payload}: PayloadAction<PartnerCostsBusTypeCost>
    ) {
      const list =
        state.vehicleTypeCosts?.list?.filter(
          vehicleTypeCost => payload.id !== vehicleTypeCost?.id
        ) ?? [];

      return {
        ...state,
        vehicleTypeCosts: {
          ...state.vehicleTypeCosts,
          list
        }
      };
    }
  }
});

export const selectBusPartnerCountry = createSelector(
  [
    ({partnerCosts}: RootState) => partnerCosts.partnerId,
    ({partners}: RootState) => partners.list,
    ({countries}: RootState) => countries.list
  ],
  (partnerId, partners, countries) => {
    const partner = partners?.find(p => p.id === partnerId);
    if (!partner) return null;

    return countries?.find(c => c.id === partner.countryId) ?? null;
  }
);

export const {
  selectPartner,
  selectYear,
  qualityCheckedBusPartnerCostEntryYear,
  receivedPartnerCostYears,
  receivedBusPartnerCostEntryYear,
  addedNewYear,
  deletedNewYear,

  // Other cost
  receivedPartnerOtherCost,

  // Driver cost
  receivedPartnerDriverCost,

  // Vehicle cost
  receivedPartnerVehicleCostAction,
  addToVehicleTypeCostAction,
  updateVehicleTypeCostAction,
  deleteFromVehicleTypeCostAction
} = partnerCostsSlice.actions;

export default partnerCostsSlice.reducer;
