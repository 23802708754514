import React, {useEffect, useState} from 'react';
import {Grid, GridCol, Text} from '@flixbus/honeycomb-react';
import {transformDate} from 'Pages/ProductionCost/components/DataImportStepTwo/Utils';
import {LineScheduleSummaryProps} from './LineScheduleSummary.types';
import styles from './LineScheduleSummary.module.scss';

const LineScheduleSummary: React.FC<LineScheduleSummaryProps> = ({
  schedule
}) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  useEffect(() => {
    const startDate = transformDate(schedule.startDate);
    const endDate = transformDate(schedule.endDate);

    setStartDate(startDate);
    setEndDate(endDate);
  }, [schedule.startDate, schedule.endDate]);

  return (
    <div className={styles.container}>
      <Grid gutterSize="4">
        <GridCol inline>
          <Text extraClasses="content-secondary-color">
            Schedule period: {startDate} - {endDate}
          </Text>
        </GridCol>
        <GridCol inline>
          <Text extraClasses="content-secondary-color">
            Name: {schedule.name}
          </Text>
        </GridCol>
        <GridCol inline>
          <Text extraClasses="content-secondary-color">
            Description: {schedule.description || '-'}
          </Text>
        </GridCol>
      </Grid>
    </div>
  );
};

export default React.memo(LineScheduleSummary);
