import React, {useCallback, useMemo, useState} from 'react';
import {Select, SelectOption, Tooltip} from '@flixbus/honeycomb-react';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useBusPartner} from 'Pages/PartnerCosts/hooks/useBusPartner';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import useCountryDriverConceptTypes from 'Pages/ProductionCost/components/DataImportStepTwo/hooks/useCountryDriverConceptTypes';
import {CountryRulesProps} from './CountryRules.types';
import style from './CountryRules.module.scss';

const CountryRules: React.FC<CountryRulesProps> = ({
  vehicleId,
  busPartnerId,
  countryRulesCountryId,
  errors,
  onUpdateVehicle,
  onUpdateBusScheduleValues
}) => {
  const busPartner = useBusPartner(busPartnerId ?? null);
  const {fetchCountryDriverConceptTypes} = useCountryDriverConceptTypes();

  const countries = useAppSelector(({countries}) => countries.list ?? []);
  const countryDriverConceptTypes = useAppSelector(
    ({productionCost}) => productionCost.countryDriverConceptTypes
  );

  const [activeTooltip, setActiveTooltip] = useState(false);

  const countryOptions = useMemo(
    () =>
      countries
        .filter(
          country =>
            country?.currency === busPartner?.currency ||
            countryRulesCountryId === country?.id
        )
        .map(country => (
          <SelectOption key={country.id} value={country.id}>
            {country.name}
          </SelectOption>
        )),
    [busPartner?.currency, countries, countryRulesCountryId]
  );

  const handleSelectOnCountryRule = useCallback(
    async (countryRulesCountryId: string) => {
      onUpdateVehicle({
        countryRulesCountryId
      });

      let driverConcepts = countryDriverConceptTypes[countryRulesCountryId];

      if (!driverConcepts) {
        driverConcepts = await fetchCountryDriverConceptTypes(
          countryRulesCountryId
        );
      }

      // preselect first item if only one available
      const countryDriverConceptTypeId =
        driverConcepts?.length === 1 && driverConcepts[0].id
          ? driverConcepts[0].id
          : undefined;

      onUpdateBusScheduleValues({countryDriverConceptTypeId});
    },
    [
      onUpdateVehicle,
      countryDriverConceptTypes,
      onUpdateBusScheduleValues,
      fetchCountryDriverConceptTypes
    ]
  );

  const id = `country-rules-${vehicleId}`;

  return (
    <div className={style.container} data-testid={`line-vehicle-country-rules`}>
      <Tooltip
        id={`country-rules-tooltip-${id}`}
        content={fieldInfoContent.countryRules}
        position="bottom"
        active={activeTooltip}
        onMouseOut={() => setActiveTooltip(false)}
        className="flex-1"
      >
        <Select
          id={`country-rules-select-${id}`}
          label={
            <LabelWithTooltip
              label="Country rules*"
              setActiveTooltip={setActiveTooltip}
            />
          }
          placeholder="Select a country"
          value={countryRulesCountryId ?? ''}
          disabled={!busPartner?.id}
          valid={!errors}
          infoError={
            errors?.optionality ??
            errors?.nullable ??
            errors?.notMatchingCurrency
          }
          onChange={e => handleSelectOnCountryRule(e.target.value)}
        >
          {countryOptions}
        </Select>
      </Tooltip>
    </div>
  );
};

export default React.memo(CountryRules);
