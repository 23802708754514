import {PlanrScheduleType} from 'Pages/ProductionCost/store/types';
import {SchedulesListPerDay} from '../LineSchedulesSelect/LineSchedulesSelect.types';

export const getOverlappedSchedules = (
  schedulesListPerDay: SchedulesListPerDay
): Set<string> => {
  const overlaps = new Set<string>();

  Object.keys(schedulesListPerDay).forEach(key => {
    const daySchedules = schedulesListPerDay[key];
    if (daySchedules[PlanrScheduleType.Regular].length > 1) {
      for (const schedule of daySchedules[PlanrScheduleType.Regular]) {
        overlaps.add(schedule);
      }
    }

    if (daySchedules[PlanrScheduleType.Holiday].length > 1) {
      for (const schedule of daySchedules[PlanrScheduleType.Holiday]) {
        overlaps.add(schedule);
      }
    }
  });

  return overlaps;
};
