import {gql} from '@apollo/client';

const DELETE_PRODUCTION_COST_ENTRY = gql`
  mutation deleteProductionCostEntry($productionCostEntryId: ID!) {
    deleteProductionCostEntry(productionCostEntryId: $productionCostEntryId) {
      id
    }
  }
`;

export default DELETE_PRODUCTION_COST_ENTRY;
