import styles from './RegionalCostsExplanationNotice.module.scss';
import React from 'react';
import {CpcIconAttention} from 'Pages/Common/CpcIcons';

const RegionalCostsExplanationNotice: React.FC = () => {
  return (
    <span className={styles.container}>
      <CpcIconAttention />{' '}
      <span className={styles.label}>
        Here you can define country cost factor defaults.
        <br />
        They will be used when you cannot provide cost factors for a certain bus
        partner.
        <br />
        Fields marked with an * are mandatory and values must be specified at
        either
        <br />
        regional or partner level.
      </span>
    </span>
  );
};

export default React.memo(RegionalCostsExplanationNotice);
