import {VehicleFuelType, VehicleTypesState} from './types';

export const initVehicleTypeObject = {
  id: null,
  name: '',
  fuelType: VehicleFuelType.DIESEL,
  dieselConsumption: null,
  adblueConsumption: null,
  lngConsumption: null,
  numberOfTires: null,
  doubleDecker: false
};

export const initialVehicleTypesState: VehicleTypesState = {
  list: null,
  vehicleType: null
};
