import {gql} from '@apollo/client';

const PRODUCTION_COST_ENTRY_QUERY = gql`
  query productionCostEntry($productionCostEntryId: ID!) {
    productionCostEntry(productionCostEntryId: $productionCostEntryId) {
      id
      name
      status
      startDate
      endDate
      costYear
      released
      driverCostCalculationType
      leasingCostCalculationType
      planrDataVersion
    }
  }
`;

export default PRODUCTION_COST_ENTRY_QUERY;
