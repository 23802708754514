import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {AccommodationsPerWeekProps} from './AccommodationsPerWeek.types';
import styles from './AccommodationsPerWeek.module.scss';

const AccommodationsPerWeek: React.FC<AccommodationsPerWeekProps> = ({
  value,
  entryId
}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-accommodations-per-week`;

  const tooltipContent = useMemo(() => fieldInfoContent.accommodationsWeek, []);

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const accommodationsPerWeek =
        validateInputNumber(editedValue, value ? value.toString() : '') ??
        undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {accommodationsPerWeek}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="accommodations-per-week"
        label="Accommodations/week"
        value={formattedValue}
        tooltipContent={tooltipContent}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(AccommodationsPerWeek);
