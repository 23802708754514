import graphqlClient from '../../../services/graphqlClient';
import productionCostEntriesQuery from '../graphql/getProductionCostEntriesQuery';
import deleteProductionCostEntryMutation from '../graphql/deleteProductionCostEntryMutation';
import cloneProductionCostEntryMutation from '../graphql/cloneProductionCostEntryMutation';
import constants from '../constants';
import productionCostAnalyticsViewQuery from '../graphql/getProductionCostAnalyticsViewQuery';
import updateProductionCostEntryMutation from '../graphql/updateProductionCostEntryMutation';
import {handleError} from '../../Common/Errors';
import {
  updateOptions,
  receivedProductionCostEntries,
  productionCostEntryWasRemoved,
  productionCostEntryWasCloned,
  productionCostEntryWasUpdated
} from './slice';

export const fetchNameOptions = name => {
  return dispatch => {
    graphqlClient
      .query({
        query: productionCostEntriesQuery,
        variables: {
          page: 0,
          size: constants.requestSize,
          name
        }
      })
      .then(result => {
        let {
          data: {productionCostEntries}
        } = result;

        const options = productionCostEntries.map(entry => ({
          title: entry.name,
          key: entry.name
        }));

        return dispatch(
          updateOptions(constants.fields.costCalculationName, options)
        );
      });
  };
};

export const getProductionCostEntries = (
  page,
  params,
  analyticsMode,
  size = constants.requestSize
) => {
  return dispatch => {
    const query = analyticsMode
      ? productionCostAnalyticsViewQuery
      : productionCostEntriesQuery;

    graphqlClient
      .query({
        query,
        variables: {
          ...params,
          page,
          size
        },
        fetchPolicy: 'no-cache'
      })
      .then(result => {
        let entries;
        let subtotals;
        let absoluteValuesSubtotals;
        let absoluteValuesEntries;
        let paginationOutput;

        if (analyticsMode) {
          const {
            data: {productionCostAnalyticsView}
          } = result;

          ({
            paginationOutput,
            entries,
            subtotals,
            absoluteValuesSubtotals,
            absoluteValuesEntries
          } = productionCostAnalyticsView);
        } else {
          const {
            data: {productionCostEntriesView}
          } = result;
          ({paginationOutput, productionCostEntries: entries} =
            productionCostEntriesView);
        }

        return dispatch(
          receivedProductionCostEntries({
            page,
            paginationOutput,
            entries,
            subtotals,
            absoluteValuesSubtotals,
            absoluteValuesEntries,
            size
          })
        );
      });
  };
};

export const deleteProductionCostEntry = id => {
  return dispatch => {
    graphqlClient
      .mutate({
        mutation: deleteProductionCostEntryMutation,
        variables: {
          productionCostEntryId: id
        }
      })
      .then(() => {
        dispatch(productionCostEntryWasRemoved());
      });
  };
};

export const cloneProductionCostEntry = (
  id,
  cloneName,
  originName,
  addToast
) => {
  return dispatch => {
    graphqlClient
      .mutate({
        mutation: cloneProductionCostEntryMutation,
        variables: {
          productionCostEntryId: id,
          clonedProductionCostEntryName: cloneName
        }
      })
      .then(() => {
        addToast({
          type: 'success',
          content: `Cloned ${originName} to ${cloneName} successfully`
        });
        dispatch(productionCostEntryWasCloned());
      })
      .catch(error =>
        handleError(error, addToast, `clone production cost entry ${cloneName}`)
      );
  };
};

export const updateProductionCostEntry = (id, newName, addToast) => {
  return dispatch => {
    graphqlClient
      .mutate({
        mutation: updateProductionCostEntryMutation,
        variables: {
          productionCostEntryId: id,
          name: newName
        }
      })
      .then(() => {
        addToast({
          type: 'success',
          content: `Name ${newName} saved successfully`
        });
        dispatch(productionCostEntryWasUpdated());
      })
      .catch(error =>
        handleError(error, addToast, `update production cost entry ${newName}`)
      );
  };
};
