import {UnitsSystem} from 'Pages/Common/LocalUnits/types';
import {ValueDimension} from 'Pages/Countries/components/index.constants';
import {Country} from 'Pages/Countries/store/types';
import {RegionalCostsOtherCost} from 'Pages/RegionalCosts/store/types';

export const overheadCostHeaders = (
  otherCost: RegionalCostsOtherCost,
  localUnits: UnitsSystem,
  country: Country
) => {
  const getDamageRepairHeader = () => {
    const repairCostDimension = otherCost.repairCostPerDistanceUnit
      ? ValueDimension.PER_DISTANCE_UNIT
      : otherCost.repairCostPerMonth
      ? ValueDimension.PER_MONTH
      : country.repairCostDimension;

    return repairCostDimension === ValueDimension.PER_DISTANCE_UNIT
      ? {
          name: `Damage repair (${country.currency}/${localUnits.distanceAbbreviation})`,
          key: 'repairCostPerDistanceUnit'
        }
      : {
          name: `Damage repair (${country.currency}/month)`,
          key: 'repairCostPerMonth'
        };
  };

  return [
    {
      name: `Overhead (${country.currency}/month)*`,
      key: 'overheadCost'
    },
    {
      name: `Legal (${country.currency}/month)`,
      key: 'legalCost'
    },
    getDamageRepairHeader(),
    {
      name: `Other costs (${country.currency}/month)`,
      key: 'otherCost'
    }
  ];
};

export const operationalCostHeaders = [
  {
    name: 'Cleaning',
    key: 'cleaningCost'
  }
];
