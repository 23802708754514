import React, {useCallback, useMemo} from 'react';
import {OverviewSelectedValue} from '../types';
import {DataTableActionButton} from '../DataTableActionButton';
import getRecalculateCostsQuery from '../../graphql/recalculateProductionCostMutation';
import {IconCalculator} from '@flixbus/honeycomb-icons-react';
import {CpcIconAttention} from 'Pages/Common/CpcIcons';
import {formatCostPerDistanceFieldName} from '../../../Common/LabelUtils';
import {useLocalUnitsContext} from '../../../Common/LocalUnits';
import styles from './RecalculateCostsButton.module.scss';

type PropsType = {
  selectedRows: OverviewSelectedValue[];
};

const RecalculateCostsButton = (props: PropsType) => {
  const {localUnits} = useLocalUnitsContext();
  const getTableRowFields = useCallback(
    (selectedRow: OverviewSelectedValue) => ({
      name: selectedRow.name,
      totalKm: selectedRow.totalKm,
      currencies: selectedRow.usedCurrencies.join(', '),
      productionCostPerKm: selectedRow.productionCostPerKm
    }),
    []
  );

  const getProcessedResultFields = useCallback(
    (resultRow: OverviewSelectedValue) => ({
      totalKm: resultRow.totalKm,
      usedCurrencies: resultRow.usedCurrencies,
      productionCostPerKm: resultRow.productionCostPerKm
    }),
    []
  );

  const tableSummary = useMemo(
    () => (
      <div className={styles.tableSummary}>
        <h4>
          Total selected calculation{props.selectedRows.length > 1 ? 's' : ''}:{' '}
          {props.selectedRows.length}
        </h4>

        <div className={styles.warningMessage}>
          <CpcIconAttention /> Schedule data is not updated when you recalculate
          a calculation.
        </div>
      </div>
    ),
    [props.selectedRows.length]
  );

  const tableHeaders = useMemo(
    () => [
      {key: 'rowNumber', title: 'No'},
      {key: 'name', title: 'Name'},
      {
        key: 'totalKm',
        title: `Total ${localUnits?.distanceAbbreviation}`
      },
      {key: 'currencies', title: 'Currency'},
      {
        key: 'productionCostPerKm',
        title: formatCostPerDistanceFieldName(
          localUnits,
          'Total production costs'
        )
      },
      {key: 'status', title: 'Status'}
    ],
    [localUnits]
  );

  return (
    <DataTableActionButton
      selectedRows={props.selectedRows}
      name={'Recalculate Costs'}
      buttonIcon={IconCalculator}
      popupActionButtonName={'Calculate'}
      popupHeaderMessage={'Recalculate Production Costs'}
      tableSummary={tableSummary}
      tableHeaders={tableHeaders}
      getTableRowFields={getTableRowFields}
      getProcessedResultFields={getProcessedResultFields}
      getMutationQuery={getRecalculateCostsQuery}
      errorMessage={
        ' Errors found when recalculating, please recalculate individually' +
        ' to know more about the errors.'
      }
    />
  );
};
export const RecalculateCostsButtonMemo = React.memo(RecalculateCostsButton);
