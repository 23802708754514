import React from 'react';
import {Spinner} from '@flixbus/honeycomb-react';

const ContentLoader = () => (
  <div className={`loading-content-overlay`}>
    <Spinner />
  </div>
);

export default ContentLoader;
