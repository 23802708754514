import React from 'react';
import {Grid, GridCol, Text} from '@flixbus/honeycomb-react';
import DriverCostCalculationTypeSelector from './DriverCostCalculationTypeSelector/DriverCostCalculationTypeSelector';
import LeasingCostCalculationTypeSelector from './LeasingCostCalculationTypeSelector/LeasingCostCalculationTypeSelector';
import style from './CalculationLogicSettings.module.scss';

const CalculationLogicSettings: React.FC = () => (
  <div className={style.container}>
    <Text extraClasses="font-bold no-margin">
      Choose calculation logic settings
    </Text>

    <Grid extraClasses="gap-10">
      <GridCol inline>
        <DriverCostCalculationTypeSelector />
      </GridCol>

      <GridCol inline>
        <LeasingCostCalculationTypeSelector />
      </GridCol>
    </Grid>
  </div>
);

export default React.memo(CalculationLogicSettings);
