import React from 'react';
import {UnitsSystem} from 'Pages/Common/LocalUnits/types';
import {TableWithPanelHeader} from 'components/TableWithPanel';

export const getCostByKmHeaders = (
  currency: string,
  localUnits: UnitsSystem
) => [
  {
    name: `Diesel (${currency}/${localUnits.volumeAbbreviation})`,
    key: 'dieselCost'
  },
  {
    name: `Ad Blue (${currency}/${localUnits.volumeAbbreviation})`,
    key: 'adblueCost'
  },
  {
    name: `LNG (${currency}/${localUnits.weightAbbreviation})`,
    key: 'lngCost'
  },
  {
    name: `Flix standard cost (${currency}/bus/month)`,
    key: 'flixStandardCost'
  },
  {
    name: `Cost per Tire (${currency}/${localUnits.distanceAbbreviation})`,
    key: 'tiresCost',
    fractionDigitsCount: 5
  }
];

export const getCostPerVehicleTypeHeaders: (
  currency: string,
  localUnits: UnitsSystem
) => TableWithPanelHeader[] = (currency, localUnits) => {
  return [
    {
      key: 'busTypeName',
      name: 'Vehicle type'
    },
    {
      key: 'fuelTypeName',
      name: 'Fuel type'
    },
    {
      key: 'customIdentifier',
      name: 'Custom identifier',
      displayName: (
        <>
          <div>Custom</div>
          <div>identifier</div>
        </>
      )
    },
    {
      key: 'vehicleProductionYear',
      name: 'Vehicle production year',
      displayName: (
        <>
          <div>Vehicle</div>
          <div>production</div>
          <div>year</div>
        </>
      )
    },
    {
      key: 'leasingCost',
      name: `Leasing/amortisation cost (${currency}/month)*`,
      displayName: (
        <>
          <div>{`Leasing/amortisation`}</div>
          <div>{`cost`}</div>
          <div>{`(${currency}/month)*`}</div>
        </>
      )
    },
    {
      key: 'insuranceCost',
      name: `Insurance cost (${currency}/month)*`,
      displayName: (
        <>
          <div>Insurance</div>
          <div>cost</div>
          <div>({currency}/month)*</div>
        </>
      )
    },
    {
      key: 'interestCost',
      name: `Interest cost (${currency}/month)`,
      displayName: (
        <>
          <div>Interest</div>
          <div>cost</div>
          <div>({currency}/month)</div>
        </>
      )
    },
    {
      key: 'motorVehicleTax',
      name: `Motor vehicle tax (${currency}/month)`,
      displayName: (
        <>
          <div>Motor</div>
          <div>vehicle tax</div>
          <div>({currency}/month)</div>
        </>
      )
    },
    {
      key: 'maintenanceCostPerMonth',
      name: `Service & Maintenance (${currency}/month)*`,
      displayName: (
        <>
          <div>Service &</div>
          <div>Maintenance</div>
          <div>({currency}/month)*</div>
        </>
      )
    },
    {
      key: 'maintenanceCostPerDistanceUnit',
      name: `Service & Maintenance (${currency}/${localUnits.distanceAbbreviation})*`,
      displayName: (
        <>
          <div>Service &</div>
          <div>Maintenance</div>
          <div>
            ({currency}/{localUnits.distanceAbbreviation})*
          </div>
        </>
      )
    },
    {
      key: 'vehicleRegistrationCost',
      name: `Vehicle Registration Cost (${currency}/month)`,
      displayName: (
        <>
          <div>Vehicle</div>
          <div>registration cost</div>
          <div>({currency}/month)*</div>
        </>
      )
    }
  ];
};
