import {PartnerCostsState} from './types';

export const initPartnerCostData = {
  vehicleCost: null,
  vehicleTypeCosts: {
    list: null
  },
  driverCost: null,
  otherCost: null,
  countryCost: null,
  qualityChecked: false
};

export const partnerCostsInitialState: PartnerCostsState = {
  partnerId: null,
  year: null,
  years: [],
  ...initPartnerCostData,
  options: []
};
