import React, {useEffect, useState, useCallback, useMemo} from 'react';
import CpcPopup from '../../../Common/CpcPopup';
import {DataTable, DataTableHead, DataTableRow} from '@flixbus/honeycomb-react';
import {ActionStatus, OverviewSelectedValue} from '../types';
import {isEmptyArray, replaceAll} from 'ramda-adjunct';
import {roundCostValues} from '../../utils';
import {productionCostEntryWasUpdated} from '../../store/slice';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import CpcAlert from '../../../Common/CpcAlert';
import useCpcMutation from '../../../Common/useCpcMutation';
import {DataTableActionPopupProps} from './DataTableActionPopup.types';

const DataTableActionPopup: React.FC<DataTableActionPopupProps> = ({
  tableSummary,
  tableHeaders,
  selectedRows,
  popupActive,
  setPopupActive,
  popupHeaderMessage,
  popupIcon,
  popupActionButtonName,
  errorMessage,
  getMutationQuery,
  getProcessedResultFields,
  getTableRowFields
}) => {
  const dispatch = useAppDispatch();

  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState<number>(0);
  const [selectedRowsWithStatus, setSelectedRowsWithStatus] = useState<
    OverviewSelectedValue[]
  >([]);

  const isMaximumRequestsExceeds = selectedRows.length > 20;

  const setSelectedRowsStatus = (status: ActionStatus): void => {
    const selectedRowsWithStatusInit = isEmptyArray(selectedRowsWithStatus)
      ? selectedRows
      : selectedRowsWithStatus;
    const rowsWithStatus = selectedRowsWithStatusInit.map(
      (row: OverviewSelectedValue) => {
        const queryId =
          'queryId' + replaceAll('-', '', row.productionCostEntryId);
        return {...row, status: status, queryId};
      }
    );
    setSelectedRowsWithStatus(rowsWithStatus);
  };

  useEffect(() => {
    setSelectedRowsStatus(ActionStatus.NA);
    setDisableButton(isMaximumRequestsExceeds);
    setErrors(0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [recalculateCosts, data] = useCpcMutation(
    getMutationQuery(selectedRows)
  );

  const processResult = useCallback(
    data => {
      let rowsWithStatus = selectedRowsWithStatus;
      let errorsCount = 0;
      for (const queryId in data) {
        rowsWithStatus = rowsWithStatus.map(row => {
          if (row.queryId === queryId) {
            if (data[queryId]) {
              const roundedEntry: any = roundCostValues(data[queryId]);
              return {
                ...row,
                status: ActionStatus.SUCCESS,
                ...getProcessedResultFields(roundedEntry)
              };
            } else {
              errorsCount++;
              return {
                ...row,
                status: ActionStatus.ERROR
              };
            }
          }
          return row;
        });
      }
      setErrors(errorsCount);
      setSelectedRowsWithStatus(rowsWithStatus);
      dispatch(productionCostEntryWasUpdated());
    },
    [selectedRowsWithStatus, getProcessedResultFields, dispatch]
  );

  // Process result after mutation
  useEffect(() => {
    if (data) {
      processResult(data);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async () => {
    setDisableButton(true);
    setSelectedRowsStatus(ActionStatus.PENDING);
    await recalculateCosts();
  };

  const onClose = () => {
    setSelectedRowsStatus(ActionStatus.NA);
  };

  const tableData = useMemo(
    () =>
      selectedRowsWithStatus.map((selectedRow, index) => ({
        rowNumber: index + 1,
        ...getTableRowFields(selectedRow),
        status: selectedRow.status
      })),
    [selectedRowsWithStatus, getTableRowFields]
  );

  return isMaximumRequestsExceeds ? (
    <CpcAlert alertActive={popupActive} setAlertActive={setPopupActive}>
      Maximum number of selected production cost calculations should not exceed
      20
    </CpcAlert>
  ) : (
    <div className={'data-table-action-popup'}>
      <CpcPopup
        popupActive={popupActive}
        setPopupActive={setPopupActive}
        actionButtonName={popupActionButtonName}
        actionButtonHandler={onSubmit}
        headerMessage={popupHeaderMessage}
        icon={popupIcon}
        disabled={disableButton}
        closeButtonHandler={onClose}
      >
        <div>
          {errors > 0 && (
            <div style={{color: 'red'}}>
              {errorMessage} No of failed calculations : {errors}
            </div>
          )}

          {tableSummary}

          <DataTable
            headers={
              <DataTableHead>
                {tableHeaders.map(header => (
                  <th key={header.key} scope="col">
                    {header.title}
                  </th>
                ))}
              </DataTableHead>
            }
            height={tableData.length > 9 ? '540px' : undefined}
            scrollable="sticky-header"
          >
            {tableData.map(row => (
              <DataTableRow key={row.rowNumber}>
                {tableHeaders.map(header => (
                  <td key={header.key}>{row[header.key]}</td>
                ))}
              </DataTableRow>
            ))}
          </DataTable>
        </div>
      </CpcPopup>
    </div>
  );
};

export default DataTableActionPopup;
