import React from 'react';
import {UnitsSystem} from './LocalUnits/types';
import {getUnitsSystemOrDefault} from './LocalUnits/Utils';

export const getKmsOrMiles = (localUnits: UnitsSystem | null) => {
  const unitsSystemOrDefault = getUnitsSystemOrDefault(localUnits?.unitsSystem);
  return unitsSystemOrDefault.distanceAbbreviation === 'km' ? 'kms' : 'miles';
};

export const formatCostPerDistanceFieldName = (
  localUnits: UnitsSystem | null,
  fieldName: string
) => {
  const {distanceAbbreviation} = getUnitsSystemOrDefault(
    localUnits?.unitsSystem
  );
  return formatCostPerUnitFieldName(fieldName, distanceAbbreviation);
};

export const formatCostPerVolumeFieldName = (
  localUnits: UnitsSystem | null,
  fieldName: string
) => {
  const {volumeAbbreviation} = getUnitsSystemOrDefault(localUnits?.unitsSystem);
  return formatCostPerUnitFieldName(fieldName, volumeAbbreviation);
};

export const formatCostPerWeightFieldName = (
  localUnits: UnitsSystem | null,
  fieldName: string
) => {
  const {weightAbbreviation} = getUnitsSystemOrDefault(localUnits?.unitsSystem);
  return formatCostPerUnitFieldName(fieldName, weightAbbreviation);
};

const formatCostPerUnitFieldName = (fieldName: string, unit: string) => (
  <>
    {fieldName}
    <div style={{whiteSpace: 'nowrap'}}>(per {unit})</div>
  </>
);
