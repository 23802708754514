import {gql} from '@apollo/client';

const CREATE_COUNTRY_DRIVER_CONCEPT_TYPE = gql`
  mutation createCountryDriverConceptType(
    $countryId: ID!
    $name: String!
    $description: String
    $multiplicationFactor: Float! = 1
    $dailyAllowance: Float!
  ) {
    createCountryDriverConceptType(
      input: {
        countryId: $countryId
        name: $name
        description: $description
        multiplicationFactor: $multiplicationFactor
        dailyAllowance: $dailyAllowance
      }
    ) {
      id
      name
      description
      multiplicationFactor
      dailyAllowance
    }
  }
`;

export default CREATE_COUNTRY_DRIVER_CONCEPT_TYPE;
