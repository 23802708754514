import React, {useState} from 'react';
import {Input, Tooltip} from '@flixbus/honeycomb-react';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import {TooltipInputProps} from './TooltipInput.types';
import styles from './TooltipInput.module.scss';

const TooltipInput: React.FC<TooltipInputProps> = ({
  id,
  name,
  label,
  value,
  step,
  inlineLabelLeft,
  placeholder,
  tooltipContent,
  tooltipPosition,
  disabled,
  valid,
  infoError,
  handleInputChange
}) => {
  const [activeTooltip, setActiveTooltip] = useState(false);

  return (
    <div className={styles.container}>
      <Tooltip
        id={`tooltip-${id}`}
        content={tooltipContent}
        position={tooltipPosition ?? 'bottom'}
        active={activeTooltip}
        onMouseOut={() => setActiveTooltip(false)}
      >
        <Input
          id={id}
          name={name}
          label={
            <LabelWithTooltip
              label={label}
              setActiveTooltip={() => setActiveTooltip(true)}
            />
          }
          inlineLabelLeft={inlineLabelLeft}
          placeholder={placeholder}
          value={value}
          step={step}
          type="number"
          disabled={disabled}
          valid={valid}
          infoError={infoError}
          onChange={e => handleInputChange(e.target.value)}
        />
      </Tooltip>
    </div>
  );
};

export default React.memo(TooltipInput);
