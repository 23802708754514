import {gql} from '@apollo/client';

const GET_PLANR_LINES = gql`
  query getPlanrLines {
    getPlanrLines {
      uuid
      number
    }
  }
`;

export default GET_PLANR_LINES;
