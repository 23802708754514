import {gql} from '@apollo/client';

const GET_VEHICLE_TYPE_COSTS_QUERY = gql`
  query GET_VEHICLE_TYPES_QUERY(
    $countryId: ID!
    $busPartnerId: ID!
    $year: Int!
  ) {
    getBusPartnerCostEntryYear(busPartnerId: $busPartnerId, year: $year) {
      busTypeCostEntries {
        id
        busTypeId
        customIdentifier
        vehicleProductionYear
      }
    }

    countryCostEntry(countryId: $countryId, year: $year) {
      busTypeCostEntries {
        id
        busTypeId
        customIdentifier
        vehicleProductionYear
      }
    }
  }
`;

export default GET_VEHICLE_TYPE_COSTS_QUERY;
