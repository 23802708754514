import {TableHeader, TableRow} from 'components/Table';
import {getScaledNumber} from 'Pages/Common/Utils';
import {VehicleFuelType, VehicleType} from 'Pages/VehicleTypes/store/types';
import {PartnerCostsBusTypeCost} from 'Pages/PartnerCosts/store/types';
import {RegionalCostsBusTypeCost} from 'Pages/RegionalCosts/store/types';
import {Country} from 'Pages/Countries/store/types';
import {TableWithPanelHeader} from 'components/TableWithPanel';

export const getBaseCostsPerVehicleTypeTableConfig: (
  vehicleTypes: VehicleType[],
  vehicleTypeCosts:
    | PartnerCostsBusTypeCost[]
    | RegionalCostsBusTypeCost[]
    | undefined,
  country: Country,
  headers: TableWithPanelHeader[]
) => {headers: TableHeader[]; rows: TableRow[]} = (
  vehicleTypes,
  vehicleTypeCosts,
  country,
  headers
) => {
  const isMaintenancePerDistanceUnitPresent = vehicleTypeCosts?.some(
    vehicleTypeCost => vehicleTypeCost?.maintenanceCostPerDistanceUnit ?? false
  );

  const isMaintainancePerMonthPresent = vehicleTypeCosts?.some(
    vehicleTypeCost => vehicleTypeCost?.maintenanceCostPerMonth ?? false
  );

  const formattedHeaders: TableHeader[] = headers
    .map(header => ({
      key: header.key,
      name: header.displayName ?? header.name
    }))
    .filter(
      header =>
        !(
          header.key === 'maintenanceCostPerDistanceUnit' &&
          !isMaintenancePerDistanceUnitPresent
        ) &&
        !(
          header.key === 'maintenanceCostPerMonth' &&
          isMaintenancePerDistanceUnitPresent &&
          !isMaintainancePerMonthPresent
        ) &&
        !(
          header.key === 'vehicleRegistrationCost' &&
          !country.vehicleRegistrationCostAllowed
        )
    );

  const rows: TableRow[] =
    vehicleTypeCosts?.map(vehicleTypeCost => {
      const vehicleType = vehicleTypes.find(
        vehicleType => vehicleType.id === vehicleTypeCost.busTypeId
      );

      return headers.reduce(
        (acc, {key}) => {
          if (key === 'busTypeName') {
            return {
              ...acc,
              busTypeId: vehicleType?.id,
              busTypeName: vehicleType?.name ?? ''
            };
          }

          if (key === 'fuelTypeName') {
            return {
              ...acc,
              fuelType: vehicleType?.fuelType,
              fuelTypeName:
                vehicleType?.fuelType === VehicleFuelType.DIESEL
                  ? 'Diesel'
                  : 'LNG'
            };
          }

          const value = [
            'leasingCost',
            'insuranceCost',
            'interestCost',
            'motorVehicleTax',
            'maintenanceCostPerMonth',
            'maintenanceCostPerDistanceUnit',
            'vehicleRegistrationCost'
          ].includes(key)
            ? getScaledNumber(vehicleTypeCost[key])
            : vehicleTypeCost[key];
          return {...acc, [key]: value};
        },
        {id: vehicleTypeCost.id} // id is required for future row actions (edit, delete, etc)
      );
    }) ?? [];
  rows.sort((row1, row2) => {
    const vehicleTypeName1 = row1.busTypeName as string;
    const vehicleTypeName2 = row2.busTypeName as string;
    return vehicleTypeName1.localeCompare(vehicleTypeName2);
  });

  return {headers: formattedHeaders, rows};
};
