const MONDAY = 'MONDAY';
const TUESDAY = 'TUESDAY';
const WEDNESDAY = 'WEDNESDAY';
const THURSDAY = 'THURSDAY';
const FRIDAY = 'FRIDAY';
const SATURDAY = 'SATURDAY';
const SUNDAY = 'SUNDAY';

export const daysOfWeekOptions = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY
];

export const daysOfWeekTitles = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday'
};
