import React from 'react';
import {Text, Grid, GridCol} from '@flixbus/honeycomb-react';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {ProductionCostHeaderProps} from './ProductionCostHeader.types';
import styles from './ProductionCostHeader.module.scss';
import ProductionCostSteps from './ProductionCostHeaderSteps/ProductionCostHeaderSteps';
import {ProductionCostHeaderStepOption} from './ProductionCostHeaderSteps/ProductionCostHeaderSteps.types';
import ProductionCostHeaderReadOnly from './ProductionCostHeaderReadOnly';
import ProductionCostHeaderEditable from './ProductionCostHeaderEditable';

const ProductionCostHeader: React.FC<ProductionCostHeaderProps> = ({
  step,
  mode,
  editable = false
}) => {
  return (
    <>
      <Grid align="center" justify="space-between">
        <GridCol>
          <h2 className={styles.title}>
            {mode === CalculationUserJourneyMode.EditCalculation
              ? 'Edit calculation'
              : 'New calculation'}

            {step !== ProductionCostHeaderStepOption.Summary && (
              <Text small extraClasses="production-cost-header-subtitle">
                Fields marked with an * are mandatory
              </Text>
            )}
          </h2>
        </GridCol>
        <GridCol>
          <ProductionCostSteps step={step} />
        </GridCol>
      </Grid>

      {editable ? (
        <ProductionCostHeaderEditable />
      ) : (
        <ProductionCostHeaderReadOnly />
      )}
    </>
  );
};

export default React.memo(ProductionCostHeader);
