import productionCostBusScheduleCostFactorsView from '../../graphql/getProductionCostBusScheduleCostFactorsViewQuery';
import graphqlClient from '../../../../services/graphqlClient';

export const getProductionCostBusScheduleCostFactorsView = (
  productionCostEntryId,
  productionCostLineId,
  busPartnerId,
  loadInputCostFactorsTable
) => {
  const query = productionCostBusScheduleCostFactorsView;
  graphqlClient
    .query({
      fetchPolicy: 'network-only',
      query,
      variables: {
        productionCostEntryId,
        productionCostLineId,
        busPartnerId
      }
    })
    .then(result => {
      loadInputCostFactorsTable(
        result.data.productionCostBusScheduleCostFactorsView
      );
    });
};
