import React, {useState, useCallback, useMemo, ReactElement} from 'react';
import {Calendar, Input, Select, SelectOption} from '@flixbus/honeycomb-react';
import {Icon, IconCalendarSolid} from '@flixbus/honeycomb-icons-react';
import {productionCostStatuses} from 'Pages/ProductionCost/constants/statuses';
import {getPlanningPeriodDate} from 'Pages/ProductionCost/utils/formatePlanningPeriodDate';
import {useDocumentEvent} from 'Pages/Common/hooks/useDocumentEvent';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {
  ProductionCostState,
  ProductionCostStatus
} from 'Pages/ProductionCost/store/types';
import {productionCostBaseUpdate} from 'Pages/ProductionCost/store/slice';
import {
  productionCostEndDate,
  productionCostEndYear,
  productionCostStartDate,
  productionCostStartYear
} from './ProductionCostHeaderEditable.data';
import styles from './ProductionCostHeaderEditable.module.scss';

const ProductionCostHeaderEditable: React.FC = () => {
  const startDate = useAppSelector(
    ({productionCost}) => productionCost.startDate
  );
  const endDate = useAppSelector(({productionCost}) => productionCost.endDate);
  const status = useAppSelector(({productionCost}) => productionCost.status);
  const name = useAppSelector(({productionCost}) => productionCost.name);
  const costYear = useAppSelector(
    ({productionCost}) => productionCost.costYear
  );

  const dispatch = useAppDispatch();

  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarStartDate, setCalendarStartDate] = React.useState(
    productionCostStartDate
  );

  const statusOptions = useMemo(
    () =>
      productionCostStatuses.map(({key, name}) => (
        <SelectOption key={key} value={key}>
          {name}
        </SelectOption>
      )),
    []
  );

  const yearOptions = useMemo(() => {
    const result: ReactElement[] = [];

    for (
      let year = productionCostStartYear;
      year <= productionCostEndYear;
      year++
    ) {
      const selectOption = (
        <SelectOption key={year} value={year}>
          {year}
        </SelectOption>
      );
      result.push(selectOption);
    }

    return result;
  }, []);

  const planningPeriodValue = useMemo(
    () => getPlanningPeriodDate(startDate, endDate),
    [startDate, endDate]
  );

  const handleEvent = useCallback(
    e => {
      if (e.type === 'click' || e.key === 'Escape') setShowCalendar(false);
    },
    [setShowCalendar]
  );
  useDocumentEvent([
    {type: 'click', callback: handleEvent},
    {type: 'keydown', callback: handleEvent}
  ]);

  const handleUpdate = useCallback(
    (payload: Partial<ProductionCostState>) => {
      dispatch(productionCostBaseUpdate(payload));
    },
    [dispatch]
  );

  const handleDateSelect = useCallback(
    date => {
      // date range is not selected yet
      const isFirstSelection = startDate === null;

      // date range is already selected and user wants to change the start date
      const isConsecutiveSelection = endDate !== null;

      if (isFirstSelection || isConsecutiveSelection) {
        handleUpdate({
          startDate: date.toISOString(),
          endDate: null
        });
        setCalendarStartDate(date);
      } else {
        handleUpdate({endDate: date.toISOString()});
        setCalendarStartDate(productionCostStartDate);
        setShowCalendar(false);
      }
    },
    [startDate, endDate, handleUpdate]
  );

  return (
    <div className={styles.container} onClick={e => e.stopPropagation()}>
      <div className={styles.planningPeriod}>
        <Input
          iconLeft={
            <Icon InlineIcon={IconCalendarSolid} aria-label={'Calendar icon'} />
          }
          label="Planning period*"
          placeholder="10.12.2020 - 23.01.2021"
          id="planning-period-input"
          onFocus={() => setShowCalendar(true)}
          value={planningPeriodValue}
          readOnly
        />

        <Calendar
          appearance={'birthday'}
          hidden={!showCalendar}
          extraClasses={'planning-period-calendar'}
          id="planning-period-calender"
          startDate={calendarStartDate}
          endDate={productionCostEndDate}
          defaultMonth={startDate === null ? new Date() : new Date(startDate)}
          startSelected={startDate === null ? undefined : new Date(startDate)}
          endSelected={endDate === null ? undefined : new Date(endDate)}
          handleSelect={handleDateSelect}
        />
      </div>

      <div className={styles.calculationStatus}>
        <Select
          id="calculation-status"
          label="Calculation status*"
          placeholder="Select a Status"
          value={status ?? ''}
          onChange={e =>
            handleUpdate({
              status: e.target.value as ProductionCostStatus
            })
          }
        >
          {statusOptions}
        </Select>
      </div>

      <div className={styles.calculationName}>
        <Input
          label="Calculation name*"
          id="calculation-name"
          value={name ?? ''}
          onChange={e => handleUpdate({name: e.target.value})}
        />
      </div>

      <div className={styles.year}>
        <Select
          id="year-of-cost-information"
          label="Year of cost information*"
          placeholder="Select a Year"
          value={costYear ?? ''}
          onChange={e => handleUpdate({costYear: Number(e.target.value)})}
        >
          {yearOptions}
        </Select>
      </div>
    </div>
  );
};

export default React.memo(ProductionCostHeaderEditable);
