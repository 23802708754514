import {gql} from '@apollo/client';

const GET_PRODUCTION_COST_ANALYTICS_VIEW_SUBTOTAL_ON_SELECTION = gql`
  query productionCostAnalyticsView(
    $productionCostAnalyticsViewEntryIds: [ProductionCostAnalyticsViewEntryId]
  ) {
    productionCostAnalyticsView(
      queryParams: {
        productionCostAnalyticsViewEntryIds: $productionCostAnalyticsViewEntryIds
      }
    ) {
      subtotals {
        totalKms
        totalProductionCosts
        totalProductionCostsPerKm
        leasingCostsPerKm
        interestCostsPerKm
        insuranceCostsPerKm
        driverSalaryCostsPerKm
        accommodationCostsPerKm
        dailyAllowanceCostsPerKm
        driverChangeCostsPerKm
        dieselCostsPerKm
        adblueCostsPerKm
        lngCostsPerKm
        totalFuelCostsPerKm
        maintenanceCostsPerKm
        tiresCostsPerKm
        cleaningCostsPerKm
        overheadCostsPerKm
        flixStandardCostsPerKm
        legalCostsPerKm
        damageRepairCostsPerKm
        motorVehicleTaxCostsPerKm
        otherCostsPerKm
        infrastructureCostsPerKm
        currency
      }
      absoluteValuesSubtotals {
        totalKms
        totalProductionCosts
        totalProductionCosts
        leasingCosts
        interestCosts
        insuranceCosts
        driverSalaryCosts
        accommodationCosts
        dailyAllowanceCosts
        driverChangeCosts
        dieselCosts
        adblueCosts
        lngCosts
        totalFuelCosts
        maintenanceCosts
        tiresCosts
        cleaningCosts
        overheadCosts
        flixStandardCosts
        legalCosts
        damageRepairCosts
        motorVehicleTaxCosts
        otherCosts
        infrastructureCosts
        currency
      }
    }
  }
`;

export default GET_PRODUCTION_COST_ANALYTICS_VIEW_SUBTOTAL_ON_SELECTION;
