import React, {useEffect} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {Infobox} from '@flixbus/honeycomb-react';
import {isNil} from 'ramda';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {getVehicleTypes} from 'Pages/VehicleTypes/store/thunks';
import {useBusPartner} from 'Pages/PartnerCosts/hooks/useBusPartner';
import PartnerCostsVehicleCostContent from './PartnerCostsVehicleCostContent/PartnerCostsVehicleCostContent';
import {selectBusPartnerCountry} from 'Pages/PartnerCosts/store/slice';

const PartnerCostsVehicleCostTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const year = useAppSelector(({partnerCosts}) => partnerCosts.year);
  const partnerId = useAppSelector(({partnerCosts}) => partnerCosts.partnerId);
  const partner = useBusPartner(partnerId);
  const partnerCountry = useAppSelector(selectBusPartnerCountry);

  const partnerVehicleCost = useAppSelector(
    ({partnerCosts}) => partnerCosts.vehicleCost
  );
  const regionalVehicleCost = useAppSelector(
    ({partnerCosts}) => partnerCosts.countryCost?.vehicleCost
  );

  const vehicleTypes = useAppSelector(({vehicleTypes}) => vehicleTypes.list);
  const partnerVehicleTypeCosts = useAppSelector(
    ({partnerCosts}) => partnerCosts.vehicleTypeCosts.list
  );
  const regionalVehicleTypeCosts = useAppSelector(
    ({partnerCosts}) => partnerCosts.countryCost?.vehicleTypeCosts
  );

  useEffect(() => {
    if (isNil(vehicleTypes)) {
      dispatch(getVehicleTypes());
    }
  }, [vehicleTypes, partnerId, year, dispatch]);

  return partnerId === null ||
    year === null ||
    partnerVehicleCost === null ||
    partnerVehicleTypeCosts === null ||
    partnerCountry === null ||
    vehicleTypes === null ? (
    <Infobox appearance="warning">
      <p>
        Select a <strong>partner</strong> and a <strong>calendar year</strong>
      </p>
    </Infobox>
  ) : (
    <PartnerCostsVehicleCostContent
      partnerId={partnerId}
      year={year}
      partnerVehicleCost={partnerVehicleCost}
      regionalVehicleCost={regionalVehicleCost}
      vehicleTypes={vehicleTypes}
      partnerVehicleTypeCosts={partnerVehicleTypeCosts}
      regionalVehicleTypeCosts={regionalVehicleTypeCosts}
      currency={partner?.currency ?? ''}
      partnerCountry={partnerCountry}
    />
  );
};

export default React.memo(PartnerCostsVehicleCostTab);
