import {gql} from '@apollo/client';

const UPDATE_PARTNER_VEHICLE_COST = gql`
  mutation updateBusPartnerBusCostEntry(
    $partnerId: ID!
    $year: Int!
    $dieselCost: FuelCost
    $adblueCost: FuelCost
    $lngCost: Float
    $tiresCost: TiresCost
  ) {
    updateBusPartnerBusCostEntry(
      input: {
        busPartnerId: $partnerId
        year: $year
        dieselCost: $dieselCost
        adblueCost: $adblueCost
        lngCost: $lngCost
        tiresCost: $tiresCost
      }
    ) {
      year
      dieselCost
      adblueCost
      lngCost
      tiresCost
    }
  }
`;

export default UPDATE_PARTNER_VEHICLE_COST;
