import React, {useCallback, useMemo} from 'react';
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  Checkbox,
  Heading,
  Spinner
} from '@flixbus/honeycomb-react';
import {getPlanningPeriodDate} from 'Pages/ProductionCost/utils/formatePlanningPeriodDate';
import {
  planrScheduleTypes,
  productionCostLineStatuses
} from 'Pages/ProductionCost/constants/statuses';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateLineSchedulesSelection} from 'Pages/ProductionCost/store/slice';
import {LineSchedulesSelectTableProps} from './LineSchedulesSelectTable.types';
import styles from './LineSchedulesSelectTable.module.scss';
import {getOverlappedSchedules} from './LineSchedulesSelectTable.utils';
import PlanrScheduleLink from './PlanrScheduleLink';

const LineSchedulesSelectTable: React.FC<LineSchedulesSelectTableProps> = ({
  lineSchedule,
  lineScheduleKey,
  isSchedulesLoading,
  schedulesListPerDay
}) => {
  const dispatch = useAppDispatch();

  const dataTableKey = `datatable-key-${lineScheduleKey}-${lineSchedule.lineUuid}-${lineSchedule.scheduleStatus}-${lineSchedule.scheduleType}`;

  const overlappedSchedules = useMemo(
    () => getOverlappedSchedules(schedulesListPerDay),
    [schedulesListPerDay]
  );

  const areAllSelected = useMemo(
    () => lineSchedule.schedules.every(schedule => schedule.selected),
    [lineSchedule.schedules]
  );

  const handleSelectAll = useCallback(() => {
    const updatedSelection = lineSchedule.schedules.map(schedule => ({
      value: schedule.uuid,
      selected: !areAllSelected
    }));

    dispatch(
      updateLineSchedulesSelection({
        lineScheduleKey,
        selection: updatedSelection
      })
    );
  }, [lineSchedule.schedules, lineScheduleKey, areAllSelected, dispatch]);

  const handleSelectOne = useCallback(
    event => {
      const {checked, value} = event.target;

      const updatedSelection = lineSchedule.schedules.map(schedule => ({
        value: schedule.uuid,
        selected: value === schedule.uuid ? checked : schedule.selected
      }));

      dispatch(
        updateLineSchedulesSelection({
          lineScheduleKey,
          selection: updatedSelection
        })
      );
    },
    [lineSchedule.schedules, lineScheduleKey, dispatch]
  );

  const tableHeader = useMemo(
    () => (
      <DataTableHead
        checkbox={
          <Checkbox
            id={`select-all-line-schedules-checkbox-${lineScheduleKey}`}
            value="select-all"
            checked={areAllSelected}
            onChange={handleSelectAll}
            small
            aria-label="Select all schedules"
          />
        }
      >
        <th key="name" scope="col">
          Name
        </th>
        <th key="description" scope="col">
          Description
        </th>
        <th key="schedulePeriod" scope="col">
          Schedule period
        </th>
        <th key="scheduleType" scope="col">
          Schedule type
        </th>
        <th key="planrScheduleStatus" scope="col">
          PlanR schedule status
        </th>
      </DataTableHead>
    ),
    [areAllSelected, handleSelectAll, lineScheduleKey]
  );

  const tableRows = useMemo(
    () =>
      lineSchedule.schedules.map(schedule => {
        const checkbox = (
          <Checkbox
            id={`select-${schedule.uuid}-schedule-checkbox`}
            value={schedule.uuid}
            checked={schedule.selected ?? false}
            onChange={handleSelectOne}
            aria-label={`Select ${schedule.uuid} schedule`}
            small
          />
        );

        const hasOverlap = overlappedSchedules.has(schedule.uuid);
        const name = (
          <PlanrScheduleLink schedule={schedule} hasOverlap={hasOverlap} />
        );

        return (
          <DataTableRow key={schedule.uuid} checkbox={checkbox}>
            <td>{name}</td>
            <td>{schedule.description}</td>
            <td>
              {getPlanningPeriodDate(schedule.startDate, schedule.endDate)}
            </td>
            <td>
              {planrScheduleTypes.find(type => type.key === schedule.type)
                ?.name ?? ''}
            </td>
            <td>
              {productionCostLineStatuses.find(
                status => status.key === schedule.status
              )?.name ?? ''}
            </td>
          </DataTableRow>
        );
      }),
    [lineSchedule.schedules, overlappedSchedules, handleSelectOne]
  );

  return (
    <>
      {tableRows.length > 0 ? (
        <DataTable
          extraClasses="hcr-space-4-top"
          key={dataTableKey}
          headers={tableHeader}
        >
          {tableRows}
        </DataTable>
      ) : (
        <div className={styles.noRecords}>
          {isSchedulesLoading ? (
            <div>
              <Spinner size="lg" aria-label="Spinner" />
              <div>Loading......</div>
            </div>
          ) : lineSchedule.lineNumber && tableRows.length === 0 ? (
            <Heading size={3}>
              No schedules available for the given period, line and schedule
              status/type.
            </Heading>
          ) : null}
        </div>
      )}
    </>
  );
};
export default React.memo(LineSchedulesSelectTable);
