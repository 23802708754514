// Transforms a date string from ISO 8601 format (YYYY-MM-DD) to a custom format (DD.MM.YYYY).
export function transformDate(dateString: string): string {
  // Split the input date string into components
  const [year, month, day] = dateString.split('-');

  // Check if the input date string is valid
  if (!year || !month || !day) {
    throw new Error('Invalid date format. Expected format is YYYY-MM-DD.');
  }

  // Return the reformatted date
  return `${day}.${month}.${year}`;
}
