import {gql} from '@apollo/client';

const UPDATE_COUNTRY_OTHER_COST = gql`
  mutation updateCountryOtherCostEntry(
    $cleaningCost: Float
    $countryId: ID!
    $legalCost: Float
    $otherCost: Float
    $overheadCost: Float
    $repairCostPerDistanceUnit: CostPerDistance
    $repairCostPerMonth: Float
    $year: Int!
  ) {
    updateCountryOtherCostEntry(
      input: {
        cleaningCost: $cleaningCost
        countryId: $countryId
        legalCost: $legalCost
        otherCost: $otherCost
        overheadCost: $overheadCost
        repairCostPerDistanceUnit: $repairCostPerDistanceUnit
        repairCostPerMonth: $repairCostPerMonth
        year: $year
      }
    ) {
      cleaningCost
      currency
      legalCost
      otherCost
      overheadCost
      repairCostPerDistanceUnit
      repairCostPerMonth
      year
    }
  }
`;

export default UPDATE_COUNTRY_OTHER_COST;
