import React, {useCallback} from 'react';
import {Checkbox, Spinner, Text} from '@flixbus/honeycomb-react';
import useBusPartnersWithVehicles from '../../hooks/useBusPartnersWithVehicles';
import {VehicleFilterProps} from './VehiclesFilter.types';
import baseStyles from '../index.module.scss';
import styles from './VehiclesFilter.module.scss';

const VehicleFilter: React.FC<VehicleFilterProps> = ({
  selectedVehicleIds,
  setSelectedVehicleIds
}) => {
  const busPartnersWithVehicles = useBusPartnersWithVehicles();

  const handleVehicleSelect = useCallback(
    vehicle => {
      const wasSelected = selectedVehicleIds.includes(vehicle.id);
      const updatedSelectedVehicleIds = wasSelected
        ? selectedVehicleIds.filter(id => id !== vehicle.id)
        : [...selectedVehicleIds, vehicle.id];

      setSelectedVehicleIds(updatedSelectedVehicleIds);
    },
    [selectedVehicleIds, setSelectedVehicleIds]
  );

  if (busPartnersWithVehicles.length === 0)
    return <Spinner size="md" aria-label="Spinner" />;

  return (
    <div className={baseStyles.accordionContent} data-testid="vehicles-filter">
      {busPartnersWithVehicles.map(partner => (
        <div
          className={baseStyles.partnerDetails}
          key={partner.busPartnerBackendId}
          data-testid={`partner-details-${partner.busPartnerBackendId}`}
        >
          <Text small className={baseStyles.noMargin}>
            <b>{partner.busPartnerName}</b>
          </Text>

          <div className={styles.vehicles}>
            {partner.vehicles.map(vehicle => (
              <div key={vehicle.id}>
                <Checkbox
                  label={
                    <Text small className={styles.vehicleName}>
                      {vehicle.name}
                    </Text>
                  }
                  id={`vehicles-filter-${vehicle.id}-select`}
                  value={vehicle.id}
                  checked={selectedVehicleIds.includes(vehicle.id)}
                  onChange={() => handleVehicleSelect(vehicle)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(VehicleFilter);
