import React, {useCallback, useEffect} from 'react';
import {Grid, GridCol} from '@flixbus/honeycomb-react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {
  selectVehicleEntryIdsByVehicleId,
  updateBusValues,
  updateVehicleEntryByIds
} from 'Pages/ProductionCost/store/slice';
import {
  ProductionCostBusProvidedValuesEntry,
  ProductionCostBusScheduleProvidedValueEntry
} from 'Pages/ProductionCost/store/types';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import useValidateBusValues from '../../hooks/useValidateBusValues';
import {LineVehicleProps} from './LineVehicle.types';
import LineVehicleId from './LineVehicleId/LineVehicleId';
import PartnerName from './PartnerName/PartnerName';
import VehicleType from './VehicleType/VehicleType';
import CountryRules from './CountryRules/CountryRules';
import style from './LineVehicle.module.scss';

const LineVehicle: React.FC<LineVehicleProps> = ({vehicle, errors}) => {
  const entryIds = useAppSelector(({productionCost}) =>
    selectVehicleEntryIdsByVehicleId(productionCost, {
      vehicleId: vehicle.vehicleId
    })
  );

  const dispatch = useAppDispatch();

  const handleUpdateBusValues = useCallback(
    (updates: Partial<ProductionCostBusProvidedValuesEntry>) => {
      dispatch(updateBusValues({vehicleId: vehicle.vehicleId, updates}));
    },
    [vehicle.vehicleId, dispatch]
  );

  const handleUpdateBusScheduleValues = useCallback(
    (updates: Partial<ProductionCostBusScheduleProvidedValueEntry>) => {
      dispatch(
        updateVehicleEntryByIds({
          entryIds,
          updates
        })
      );
    },
    [entryIds, dispatch]
  );

  // trigger validation on mount and change
  const validateBusValues = useValidateBusValues();
  useEffect(() => validateBusValues(vehicle), [validateBusValues, vehicle]);

  if (!vehicle) return null;

  return (
    <div
      className={style.container}
      data-testid={`line-vehicle-container${vehicle.vehicleId}`}
    >
      <Grid gutterSize="2">
        <GridCol>
          <LineVehicleId vehicleName={vehicle.vehicleName} />
        </GridCol>
        <GridCol>
          <PartnerName
            vehicleId={vehicle.vehicleId}
            busPartnerId={vehicle.busPartnerId}
            entryIds={entryIds}
            errors={errors?.busPartnerId}
            onUpdateBusValues={handleUpdateBusValues}
            onUpdateBusScheduleValues={handleUpdateBusScheduleValues}
          />
        </GridCol>
        <GridCol>
          <VehicleType
            vehicleId={vehicle.vehicleId}
            busPartnerId={vehicle.busPartnerId}
            busPartnerBusTypeCostEntryId={vehicle.busPartnerBusTypeCostEntryId}
            countryBusTypeCostEntryId={vehicle.countryBusTypeCostEntryId}
            errors={errors?.busTypeCostEntryId}
            onUpdateBusValues={handleUpdateBusValues}
          />
        </GridCol>
        <GridCol>
          <CountryRules
            vehicleId={vehicle.vehicleId}
            busPartnerId={vehicle.busPartnerId}
            countryRulesCountryId={vehicle.countryRulesCountryId}
            errors={errors?.countryRulesCountryId}
            onUpdateVehicle={handleUpdateBusValues}
            onUpdateBusScheduleValues={handleUpdateBusScheduleValues}
          />
        </GridCol>
      </Grid>
    </div>
  );
};

export default React.memo(LineVehicle);
