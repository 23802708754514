import React from 'react';
import {
  Button,
  ButtonGroup,
  Popup,
  PopupSection
} from '@flixbus/honeycomb-react';
import {Icon} from '@flixbus/honeycomb-icons-react';
import PropTypes from 'prop-types';
import {confirmable} from 'react-confirm';

const ConfirmPopup = ({
  show,
  proceed,
  PopupIcon,
  title,
  message,
  acceptBtnText,
  cancelBtnText
}) => (
  <Popup
    extraClasses={`cpc-confirm-popup-overlay`}
    active={show}
    onOverlayClick={() => proceed(false)}
  >
    {PopupIcon && (
      <PopupSection type="icon" extraClasses="my-class">
        <Icon InlineIcon={PopupIcon} size={6} />
      </PopupSection>
    )}
    <PopupSection type="title">{title}</PopupSection>
    <PopupSection type="content">{message}</PopupSection>
    <PopupSection type="actions">
      <ButtonGroup alignment="center">
        <Button appearance={'primary'} onClick={() => proceed(true)}>
          {acceptBtnText ?? 'Yes'}
        </Button>
        <Button appearance="secondary" onClick={() => proceed(false)}>
          {cancelBtnText ?? 'No'}
        </Button>
      </ButtonGroup>
    </PopupSection>
  </Popup>
);

ConfirmPopup.propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  PopupIcon: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  acceptBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string
};

export default confirmable(ConfirmPopup);
