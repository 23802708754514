import {DriverCostCalculationType} from '../store/types';

const DriverCostCalculationTypes = Object.freeze({
  DRIVER_SCHEDULE_HOURS: {
    value: DriverCostCalculationType.DriverScheduleHours,
    label: 'Schedule Hours'
  },
  DRIVER_COST_OVERWRITE: {
    value: DriverCostCalculationType.DriverCostOverwrite,
    label: 'Overwrite'
  },
  DRIVER_SALARY: {
    value: DriverCostCalculationType.DriverSalary,
    label: 'Italian'
  },
  DRIVER_SHIFTS: {
    value: DriverCostCalculationType.DriverShifts,
    label: 'Planr Driver Shifts'
  }
});

export default DriverCostCalculationTypes;
