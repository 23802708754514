import {useEffect, useState} from 'react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {ProductionCostBus} from 'Pages/ProductionCost/store/types';

export const useProductionCostBus = (
  vehicleId: string | null
): ProductionCostBus | null => {
  const productionCostBuses = useAppSelector(
    ({productionCost}) => productionCost.productionCostBuses
  );

  const [productionCostBus, setProductionCostBus] =
    useState<ProductionCostBus | null>(null);

  useEffect(() => {
    const nextProductionCostBus = productionCostBuses?.find(
      bus => bus.vehicleId === vehicleId
    );
    if (productionCostBus?.vehicleId !== nextProductionCostBus?.vehicleId) {
      setProductionCostBus(nextProductionCostBus ?? null);
    }
  }, [vehicleId, productionCostBus?.vehicleId, productionCostBuses]);

  return productionCostBus;
};
