import {
  Button,
  Heading,
  Popup,
  PopupSection,
  Text
} from '@flixbus/honeycomb-react';
import {isNil} from 'ramda';
import React from 'react';
import {connect} from 'react-redux';
import ViewInputCostFactorTable from './ViewInputCostFactorTable';
import DriverCostCalculationTypes from '../../../ProductionCost/constants/driverCostCalculationTypes';

const ViewInputCostFactorPopup = ({setEntryToView, entryToView, state}) => {
  const getPartnerName = busPartnerId => {
    const partner = state.partners?.list?.find(
      partner => partner.id === busPartnerId
    );
    return isNil(partner) ? '' : partner.name;
  };

  const getBusPartnerCountry = busPartnerId => {
    const partner = state.partners?.list?.find(
      partner => partner.id === busPartnerId
    );

    return state.countries?.list?.find(
      country => country.id === partner.countryId
    );
  };

  return (
    entryToView && (
      <Popup
        id="view-entry-popup"
        active={!isNil(entryToView)}
        onOverlayClick={() => setEntryToView(null)}
        extraClasses={`popup-table`}
      >
        <PopupSection type="title">
          <Heading size={3}>{entryToView.productionCostEntryName}</Heading>
          <Text>
            <span style={{fontWeight: 'bold'}}>Line: </span>{' '}
            {entryToView.lineNumber} &nbsp; &nbsp;
            <span style={{fontWeight: 'bold'}}>Partner: </span>
            {getPartnerName(entryToView.busPartnerId)} &nbsp; &nbsp;
            <span style={{fontWeight: 'bold'}}>Currency: </span>{' '}
            {entryToView.currency} &nbsp; &nbsp;
            <span style={{fontWeight: 'bold'}}>
              Driver Calculation Type:{' '}
            </span>{' '}
            {
              DriverCostCalculationTypes[entryToView?.driverCostCalculationType]
                ?.label
            }
          </Text>
        </PopupSection>
        <PopupSection type="content">
          <ViewInputCostFactorTable
            productionCostEntryId={entryToView.productionCostEntryId}
            productionCostLineId={entryToView.productionCostLineId}
            busPartnerId={entryToView.busPartnerId}
            busPartnerCountry={getBusPartnerCountry(entryToView.busPartnerId)}
          />
        </PopupSection>
        <PopupSection type="actions">
          <Button appearance="primary" onClick={() => setEntryToView(null)}>
            Close
          </Button>
        </PopupSection>
      </Popup>
    )
  );
};

export default connect(
  () => state => ({
    state: state
  }),
  undefined
)(ViewInputCostFactorPopup);
