import React, {useEffect} from 'react';
import {Spinner, Text, Fieldset} from '@flixbus/honeycomb-react';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import LineSchedulesSelect from './LineSchedulesSelect/LineSchedulesSelect';
import {fetchPlanrLines} from '../../store/thunks';
import {useDispatch, useSelector} from 'react-redux';
import LineSchedulesFooter from './LineSchedulesFooter';
import ProductionCostHeader from '../ProductionCostHeader';
import {ProductionCostHeaderStepOption} from '../ProductionCostHeader/ProductionCostHeaderSteps/ProductionCostHeaderSteps.types';
import styles from './index.module.scss';

const ProductionCostStepOne = () => {
  const dispatch = useDispatch();
  const lineSchedules = useSelector(
    state => state.productionCost.lineSchedules
  );
  const loading = useSelector(state => state.productionCost.loading);
  const serverErrors = useSelector(state => state.productionCost.serverErrors);
  useEffect(() => {
    dispatch(fetchPlanrLines());
  }, [dispatch]);

  const renderLoader = () => (
    <div className="spinner-container">
      <Spinner />
    </div>
  );
  const renderErrorMessage = () => (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <Fieldset key="production-cost-errors-fieldset">
        {serverErrors?.importProductionCostPlanrData?.map(error => (
          <Text
            key={`production-cost-errors-${error.message}`}
            style={{color: 'red'}}
          >
            {error.message}
          </Text>
        ))}
      </Fieldset>
    </div>
  );

  return (
    <>
      {loading && renderLoader()}
      {!loading && (
        <>
          <div className={styles.header}>
            <ProductionCostHeader
              mode={CalculationUserJourneyMode.CreateCalculation}
              step={ProductionCostHeaderStepOption.SelectSchedules}
              editable
            />
          </div>

          {!loading &&
            serverErrors?.importProductionCostPlanrData &&
            renderErrorMessage()}

          <div className={styles.schedules}>
            {lineSchedules.map(lineSchedule => (
              <LineSchedulesSelect
                key={`line-schedule-${lineSchedule.lineScheduleKey}`}
                lineScheduleKey={lineSchedule.lineScheduleKey}
                lineSchedule={lineSchedule}
              />
            ))}
          </div>

          <LineSchedulesFooter />
        </>
      )}
    </>
  );
};

export default React.memo(ProductionCostStepOne);
