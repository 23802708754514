import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import qualityCheckCountryCostEntryYearMutation from '../graphql/qualityCheckCountryCostEntryYearMutation';
import {qualityCheckedCountryCostEntryYear} from '../store/slice';

interface UpdateQualityCheckMutationVars {
  countryId: string;
  year: number;
  qualityChecked: boolean;
  withValidation: boolean;
}

interface UpdateQualityCheckMutationResponse {
  qualityCheckCountryCostEntryYear: {
    year: number;
    qualityChecked: boolean;
    qualityCheckedLastEditedAt: string;
  };
}

export const useUpdateCountryQualityCheck = (
  ignoreValidationConfirmation: () => Promise<boolean>
) => {
  const dispatch = useDispatch();
  const countryId = useAppSelector(({regionalCosts}) => regionalCosts.country);
  const year = useAppSelector(({regionalCosts}) => regionalCosts.year);
  const [qualityChecked, setQualityChecked] = useState<boolean | null>(null);

  const [updateQualityCheckWithValidation] =
    useMutation<UpdateQualityCheckMutationResponse>(
      qualityCheckCountryCostEntryYearMutation,
      {
        variables: {
          countryId,
          year,
          qualityChecked,
          withValidation: true
        } as Partial<UpdateQualityCheckMutationVars>,
        onCompleted: ({qualityCheckCountryCostEntryYear}) => {
          updateStore(qualityCheckCountryCostEntryYear);
        },
        onError: async error => {
          const onlyValidationErrors = error.graphQLErrors.every(
            ({extensions}) =>
              extensions?.errorType === 'InvalidCountryBusTypeCostDataError'
          );

          if (onlyValidationErrors) {
            const confirmed = await ignoreValidationConfirmation();

            if (confirmed)
              updateQualityCheckWithoutValidation({
                variables: {qualityChecked}
              });
          } else {
            console.error('Error while updating quality check', error);
          }
        }
      }
    );

  const [updateQualityCheckWithoutValidation] =
    useMutation<UpdateQualityCheckMutationResponse>(
      qualityCheckCountryCostEntryYearMutation,
      {
        variables: {
          countryId,
          year,
          qualityChecked,
          withValidation: false
        } as Partial<UpdateQualityCheckMutationVars>,
        onCompleted: ({qualityCheckCountryCostEntryYear}) => {
          updateStore(qualityCheckCountryCostEntryYear);
        },
        onError: error => {
          console.error(
            'Error while updating quality check without validation',
            error
          );
        }
      }
    );

  const updateStore = useCallback(
    qualityCheckResult =>
      dispatch(qualityCheckedCountryCostEntryYear(qualityCheckResult)),
    [dispatch]
  );

  const updateQualityCheck = useCallback(
    (qualityChecked: boolean) => {
      setQualityChecked(qualityChecked);
      updateQualityCheckWithValidation({variables: {qualityChecked}});
    },
    [setQualityChecked, updateQualityCheckWithValidation]
  );

  return {updateQualityCheck};
};
