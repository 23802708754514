import {PartnerCostsDriverCost} from 'Pages/PartnerCosts/store/types';
import {
  CountryDriverConceptType,
  RegionalCostsDriverCost
} from 'Pages/RegionalCosts/store/types';
import {
  BusInputValidationErrorExtensions,
  InvalidProductionCostBusScheduleCostFactorErrorExtensions,
  InvalidProductionCostBusScheduleProvidedValueErrorExtensions,
  ProductionCostServerError,
  ScheduleInputValidationErrorExtensions
} from '../components/types';

export interface ProductionCostState {
  // Basic form state
  id: string | null;
  loading: boolean;
  released: boolean | null;
  calculationUserJourneyMode: CalculationUserJourneyMode;
  validationErrors: ProductionCostValidationErrors;
  serverErrors: ProductionCostServerErrors;
  calculatedProductionCosts: CalculatedProductionCost | null;

  // DIS1
  name: string | null;
  startDate: string | null;
  endDate: string | null;
  status: ProductionCostStatus | null;
  costYear: number | null;
  lineSchedules: PlanrLineSchedule[];

  filterType: ProductionCostFilterType;
  filteredPlanrBusPartnerIds: string[];
  filteredPlanrVehicleIds: string[];

  // DIS2
  driverCostCalculationType: DriverCostCalculationType;
  leasingCostCalculationType: LeasingCostCalculationType;
  driverShiftsCalculationTypeAllowed: boolean;
  busValues: ProductionCostBusProvidedValuesEntry[] | null;
  busScheduleValues: ProductionCostScheduleWithProvidedValues[] | null;

  // Data
  lines: PlanrLine[];
  planrBusPartners: PlanrBusPartner[];
  planrVehicles: PlanrVehicle[];
  planrDataVersion: string; // "1" - rides-based calculation, otherwise - trips-based calculation
  countryPrefilledEmptyValues: {
    [countryId: string]: CountryPrefilledEmptyValues;
  };
  countryDriverConceptTypes: {[countryId: string]: CountryDriverConceptType[]};
  busPartnerAndCountryDriverCostEntry: {
    [id: string]: {
      busPartnerDriverCostEntry: PartnerCostsDriverCost;
      countryDriverCostEntry: RegionalCostsDriverCost;
    };
  };
  productionCostBuses: ProductionCostBus[] | null;
}

export interface PlanrLine {
  uuid: string;
  number?: string;
  description?: string;
}

export interface PlanrLineSchedule {
  lineScheduleKey: number;
  isValid: boolean;
  toggle: boolean;
  lineNumber: string | null;
  lineUuid: string | null;
  scheduleStatus: PlanrScheduleStatus | null;
  scheduleType: PlanrScheduleType | null;
  schedules: PlanrSchedule[];
  productionCostBuses: ProductionCostBus[] | null;
}

export interface PlanrSchedule {
  uuid: string;
  lineUuid: string;
  name: string;
  startDate: string;
  endDate: string;
  status: PlanrScheduleStatus;
  type: PlanrScheduleType;
  description?: string;
  selected?: boolean;
}

export type CountryPrefilledEmptyValues = {
  prefilledEmptyHr?: number;
  prefilledEmptyKm?: number;
};

export interface ProductionCostBusProvidedValuesEntry {
  busPartnerBusTypeCostEntryId?: string;
  busPartnerId?: string;
  countryBusTypeCostEntryId?: string;
  countryRulesCountryId?: string;
  vehicleId: string;
  vehicleName?: string;
}

export interface ProductionCostScheduleWithProvidedValues {
  scheduleId: string;
  startDate: string;
  endDate: string;
  lineNumber: string;
  name?: string;
  description?: string;
  size?: string;
  busScheduleProvidedValuesEntries: ProductionCostBusScheduleProvidedValueEntry[];
  isValid: boolean;
}

export interface ProductionCostBusScheduleProvidedValueEntry {
  id: string;
  vehicleId: string;
  emptyKmPerDay?: number;
  emptyHrPerDay?: number;
  countryDriverConceptTypeId?: string;
  salaryChange?: number;
  accommodationsPerWeek?: number;
  accommodationCostPerNight?: number;
  replacementKmPerDay?: number;
  replacementHrPerDay?: number;
  additionalCostPerTrip?: number;
  totalDriverCostOverwrite?: number;
  numberOfDrivers?: number;
  driverSalary?: number;
  busUtilizationFactor?: number;
  flixStandardCostOverwrite?: number;
  isValid: boolean;
  currency?: string;
  avgDriverFactor?: number;
  avgWeeklyOpDays?: number;
  avgWeeklyScheduleHours?: number;
  avgWeeklyWorkingHours?: number;
  dailyAllowance?: number;
}

export interface CalculatedProductionCost {
  busCostPerKm?: number;
  busName: string;
  busPartnerId: string;
  busVehicleId: string;
  currency: string;
  lineId: string;
  lineNumber: string;
  lineWeightFactor: number;
  numOperatingDays: number;
  numSchedules: number;
  totalAdBlueCostPerKm?: number;
  totalCostPerKm?: number;
  totalDieselCostPerKm?: number;
  totalDriverSalaryCostPerKm?: number;
  totalLngCostPerKm?: number;
  totalOtherCostPerKm?: number;
  weeklyDaytimeWeekdayScheduleHours: number;
  weeklyDaytimeWeekendScheduleHours: number;
  weeklyNighttimeWeekdayScheduleHours: number;
  weeklyNighttimeWeekendScheduleHours: number;
  weeklyTotalKm: number;
  weeklyTotalScheduleHours: number;
  weeklyTotalWorkingHours?: number;
}

export interface PlanrBusPartner {
  busPartnerBackendId: string;
  busPartnerName?: string;
  busPartnerShortName?: string;
}

export interface PlanrVehicle {
  id: string;
  name: string;
  planrBusPartner: PlanrBusPartner;
}

export interface ProductionCostBus {
  busPartnerName?: string;
  name: string;
  vehicleId: string;
}

export enum ProductionCostStatus {
  InProduction = 'IN_PRODUCTION',
  Draft = 'DRAFT',
  Planned = 'PLANNED'
}

export enum PlanrScheduleStatus {
  Archived = 'ARCHIVED',
  ConceptChange = 'CONCEPT_CHANGE',
  Draft = 'DRAFT',
  Final = 'FINAL',
  InGeneration = 'IN_GENERATION',
  Planned = 'PLANNED',
  Published = 'PUBLISHED'
}

export enum PlanrScheduleType {
  Holiday = 'HOLIDAY',
  Regular = 'REGULAR'
}

export enum DriverCostCalculationType {
  DriverScheduleHours = 'DRIVER_SCHEDULE_HOURS',
  DriverCostOverwrite = 'DRIVER_COST_OVERWRITE',
  DriverSalary = 'DRIVER_SALARY',
  DriverShifts = 'DRIVER_SHIFTS'
}

export enum LeasingCostCalculationType {
  Standard = 'STANDARD',
  FixedSplit30To70 = 'FIXED_SPLIT_30_70',
  FixedSplit25To75 = 'FIXED_SPLIT_25_75'
}

export enum CalculationUserJourneyMode {
  CreateCalculation = 1,
  EditCalculation = 2,
  EditJustCreatedCalculation = 3
}

export enum ProductionCostFilterType {
  AllVehicles = 'ALL_VEHICLES',
  PlanrBusPartners = 'PLANR_BUS_PARTNERS',
  PlanrVehicles = 'PLANR_VEHICLES'
}

// Error types
export interface ProductionCostValidationErrors {
  busValues: ProductionCostBusValuesErrors;
  busScheduleValues: ProductionCostBusScheduleValuesErrors;
}

export interface ProductionCostBusValuesErrors {
  [vehicleId: string]: ProductionCostBusProvidedValuesEntryErrors;
}

export interface ProductionCostBusScheduleValuesErrors {
  [vehicleEntryId: string]:
    | ProductionCostBusScheduleProvidedValueEntryErrors
    | undefined;
}

export type ProductionCostBusProvidedValuesEntryErrors = {
  busPartnerId?: ProductionCostFormControlErrors;
  busTypeCostEntryId?: ProductionCostFormControlErrors;
  countryRulesCountryId?: ProductionCostFormControlErrors;
};

export type ProductionCostBusScheduleProvidedValueEntryErrors = Partial<
  Record<
    keyof ProductionCostBusScheduleProvidedValueEntry,
    ProductionCostFormControlErrors
  >
>;

export type ProductionCostFormControlErrors = {[errorKey: string]: string};

export interface ProductionCostServerErrors {
  // DIS1
  importProductionCostPlanrData?: ProductionCostServerError[];

  // DIS2
  calculateProductionCost?: CalculateProductionCostErrors;
}

export interface CalculateProductionCostErrors {
  invalidProvidedValues: InvalidProductionCostBusScheduleProvidedValueErrorExtensions[];
  invalidCostFactors: InvalidProductionCostBusScheduleCostFactorErrorExtensions[];
  other: Array<
    | BusInputValidationErrorExtensions
    | ScheduleInputValidationErrorExtensions
    | BasicError
  >;
}

export interface BasicError {
  id: string;
  errorMessage: string;
}
// End of error types
