import React from 'react';
import {Text} from '@flixbus/honeycomb-react';
import {getKmsOrMiles} from '../../Common/LabelUtils';

export default {
  partnerNameForCalculation:
    'Please validate the default Partner and – in case needed – choose the Partner you want to calculate cost for this service.',
  countryRules:
    'Please select the country laws that should be applied. This setting affects the following data: driver concept that you can select here, and the underlying driver salary periods.',
  emptyKmsOperatingDay: localUnits =>
    `Enter here the number of ${getKmsOrMiles(
      localUnits
    )} per operating day the bus is driving empty (e.g. from depot to start station).`,
  emptyHoursOperatingDay:
    'Enter here the number of hours the driver is driving / working on top of the hours stated in the PlanR schedule (e.g. waiting time, driving an empty bus). Breaks that are included in the PlanR schedule should not be included here. The value in this field will be ignored if you enter a vale in the “overwrite total driver salary” field.',
  salaryChange:
    'Enter here an increase or decrease (e.g. -2.50) in Euro of the default base salary stated in “Partner Costs->Driver Costs”. The increase / decrease will be applied to all four salary types (day week, day weekend, night day, night weekend) equally and only to the hours of this schedule and this bus.',
  accommodationsWeek:
    'Enter here the average number of accommodations per week needed by drivers operating this bus on this schedule.',
  accommodationCostPerNight:
    'Enter here the average price per accommodation for drivers operating this bus.',
  driverChangeKmsOperatingDay: localUnits =>
    `Enter here number of ${getKmsOrMiles(
      localUnits
    )} per operating day that occur in the context of driver change (e.g. cost for a separate car that the Partner needs to operate in order to bring drivers to their bus entering point).`,
  driverChangeHrsOperatingDay:
    'Enter here the number of extra hours of the driver per operating day in the context of driver change. The value in this field will be ignored if you enter a vale in the “overwrite total driver salary” field.',
  addInfrastructureCost:
    'Enter here additional infrastructure cost per ride that the bus partner needs to pay out of his own pocket.',
  totalDriverCostOverwrite:
    'Only enter value here if you want to overwrite the system-calculated driver salary cost. Enter the total driver cost for this schedule across all buses of this BusPartner: We recommend entering either the total cost for one bus and setting the others to “0,” or distributing the cost across buses. Costs overwrite include direct salary (incl. breaks, empty hours, driver change) and daily allowances.',
  numberOfDrivers:
    'Please distribute the total # of drivers needed to operate this line across the individual vehicles in this schedule.',
  driverSalary: 'Enter here the annual driver salary.',
  busUtilizationFactor:
    'Please enter the share (in %) the vehicle is utilized for this line.',
  flixStandardCostOverwrite: (
    <>
      <Text>
        Only use this field in case vehicle is used for non-Flix services.
      </Text>
      <Text>
        Please enter Flix standard costs per month for the bus.In case you do
        not provide any value the monthly Flix standard costs used in the
        calculation are the country specific monthly Flix Standard costs
        multiplied by the vehicle utilization factor.
      </Text>
    </>
  )
};
