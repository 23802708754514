import styles from './RegionalCostsOtherCostContent.module.scss';
import React, {useCallback, useMemo} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {IconEditSolid} from '@flixbus/honeycomb-icons-react';
import {updateOtherCost} from 'Pages/RegionalCosts/store/thunks';
import {getScaledNumber} from 'Pages/Common/Utils';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import {RegionalCostsOtherCost} from 'Pages/RegionalCosts/store/types';
import {TableHeader, TableRow} from 'components/Table';
import {
  TableWithPanelAction,
  TableWithPanelHeader,
  TableWithPanelRow
} from 'components/TableWithPanel';
import RegionalCostsExplanationNotice from 'components/RegionalCostsExplanationNotice/RegionalCostsExplanationNotice';
import {OtherCosts} from 'components/OtherCosts';
import {
  operationalCostHeaders,
  overheadCostHeaders
} from './RegionalCostsOtherCosts.data';
import {RegionalCostsOtherCostContentProps} from './RegionalCostsOtherCostContent.types';

const RegionalCostsOtherCostContent: React.FC<
  RegionalCostsOtherCostContentProps
> = ({country, year, otherCost}) => {
  const dispatch = useAppDispatch();
  const {localUnits} = useLocalUnitsContext();

  const updateOtherCosts = useCallback(
    (updatedCosts: RegionalCostsOtherCost) => {
      const updatedOtherCost = {...otherCost, ...updatedCosts};
      dispatch(updateOtherCost(updatedOtherCost, country.id, year));
    },
    [otherCost, country, year, dispatch]
  );

  const cellRenderer = useCallback(
    (key: keyof RegionalCostsOtherCost) =>
      getScaledNumber(String(otherCost[key])),
    [otherCost]
  );

  const overheadCosts = useMemo(() => {
    const headers: TableHeader[] = overheadCostHeaders(
      otherCost,
      localUnits,
      country
    );

    const row = {
      overheadCost: cellRenderer('overheadCost'),
      legalCost: cellRenderer('legalCost'),
      repairCostPerDistanceUnit: cellRenderer('repairCostPerDistanceUnit'),
      repairCostPerMonth: cellRenderer('repairCostPerMonth'),
      otherCost: cellRenderer('otherCost')
    } as TableRow;

    return {headers, rows: [row], data: [otherCost]};
  }, [otherCost, localUnits, country, cellRenderer]);

  const operationalCosts = useMemo(() => {
    const headers: TableWithPanelHeader[] = operationalCostHeaders;
    const row: TableWithPanelRow = otherCost
      ? headers.map(({key}) => ({
          key,
          value: otherCost[key],
          displayValue: getScaledNumber(otherCost[key])
        }))
      : [];

    const actions: TableWithPanelAction[] = [
      {
        type: 'panel',
        icon: IconEditSolid,
        panel: {
          title: `Update operational cost`,
          onSuccess: (row: TableRow) =>
            updateOtherCosts(row as unknown as RegionalCostsOtherCost)
        }
      }
    ];

    return {headers, rows: [row], actions};
  }, [otherCost, updateOtherCosts]);

  return (
    <>
      <div className={styles.alignRight}>
        <RegionalCostsExplanationNotice />
      </div>
      <OtherCosts
        overheadCosts={overheadCosts}
        operationalCosts={operationalCosts}
        currency={country.currency}
        updateOtherCosts={updateOtherCosts}
      />
    </>
  );
};

export default React.memo(RegionalCostsOtherCostContent);
