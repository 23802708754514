import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOptions,
  AutocompleteSelectedOptions,
  Fieldset,
  SelectGroup
} from '@flixbus/honeycomb-react';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {fetchPlanrLines} from '../../ProductionCost/store/thunks';
import {sortOptions} from '../../ProductionCost/utils/sortOptions';
import {
  deleteFilter,
  resetFilters,
  updateFilter,
  updateOptions
} from '../store/slice';
import {fetchNameOptions} from '../store/thunks';
import constants from '../constants';
import {getKey} from '../utils';
import PlanningPeriod from './PlanningPeriod/PlanningPeriod';

const HomeFilters = ({
  calculatedEntries,
  updateFilter,
  deleteFilter,
  resetFilters,
  activeOptions,
  updateOptions,
  fetchPlanrLines,
  fetchNameOptions,
  state
}) => {
  useEffect(() => {
    fetchPlanrLines();
  }, [fetchPlanrLines]);

  const selectGroupOptions = constants.calculationStatusOptions.map(option => {
    const onClick = e => {
      const field = constants.fields.calculationStatus;
      const value = {
        title: e.target.value,
        key: e.target.value
      };
      updateFilter({field, value});
    };

    return {
      ...option,
      onClick
    };
  });

  const handleSelect = (field, value) => {
    updateFilter({field, value});
    updateOptions({});
  };

  const getListToIterate = field => {
    switch (field) {
      case constants.fields.countries:
        return state.countries.list;
      case constants.fields.partners:
        return state.partners.list;
      case constants.fields.lineIds:
        return state.productionCost.lines;
      default:
        return state.calculatedEntries.entries;
    }
  };

  const handleDebounce = (field, e) => {
    const {value} = e.target;
    const listToIterate = getListToIterate(field);
    const options = getOptions(field, listToIterate, value);
    if (field === constants.fields.costCalculationName && options.length < 2)
      fetchNameOptions(value);

    updateOptions({field, options});
  };

  const getSelectorKey = field => {
    switch (field) {
      case constants.fields.countries:
      case constants.fields.partners:
        return 'id';
      case constants.fields.lineIds:
        return 'uuid';
      default:
        return getKey(field);
    }
  };

  const getParam = field => {
    if (field === constants.fields.lineIds) return 'number';
    if (
      calculatedEntries.analyticsMode &&
      field === constants.fields.costCalculationName
    )
      return 'productionCostEntryName';
    else return 'name';
  };

  const getOptions = (field, list, value) => {
    const param = getParam(field);
    const key = getSelectorKey(field);
    const options = list.reduce((acc, curr) => {
      if (curr[param].toLowerCase().includes(value.toLowerCase())) {
        return [
          ...acc,
          {
            [key]: curr[key],
            title: curr[param],
            key: curr.id || curr[key]
          }
        ];
      } else {
        return acc;
      }
    }, []);

    return sortOptions(options);
  };

  const renderInputField = input => {
    if (input.field === constants.fields.calculationStatus) {
      return (
        <Fieldset>
          <SelectGroup
            id={input.field}
            extraClasses="calculation-select-group"
            label={input.title}
            multi={true}
            options={selectGroupOptions}
          />
        </Fieldset>
      );
    } else if (input.field === constants.fields.planningPeriods) {
      return (
        <Fieldset>
          <PlanningPeriod
            planningPeriods={calculatedEntries.filters.planningPeriods}
            clearSelection={resetFilters}
            handleSelect={handleSelect}
          />
        </Fieldset>
      );
    } else if (input.field === constants.fields.costCalculationName) {
      return (
        <Fieldset>
          <Autocomplete
            id={input.key}
            options={[]}
            onDebounce={e => {
              handleSelect(input.field, {
                name: e.target.value,
                title: e.target.value
              });
            }}
          >
            <AutocompleteInput id={input.key} label={input.title} />
            <AutocompleteOptions label={input.title} optionsToDisplay={5} />
          </Autocomplete>
        </Fieldset>
      );
    } else {
      return (
        <Fieldset>
          <Autocomplete
            options={activeOptions[input.field] || []}
            onSelect={value => handleSelect(input.field, value)}
            optionsSelected={calculatedEntries.filters[input.field]}
            onReset={() => resetFilters(input.field)}
            onDelete={value => deleteFilter({field: input.field, value})}
            onDebounce={e => handleDebounce(input.field, e)}
          >
            <AutocompleteInput id={input.key} label={input.title} />
            <AutocompleteOptions label={input.title} optionsToDisplay={5} />
            <AutocompleteSelectedOptions
              deleteAllItemsLabel="Clear selection"
              deleteItemLabel="Remove item from selection"
            />
          </Autocomplete>
        </Fieldset>
      );
    }
  };

  const renderInputFields = () => {
    return constants.filterInputFields.map((inputRow, idx) => (
      <div key={idx} className="filter-container">
        {inputRow.map(input => (
          <div key={input.key} style={{flexBasis: '25%'}}>
            {renderInputField(input)}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div
      style={{display: 'flex', flexDirection: 'column'}}
      className="production-cost-criteria-section"
    >
      {renderInputFields()}
    </div>
  );
};

export default connect(
  () => state => ({
    calculatedEntries: state.calculatedEntries,
    activeOptions: state.calculatedEntries.options,
    state: state
  }),
  {
    fetchNameOptions,
    updateFilter,
    resetFilters,
    deleteFilter,
    updateOptions,
    fetchPlanrLines
  }
)(HomeFilters);
