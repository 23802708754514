import {gql} from '@apollo/client';

const DELETE_COUNTRY_DRIVER_CONCEPT_TYPE = gql`
  mutation deleteCountryDriverConceptType($id: ID!) {
    deleteCountryDriverConceptType(countryDriverConceptTypeId: $id) {
      id
    }
  }
`;

export default DELETE_COUNTRY_DRIVER_CONCEPT_TYPE;
