import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOptions,
  AutocompleteSelectedOptions,
  Fieldset
} from '@flixbus/honeycomb-react';
import React from 'react';
import {connect} from 'react-redux';
import {fetchPlanrLines} from '../../ProductionCost/store/thunks';
import {sortOptions} from '../../ProductionCost/utils/sortOptions';
import {
  deleteFilter,
  resetFilters,
  updateFilter,
  updateOptions
} from '../store/slice';
import constants from '../constants';

const PartnerFilters = ({
  partners,
  updateFilter,
  deleteFilter,
  resetFilters,
  activeOptions,
  updateOptions,
  state
}) => {
  const handleSelect = (field, value) => {
    updateFilter(field, value);
    updateOptions([]);
  };

  const handleDebounce = (field, e) => {
    const {value} = e.target;
    const listToIterate = state.countries.list;
    const options = getOptions(listToIterate, value);

    updateOptions(field, options);
  };

  const getOptions = (list, value) => {
    const param = 'name';
    const key = 'id';
    const options = list.reduce((acc, curr) => {
      if (curr[param].toLowerCase().includes(value.toLowerCase())) {
        return [
          ...acc,
          {
            [key]: curr[key],
            title: curr[param],
            key: curr.id || curr[key]
          }
        ];
      } else {
        return acc;
      }
    }, []);

    return sortOptions(options);
  };

  const renderInputField = input => {
    return (
      <Fieldset>
        <Autocomplete
          options={activeOptions[input.field] || []}
          onSelect={value => handleSelect(input.field, value)}
          optionsSelected={partners.filters[input.field]}
          onReset={() => resetFilters(input.field)}
          onDelete={value => deleteFilter(input.field, value)}
          onDebounce={e => handleDebounce(input.field, e)}
        >
          <AutocompleteInput id={input.key} label={input.title} />
          <AutocompleteOptions label={input.title} optionsToDisplay={5} />
          <AutocompleteSelectedOptions
            deleteAllItemsLabel="Clear selection"
            deleteItemLabel="Remove item from selection"
          />
        </Autocomplete>
      </Fieldset>
    );
  };

  const renderInputFields = () => {
    return constants.filterInputFields.map((inputRow, idx) => (
      <div key={idx} className="filter-container">
        {inputRow.map(input => (
          <div key={input.key} style={{flexBasis: idx === 0 ? '25%' : '50%'}}>
            {renderInputField(input)}
          </div>
        ))}
      </div>
    ));
  };

  return renderInputFields();
};

export default connect(
  () => state => ({
    partners: state.partners,
    activeOptions: state.partners.options,
    state: state
  }),
  {
    updateFilter,
    resetFilters,
    deleteFilter,
    updateOptions,
    fetchPlanrLines
  }
)(PartnerFilters);
