import React, {useCallback, useEffect, useState} from 'react';
import {
  ProductionCostBusScheduleProvidedValueEntry,
  ProductionCostScheduleWithProvidedValues
} from 'Pages/ProductionCost/store/types';
import useValidateBusScheduleValues from '../../hooks/useValidateBusScheduleValues';
import CopyVehicle from './CopyVehicle/CopyVehicle';
import {LineSettingsProps} from './LineSettings.types';
import LineScheduleVehicleNav from './LineScheduleVehicleNav/LineScheduleVehicleNav';
import LineScheduleVehicleSettings from './LineScheduleVehicleSettings/LineScheduleVehicleSettings';
import style from './LineSettings.module.scss';

const LineSettings: React.FC<LineSettingsProps> = ({
  lineNumber,
  schedules,
  busValues,
  driverCostCalculationType,
  errors
}) => {
  const [activeScheduleId, setActiveScheduleId] = useState<string | null>(null);
  const [activeVehicleEntryId, setActiveVehicleEntryId] = useState<
    string | null
  >(null);

  const handleScheduleSelect = useCallback(
    (schedule: ProductionCostScheduleWithProvidedValues) => {
      setActiveScheduleId(schedule.scheduleId);

      const shouldPreselectVehicle =
        !schedule.busScheduleProvidedValuesEntries.find(
          entry => entry.id === activeVehicleEntryId
        );
      if (shouldPreselectVehicle) {
        const entry = schedule.busScheduleProvidedValuesEntries[0];
        setActiveVehicleEntryId(entry.id);
      }
    },
    [activeVehicleEntryId]
  );

  const handleVehicleEntrySelect = useCallback(
    (entry: ProductionCostBusScheduleProvidedValueEntry) =>
      setActiveVehicleEntryId(entry.id),
    []
  );

  // preselect first schedule and vehicle
  useEffect(() => {
    if (schedules.length > 0 && !activeScheduleId) {
      handleScheduleSelect(schedules[0]);
    }
  }, [schedules, activeScheduleId, activeVehicleEntryId, handleScheduleSelect]);

  // validate line schedules
  const validateBusScheduleValues = useValidateBusScheduleValues();
  useEffect(
    () => validateBusScheduleValues(schedules, driverCostCalculationType),
    [validateBusScheduleValues, schedules, driverCostCalculationType]
  );

  if (!activeScheduleId || !activeVehicleEntryId) return null;

  return (
    <div className={style.container} data-testid={`line-${lineNumber}`}>
      <span data-testid={`line-${lineNumber}-navigation`}>
        <LineScheduleVehicleNav
          lineNumber={lineNumber}
          schedules={schedules}
          busValues={busValues}
          activeSchedule={activeScheduleId}
          activeVehicleEntry={activeVehicleEntryId}
          errors={errors}
          onScheduleSelect={handleScheduleSelect}
          onVehicleEntrySelect={handleVehicleEntrySelect}
        />
      </span>

      <span
        className={style.rightSide}
        data-testid={`schedule-${activeScheduleId}-settings`}
      >
        <LineScheduleVehicleSettings
          activeSchedule={activeScheduleId}
          activeVehicleEntry={activeVehicleEntryId}
          schedules={schedules}
        />

        <CopyVehicle
          lineNumber={lineNumber}
          scheduleId={activeScheduleId}
          vehicleEntryId={activeVehicleEntryId}
        />
      </span>
    </div>
  );
};

export default React.memo(LineSettings);
