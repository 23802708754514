import React, {useEffect} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {isNil} from 'ramda';
import {Infobox} from '@flixbus/honeycomb-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {fetchCountrySpecificCost} from '../../store/thunks';
import RegionalCostsOtherCostContent from './RegionalCostsOtherCostContent/RegionalCostsOtherCostContent';

const RegionalCostsOtherCostTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const countryId = useAppSelector(({regionalCosts}) => regionalCosts.country);
  const country = useAppSelector(({countries}) =>
    countries.list?.find(({id}) => id === countryId)
  );
  const year = useAppSelector(({regionalCosts}) => regionalCosts.year);
  const otherCost = useAppSelector(
    ({regionalCosts}) => regionalCosts.otherCost
  );

  useEffect(() => {
    if (isNil(otherCost)) {
      dispatch(fetchCountrySpecificCost(countryId, year));
    }
  }, [otherCost, countryId, year, dispatch]);

  return !country || !year || !otherCost ? (
    <Infobox appearance="warning">
      <p>
        Select <strong>country</strong> and <strong>calendar year</strong>
      </p>
    </Infobox>
  ) : (
    <RegionalCostsOtherCostContent
      country={country}
      year={year}
      otherCost={otherCost}
    />
  );
};

export default React.memo(RegionalCostsOtherCostTab);
