import {
  DocumentNode,
  FetchResult,
  MutationFunctionOptions,
  useMutation
} from '@apollo/client';
import {useEffect} from 'react';
import {useToast} from './toasts-container';
import {handleError} from './Errors';

const useCpcMutation = (
  mutationQuery: DocumentNode
): [
  (options?: MutationFunctionOptions) => Promise<FetchResult<any>>,
  {data: any}
] => {
  const {addToast} = useToast();
  const [mutateFunction, {data, error}] = useMutation(mutationQuery, {
    errorPolicy: 'all'
  });

  useEffect(() => {
    if (error && !data) {
      handleError(error, addToast, `sending mutation :  ${error.name}`);
    }
  }, [error, data, addToast]);

  return [mutateFunction, data];
};

export default useCpcMutation;
