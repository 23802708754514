import React, {useMemo} from 'react';
import {getScaledNumber} from 'Pages/Common/Utils';
import TableComparisonCell from 'components/TableWithPanel/TableComparisonCell/TableComparisonCell';
import {ValueDimension} from 'Pages/Countries/components/index.constants';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import {getRepairCostAbbreviation} from '../PartnerCostsOtherCostContent/PartnerCostsOtherCostContent.data';
import {PartnerCostsRepairCostTableCellProps} from './PartnerCostsRepairCostTableCell.types';

const PartnerCostsRepairCostTableCell: React.FC<
  PartnerCostsRepairCostTableCellProps
> = ({partnerOtherCost, partnerRepairCostKey, regionalOtherCost, country}) => {
  const {localUnits} = useLocalUnitsContext();
  const partnerValueDimension =
    partnerRepairCostKey === 'repairCostPerMonth'
      ? ValueDimension.PER_MONTH
      : ValueDimension.PER_DISTANCE_UNIT;

  const regionalCostData = useMemo(() => {
    if (!regionalOtherCost) return null;

    if (regionalOtherCost.repairCostPerDistanceUnit) {
      return {
        value: regionalOtherCost.repairCostPerDistanceUnit,
        dimension: ValueDimension.PER_DISTANCE_UNIT,
        abbreviation: getRepairCostAbbreviation(
          ValueDimension.PER_DISTANCE_UNIT,
          country,
          localUnits
        )
      };
    } else if (regionalOtherCost.repairCostPerMonth) {
      return {
        value: regionalOtherCost.repairCostPerMonth,
        dimension: ValueDimension.PER_MONTH,
        abbreviation: getRepairCostAbbreviation(
          ValueDimension.PER_MONTH,
          country,
          localUnits
        )
      };
    } else {
      return null;
    }
  }, [regionalOtherCost, country, localUnits]);

  return (
    <TableComparisonCell
      originalValue={getScaledNumber(
        String(partnerOtherCost[partnerRepairCostKey])
      )}
      referenceValue={
        regionalCostData ? (
          <>
            {getScaledNumber(String(regionalCostData.value))}
            {partnerValueDimension === regionalCostData.dimension
              ? null
              : ` ${regionalCostData.abbreviation}`}
          </>
        ) : null
      }
      header={partnerRepairCostKey}
      tooltipContent={`Regional value`}
    />
  );
};

export default React.memo(PartnerCostsRepairCostTableCell);
