import {ProductionCostPublishingStatus} from '../store/types';

export enum ActionStatus {
  NA = 'N/A',
  PENDING = 'In progress',
  SUCCESS = 'Success',
  ERROR = 'Error'
}

export interface OverviewSelectedValue {
  queryId?: string;
  publishingStatus: ProductionCostPublishingStatus;
  productionCostEntryId: string;
  name: string;
  productionCostPerKm: string;
  totalKm: string;
  usedCurrencies: string[];
  publishedAt: string;
  status?: ActionStatus;
}
