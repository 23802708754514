import {isEmpty} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
  CalculateProductionCostErrors,
  CalculationUserJourneyMode,
  ProductionCostBusProvidedValuesEntry,
  ProductionCostBusProvidedValuesEntryErrors,
  ProductionCostBusScheduleValuesErrors,
  ProductionCostScheduleWithProvidedValues
} from 'Pages/ProductionCost/store/types';
import {convertDecimalToPercentage} from '../../Common/Utils';
import {
  productionCostsInitialState,
  getInitLineSchedule,
  productionCostsInitialValidationErrorsState
} from './data';
import {
  CalculatedProductionCost,
  CountryPrefilledEmptyValues,
  PlanrBusPartner,
  PlanrLine,
  PlanrSchedule,
  PlanrVehicle,
  ProductionCostBus,
  ProductionCostBusScheduleProvidedValueEntry,
  ProductionCostFilterType,
  ProductionCostState
} from './types';
import {
  CountryDriverConceptType,
  RegionalCostsDriverCost
} from 'Pages/RegionalCosts/store/types';
import {PartnerCostsDriverCost} from 'Pages/PartnerCosts/store/types';
import {
  selectLineScheduleById,
  selectVehicleEntryById,
  selectVehicleEntryIdsByVehicleId
} from './customSelectors';
import {ProductionCostServerError} from '../components/types';

const productionCostsSlice = createSlice({
  name: 'production-costs',
  initialState: productionCostsInitialState,
  reducers: {
    /* DIS 1 */
    receivedPlanrLines: (state, {payload}: PayloadAction<PlanrLine[]>) => {
      return {
        ...state,
        lines: payload
      };
    },
    addLineSchedule: state => {
      const lineSchedules = [...state.lineSchedules];
      lineSchedules.push(getInitLineSchedule());

      return {
        ...state,
        lineSchedules
      };
    },
    deleteLineSchedule: (
      state,
      {payload: lineScheduleKey}: PayloadAction<number>
    ) => {
      const lineSchedules = [...state.lineSchedules].filter(
        schedule => schedule.lineScheduleKey !== lineScheduleKey
      );
      const resetProductionCost: any = {};
      if (isEmpty(lineSchedules)) {
        resetProductionCost.startDate = null;
        resetProductionCost.endDate = null;
        resetProductionCost.status = null;
        resetProductionCost.costYear = null;
      }
      return {
        ...state,
        lineSchedules,
        ...resetProductionCost
      };
    },
    productionCostWasCreated: (
      state,
      {payload: productionCostEntryId}: PayloadAction<string>
    ) => {
      return {
        ...state,
        id: productionCostEntryId
      };
    },
    receivedPlanrSchedules: (
      state,
      {
        payload: {lineScheduleKey, schedules}
      }: PayloadAction<{
        lineScheduleKey: number;
        schedules: PlanrSchedule[];
      }>
    ) => {
      const lineSchedules = [...state.lineSchedules].map(schedule =>
        schedule.lineScheduleKey === lineScheduleKey
          ? {...schedule, schedules}
          : schedule
      );
      return {
        ...state,
        lineSchedules
      };
    },
    receivedPlanrBusPartners: (
      state,
      {payload: planrBusPartners}: PayloadAction<PlanrBusPartner[]>
    ) => {
      return {
        ...state,
        planrBusPartners
      };
    },
    receivedPlanrVehicles: (
      state,
      {payload: planrVehicles}: PayloadAction<PlanrVehicle[]>
    ) => {
      return {
        ...state,
        planrVehicles
      };
    },
    productionCostLineScheduleUpdate: (state, {payload}) => {
      const lineSchedules = [...state.lineSchedules].map(schedule =>
        schedule.lineScheduleKey === payload.lineScheduleKey
          ? {...schedule, ...payload.lineSchedule}
          : schedule
      );
      return {
        ...state,
        lineSchedules
      };
    },
    receivedImportProductionCostData: (
      state,
      {
        payload: {
          driverShiftsCalculationTypeAllowed,
          busProvidedValues,
          busScheduleProvidedValues
        }
      }: PayloadAction<{
        driverShiftsCalculationTypeAllowed: boolean;
        busProvidedValues: ProductionCostBusProvidedValuesEntry[];
        busScheduleProvidedValues: ProductionCostScheduleWithProvidedValues[];
      }>
    ) => {
      const busScheduleValues = busScheduleProvidedValues.map(schedule => {
        const busScheduleProvidedValuesEntries =
          schedule.busScheduleProvidedValuesEntries.map(entry => {
            entry.busUtilizationFactor =
              convertDecimalToPercentage(entry?.busUtilizationFactor) ??
              undefined;
            return entry;
          });

        return {
          ...schedule,
          busScheduleProvidedValuesEntries
        };
      });

      return {
        ...state,
        driverShiftsCalculationTypeAllowed,
        busValues: busProvidedValues,
        busScheduleValues,
        serverErrors: {},
        loading: false
      };
    },

    // Validation
    importProductionCostDataFailed: (
      state,
      {
        payload: importProductionCostPlanrData
      }: PayloadAction<ProductionCostServerError[]>
    ) => {
      const serverErrors = {
        ...state.serverErrors,
        importProductionCostPlanrData
      };
      return {
        ...state,
        serverErrors,
        loading: false
      };
    },
    removeEditScreenError: (
      state,
      {payload: errorId}: PayloadAction<string | number>
    ) => {
      const {calculateProductionCost} = state.serverErrors;
      if (!calculateProductionCost?.other) return;
      calculateProductionCost.other = calculateProductionCost.other.filter(
        error => error.id !== errorId
      );
    },

    // Bus partners / vehicles selection popup
    updateFilterType: (
      state,
      {payload: filterType}: PayloadAction<ProductionCostFilterType>
    ) => {
      return {
        ...state,
        filterType
      };
    },
    filteredPlanrBusPartnerIds: (
      state,
      {payload: filteredPlanrBusPartnerIds}: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        filteredPlanrBusPartnerIds
      };
    },
    filteredPlanrVehicleIds: (
      state,
      {payload: filteredPlanrVehicleIds}: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        filteredPlanrVehicleIds
      };
    },
    updateLineSchedulesSelection: (
      state,
      {
        payload
      }: PayloadAction<{
        lineScheduleKey: number;
        selection: Array<{value: string; selected: boolean}>;
      }>
    ) => {
      const lineSchedules = [...state.lineSchedules].map(lineSchedule => {
        if (lineSchedule.lineScheduleKey !== payload.lineScheduleKey) {
          return lineSchedule;
        }

        let hasSelectedLineSchedules = false;

        const schedules = [...lineSchedule.schedules].map(schedule => {
          schedule = {...schedule};
          const selection = payload.selection.find(
            selection => selection.value === schedule.uuid
          );

          if (selection && selection.selected) {
            schedule.selected = true;

            hasSelectedLineSchedules = true;
          } else {
            delete schedule.selected;
          }

          return schedule;
        });

        return {
          ...lineSchedule,
          schedules,
          isValid: hasSelectedLineSchedules
        };
      });

      return {
        ...state,
        lineSchedules
      };
    },
    toggleLineSchedule: (state, {payload}: PayloadAction<number>) => {
      const lineSchedules = [...state.lineSchedules].map(schedule =>
        schedule.lineScheduleKey === payload
          ? {...schedule, toggle: !schedule.toggle}
          : schedule
      );
      return {
        ...state,
        lineSchedules
      };
    },

    /* DIS 2 */
    receivedCalculateProductionCost: (
      state,
      {
        payload: calculatedProductionCosts
      }: PayloadAction<CalculatedProductionCost>
    ) => {
      return {
        ...state,
        calculatedProductionCosts,
        validationErrors: productionCostsInitialValidationErrorsState,
        serverErrors: {},
        loading: false
      };
    },
    receivedProductionCostBuses: (
      state,
      {payload: productionCostBuses}: PayloadAction<ProductionCostBus[]>
    ) => {
      return {
        ...state,
        productionCostBuses
      };
    },
    receivedCountryPrefilledEmptyValuesForVehicle: (
      state,
      {
        payload: {countryId, countryPrefilledEmptyValues, entryIds}
      }: PayloadAction<{
        countryId: string;
        countryPrefilledEmptyValues: CountryPrefilledEmptyValues;
        entryIds: string[];
      }>
    ) => {
      const busScheduleValues =
        state.busScheduleValues?.map(busSchedule => {
          const busScheduleProvidedValuesEntries =
            busSchedule.busScheduleProvidedValuesEntries.map(vehicleEntry => {
              if (!entryIds.includes(vehicleEntry.id)) return vehicleEntry;
              return {
                ...vehicleEntry,
                emptyKmPerDay: countryPrefilledEmptyValues.prefilledEmptyKm,
                emptyHrPerDay: countryPrefilledEmptyValues.prefilledEmptyHr
              };
            });

          return {
            ...busSchedule,
            busScheduleProvidedValuesEntries
          };
        }) ?? null;

      return {
        ...state,
        countryPrefilledEmptyValues: {
          ...state.countryPrefilledEmptyValues,
          [countryId]: countryPrefilledEmptyValues
        },
        busScheduleValues
      };
    },
    receivedCountryDriverConceptTypesForVehicle: (
      state,
      {
        payload: {countryId, countryDriverConceptTypes}
      }: PayloadAction<{
        countryId: string;
        countryDriverConceptTypes: CountryDriverConceptType[];
      }>
    ) => {
      if (isNotNilOrEmpty(state.countryDriverConceptTypes[countryId])) {
        return state;
      }

      return {
        ...state,
        countryDriverConceptTypes: {
          ...state.countryDriverConceptTypes,
          [countryId]: countryDriverConceptTypes
        }
      };
    },
    receivedPartnerAndCountryDriverCostForVehicle: (
      state,
      {
        payload
      }: PayloadAction<{
        entryIds: string[];
        busPartnerId: string;
        countryId: string;
        productionCostYear: number;
        busPartnerDriverCostEntry: PartnerCostsDriverCost;
        countryDriverCostEntry: RegionalCostsDriverCost;
      }>
    ) => {
      const {
        entryIds,
        busPartnerId,
        countryId,
        productionCostYear,
        busPartnerDriverCostEntry,
        countryDriverCostEntry
      } = payload;

      const busScheduleValues =
        state.busScheduleValues?.map(busSchedule => {
          const busScheduleProvidedValuesEntries =
            busSchedule.busScheduleProvidedValuesEntries.map(vehicleEntry => {
              if (!entryIds.includes(vehicleEntry.id)) {
                return vehicleEntry;
              }

              return {
                ...vehicleEntry,
                accommodationCostPerNight:
                  busPartnerDriverCostEntry?.accommodationCostPerNight ??
                  countryDriverCostEntry?.accommodationCostPerNight ??
                  undefined
              };
            });

          return {
            ...busSchedule,
            busScheduleProvidedValuesEntries
          };
        }) ?? [];

      const busPartnerAndCountryDriverCostEntry = {
        ...state.busPartnerAndCountryDriverCostEntry,
        [busPartnerId + countryId + productionCostYear]: {
          busPartnerDriverCostEntry,
          countryDriverCostEntry
        }
      };

      return {
        ...state,
        busScheduleValues,
        busPartnerAndCountryDriverCostEntry
      };
    },
    productionCostBaseUpdate: (
      state,
      {payload}: PayloadAction<Partial<ProductionCostState>>
    ) => {
      return {
        ...state,
        ...payload,
        lineSchedules: payload.startDate
          ? productionCostsInitialState.lineSchedules
          : state.lineSchedules
      };
    },
    backToFirstDataImportScreen: state => {
      return {
        ...state,
        validationErrors: productionCostsInitialValidationErrorsState,
        serverErrors: {},
        busScheduleValues: null,
        calculationUserJourneyMode: CalculationUserJourneyMode.CreateCalculation
      };
    },
    resetProductionCostState: () => {
      return {...productionCostsInitialState};
    },
    productionCostCreationIsPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    // Update form values
    updateBusValues: (
      state,
      {
        payload
      }: PayloadAction<{
        vehicleId: string;
        updates: Partial<ProductionCostBusProvidedValuesEntry>;
      }>
    ) => {
      const {vehicleId, updates} = payload;

      state.busValues =
        state.busValues?.map(bus =>
          bus.vehicleId === vehicleId ? {...bus, ...updates} : bus
        ) ?? [];

      return state;
    },
    updateVehicleEntryByIds: (
      state,
      {
        payload
      }: PayloadAction<{
        entryIds: string[];
        updates: Partial<ProductionCostBusScheduleProvidedValueEntry>;
      }>
    ) => {
      const {entryIds, updates} = payload;

      state.busScheduleValues =
        state.busScheduleValues?.map(busSchedule => {
          busSchedule.busScheduleProvidedValuesEntries =
            busSchedule.busScheduleProvidedValuesEntries.map(vehicleEntry => {
              if (!entryIds.includes(vehicleEntry.id)) return vehicleEntry;
              return {...vehicleEntry, ...updates};
            });

          return busSchedule;
        }) ?? null;

      return state;
    },

    // Validation
    updateBusValuesValidationErrors: (
      state,
      {
        payload
      }: PayloadAction<{
        validationErrors: ProductionCostBusProvidedValuesEntryErrors | null;
        vehicleId: string;
      }>
    ) => {
      const {validationErrors, vehicleId} = payload;
      if (validationErrors) {
        state.validationErrors.busValues[vehicleId] = validationErrors;
      } else {
        delete state.validationErrors.busValues[vehicleId];
      }
    },
    updateBusScheduleValuesValidationErrors: (
      state,
      {
        payload
      }: PayloadAction<{
        validationErrors: ProductionCostBusScheduleValuesErrors;
      }>
    ) => {
      const {validationErrors} = payload;
      for (const [entryId, entryErrors] of Object.entries(validationErrors)) {
        if (entryErrors) {
          state.validationErrors.busScheduleValues[entryId] = entryErrors;
        } else {
          delete state.validationErrors.busScheduleValues[entryId];
        }
      }
    },
    setCalculateProductionCostErrors: (
      state,
      {
        payload: calculateProductionCost
      }: PayloadAction<CalculateProductionCostErrors>
    ) => {
      state.serverErrors.calculateProductionCost = calculateProductionCost;
      state.loading = false;
    },
    resetInvalidCostFactorsErrors: state => {
      if (state.serverErrors.calculateProductionCost) {
        state.serverErrors.calculateProductionCost.invalidCostFactors = [];
      }
    },

    // Copy functionality
    copyValuesToAllVehicles: (
      state,
      {
        payload: {
          lineNumber,
          sourceVehicleEntry,
          vehicleIdToCountryRulesCountryId
        }
      }: PayloadAction<{
        lineNumber: string;
        sourceVehicleEntry: ProductionCostBusScheduleProvidedValueEntry;
        vehicleIdToCountryRulesCountryId: {[vehicleId: string]: string};
      }>
    ) => {
      for (const busSchedule of state.busScheduleValues ?? []) {
        if (busSchedule.lineNumber !== lineNumber) continue;

        for (const entry of busSchedule.busScheduleProvidedValuesEntries) {
          const {id, vehicleId} = entry;

          const currEntryCountryId =
            vehicleIdToCountryRulesCountryId[vehicleId];
          const sourceEntryCountryId =
            vehicleIdToCountryRulesCountryId[sourceVehicleEntry.vehicleId];

          const countryDriverConceptTypes =
            state.countryDriverConceptTypes[currEntryCountryId] ?? [];

          const countryDriverConceptTypeId =
            currEntryCountryId === sourceEntryCountryId
              ? sourceVehicleEntry.countryDriverConceptTypeId // use the same country driver concept type if the country is the same
              : countryDriverConceptTypes.length === 1 // otherwise try to preselect if only one option is available
              ? countryDriverConceptTypes[0].id
              : undefined;

          Object.assign(entry, sourceVehicleEntry, {
            id,
            vehicleId,
            countryDriverConceptTypeId
          });
        }
      }
    },
    copyValuesToSameVehicle: (
      state,
      {
        payload: {lineNumber, sourceVehicleEntry}
      }: PayloadAction<{
        lineNumber: string;
        sourceVehicleEntry: ProductionCostBusScheduleProvidedValueEntry;
      }>
    ) => {
      for (const busSchedule of state.busScheduleValues ?? []) {
        if (busSchedule.lineNumber !== lineNumber) continue;

        for (const entry of busSchedule.busScheduleProvidedValuesEntries) {
          const {id, vehicleId} = entry;
          if (vehicleId !== sourceVehicleEntry.vehicleId) continue;

          Object.assign(entry, sourceVehicleEntry, {id});
        }
      }
    },
    copyValuesToSameVehicleAndScheduleSize: (
      state,
      {
        payload: {lineNumber, sourceVehicleEntry, scheduleSize}
      }: PayloadAction<{
        lineNumber: string;
        sourceVehicleEntry: ProductionCostBusScheduleProvidedValueEntry;
        scheduleSize?: string;
      }>
    ) => {
      for (const busSchedule of state.busScheduleValues ?? []) {
        if (
          busSchedule.lineNumber !== lineNumber ||
          busSchedule.size !== scheduleSize
        )
          continue;

        for (const entry of busSchedule.busScheduleProvidedValuesEntries) {
          const {id, vehicleId} = entry;
          if (vehicleId !== sourceVehicleEntry.vehicleId) continue;

          Object.assign(entry, sourceVehicleEntry, {id});
        }
      }
    },

    /* DIS 3 */
    backToSecondDataImportScreen: state => {
      return {
        ...state,
        calculatedProductionCosts: null,
        calculationUserJourneyMode:
          state.calculationUserJourneyMode ===
          CalculationUserJourneyMode.EditCalculation
            ? CalculationUserJourneyMode.EditCalculation
            : CalculationUserJourneyMode.EditJustCreatedCalculation
      };
    },

    // Home Page
    receivedProductionCost: (
      state,
      {payload}: PayloadAction<ProductionCostState>
    ) => {
      return {
        ...state,
        ...payload,
        calculationUserJourneyMode: CalculationUserJourneyMode.EditCalculation
      };
    }
  }
});

export const {
  /* DIS 1 */
  receivedPlanrLines,
  addLineSchedule,
  deleteLineSchedule,
  productionCostWasCreated,
  receivedPlanrSchedules,
  receivedPlanrBusPartners,
  receivedPlanrVehicles,
  productionCostLineScheduleUpdate,
  receivedImportProductionCostData,
  importProductionCostDataFailed,
  removeEditScreenError,

  // Bus partners / vehicles selection popup
  updateFilterType,
  filteredPlanrBusPartnerIds,
  filteredPlanrVehicleIds,
  updateLineSchedulesSelection,
  toggleLineSchedule,

  /* DIS 2 */
  receivedCalculateProductionCost,
  receivedProductionCostBuses,
  receivedCountryPrefilledEmptyValuesForVehicle,
  receivedCountryDriverConceptTypesForVehicle,
  receivedPartnerAndCountryDriverCostForVehicle,
  productionCostBaseUpdate,
  backToFirstDataImportScreen,
  resetProductionCostState,
  productionCostCreationIsPending,

  // Update form values
  updateBusValues,
  updateVehicleEntryByIds,

  // Validation
  updateBusValuesValidationErrors,
  updateBusScheduleValuesValidationErrors,
  setCalculateProductionCostErrors,
  resetInvalidCostFactorsErrors,

  // Copy functionality
  copyValuesToAllVehicles,
  copyValuesToSameVehicle,
  copyValuesToSameVehicleAndScheduleSize,

  /* DIS 3 */
  backToSecondDataImportScreen,

  // Home Page,
  receivedProductionCost
} = productionCostsSlice.actions;

export {
  selectLineScheduleById,
  selectVehicleEntryById,
  selectVehicleEntryIdsByVehicleId
};

export default productionCostsSlice.reducer;
