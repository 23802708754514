import {gql} from '@apollo/client';

const UPDATE_COUNTRY_DRIVER_COST = gql`
  mutation updateCountryDriverCostEntry(
    $countryId: ID!
    $year: Int!
    $daytimeWeekdaysWorkingHourCost: Float
    $daytimeWeekendsWorkingHourCost: Float
    $nighttimeWeekdaysWorkingHourCost: Float
    $nighttimeWeekendsWorkingHourCost: Float
    $replacementCostPerKm: CostPerDistance
    $replacementCostPerHr: Float
    $accommodationCostPerNight: Float
  ) {
    updateCountryDriverCostEntry(
      input: {
        countryId: $countryId
        year: $year
        daytimeWeekdaysWorkingHourCost: $daytimeWeekdaysWorkingHourCost
        daytimeWeekendsWorkingHourCost: $daytimeWeekendsWorkingHourCost
        nighttimeWeekdaysWorkingHourCost: $nighttimeWeekdaysWorkingHourCost
        nighttimeWeekendsWorkingHourCost: $nighttimeWeekendsWorkingHourCost
        replacementCostPerKm: $replacementCostPerKm
        replacementCostPerHr: $replacementCostPerHr
        accommodationCostPerNight: $accommodationCostPerNight
      }
    ) {
      year
      daytimeWeekdaysWorkingHourCost
      daytimeWeekendsWorkingHourCost
      nighttimeWeekdaysWorkingHourCost
      nighttimeWeekendsWorkingHourCost
      replacementCostPerKm
      replacementCostPerHr
      accommodationCostPerNight
    }
  }
`;

export default UPDATE_COUNTRY_DRIVER_COST;
