import {useEffect, useRef} from 'react';

const useComponentDidMount = () => {
  const ref = useRef<boolean>(false);
  useEffect(() => {
    ref.current = true;
  }, []);
  return ref.current;
};

export default useComponentDidMount;
