import React from 'react';
import {Accordion, Radio} from '@flixbus/honeycomb-react';
import {FilterTypeAccordionProps} from './FilterTypeAccordion.types';
import style from './FilterTypeAccordion.module.scss';

const FilterTypeAccordion: React.FC<FilterTypeAccordionProps> = ({
  label,
  filterType,
  selectedFilterType,
  onFilterTypeChange,
  children
}) => (
  <Accordion
    title={
      <Radio
        label={label}
        id={`${filterType}-accordion`}
        value={filterType}
        name="filterType"
        small
        checked={filterType === selectedFilterType}
        onChange={() => onFilterTypeChange(filterType)}
      />
    }
    open={filterType === selectedFilterType}
    onToggle={(_, isOpen) => isOpen && onFilterTypeChange(filterType)}
  >
    <div className={style.container}>{children}</div>
  </Accordion>
);

export default React.memo(FilterTypeAccordion);
