import React from 'react';
import IconButton from '../../Common/IconButton';
import {IconEye} from '@flixbus/honeycomb-icons-react';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import {
  getBusPartnerName,
  getPlanningPeriod,
  roundCostValues,
  getAnalyticsRowId,
  calculateSubtotalRowId
} from './index';

export const getAnalyticsRows = (
  analyticsEntriesAreReady,
  entries,
  calculatedSubtotal,
  partners,
  setEntryToView
) => {
  if (!analyticsEntriesAreReady) return [];

  const rows = entries.map(entry => {
    const viewButton = (
      <IconButton
        title={'View Input Cost Factors'}
        aria-label="view-entry"
        onClick={() => setEntryToView(entry)}
        InlineIcon={IconEye}
      />
    );
    const roundedEntry = roundCostValues(entry);
    const partnerName = getBusPartnerName(partners, roundedEntry.busPartnerId);
    const rowId = getAnalyticsRowId(roundedEntry);

    return {
      rowId,
      rowIdStringified: JSON.stringify(rowId),
      viewButton,
      lineNumbers: roundedEntry.lineNumber,
      partnerName,
      planningPeriod: getPlanningPeriod(
        roundedEntry.startDate,
        roundedEntry.endDate
      ),
      productionCostEntryName: roundedEntry.productionCostEntryName,
      productionCostEntryStatus: roundedEntry.productionCostEntryStatus,
      totalKms: roundedEntry.totalKms,
      currency: roundedEntry.currency,
      totalProductionCosts: roundedEntry.totalProductionCosts,
      totalProductionCostsPerKm: roundedEntry.totalProductionCostsPerKm,
      leasingCostsPerKm: roundedEntry.leasingCostsPerKm,
      interestCostsPerKm: roundedEntry.interestCostsPerKm,
      maintenanceCostsPerKm: roundedEntry.maintenanceCostsPerKm,
      insuranceCostsPerKm: roundedEntry.insuranceCostsPerKm,
      motorVehicleTaxCostsPerKm: roundedEntry.motorVehicleTaxCostsPerKm,
      driverSalaryCostsPerKm: roundedEntry.driverSalaryCostsPerKm,
      accommodationCostsPerKm: roundedEntry.accommodationCostsPerKm,
      dailyAllowanceCostsPerKm: roundedEntry.dailyAllowanceCostsPerKm,
      driverChangeCostsPerKm: roundedEntry.driverChangeCostsPerKm,
      dieselCostsPerKm: roundedEntry.dieselCostsPerKm,
      adblueCostsPerKm: roundedEntry.adblueCostsPerKm,
      lngCostsPerKm: roundedEntry.lngCostsPerKm,
      totalFuelCostsPerKm: roundedEntry.totalFuelCostsPerKm,
      tiresCostsPerKm: roundedEntry.tiresCostsPerKm,
      cleaningCostsPerKm: roundedEntry.cleaningCostsPerKm,
      overheadCostsPerKm: roundedEntry.overheadCostsPerKm,
      flixStandardCostsPerKm: roundedEntry.flixStandardCostsPerKm,
      legalCostsPerKm: roundedEntry.legalCostsPerKm,
      damageRepairCostsPerKm: roundedEntry.damageRepairCostsPerKm,
      otherCostsPerKm: roundedEntry.otherCostsPerKm,
      infrastructureCostsPerKm: roundedEntry.infrastructureCostsPerKm
    };
  });

  if (isNotNilOrEmpty(calculatedSubtotal)) {
    const roundedCalculatedSubtotalRows = getCalculatedSubtotalRows(
      calculatedSubtotal.subtotals
    );
    rows.unshift(...roundedCalculatedSubtotalRows);
  }

  return rows;
};

const getCalculatedSubtotalRows = calculatedSubtotal => {
  const roundedCalculatedSubtotals = calculatedSubtotal.map(subtotals =>
    roundCostValues(subtotals)
  );

  return roundedCalculatedSubtotals.map((roundedCalculatedSubtotal, index) => ({
    rowId: `${calculateSubtotalRowId}-${index}`,
    rowIdStringified: `${calculateSubtotalRowId}-${index}`,
    viewButton: '',
    lineNumbers: index === 0 ? 'CALCULATED SUBTOTAL' : '',
    partnerName: '',
    planningPeriod: '',
    productionCostEntryName: '',
    productionCostEntryStatus: '',
    totalKms: roundedCalculatedSubtotal.totalKms,
    currency: roundedCalculatedSubtotal.currency,
    totalProductionCosts: roundedCalculatedSubtotal.totalProductionCosts,
    totalProductionCostsPerKm:
      roundedCalculatedSubtotal.totalProductionCostsPerKm,
    leasingCostsPerKm: roundedCalculatedSubtotal.leasingCostsPerKm,
    interestCostsPerKm: roundedCalculatedSubtotal.interestCostsPerKm,
    maintenanceCostsPerKm: roundedCalculatedSubtotal.maintenanceCostsPerKm,
    insuranceCostsPerKm: roundedCalculatedSubtotal.insuranceCostsPerKm,
    motorVehicleTaxCostsPerKm:
      roundedCalculatedSubtotal.motorVehicleTaxCostsPerKm,
    driverSalaryCostsPerKm: roundedCalculatedSubtotal.driverSalaryCostsPerKm,
    accommodationCostsPerKm: roundedCalculatedSubtotal.accommodationCostsPerKm,
    dailyAllowanceCostsPerKm:
      roundedCalculatedSubtotal.dailyAllowanceCostsPerKm,
    driverChangeCostsPerKm: roundedCalculatedSubtotal.driverChangeCostsPerKm,
    dieselCostsPerKm: roundedCalculatedSubtotal.dieselCostsPerKm,
    adblueCostsPerKm: roundedCalculatedSubtotal.adblueCostsPerKm,
    lngCostsPerKm: roundedCalculatedSubtotal.lngCostsPerKm,
    totalFuelCostsPerKm: roundedCalculatedSubtotal.totalFuelCostsPerKm,
    tiresCostsPerKm: roundedCalculatedSubtotal.tiresCostsPerKm,
    cleaningCostsPerKm: roundedCalculatedSubtotal.cleaningCostsPerKm,
    overheadCostsPerKm: roundedCalculatedSubtotal.overheadCostsPerKm,
    flixStandardCostsPerKm: roundedCalculatedSubtotal.flixStandardCostsPerKm,
    legalCostsPerKm: roundedCalculatedSubtotal.legalCostsPerKm,
    damageRepairCostsPerKm: roundedCalculatedSubtotal.damageRepairCostsPerKm,
    otherCostsPerKm: roundedCalculatedSubtotal.otherCostsPerKm,
    infrastructureCostsPerKm: roundedCalculatedSubtotal.infrastructureCostsPerKm
  }));
};
