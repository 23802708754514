import {isEmpty} from 'ramda';
import constants from '../constants';

const entryKeys = {
  partners: 'busPartnerIds',
  countries: 'countryIds',
  costCalculationName: 'name',
  lineIds: 'lineNumbers',
  planningPeriods: 'date',
  calculationStatus: 'status'
};

export const getPlanningPeriod = (startDate, endDate) =>
  `${startDate} - ${endDate}`;

export const getKey = field => entryKeys[field];

export const getQueryParamsSelector = filters => {
  return Object.keys(filters).reduce((acc, curr) => {
    let key = getKey(curr);
    let param = {};

    if (
      curr === constants.fields.lineIds ||
      curr === constants.fields.partners ||
      curr === constants.fields.countries
    ) {
      const ids = filters[curr].map(filter => filter.id || filter.uuid);

      param = {[key]: ids};
    } else if (curr === constants.fields.planningPeriods) {
      const startDate =
        filters.planningPeriods[0] && filters.planningPeriods[0].startDate;
      const endDate =
        filters.planningPeriods[0] && filters.planningPeriods[0].endDate;

      param = {
        startDate: startDate && formatDate(startDate),
        endDate: endDate && formatDate(endDate)
      };
    } else if (curr === constants.fields.costCalculationName) {
      if (!isEmpty(filters.costCalculationName)) {
        param = {name: filters.costCalculationName[0].title};
      }
    } else if (curr === constants.fields.calculationStatus) {
      const statuses = filters.calculationStatus.map(filter => filter.title);

      param = {statuses};
    }

    if (filters[curr].length === 0) return acc;
    else
      return {
        ...acc,
        ...param
      };
  }, []);
};

export const getRequestKey = key => {
  const map = {
    [constants.keys.costCalculationName]: 'name',
    [constants.keys.countries]: 'countries',
    [constants.keys.partners]: 'busPartners',
    [constants.keys.lineIds]: 'lines'
  };

  return map[key];
};

export const formatDate = date => {
  const dateObj = new Date(date);

  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);

  return `${dateObj.getFullYear()}-${month}-${day}`;
};

export const formatDateString = dateString =>
  `${dateString.substring(0, 10)} ${dateString.substring(11, 16)}`;

// Rounds cost values to 2 decimals, hour values to 1 decimal and anything else to 0 decimals
export const roundCostValues = entry => {
  return Object.keys(entry).reduce((acc, curr) => {
    const isWorkingHours =
      curr.includes('WorkingHours') ||
      curr.includes('ScheduleHours') ||
      curr === 'totalHr' ||
      curr === 'totalWorkingHr';

    const isCost =
      curr.includes('CostsPerKm') ||
      curr.includes('CostPerKm') ||
      curr === 'productionCost' ||
      curr === 'lineWeightFactor' ||
      curr === 'totalProductionCosts';
    const value = entry[curr];

    const getRoundedValue = val => {
      let roundedValue;

      if (isCost) roundedValue = val.toFixed(2);
      else if (isWorkingHours) roundedValue = val.toFixed(1);
      else roundedValue = Math.round(val);

      if (roundedValue === '0.00' || roundedValue === '0.0') roundedValue = '0';

      return roundedValue;
    };

    return {
      ...acc,
      [curr]:
        value === null
          ? 'N/A'
          : typeof value === 'number' && !Number.isInteger(value)
          ? getRoundedValue(value)
          : value
    };
  }, {});
};

export const getOverviewRowId = row => ({
  productionCostEntryId: row.id,
  name: row.name,
  productionCostPerKm: row.productionCostPerKm,
  usedCurrencies: row.usedCurrencies,
  publishingStatus: row.publishingStatus,
  publishedAt: formatDateString(row.publishedAt),
  totalKm: row.totalKm
});

export const getAnalyticsRowId = row => ({
  productionCostEntryId: row.productionCostEntryId,
  productionCostLineId: row.productionCostLineId,
  busPartnerId: row.busPartnerId
});

export const calculateSubtotalRowId = 'calculated-subtotal';
export const isCalculatedSubtotalRow = row =>
  row.rowIdStringified.startsWith(calculateSubtotalRowId);

export const getBusPartnerName = (busPartners, busPartnerId) => {
  const partner = busPartners.list.find(partner => partner.id === busPartnerId);
  return partner && partner.name;
};

export const getBusPartner = (busPartners, busPartnerId) => {
  return busPartners.list.find(partner => partner.id === busPartnerId);
};

export const getVehicleTypeName = (vehicleTypes, busTypeId) => {
  return vehicleTypes?.find(vehicleType => vehicleType.id === busTypeId)?.name;
};

export const getCountryName = (countries, countryId) => {
  return countries?.find(country => country.id === countryId)?.name;
};

export const getCountry = (countries, countryId) => {
  return countries?.find(country => country.id === countryId);
};
