import {gql} from '@apollo/client';

const DELETE_PARTNER_COST_ENTRY_YEAR = gql`
  mutation deleteBusPartnerCostEntryYear($partnerId: ID!, $year: Int!) {
    deleteBusPartnerCostEntryYear(busPartnerId: $partnerId, year: $year) {
      year
    }
  }
`;

export default DELETE_PARTNER_COST_ENTRY_YEAR;
