import {gql} from '@apollo/client';

const GET_PRODUCTION_COST_ANALYTICS_VIEW = gql`
  query productionCostAnalyticsView(
    $page: Int!
    $size: Int!
    $name: String
    $busPartnerIds: [ID]
    $lineNumbers: [ID]
    $countryIds: [ID]
    $startDate: Date
    $endDate: Date
    $statuses: [ProductionCostEntryStatus]
  ) {
    productionCostAnalyticsView(
      pagination: {page: $page, size: $size}
      queryParams: {
        name: $name
        busPartners: $busPartnerIds
        lines: $lineNumbers
        countries: $countryIds
        startDate: $startDate
        endDate: $endDate
        statuses: $statuses
      }
    ) {
      paginationOutput {
        totalRecords
        totalPages
      }
      entries {
        productionCostEntryId
        productionCostLineId
        lineId
        lineNumber
        busPartnerId
        leasingCostCalculationType
        startDate
        endDate
        productionCostEntryName
        driverCostCalculationType
        productionCostEntryStatus
        totalKms
        totalProductionCosts
        totalProductionCostsPerKm
        leasingCostsPerKm
        interestCostsPerKm
        insuranceCostsPerKm
        driverSalaryCostsPerKm
        accommodationCostsPerKm
        dailyAllowanceCostsPerKm
        driverChangeCostsPerKm
        dieselCostsPerKm
        adblueCostsPerKm
        lngCostsPerKm
        totalFuelCostsPerKm
        maintenanceCostsPerKm
        tiresCostsPerKm
        cleaningCostsPerKm
        overheadCostsPerKm
        flixStandardCostsPerKm
        legalCostsPerKm
        damageRepairCostsPerKm
        motorVehicleTaxCostsPerKm
        otherCostsPerKm
        infrastructureCostsPerKm
        lastEditedAt
        publishedAt
        currency
      }
      absoluteValuesEntries {
        productionCostEntryId
        productionCostLineId
        lineId
        lineNumber
        busPartnerId
        leasingCostCalculationType
        startDate
        endDate
        productionCostEntryName
        driverCostCalculationType
        productionCostEntryStatus
        totalKms
        totalProductionCosts
        leasingCosts
        interestCosts
        insuranceCosts
        driverSalaryCosts
        accommodationCosts
        dailyAllowanceCosts
        driverChangeCosts
        dieselCosts
        adblueCosts
        lngCosts
        totalFuelCosts
        maintenanceCosts
        tiresCosts
        cleaningCosts
        overheadCosts
        flixStandardCosts
        legalCosts
        damageRepairCosts
        motorVehicleTaxCosts
        otherCosts
        infrastructureCosts
        lastEditedAt
        publishedAt
        currency
      }
    }
  }
`;

export default GET_PRODUCTION_COST_ANALYTICS_VIEW;
