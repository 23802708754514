import {gql} from '@apollo/client';

const UPDATE_PRODUCTION_COST_ENTRY = gql`
  mutation UpdateProductionCostEntry(
    $productionCostEntryId: ID!
    $name: String!
  ) {
    updateProductionCostEntry(
      input: {productionCostEntryId: $productionCostEntryId, name: $name}
    ) {
      id
      name
      status
      publishingStatus
      startDate
      endDate
      lineNumbers
      countryIds
      busPartnerIds
      lineCount
      vehicleCount
      operatingDays
      totalKm
      totalHr
      totalWorkingHr
      productionCost
      productionCostPerKm
      createdAt
      lastEditedAt
      publishedAt
      calculationVersion
      usedCurrencies
      planrDataVersion
    }
  }
`;

export default UPDATE_PRODUCTION_COST_ENTRY;
