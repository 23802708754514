import React, {useState, useMemo} from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Select,
  SelectGroup,
  SelectOption,
  Tag
} from '@flixbus/honeycomb-react';
import {
  Icon,
  IconArrowDownL,
  IconArrowUpL,
  IconCheckmarkStrongSolid,
  IconClose,
  IconCrossedSolid,
  IconReset
} from '@flixbus/honeycomb-icons-react';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteLineSchedule,
  productionCostLineScheduleUpdate,
  toggleLineSchedule
} from '../../../store/slice';
import {searchPlanrSchedules} from '../../../store/thunks';
import LineAutocompleteField from '../LineAutocompleteField';
import {
  productionCostLineStatuses,
  planrScheduleTypes
} from '../../../constants/statuses';
import {isNil} from 'ramda';
import {getSchedulesListPerDays} from './LineSchedulesSelect.utils';
import LineSchedulesSelectSummary from '../LineSchedulesSelectSummary/LineSchedulesSelectSummary';
import LineSchedulesTable from '../LineSchedulesSelectTable/LineSchedulesSelectTable';

const LineSchedulesSelect = ({lineSchedule, lineScheduleKey}) => {
  const dispatch = useDispatch();
  const [isSchedulesLoading, setIsSchedulesLoading] = useState(false);
  const startDate = useSelector(state =>
    state.productionCost.startDate !== null
      ? new Date(state.productionCost.startDate)
      : null
  );
  const endDate = useSelector(state =>
    state.productionCost.endDate !== null
      ? new Date(state.productionCost.endDate)
      : null
  );

  const schedulesListPerDay = useMemo(
    () => getSchedulesListPerDays(startDate, endDate, lineSchedule.schedules),
    [startDate, endDate, lineSchedule.schedules]
  );

  const getTypeOptions = () => {
    return planrScheduleTypes.map(type => ({
      key: `schedule-type-select-key-${lineScheduleKey}-${type.name}`,
      id: `schedule-type-select-${lineScheduleKey}-${type.name}`,
      value: type.key,
      name: `planrScheduleType-${lineScheduleKey}`,
      label: type.name,
      checked: type.key === lineSchedule.scheduleType,
      onChange: () => onScheduleTypeChange(type)
    }));
  };

  const resetTable = {schedules: [], isValid: false};

  const onScheduleTypeChange = type => {
    updateLineSchedule(lineScheduleKey, {
      scheduleType: type.key,
      ...resetTable
    });
  };

  if (isNil(startDate) || isNil(endDate)) {
    return null;
  }

  if (!lineSchedule.toggle) {
    return (
      <div className={`production-cost-line-schedule-select`}>
        <Box>
          <div className={`production-cost-line-schedule-actions`}>
            <ButtonGroup alignment="end">
              <Button
                display="square"
                aria-label="Open"
                onClick={() => dispatch(toggleLineSchedule(lineScheduleKey))}
              >
                <Icon InlineIcon={IconArrowDownL} aria-hidden="true" />
              </Button>
              <Button
                display="square"
                aria-label="Delete"
                onClick={() => dispatch(deleteLineSchedule(lineScheduleKey))}
              >
                <Icon InlineIcon={IconClose} aria-hidden="true" />
              </Button>
            </ButtonGroup>
          </div>
          <div className={`production-cost-line-schedule-short`}>
            <div>
              {!lineSchedule.isValid ? (
                <Icon
                  InlineIcon={IconCrossedSolid}
                  style={{fill: 'red'}}
                  size={4}
                />
              ) : (
                <Icon
                  InlineIcon={IconCheckmarkStrongSolid}
                  style={{fill: 'green'}}
                  size={4}
                />
              )}
            </div>
            <div>
              <Tag>Line {lineSchedule.lineNumber}</Tag>
            </div>
            <div>
              <strong>Selected:</strong>{' '}
              {lineSchedule.schedules
                .filter(schedule => schedule.selected)
                .map(schedule => schedule.name)
                .join(', ')}
            </div>
          </div>
        </Box>
      </div>
    );
  }

  const updateLineSchedule = (lineScheduleKey, line) => {
    dispatch(
      productionCostLineScheduleUpdate({
        lineScheduleKey,
        lineSchedule: line
      })
    );

    const uuidExists = line.lineUuid || lineSchedule.lineUuid;
    const filterWasChanged =
      lineSchedule.lineUuid !== line.lineUuid ||
      lineSchedule.scheduleStatus !== line.scheduleStatus ||
      lineSchedule.scheduleType !== line.scheduleType;

    if (uuidExists && filterWasChanged) {
      dispatch(
        searchPlanrSchedules(
          lineScheduleKey,
          {
            lineUuid: line.lineUuid || lineSchedule.lineUuid,
            scheduleStatus: line.scheduleStatus || lineSchedule.scheduleStatus,
            scheduleType: line.scheduleType || lineSchedule.scheduleType,
            ...line
          },
          setIsSchedulesLoading
        )
      );
    }
  };

  return (
    <div className={`production-cost-line-schedule-select`}>
      <Box
        key={`production-cost-line-schedule-select-box-key-${lineScheduleKey}`}
      >
        <LineSchedulesSelectSummary schedulesListPerDay={schedulesListPerDay} />

        <div className={`production-cost-line-schedule-actions`}>
          <ButtonGroup alignment="end">
            <Button
              display="square"
              aria-label="Close"
              onClick={() => dispatch(toggleLineSchedule(lineScheduleKey))}
            >
              <Icon InlineIcon={IconArrowUpL} aria-hidden="true" />
            </Button>
            <Button
              display="square"
              aria-label="Delete"
              onClick={() => dispatch(deleteLineSchedule(lineScheduleKey))}
            >
              <Icon InlineIcon={IconClose} aria-hidden="true" />
            </Button>
          </ButtonGroup>
        </div>

        <div>
          <h2>Select schedules for calculation</h2>
        </div>
        <div className={`production-cost-line-schedule-filter`}>
          <div className={`production-cost-line-schedule-field`}>
            <LineAutocompleteField
              lineScheduleKey={lineScheduleKey}
              line={lineSchedule.lineNumber}
              onSelected={item =>
                updateLineSchedule(lineScheduleKey, {
                  lineNumber: item.title,
                  lineUuid: item.value,
                  ...resetTable
                })
              }
            />
          </div>
          <div className={`production-cost-line-schedule-field`}>
            <Select
              id={`calculation-status-${lineScheduleKey}`}
              key={`calculation-status-key-${lineScheduleKey}`}
              label="PlanR schedule status"
              placeholder="Select a Status"
              value={lineSchedule.scheduleStatus || ''}
              onChange={e => {
                updateLineSchedule(lineScheduleKey, {
                  scheduleStatus: e.target.value,
                  ...resetTable
                });
              }}
              disabled={!lineSchedule.lineNumber}
            >
              {productionCostLineStatuses.map(status => (
                <SelectOption key={status.key} value={status.key}>
                  {status.name}
                </SelectOption>
              ))}
            </Select>
          </div>
          <div className={`production-cost-line-schedule-field`}>
            <SelectGroup
              key={`schedule-type-key-${lineScheduleKey}`}
              aria-label="Schedule type"
              label="Schedule type"
              options={getTypeOptions()}
              id={`schedule-type-${lineScheduleKey}`}
              disabled={!lineSchedule.lineNumber}
            />
          </div>
          <div className={`production-cost-line-schedule-field`}>
            <Button
              style={{marginTop: '24px'}}
              display="square"
              aria-label="Reset filters"
              onClick={() =>
                updateLineSchedule(lineScheduleKey, {
                  scheduleStatus: null,
                  scheduleType: null,
                  ...resetTable
                })
              }
              disabled={!lineSchedule.lineNumber}
            >
              <Icon InlineIcon={IconReset} aria-hidden="true" />
            </Button>
          </div>
        </div>

        <LineSchedulesTable
          lineSchedule={lineSchedule}
          lineScheduleKey={lineScheduleKey}
          isSchedulesLoading={isSchedulesLoading}
          schedulesListPerDay={schedulesListPerDay}
        />
      </Box>
    </div>
  );
};

export default React.memo(LineSchedulesSelect);
