import React, {useState, useEffect} from 'react';
import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOptions
} from '@flixbus/honeycomb-react';

import {countriesList} from '../constant/countriesList';
import {isNil} from 'ramda';

export const countryNameOptions = countriesList.map(country => ({
  title: country.name
}));

const CountryAutocompleteField = ({
  countryName,
  onSelected,
  valid,
  errorMessage
}) => {
  const [name, setName] = useState(isNil(countryName) ? '' : countryName);

  useEffect(() => {
    setName(isNil(countryName) ? '' : countryName.trim());
  }, [countryName]);

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const filterAutocompleteData = (searchQuery, data) =>
    new Promise(resolve => {
      setTimeout(() => {
        const res = data.filter(item =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        resolve(res);
      }, 200);
    });

  return (
    <Autocomplete
      options={data ?? []}
      value={name.trim()}
      onDebounce={e => {
        setLoading(true);

        filterAutocompleteData(e.target.value, countryNameOptions).then(
          options => {
            setData(options);
            setLoading(false);
          }
        );
      }}
      onChange={e => {
        setLoading(true);
        onSelected(e.target.value.trim());
      }}
      onSelect={item => {
        setData(null);
        setName(item.title.trim());
        onSelected(item.title.trim());
      }}
    >
      <AutocompleteInput
        id="country-name-autocomplete"
        placeholder=""
        label="Country"
        infoError={errorMessage ? errorMessage : `Country is mandatory`}
        valid={valid}
        loading={loading}
      />
      <AutocompleteOptions label="Country" optionsToDisplay={5} />
    </Autocomplete>
  );
};

export default CountryAutocompleteField;
