import React from 'react';
import {Text} from '@flixbus/honeycomb-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import LineVehicle from './LineVehicle/LineVehicle';
import style from './LineVehicles.module.scss';

const LineVehicles: React.FC = () => {
  const busValues = useAppSelector(
    ({productionCost}) => productionCost.busValues
  );
  const errors = useAppSelector(
    ({productionCost}) => productionCost.validationErrors.busValues
  );

  return (
    <div className={style.container}>
      <Text extraClasses="font-bold no-margin">
        Define vehicle settings for all schedules
      </Text>

      {busValues?.map(vehicle => (
        <LineVehicle
          vehicle={vehicle}
          errors={errors[vehicle.vehicleId]}
          key={vehicle.vehicleId}
        />
      ))}
    </div>
  );
};

export default React.memo(LineVehicles);
