import {gql} from '@apollo/client';

const GET_COUNTRY_SALARY_DEFINITIONS = gql`
  query countrySalaryDefinitions($countryId: ID!) {
    countrySalaryDefinitions(countryId: $countryId) {
      salaryType
      dayOfWeek
      startTime
      endTime
    }
  }
`;

export default GET_COUNTRY_SALARY_DEFINITIONS;
