import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {AccommodationCostPerNightProps} from './AccommodationCostPerNight.types';
import styles from './AccommodationCostPerNight.module.scss';

const AccommodationCostPerNight: React.FC<AccommodationCostPerNightProps> = ({
  value,
  entryId,
  currency,
  errors
}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-accommodation-cost-per-night`;

  const tooltipContent = useMemo(
    () => fieldInfoContent.accommodationCostPerNight,
    []
  );

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const accommodationCostPerNight =
        validateInputNumber(editedValue, value ? value.toString() : '') ??
        undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {accommodationCostPerNight}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="accommodation-cost-per-night"
        label="Price/accommodation"
        inlineLabelLeft={currency}
        value={formattedValue}
        tooltipContent={tooltipContent}
        valid={!errors}
        infoError={errors?.optionality ?? errors?.nullable}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(AccommodationCostPerNight);
