import graphqlClient from '../../../services/graphqlClient';
import {isNil, pick} from 'ramda';
import {VehicleFuelType} from 'Pages/VehicleTypes/store/types';
import getVehicleTypesQuery from '../graphql/getVehicleTypesQuery';
import createVehicleTypeMutation from '../graphql/createVehicleTypeMutation';
import deleteVehicleTypeMutation from '../graphql/deleteVehicleTypeMutation';
import updateVehicleTypeMutation from '../graphql/updateVehicleTypeMutation';
import {
  addToVehicleTypes,
  deleteFromVehicleTypes,
  receivedVehicleTypes,
  resetVehicleType,
  updateVehicleTypes
} from './slice';

export const getVehicleTypes = () => {
  return dispatch => {
    graphqlClient.query({query: getVehicleTypesQuery}).then(result => {
      const {
        data: {busTypes}
      } = result;
      dispatch(receivedVehicleTypes(busTypes));
    });
  };
};

export const saveVehicleType = () => {
  return (dispatch, _getState) => {
    const state = _getState();
    const {vehicleType} = state.vehicleTypes;

    const variables = {
      name: vehicleType.name,
      fuelType: vehicleType.fuelType,
      dieselConsumption: vehicleType.dieselConsumption,
      adblueConsumption: vehicleType.adblueConsumption,
      lngConsumption: vehicleType.lngConsumption,
      doubleDecker: vehicleType.doubleDecker,
      numberOfTires: vehicleType.numberOfTires
    };
    if (variables.fuelType === VehicleFuelType.DIESEL) {
      variables.lngConsumption = null;
    } else {
      variables.dieselConsumption = null;
      variables.adblueConsumption = null;
    }

    if (isNil(vehicleType.id)) {
      graphqlClient
        .mutate({
          mutation: createVehicleTypeMutation,
          variables
        })
        .then(result => {
          const {
            data: {createBusType}
          } = result;

          dispatch(addToVehicleTypes(createBusType));
          dispatch(resetVehicleType());
        });
    } else {
      graphqlClient
        .mutate({
          mutation: updateVehicleTypeMutation,
          variables: {
            ...variables,
            id: vehicleType.id
          }
        })
        .then(result => {
          const {
            data: {updateBusType}
          } = result;

          dispatch(updateVehicleTypes(updateBusType));
          dispatch(resetVehicleType());
        });
    }
  };
};

export const deleteVehicleType = (vehicleType, addToast) => {
  return dispatch => {
    graphqlClient
      .mutate({
        mutation: deleteVehicleTypeMutation,
        variables: pick(['id'], vehicleType),
        errorPolicy: 'all'
      })
      .then(result => {
        const {
          data: {deleteBusType},
          errors
        } = result;
        if (errors) {
          addToast({
            type: 'danger',
            content: errors[0]?.extensions?.errorMessage || errors[0].message
          });
        } else {
          addToast({
            type: 'success',
            content: `Vehicle type ${vehicleType.name} deleted successfully`
          });
        }
        dispatch(deleteFromVehicleTypes(deleteBusType));
      });
  };
};
