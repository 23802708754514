import {CountryDriverConceptType, RegionalCostsState} from './types';

export const countryCostYearData = {
  year: null,
  years: []
};

export const initCountrySalaryDefinition = {
  id: null,
  salaryType: null,
  dayOfWeek: null,
  startTime: '01:00',
  endTime: '16:00'
};

export const initCountryDriverConceptType: CountryDriverConceptType = {
  id: null,
  name: null,
  description: null,
  multiplicationFactor: null,
  dailyAllowance: null
};

export const countryCostData = {
  vehicleCost: null,
  vehicleTypeCosts: {
    list: null
  },
  otherCost: null,
  driverCost: null,
  countryDriverConceptTypes: {
    list: null,
    countryDriverConceptType: null
  },
  countryPrefilledEmptyValues: null,
  countrySalaryDefinition: {
    list: null,
    messages: []
  },
  qualityChecked: false
};

export const regionalCostsInitialState: RegionalCostsState = {
  country: null,
  ...countryCostYearData,
  ...countryCostData
};
