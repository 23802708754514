import styles from './RegionalCostsDriverCostContent.module.scss';
import React, {useCallback, useMemo} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {IconEditSolid} from '@flixbus/honeycomb-icons-react';
import {RegionalCostsDriverCost} from 'Pages/RegionalCosts/store/types';
import {updateDriverCost} from 'Pages/RegionalCosts/store/thunks';
import {getScaledNumber} from 'Pages/Common/Utils';
import {DriverCosts, DriverCostsType} from 'components/DriverCosts';
import {TableRow} from 'components/Table';
import {
  baseSalaryHeaders,
  driverChangeCostHeaders,
  accommodationCostHeaders
} from './RegionalCostsDriverCostContent.data';
import {
  TableWithPanelAction,
  TableWithPanelHeader,
  TableWithPanelRow
} from 'components/TableWithPanel';
import RegionalCostsExplanationNotice from 'components/RegionalCostsExplanationNotice/RegionalCostsExplanationNotice';
import {RegionalCostsDriverCostContentProps} from './RegionalCostsDriverCostContent.types';
import {useLocalUnitsContext} from '../../../../Common/LocalUnits';

const RegionalCostsDriverCostContent: React.FC<
  RegionalCostsDriverCostContentProps
> = ({countryId, year, driverCost, currency}) => {
  const dispatch = useAppDispatch();
  const {localUnits} = useLocalUnitsContext();

  const onUpdateDriverCosts = useCallback(
    (row: TableRow) => {
      const updatedDriverCost = {...driverCost, ...row};
      dispatch(updateDriverCost(updatedDriverCost, countryId, year));
    },
    [driverCost, countryId, year, dispatch]
  );

  const getTableConfig = useCallback(
    (
      type: DriverCostsType,
      headers: TableWithPanelHeader[],
      driverCost: RegionalCostsDriverCost
    ) => {
      const row: TableWithPanelRow = driverCost
        ? headers.map(({key}) => ({
            key,
            value: driverCost[key],
            displayValue: getScaledNumber(driverCost[key])
          }))
        : [];

      const actions: TableWithPanelAction[] = [
        {
          type: 'panel',
          icon: IconEditSolid,
          panel: {
            title: `Update ${type.toLowerCase()} cost`,
            onSuccess: onUpdateDriverCosts
          }
        }
      ];

      return {headers, rows: [row], actions};
    },
    [onUpdateDriverCosts]
  );

  const baseSalaryCosts = useMemo(
    () =>
      getTableConfig(
        DriverCostsType.BaseSalary,
        baseSalaryHeaders(currency),
        driverCost
      ),
    [getTableConfig, driverCost, currency]
  );

  const driverChangeCosts = useMemo(
    () =>
      getTableConfig(
        DriverCostsType.DriverChange,
        driverChangeCostHeaders(currency, localUnits),
        driverCost
      ),
    [getTableConfig, driverCost, currency, localUnits]
  );

  const accommodationCosts = useMemo(
    () =>
      getTableConfig(
        DriverCostsType.Accommodation,
        accommodationCostHeaders(currency),
        driverCost
      ),
    [getTableConfig, driverCost, currency]
  );

  return (
    <>
      <div className={styles.alignRight}>
        <RegionalCostsExplanationNotice />
      </div>
      <DriverCosts
        baseSalaryCosts={baseSalaryCosts}
        driverChangeCosts={driverChangeCosts}
        accommodationCosts={accommodationCosts}
        currency={currency}
      />
    </>
  );
};

export default React.memo(RegionalCostsDriverCostContent);
