import {gql} from '@apollo/client';

const CREATE_PRODUCTION_COST_ENTRY = gql`
  mutation createProductionCostEntry(
    $name: String!
    $startDate: Date!
    $endDate: Date!
    $status: ProductionCostEntryStatus! = DRAFT
    $costYear: Int!
    $planrBusPartnerIdFilters: [PlanrBusPartnerIdFilterInput!] = []
    $planrVehicleIdFilters: [PlanrVehicleIdFilterInput!] = []
  ) {
    createProductionCostEntry(
      input: {
        name: $name
        startDate: $startDate
        endDate: $endDate
        status: $status
        costYear: $costYear
        planrBusPartnerIdFilters: $planrBusPartnerIdFilters
        planrVehicleIdFilters: $planrVehicleIdFilters
      }
    ) {
      id
    }
  }
`;

export default CREATE_PRODUCTION_COST_ENTRY;
