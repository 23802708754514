import React, {useCallback, useEffect, useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {ProductionCostBusProvidedValuesEntry} from 'Pages/ProductionCost/store/types';
import {useBusPartner} from 'Pages/PartnerCosts/hooks/useBusPartner';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import GET_VEHICLE_TYPE_COSTS_QUERY from './VehicleTypeCostsSelect/VehicleTypeCostsSelectQuery';
import VehicleTypeCostsSelect from './VehicleTypeCostsSelect/VehicleTypeCostsSelect';
import {VehicleTypeCost} from './VehicleTypeCostsSelect/VehicleTypeCostsSelect.types';
import {VehicleTypeProps} from './VehicleType.types';

const VehicleType: React.FC<VehicleTypeProps> = ({
  busPartnerId,
  vehicleId,
  busPartnerBusTypeCostEntryId,
  countryBusTypeCostEntryId,
  errors,
  onUpdateBusValues
}) => {
  const busPartner = useBusPartner(busPartnerId ?? null);
  const countries = useAppSelector(({countries}) => countries.list) ?? [];
  const vehicleTypes =
    useAppSelector(({vehicleTypes}) => vehicleTypes.list) ?? [];
  const productionCostYear = useAppSelector(
    ({productionCost}) => productionCost.costYear
  );

  const busTypeCostEntryId =
    busPartnerBusTypeCostEntryId ?? countryBusTypeCostEntryId;

  const {loading, data} = useQuery(GET_VEHICLE_TYPE_COSTS_QUERY, {
    variables: {
      countryId: busPartner?.countryId,
      busPartnerId: busPartner?.id,
      year: productionCostYear
    },
    errorPolicy: 'all'
  });

  const busPartnerBusTypeCostEntries: VehicleTypeCost[] = useMemo(
    () => data?.getBusPartnerCostEntryYear?.busTypeCostEntries ?? [],
    [data?.getBusPartnerCostEntryYear?.busTypeCostEntries]
  );

  const countryBusTypeCostEntries: VehicleTypeCost[] = useMemo(
    () => data?.countryCostEntry?.busTypeCostEntries ?? [],
    [data?.countryCostEntry?.busTypeCostEntries]
  );

  const handleSelectChange = useCallback(
    (updates: Partial<ProductionCostBusProvidedValuesEntry>) => {
      onUpdateBusValues(updates);
    },
    [onUpdateBusValues]
  );

  useEffect(() => {
    if (loading) return;

    const isOptionAvailable =
      busPartnerBusTypeCostEntries.some(e => e.id === busTypeCostEntryId) ||
      countryBusTypeCostEntries.some(e => e.id === busTypeCostEntryId);

    // no need to proceed because the selected option is available
    if (isOptionAvailable) return;

    // try to preselect
    let busPartnerBusTypeCostEntryId: string | undefined = undefined;
    let countryBusTypeCostEntryId: string | undefined = undefined;

    // if there is only one bus partner entry and no country entry, set it
    if (
      busPartnerBusTypeCostEntries.length === 1 &&
      countryBusTypeCostEntries.length === 0
    ) {
      busPartnerBusTypeCostEntryId = busPartnerBusTypeCostEntries[0].id;
    }

    // if there is only one country entry and no bus partner entry, set it
    if (
      countryBusTypeCostEntries.length === 1 &&
      busPartnerBusTypeCostEntries.length === 0
    ) {
      countryBusTypeCostEntryId = countryBusTypeCostEntries[0].id;
    }

    handleSelectChange({
      busPartnerBusTypeCostEntryId,
      countryBusTypeCostEntryId
    });
  }, [
    busPartnerBusTypeCostEntries,
    countryBusTypeCostEntries,
    busTypeCostEntryId,
    loading,
    handleSelectChange
  ]);

  return (
    <div data-testid={`line-vehicle-vehicle-type`}>
      <VehicleTypeCostsSelect
        busPartner={busPartner}
        countries={countries}
        vehicleTypes={vehicleTypes}
        selectedBusTypeCostEntryId={busTypeCostEntryId}
        vehicleId={vehicleId}
        busPartnerBusTypeCostEntries={busPartnerBusTypeCostEntries}
        countryBusTypeCostEntries={countryBusTypeCostEntries}
        errors={errors}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default React.memo(VehicleType);
