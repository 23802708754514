import {Button, Heading, Popup, PopupSection} from '@flixbus/honeycomb-react';
import {Icon, IconNotification} from '@flixbus/honeycomb-icons-react';
import React, {Dispatch, PropsWithChildren, SetStateAction} from 'react';

type PropType = {
  alertActive: boolean;
  setAlertActive: Dispatch<SetStateAction<boolean>>;
  children: string;
};

const CpcAlert: React.FC<PropsWithChildren<PropType>> = props => {
  return (
    <Popup
      aria-labelledby="alert-popup-title"
      active={props.alertActive}
      onOverlayClick={() => props.setAlertActive(false)}
    >
      <PopupSection type="icon">
        <Icon
          InlineIcon={IconNotification}
          size={8}
          style={{fill: 'var(--hcr-secondary-ui-color)'}}
          title="alert icon"
        />
      </PopupSection>
      <PopupSection type="title">
        <Heading id="alert-heading-popup-title" size={3}>
          {props.children}
        </Heading>
      </PopupSection>
      <PopupSection type="actions">
        <Button
          id="alert-popup-close"
          appearance="secondary"
          onClick={() => {
            props.setAlertActive(false);
          }}
        >
          Close
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default React.memo(CpcAlert);
