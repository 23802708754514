import {gql} from '@apollo/client';
import {isEmptyArray, replaceAll} from 'ramda-adjunct';

const RECALCULATE_PRODUCTION_COST_DEFAULT_QUERY = gql`
  mutation recalculateProductionCost {
    recalculateProductionCost {
      id
    }
  }
`;

const getRecalculateCostsQuery = selectedRows => {
  if (isEmptyArray(selectedRows))
    return RECALCULATE_PRODUCTION_COST_DEFAULT_QUERY;

  let query = '';
  for (const row of selectedRows) {
    const productionCostEntryId = row?.productionCostEntryId;
    const queryId = 'queryId' + replaceAll('-', '', row.productionCostEntryId);
    query += `
      ${queryId} : recalculateProductionCost(
        productionCostEntryId: "${productionCostEntryId}"
      ) {
        totalKm
        productionCostPerKm
        usedCurrencies
      }
    `;
  }
  return gql`
    mutation recalculateProductionCost {
      ${query}
    }`;
};

export default getRecalculateCostsQuery;
