import React, {useMemo} from 'react';
import {Select, SelectOption, SelectOptGroup} from '@flixbus/honeycomb-react';
import {getCountryName} from 'Pages/Home/utils';
import {VehicleFuelType} from 'Pages/VehicleTypes/store/types';
import {
  VehicleTypeCost,
  VehicleTypeCostsSelectProps
} from './VehicleTypeCostsSelect.types';

const VehicleTypeCostsSelect: React.FC<VehicleTypeCostsSelectProps> = ({
  busPartner,
  countries,
  vehicleTypes,
  selectedBusTypeCostEntryId,
  vehicleId,
  busPartnerBusTypeCostEntries,
  countryBusTypeCostEntries,
  errors,
  onChange
}) => {
  const countryName: string = useMemo(
    () => getCountryName(countries, busPartner?.countryId),
    [countries, busPartner?.countryId]
  );

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;

    const selectedBusPartnerBusTypeCostEntry =
      busPartnerBusTypeCostEntries.find(entry => entry.id === selectedValue);
    if (selectedBusPartnerBusTypeCostEntry) {
      onChange({
        busPartnerBusTypeCostEntryId: selectedBusPartnerBusTypeCostEntry.id,
        countryBusTypeCostEntryId: undefined
      });
      return;
    }

    const selectedCountryBusTypeCostEntry = countryBusTypeCostEntries.find(
      entry => entry.id === selectedValue
    );
    if (selectedCountryBusTypeCostEntry) {
      onChange({
        busPartnerBusTypeCostEntryId: undefined,
        countryBusTypeCostEntryId: selectedCountryBusTypeCostEntry.id
      });
    }
  };

  const getSelectOption = (busTypeCost: VehicleTypeCost) => {
    const vehicleType = vehicleTypes.find(
      (vehicleType: {id: string}) => vehicleType.id === busTypeCost.busTypeId
    );
    return (
      <SelectOption
        key={busTypeCost.id}
        value={busTypeCost.id}
        data-group="Bus Partner"
      >
        {`${vehicleType?.name}${
          busTypeCost.customIdentifier
            ? ` - ${busTypeCost.customIdentifier}`
            : ''
        }${
          busTypeCost.vehicleProductionYear
            ? ` - ${busTypeCost.vehicleProductionYear}`
            : ''
        }${
          vehicleType?.fuelType !== VehicleFuelType.DIESEL
            ? ` - ${vehicleType?.fuelType}`
            : ''
        }`}
      </SelectOption>
    );
  };

  return (
    <Select
      id={`${vehicleId}-vehicle-type`}
      label="Vehicle type*"
      placeholder="Select a vehicle type"
      value={selectedBusTypeCostEntryId || ''}
      disabled={!busPartner?.id}
      valid={!errors}
      infoError={errors?.optionality ?? errors?.nullable}
      onChange={handleSelectChange}
    >
      <>
        {busPartnerBusTypeCostEntries?.length > 0 && (
          <SelectOptGroup label="Bus Partner">
            {busPartnerBusTypeCostEntries?.map((busTypeCost: VehicleTypeCost) =>
              getSelectOption(busTypeCost)
            )}
          </SelectOptGroup>
        )}

        {countryBusTypeCostEntries?.length > 0 && (
          <SelectOptGroup label={`Country: ${countryName}`}>
            {countryBusTypeCostEntries?.map((busTypeCost: VehicleTypeCost) =>
              getSelectOption(busTypeCost)
            )}
          </SelectOptGroup>
        )}
      </>
    </Select>
  );
};

export default React.memo(VehicleTypeCostsSelect);
