const base = '/regional-costs';

export const RegionalCostsPath = {
  base,
  vehicleCosts: `${base}/vehicle-costs`,
  driverCosts: `${base}/driver-costs`,
  otherCosts: `${base}/other-costs`,
  emptyDrivingDefaults: `${base}/empty-driving-defaults`,
  driverConceptTypes: `${base}/driver-concept-types`,
  driverSalaryPeriods: `${base}/driver-salary-periods`
};
