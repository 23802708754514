import {Button} from '@flixbus/honeycomb-react';
import React from 'react';
import {Icon} from '@flixbus/honeycomb-icons-react';

const IconButton = ({InlineIcon, ...props}) => {
  return (
    <Button
      {...props}
      style={{minWidth: 36, maxWidth: 36, height: 36, padding: 0}}
    >
      <Icon InlineIcon={InlineIcon} aria-hidden="true" />
    </Button>
  );
};

export default IconButton;
