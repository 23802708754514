import {useState, useEffect} from 'react';
import {UnitsSystem} from './types';
import {getUnitsSystemOrDefault} from './Utils';

const LOCAL_UNIT_KEY = 'gbx-bdts-user-local-units';

export const getUnitsSystemFromLocalStorage = () =>
  localStorage.getItem(LOCAL_UNIT_KEY);

export const useLocalUnits = (): [
  UnitsSystem,
  (units: UnitsSystem) => void
] => {
  const [localUnits, setLocalUnits] = useState<UnitsSystem>(
    getUnitsSystemOrDefault(getUnitsSystemFromLocalStorage())
  );

  useEffect(() => {
    localStorage.setItem(LOCAL_UNIT_KEY, localUnits?.unitsSystem || '');
  }, [localUnits]);

  return [localUnits, setLocalUnits];
};
