import {gql} from '@apollo/client';

const CREATE_COUNTRY_COST_ENTRY_YEAR = gql`
  mutation createCountryCostEntryYear($countryId: ID!) {
    createCountryCostEntryYear(countryId: $countryId) {
      year
      busCostEntry {
        adblueCost
        flixStandardCost
        dieselCost
        lngCost
        tiresCost
        currency
      }
      busTypeCostEntries {
        id
        busTypeId
        year
        customIdentifier
        insuranceCost
        interestCost
        leasingCost
        maintenanceCostPerMonth
        maintenanceCostPerDistanceUnit
        motorVehicleTax
        vehicleProductionYear
      }
      otherCostEntry {
        year
        overheadCost
        legalCost
        cleaningCost
        repairCostPerDistanceUnit
        repairCostPerMonth
        otherCost
      }
      driverCostEntry {
        year
        daytimeWeekdaysWorkingHourCost
        daytimeWeekendsWorkingHourCost
        nighttimeWeekdaysWorkingHourCost
        nighttimeWeekendsWorkingHourCost
        replacementCostPerKm
        replacementCostPerHr
        accommodationCostPerNight
      }
      qualityChecked
    }
  }
`;

export default CREATE_COUNTRY_COST_ENTRY_YEAR;
