import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Input, Textarea} from '@flixbus/honeycomb-react';
import {isNil} from 'ramda';
import {
  resetCountryDriverConceptType,
  setCountryDriverConceptType
} from '../../store/slice';
import {saveDriverConceptType} from '../../store/thunks';
import ContentLoader from '../../../Common/ContentLoader';
import {isBlank} from 'ramda-adjunct';
import CpcPanel from '../../../Common/CpcPanel';
import {validateInputNumber} from '../../../Common/Utils';

const DriverConceptTypePanel = () => {
  const dispatch = useDispatch();
  const country = useSelector(state =>
    state?.countries?.list?.find(
      country => country.id === state?.regionalCosts?.country
    )
  );
  const countryDriverConceptType = useSelector(
    state =>
      state?.regionalCosts.countryDriverConceptTypes.countryDriverConceptType
  );
  const [showPanel, setShowPanel] = useState(false);

  const [nameIsValid, setNameIsValid] = useState(null);
  const [multiplicationFactorIsValid, setMultiplicationFactorIsValid] =
    useState(null);
  const [dailyAllowanceIsValid, setDailyAllowanceIsValid] = useState(null);

  useEffect(() => {
    setShowPanel(!isNil(countryDriverConceptType));
  }, [countryDriverConceptType]);

  const closePanel = () => {
    setShowPanel(false);
    resetValidationState();
    dispatch(resetCountryDriverConceptType());
  };

  const resetValidationState = () => {
    setNameIsValid(null);
    setMultiplicationFactorIsValid(null);
    setDailyAllowanceIsValid(null);
  };

  const handleSaveCountryDriverConceptType = () => {
    resetValidationState();
    let formIsValid = true;

    if (isBlank(countryDriverConceptType.name)) {
      setNameIsValid(false);
      formIsValid = false;
    }

    if (isBlank(countryDriverConceptType.multiplicationFactor)) {
      setMultiplicationFactorIsValid(false);
      formIsValid = false;
    }

    if (isBlank(countryDriverConceptType.dailyAllowance)) {
      setDailyAllowanceIsValid(false);
      formIsValid = false;
    }

    if (formIsValid) {
      dispatch(saveDriverConceptType());
    }
  };

  const onChangeInput = (changeEvent, field) => {
    const {value} = changeEvent.target;
    const newValue = validateInputNumber(
      value,
      countryDriverConceptType[field]
    );

    dispatch(
      setCountryDriverConceptType({
        ...countryDriverConceptType,
        [field]: newValue || null
      })
    );
  };

  return (
    <CpcPanel
      title="Country driver concept type"
      activeAction={showPanel}
      closeAction={closePanel}
      saveAction={handleSaveCountryDriverConceptType}
    >
      {!isNil(countryDriverConceptType) ? (
        <>
          <div>
            <Input
              name="country-driver-concept-type-name"
              id="country-driver-concept-type-name"
              label="Driver concept type"
              valid={nameIsValid}
              value={countryDriverConceptType?.name || ''}
              onChange={e =>
                dispatch(
                  setCountryDriverConceptType({
                    ...countryDriverConceptType,
                    name: e.target.value
                  })
                )
              }
            />
          </div>

          <div className={`hcr-space-4-top`}>
            <Textarea
              name="country-driver-concept-type-description"
              id="country-driver-concept-type-description"
              label="Description"
              value={countryDriverConceptType.description || ''}
              onChange={e =>
                dispatch(
                  setCountryDriverConceptType({
                    ...countryDriverConceptType,
                    description: e.target.value
                  })
                )
              }
            />
          </div>

          <div className={`hcr-space-4-top`}>
            <Input
              type={`number`}
              name="country-driver-concept-type-multiplicationFactor"
              id="country-driver-concept-type-multiplicationFactor"
              label="Multiplication factor for working hour adjustment"
              valid={multiplicationFactorIsValid}
              value={countryDriverConceptType?.multiplicationFactor || ''}
              step={'0.1'}
              onChange={event => onChangeInput(event, 'multiplicationFactor')}
            />
          </div>

          <div className={`hcr-space-4-top`}>
            <Input
              type={`number`}
              name="country-driver-concept-type-dailyAllowance"
              id="country-driver-concept-type-dailyAllowance"
              label="Meals/daily allowance"
              inlineLabelLeft={`${country?.currency}/operating day`}
              placeholder="0.1"
              valid={dailyAllowanceIsValid}
              value={countryDriverConceptType?.dailyAllowance || ''}
              onChange={event => onChangeInput(event, 'dailyAllowance')}
              step={`0.1`}
            />
          </div>
        </>
      ) : (
        <ContentLoader />
      )}
    </CpcPanel>
  );
};

export default React.memo(DriverConceptTypePanel);
