import React, {useCallback, useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {AutocompleteOptionType, Text} from '@flixbus/honeycomb-react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import getCountryPrefilledEmptyValuesQuery from 'Pages/RegionalCosts/graphql/getCountryPrefilledEmptyValuesQuery';
import {receivedCountryPrefilledEmptyValuesForVehicle} from 'Pages/ProductionCost/store/slice';
import {useBusPartner} from 'Pages/PartnerCosts/hooks/useBusPartner';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {useProductionCostBus} from 'Pages/ProductionCost/components/DataImportStepTwo/hooks/useProductionCostBus';
import PartnerNameAutocompleteField from './PartnerNameAutocompleteField/PartnerNameAutocompleteField';
import {PartnerNameProps} from './PartnerName.types';
import style from './PartnerName.module.scss';
import useAccommodationCost from './useAccommodationCostPerNight';

const PartnerName: React.FC<PartnerNameProps> = ({
  vehicleId,
  busPartnerId,
  entryIds,
  errors,
  onUpdateBusValues,
  onUpdateBusScheduleValues
}) => {
  const busPartner = useBusPartner(busPartnerId ?? null);
  const partners = useAppSelector(({partners}) => partners.list ?? []);
  const productionCostBuses = useProductionCostBus(vehicleId);

  const countryPrefilledEmptyValues = useAppSelector(
    ({productionCost}) => productionCost.countryPrefilledEmptyValues
  );
  const countryDriverConceptTypes = useAppSelector(
    ({productionCost}) => productionCost.countryDriverConceptTypes
  );
  const calculationUserJourneyMode = useAppSelector(
    ({productionCost}) => productionCost.calculationUserJourneyMode
  );
  const dispatch = useAppDispatch();

  const {getAccommodationCostPerNight} = useAccommodationCost(entryIds);

  const [getCountryPrefilledEmptyValues] = useLazyQuery(
    getCountryPrefilledEmptyValuesQuery
  );
  const fetchCountryPrefilledEmptyValues = useCallback(
    async (countryId: string) => {
      const {data} = await getCountryPrefilledEmptyValues({
        variables: {countryId}
      });

      if (data) {
        dispatch(
          receivedCountryPrefilledEmptyValuesForVehicle({
            countryId,
            countryPrefilledEmptyValues: data.countryPrefilledEmptyValues,
            entryIds
          })
        );
      }
    },
    [getCountryPrefilledEmptyValues, entryIds, dispatch]
  );

  const handleSelectPartnerName = useCallback(
    (option: AutocompleteOptionType) => {
      const busPartnerId = option.key as string;
      const busPartnerCountryId = partners.find(
        ({id}) => id === busPartnerId
      )?.countryId;

      if (!busPartnerCountryId) {
        console.error('Country not found for bus partner', busPartnerId);
        return;
      }

      onUpdateBusValues({
        busPartnerId,
        countryRulesCountryId: busPartnerCountryId,
        busPartnerBusTypeCostEntryId: undefined,
        countryBusTypeCostEntryId: undefined
      });

      const countryPrefilledEmptyValuesEntry =
        countryPrefilledEmptyValues[busPartnerCountryId];
      if (!countryPrefilledEmptyValuesEntry) {
        fetchCountryPrefilledEmptyValues(busPartnerCountryId);
      }

      const accommodationCostPerNight = getAccommodationCostPerNight(
        busPartnerId,
        busPartnerCountryId
      );

      const driverConcepts =
        countryDriverConceptTypes[busPartnerCountryId] ?? [];

      const countryDriverConceptTypeId =
        driverConcepts?.length === 1 && driverConcepts[0].id
          ? driverConcepts[0].id
          : undefined;

      const updates = {
        emptyKmPerDay: countryPrefilledEmptyValuesEntry?.prefilledEmptyKm,
        emptyHrPerDay: countryPrefilledEmptyValuesEntry?.prefilledEmptyHr,
        accommodationCostPerNight,
        countryDriverConceptTypeId
      };

      onUpdateBusScheduleValues(updates);
    },
    [
      countryDriverConceptTypes,
      countryPrefilledEmptyValues,
      fetchCountryPrefilledEmptyValues,
      getAccommodationCostPerNight,
      partners,
      onUpdateBusValues,
      onUpdateBusScheduleValues
    ]
  );

  const [shouldPreloadData, setShouldPreloadData] = useState(
    calculationUserJourneyMode === CalculationUserJourneyMode.CreateCalculation
  );
  useEffect(() => {
    if (busPartner && shouldPreloadData) {
      setShouldPreloadData(false);

      // we need to wait for the shouldPreloadData be set to false before calling the handleSelectPartnerName
      setTimeout(() => {
        handleSelectPartnerName({
          key: busPartner.id,
          title: busPartner.name
        });
      });
    }
  }, [busPartner, shouldPreloadData, handleSelectPartnerName]);

  return (
    <div className={style.container} data-testid={`line-vehicle-partner-name`}>
      <PartnerNameAutocompleteField
        partners={partners}
        busPartner={busPartner}
        vehicleId={vehicleId}
        errors={errors}
        onSelectPartnerName={handleSelectPartnerName}
      />

      <Text small extraClasses="content-secondary-color">
        Planr partner name: {productionCostBuses?.busPartnerName ?? 'N/A'}
      </Text>
    </div>
  );
};

export default React.memo(PartnerName);
