import graphqlClient from '../../../services/graphqlClient';
import getCountriesQuery from '../graphql/getCountriesQuery';
import getCountryCurrenciesQuery from '../graphql/getCountryCurrenciesQuery';
import {isNil, pick} from 'ramda';
import createCountryMutation from '../graphql/createCountryMutation';
import updateCountryMutation from '../graphql/updateCountryMutation';
import deleteCountryMutation from '../graphql/deleteCountryMutation';
import {
  receivedCountries,
  receivedCountryCurrencies,
  resetCountry,
  receivedError,
  addToCountries,
  updateCountries,
  deleteFromCountries
} from './slice';

export const getCountryCurrencies = () => {
  return dispatch => {
    graphqlClient.query({query: getCountryCurrenciesQuery}).then(result => {
      let {
        data: {countryCurrencies}
      } = result;

      dispatch(receivedCountryCurrencies(countryCurrencies));
    });
  };
};

export const getCountries = () => {
  return dispatch => {
    graphqlClient.query({query: getCountriesQuery}).then(result => {
      let {
        data: {countries}
      } = result;

      dispatch(receivedCountries(countries));
    });
  };
};

export const saveCountry = () => {
  return (dispatch, _getState) => {
    const state = _getState();
    let country = state.countries.country;

    if (isNil(country.id)) {
      graphqlClient
        .mutate({
          mutation: createCountryMutation,
          variables: pick(
            [
              'name',
              'businessRegion',
              'currency',
              'repairCostDimension',
              'maintenanceCostDimension'
            ],
            country
          ),
          errorPolicy: 'all'
        })
        .then(result => {
          const errors = result?.errors;
          if (errors) {
            const errorMessage = errors[0]?.extensions?.errorMessage;
            dispatch(receivedError(errorMessage));
          } else {
            let {
              data: {createCountry}
            } = result;

            dispatch(addToCountries(createCountry));
            dispatch(resetCountry());
          }
        });
    } else {
      graphqlClient
        .mutate({
          mutation: updateCountryMutation,
          variables: pick(
            [
              'id',
              'name',
              'businessRegion',
              'currency',
              'repairCostDimension',
              'maintenanceCostDimension'
            ],
            country
          ),
          errorPolicy: 'all'
        })
        .then(result => {
          const errors = result?.errors;
          if (errors) {
            const errorMessage = errors[0]?.extensions?.errorMessage;
            dispatch(receivedError(errorMessage));
          } else {
            let {
              data: {updateCountry}
            } = result;

            dispatch(updateCountries(updateCountry));
            dispatch(resetCountry());
          }
        });
    }
  };
};

export const deleteCountry = (country, addToast) => {
  return dispatch => {
    graphqlClient
      .mutate({
        mutation: deleteCountryMutation,
        variables: pick(['id'], country),
        errorPolicy: 'all'
      })
      .then(result => {
        let {
          data: {deleteCountry},
          errors
        } = result;
        if (errors) {
          addToast({
            type: 'danger',
            content: errors[0]?.extensions?.errorMessage || errors[0].message
          });
        } else {
          dispatch(deleteFromCountries(deleteCountry));
          addToast({
            type: 'success',
            content: `Country ${country.name} deleted successfully`
          });
        }
      });
  };
};
