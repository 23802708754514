import {gql} from '@apollo/client';

const GET_BUS_PARTNER_AND_COUNTRY_BUS_TYPE_COST_ENTRIES_BY_IDS = gql`
  query GetBusPartnerAndCountryBusTypeCostEntriesByIds(
    $busPartnerBusTypeCostEntryIds: [ID!]!
    $countryBusTypeCostEntryIds: [ID!]!
  ) {
    busPartnerBusTypeCostEntriesByIds(ids: $busPartnerBusTypeCostEntryIds) {
      id
      busTypeId
    }

    countryBusTypeCostEntriesByIds(ids: $countryBusTypeCostEntryIds) {
      id
      busTypeId
    }
  }
`;

export default GET_BUS_PARTNER_AND_COUNTRY_BUS_TYPE_COST_ENTRIES_BY_IDS;
