import {gql} from '@apollo/client';

const GET_PRODUCTION_COST_BUSES = gql`
  query productionCostBuses($productionCostEntryId: ID!) {
    productionCostBuses(productionCostEntryId: $productionCostEntryId) {
      vehicleId
      name
      busPartnerName
    }
  }
`;

export default GET_PRODUCTION_COST_BUSES;
