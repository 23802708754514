import {useCallback} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {fetchPartnerAndCountryDriverCost} from 'Pages/ProductionCost/store/thunks';
const useAccommodationCost = (entryIds: string[]) => {
  const busPartnerAndCountryDriverCostEntry = useAppSelector(
    ({productionCost}) => productionCost.busPartnerAndCountryDriverCostEntry
  );
  const productionCostYear = useAppSelector(
    ({productionCost}) => productionCost.costYear
  ) as number;
  const dispatch = useAppDispatch();

  const getAccommodationCostPerNight = useCallback(
    (busPartnerId: string, countryId: string) => {
      const busPartnerAndCountryDriverCostEntryByYear =
        busPartnerAndCountryDriverCostEntry[
          busPartnerId + countryId + productionCostYear
        ];

      if (!busPartnerAndCountryDriverCostEntryByYear) {
        dispatch(
          fetchPartnerAndCountryDriverCost(
            busPartnerId,
            countryId,
            productionCostYear,
            entryIds
          )
        );
        return;
      }

      const {busPartnerDriverCostEntry, countryDriverCostEntry} =
        busPartnerAndCountryDriverCostEntryByYear;
      return (
        busPartnerDriverCostEntry.accommodationCostPerNight ??
        countryDriverCostEntry.accommodationCostPerNight ??
        undefined
      );
    },
    [
      busPartnerAndCountryDriverCostEntry,
      productionCostYear,
      entryIds,
      dispatch
    ]
  );

  return {getAccommodationCostPerNight};
};

export default useAccommodationCost;
