import React, {useMemo} from 'react';
import {
  Button,
  ButtonGroup,
  DataTable,
  DataTableHead,
  DataTableRow
} from '@flixbus/honeycomb-react';
import {Icon} from '@flixbus/honeycomb-icons-react';
import {TableProps} from './Table.types';

const Table: React.FC<TableProps> = ({
  headers,
  rows,
  actions,
  caption,
  extraDataTableProps
}) => {
  const tableHeaders = useMemo(() => {
    const tableHeaders = [...headers];
    if (actions) {
      tableHeaders.push({name: '', key: 'actions'});
    }

    return (
      <DataTableHead>
        {tableHeaders.map(header => (
          <th key={header.key} scope="col">
            {header.name}
          </th>
        ))}
      </DataTableHead>
    );
  }, [headers, actions]);

  const tableRows = useMemo(
    () =>
      rows.map((row, rowIndex) => {
        const cells = headers.map(({key}, cellIndex) => (
          <td key={cellIndex}>{row[key]}</td>
        ));

        if (actions) {
          const actionIcons = actions.map(
            ({icon, appearance, onClick}, index) => (
              <Button
                display="square"
                aria-label={`Action Button ${index}`}
                key={`action-button-${icon.name}`}
                appearance={appearance}
                onClick={() => onClick(row, index)}
              >
                <Icon InlineIcon={icon} aria-hidden="true" />
              </Button>
            )
          );
          cells.push(
            <td key="actions">
              <ButtonGroup alignment="end">{actionIcons}</ButtonGroup>
            </td>
          );
        }

        return (
          <DataTableRow
            key={rowIndex}
            extraClasses={row.extraClasses as string}
          >
            {cells}
          </DataTableRow>
        );
      }),
    [headers, rows, actions]
  );

  return (
    <DataTable
      extraClasses={`data-table-with-actions`}
      headers={tableHeaders}
      caption={caption}
      {...extraDataTableProps}
    >
      {tableRows}
    </DataTable>
  );
};

export default React.memo(Table);
