export const baseSalaryHeaders = currency => [
  {
    name: `Daytime/weekdays (${currency}/h)*`,
    key: 'daytimeWeekdaysWorkingHourCost'
  },
  {
    name: `Nighttime/weekdays (${currency}/h)*`,
    key: 'nighttimeWeekdaysWorkingHourCost'
  },
  {
    name: `Daytime/weekend (${currency}/h)*`,
    key: 'daytimeWeekendsWorkingHourCost'
  },
  {
    name: `Nighttime/weekend (${currency}/h)*`,
    key: 'nighttimeWeekendsWorkingHourCost'
  }
];

export const driverChangeCostHeaders = (currency, localUnits) => [
  {
    name: `${currency}/${localUnits.distanceAbbreviation}`,
    key: 'replacementCostPerKm',
    integerDigitsCount: 7
  },
  {
    name: `${currency}/h`,
    key: 'replacementCostPerHr'
  }
];

export const accommodationCostHeaders = currency => [
  {
    name: `${currency}/night*`,
    key: 'accommodationCostPerNight'
  }
];
