import {AnalyticsModeType, CalculatedEntriesState} from './types';

export const initFilters = {
  partners: [],
  lineIds: [],
  planningPeriods: [],
  countries: [],
  costCalculationName: [],
  calculationStatus: []
};

export const resetEntriesData = {
  page: 0,
  entries: [],
  absoluteValuesEntries: [],
  subtotals: {},
  absoluteValuesSubtotals: {},
  paginationOutput: {
    totalPages: null,
    totalRecords: null
  }
};

export const calculatedEntriesInitialState: CalculatedEntriesState = {
  filters: initFilters,
  entries: [],
  options: {},
  page: 0,
  analyticsMode: false,
  analyticsModeType: AnalyticsModeType.Overview,
  subtotals: {},
  absoluteValuesSubtotals: {},
  absoluteValuesEntries: [],
  paginationOutput: {
    totalPages: null,
    totalRecords: null
  },
  recordsPerPage: 10,
  isReloadData: false
};
