import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {AnnualDriverSalaryProps} from './AnnualDriverSalary.types';
import styles from './AnnualDriverSalary.module.scss';

const AnnualDriverSalary: React.FC<AnnualDriverSalaryProps> = ({
  value,
  entryId,
  currency,
  errors
}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-annual-driver-salary`;

  const tooltipContent = useMemo(() => fieldInfoContent.driverSalary, []);

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const driverSalary =
        validateInputNumber(
          editedValue,
          value ? value.toString() : '',
          undefined,
          0
        ) ?? undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {driverSalary}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="driver-salary"
        label="Annual driver salary*"
        inlineLabelLeft={currency}
        value={formattedValue}
        tooltipContent={tooltipContent}
        valid={!errors}
        infoError={errors?.optionality ?? errors?.nullable}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(AnnualDriverSalary);
