export function getRepairCosts({
  busPartnerRepairCostPerMonth,
  busPartnerRepairCostPerDistanceUnit,
  countryOtherRepairCostPerMonth,
  countryOtherRepairCostPerDistanceUnit
}) {
  // Check if there are repair costs from BP
  if (busPartnerRepairCostPerMonth) {
    return buildRepairCostsResult(busPartnerRepairCostPerMonth, null);
  } else if (busPartnerRepairCostPerDistanceUnit) {
    return buildRepairCostsResult(null, busPartnerRepairCostPerDistanceUnit);
  }

  // If no repair costs from BP, check country's repair costs
  if (countryOtherRepairCostPerMonth) {
    return buildRepairCostsResult(countryOtherRepairCostPerMonth, null);
  } else if (countryOtherRepairCostPerDistanceUnit) {
    return buildRepairCostsResult(null, countryOtherRepairCostPerDistanceUnit);
  }

  return buildRepairCostsResult(null, null);
}

function buildRepairCostsResult(
  repairCostPerMonth: number | null,
  repairCostPerDistanceUnit: number | null
) {
  return {
    repairCostPerMonth,
    repairCostPerDistanceUnit
  };
}
