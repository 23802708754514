import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {Login} from 'Pages/Auth';
import AppLayout from 'app/AppLayout';
import CountriesPage from 'Pages/Countries/components';
import VehicleTypesPage from 'Pages/VehicleTypes/components';
import PartnersPage from 'Pages/Partners/components';
import RegionalCostsVehicleCostTab from 'Pages/RegionalCosts/components/RegionalCostsVehicleCostTab/RegionalCostsVehicleCostTab';
import RegionalCostsEmptyDrivingDefaultsTab from 'Pages/RegionalCosts/components/RegionalCostsEmptyDrivingDefaultsTab';
import DriverConceptTypes from 'Pages/RegionalCosts/components/DriverConceptTypes';
import RegionalCostsOtherCostTab from 'Pages/RegionalCosts/components/RegionalCostsOtherCostTab/RegionalCostsOtherCostTab';
import DriverSalaryPeriods from 'Pages/RegionalCosts/components/DriverSalaryPeriods';
import PartnerCostsOtherCostTab from 'Pages/PartnerCosts/components/PartnerCostsOtherCostTab/PartnerCostsOtherCostTab';
import PartnerCosts from 'Pages/PartnerCosts/components';
import ProductionCost from 'Pages/ProductionCost/components';
import Home from 'Pages/Home/components';
import RegionalCostsDriverCostTab from 'Pages/RegionalCosts/components/RegionalCostsDriverCostTab/RegionalCostsDriverCostTab';
import PartnerCostsDriverCostTab from 'Pages/PartnerCosts/components/PartnerCostsDriverCostTab/PartnerCostsDriverCostTab';
import RegionalCosts from 'Pages/RegionalCosts/components';

import {ProductionCostCalculationPath} from 'Pages/ProductionCost/constants/routePaths';
import {RegionalCostsPath} from 'Pages/RegionalCosts/constants/routePaths';
import {PartnerCostsPath} from 'Pages/PartnerCosts/constants/routePaths';
import PartnerCostsVehicleCostTab from 'Pages/PartnerCosts/components/PartnerCostsVehicleCostTab/PartnerCostsVehicleCostTab';
import {CountriesPath} from 'Pages/Countries/constant/routePaths';
import {VehicleTypesPath} from 'Pages/VehicleTypes/constants/routePaths';
import {PartnersPath} from 'Pages/Partners/constants/routePaths';

const AppRoutes: React.FC = () => (
  <>
    <AuthenticatedTemplate>
      <Routes>
        <Route path="/" element={<AppLayout displayNavigation={true} />}>
          <Route path={CountriesPath.base} element={<CountriesPage />} />
          <Route path={VehicleTypesPath.base} element={<VehicleTypesPage />} />
          <Route path={PartnersPath.base} element={<PartnersPage />} />
          <Route path={PartnerCostsPath.base} element={<PartnerCosts />}>
            <Route
              path={PartnerCostsPath.vehicleCosts}
              element={<PartnerCostsVehicleCostTab />}
            />
            <Route
              path={PartnerCostsPath.driverCosts}
              element={<PartnerCostsDriverCostTab />}
            />
            <Route
              path={PartnerCostsPath.otherCosts}
              element={<PartnerCostsOtherCostTab />}
            />
            <Route
              index
              element={<Navigate replace to={PartnerCostsPath.vehicleCosts} />}
            />
          </Route>
          <Route path={RegionalCostsPath.base} element={<RegionalCosts />}>
            <Route
              path={RegionalCostsPath.vehicleCosts}
              element={<RegionalCostsVehicleCostTab />}
            />
            <Route
              path={RegionalCostsPath.driverCosts}
              element={<RegionalCostsDriverCostTab />}
            />
            <Route
              path={RegionalCostsPath.otherCosts}
              element={<RegionalCostsOtherCostTab />}
            />
            <Route
              path={RegionalCostsPath.emptyDrivingDefaults}
              element={<RegionalCostsEmptyDrivingDefaultsTab />}
            />
            <Route
              path={RegionalCostsPath.driverConceptTypes}
              element={<DriverConceptTypes />}
            />
            <Route
              path={RegionalCostsPath.driverSalaryPeriods}
              element={<DriverSalaryPeriods />}
            />
            <Route
              index
              element={<Navigate replace to={RegionalCostsPath.vehicleCosts} />}
            />
          </Route>
          <Route
            path={ProductionCostCalculationPath.base}
            element={<ProductionCost />}
          />
          <Route index element={<Home />} />
          <Route path={'*'} element={<Navigate replace to={'/'} />} />
        </Route>
      </Routes>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Routes>
        <Route path="/" element={<AppLayout displayNavigation={false} />}>
          <Route index element={<Login />} />
          <Route path={'*'} element={<Navigate replace to={'/'} />} />
        </Route>
      </Routes>
    </UnauthenticatedTemplate>
  </>
);

export default React.memo(AppRoutes);
