import {gql} from '@apollo/client';

const DELETE_COUNTRY_COST_ENTRY_YEAR = gql`
  mutation deleteCountryCostEntryYear($countryId: ID!, $year: Int!) {
    deleteCountryCostEntryYear(countryId: $countryId, year: $year) {
      year
    }
  }
`;

export default DELETE_COUNTRY_COST_ENTRY_YEAR;
