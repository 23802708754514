import {useCallback} from 'react';
import {useLazyQuery} from '@apollo/client';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {receivedCountryDriverConceptTypesForVehicle} from 'Pages/ProductionCost/store/slice';
import getCountryDriverConceptTypesQuery from 'Pages/RegionalCosts/graphql/getCountryDriverConceptTypesQuery';

const useCountryDriverConceptTypes = () => {
  const dispatch = useAppDispatch();
  const [getCountryDriverConceptTypes] = useLazyQuery(
    getCountryDriverConceptTypesQuery
  );

  const fetchCountryDriverConceptTypes = useCallback(
    async (countryId: string) => {
      const {data, error} = await getCountryDriverConceptTypes({
        variables: {countryId}
      });

      if (data) {
        dispatch(
          receivedCountryDriverConceptTypesForVehicle({
            countryId,
            countryDriverConceptTypes: data.countryDriverConceptTypes
          })
        );
        return data.countryDriverConceptTypes;
      } else {
        console.error(
          'Error fetching country driver concept types',
          error?.message
        );
        return [];
      }
    },
    [getCountryDriverConceptTypes, dispatch]
  );

  return {fetchCountryDriverConceptTypes};
};

export default useCountryDriverConceptTypes;
