import {gql} from '@apollo/client';

const GET_PLANR_BUS_PARTNERS = gql`
  query GetPlanrBusPartners($scheduleIds: [ID!]!) {
    getPlanrBusPartners(scheduleIds: $scheduleIds) {
      busPartnerBackendId
      busPartnerName
      busPartnerShortName
    }
  }
`;

export default GET_PLANR_BUS_PARTNERS;
