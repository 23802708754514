import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Input,
  Fieldset,
  Select,
  SelectOption,
  Heading,
  Text
} from '@flixbus/honeycomb-react';
import CountryAutocompleteField from './CountryAutocompleteField';
import {isNil} from 'ramda';
import {getCountryCurrencies, saveCountry} from '../store/thunks';
import {resetCountry, setCountry} from '../store/slice';
import {confirm} from '../../Common/Confirm';
import ContentLoader from '../../Common/ContentLoader';
import {isBlank, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';
import CpcPanel from '../../Common/CpcPanel';
import {COUNTRY_CURRENCY_FEATURE_ENABLED} from './index';
import {IconWarning} from '@flixbus/honeycomb-icons-react';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import {getValueDimensions} from './index.constants.ts';

const CountryPanel = () => {
  const dispatch = useDispatch();
  const country = useSelector(state => state?.countries?.country);
  const currencies = useSelector(state => state?.countries?.currencies);
  const {localUnits} = useLocalUnitsContext();
  const isEditCountry = isNotNilOrEmpty(country?.id);
  const [showPanel, setShowPanel] = useState(false);
  const [valid, setValid] = useState(null);

  useEffect(() => {
    if (isNil(currencies)) {
      dispatch(getCountryCurrencies());
    }
  }, [currencies, dispatch]);

  useEffect(() => {
    setShowPanel(!isNil(country));
    if (isNotNil(country?.errorMessage)) {
      setValid(false);
    }
  }, [country]);

  const currencyOptions = useMemo(() => {
    if (!currencies) return [];
    return currencies.map(currency => (
      <SelectOption key={currency} value={currency}>
        {currency}
      </SelectOption>
    ));
  }, [currencies]);

  const costDimensionOptions = useMemo(() => {
    if (!country) return [];
    return getValueDimensions(country.currency, localUnits).map(
      ({key, value}) => (
        <SelectOption key={key} value={key}>
          {value}
        </SelectOption>
      )
    );
  }, [country, localUnits]);

  const closePanel = () => {
    setShowPanel(false);
    setValid(null);
    dispatch(resetCountry());
  };

  const handleSaveCountry = async () => {
    if (isBlank(country.name)) {
      dispatch(setCountry({...country, errorMessage: null}));
      setValid(false);
    } else {
      setValid(null);
      if (
        isEditCountry &&
        country?.countryBeforeEdit?.currency !== country.currency
      ) {
        if (
          await confirm({
            PopupIcon: IconWarning,
            title:
              'Currency has been edited. Be aware that Regional and Bus Partner cost values might need to be adjusted to match the new currency. Do you wish to proceed with the changes?',
            message: `${country.name}`
          })
        ) {
          dispatch(saveCountry());
        }
      } else {
        dispatch(saveCountry());
      }
    }
  };

  const handleCurrencySelection = event => {
    dispatch(setCountry({...country, currency: event.target.value}));
  };

  return (
    <CpcPanel
      title={isEditCountry ? 'Edit country' : 'Add country'}
      activeAction={showPanel}
      closeAction={closePanel}
      saveAction={handleSaveCountry}
    >
      {!isNil(country) ? (
        <>
          <Fieldset>
            <CountryAutocompleteField
              valid={valid}
              countryName={country.name}
              onSelected={name => dispatch(setCountry({...country, name}))}
              errorMessage={country?.errorMessage}
            />
            <Input
              name="country-business-region"
              id="country-business-region"
              label="Business region"
              value={country.businessRegion}
              onChange={e =>
                dispatch(
                  setCountry({...country, businessRegion: e.target.value})
                )
              }
            />
            {COUNTRY_CURRENCY_FEATURE_ENABLED && (
              <Select
                id="country-select-currency"
                label="Currency"
                defaultValue={country?.currency}
                placeholder="Select a Currency"
                onChange={handleCurrencySelection}
              >
                {currencyOptions}
              </Select>
            )}
          </Fieldset>
          <Heading key={4} size={4}>
            Measuring units
          </Heading>
          <Text
            extraClasses="hcr-space-5-bottom grayscale-70-color"
            style={{whiteSpace: 'nowrap'}}
            small
          >
            Specify the measuring unit for “Service &
            <br />
            Maintenance” and “Damage repair” on country level:
          </Text>
          <Fieldset>
            <Select
              id="country-select-repair-cost-dimension"
              label="Damage repair"
              defaultValue={
                country?.repairCostDimension ?? costDimensionOptions[0]?.key
              }
              onChange={({target: {value}}) =>
                dispatch(setCountry({...country, repairCostDimension: value}))
              }
            >
              {costDimensionOptions}
            </Select>
            <Select
              id="country-select-maintenance-cost-dimension"
              label="Service & Maintenance"
              defaultValue={
                country?.maintenanceCostDimension ??
                costDimensionOptions[0]?.key
              }
              onChange={({target: {value}}) => {
                dispatch(
                  setCountry({...country, maintenanceCostDimension: value})
                );
              }}
            >
              {costDimensionOptions}
            </Select>
          </Fieldset>
        </>
      ) : (
        <ContentLoader />
      )}
    </CpcPanel>
  );
};

export default React.memo(CountryPanel);
