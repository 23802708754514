import React, {useCallback, useMemo, useState} from 'react';
import {IconEditSolid} from '@flixbus/honeycomb-icons-react';
import {TableWithPanel} from 'components/TableWithPanel';
import {Table, TableAction} from 'components/Table';
import {PartnerCostsOtherCost} from 'Pages/PartnerCosts/store/types';
import {ValueDimension} from 'Pages/Countries/components/index.constants';
import OtherCostsOverheadCostsPanel from './OtherCostsOverheadCostsPanel/OtherCostsOverheadCostsPanel';
import {OtherCostsOverheadCostsPanelData} from './OtherCostsOverheadCostsPanel/OtherCostsOverheadCostsPanel.types';
import {OtherCostsProps} from './OtherCosts.types';
import styles from './OtherCosts.module.scss';

const OtherCosts: React.FC<OtherCostsProps> = ({
  overheadCosts,
  operationalCosts,
  currency,
  updateOtherCosts
}) => {
  const [overheadCostsPanelData, setOverheadCostsPanelData] =
    useState<OtherCostsOverheadCostsPanelData | null>(null);

  const convertOtherCostsToPanelData = useCallback(
    (costs: PartnerCostsOtherCost) => {
      const repairCostHeader = overheadCosts.headers[2];
      return {
        overheadCost: costs.overheadCost,
        legalCost: costs.legalCost,
        repairCostUnit:
          repairCostHeader.key === 'repairCostPerMonth'
            ? ValueDimension.PER_MONTH
            : ValueDimension.PER_DISTANCE_UNIT,
        repairCostValue: costs[repairCostHeader.key],
        otherCost: costs.otherCost
      } as OtherCostsOverheadCostsPanelData;
    },
    [overheadCosts]
  );

  const convertPanelDataToOtherCosts = useCallback(
    (panelData: OtherCostsOverheadCostsPanelData) =>
      ({
        overheadCost: panelData.overheadCost,
        legalCost: panelData.legalCost,
        repairCostPerMonth:
          panelData.repairCostUnit === ValueDimension.PER_MONTH
            ? panelData.repairCostValue
            : null,
        repairCostPerDistanceUnit:
          panelData.repairCostUnit === ValueDimension.PER_DISTANCE_UNIT
            ? panelData.repairCostValue
            : null,
        otherCost: panelData.otherCost
      } as PartnerCostsOtherCost),
    []
  );

  const showPanel = useCallback(
    (rowIndex: number) => {
      const costs = overheadCosts.data[rowIndex];
      const panelData = convertOtherCostsToPanelData(costs);

      setOverheadCostsPanelData(panelData);
    },
    [overheadCosts, convertOtherCostsToPanelData]
  );

  const closePanel = useCallback(() => {
    setOverheadCostsPanelData(null);
  }, []);

  const overheadCostsTableAction: TableAction = useMemo(
    () => ({
      icon: IconEditSolid,
      onClick: (_, index: number) => showPanel(index)
    }),
    [showPanel]
  );

  const saveOverheadCosts = useCallback(
    (panelData: OtherCostsOverheadCostsPanelData) => {
      const updatedCosts = convertPanelDataToOtherCosts(panelData);
      updateOtherCosts(updatedCosts);

      closePanel();
    },
    [updateOtherCosts, convertPanelDataToOtherCosts, closePanel]
  );

  return (
    <>
      <section data-testid="overhead-cost">
        <div>
          <h3 className={styles.title}>Overhead cost</h3>
          <div className={styles.subtitle}>Per vehicle</div>
        </div>
        <Table
          headers={overheadCosts.headers}
          rows={overheadCosts.rows}
          actions={[overheadCostsTableAction]}
        />
        <OtherCostsOverheadCostsPanel
          panelData={overheadCostsPanelData}
          currency={currency}
          onSuccess={saveOverheadCosts}
          onCancel={closePanel}
        />
      </section>

      <section data-testid="operational-cost">
        <div>
          <h3 className={styles.title}>Operational cost</h3>
          <div className={styles.subtitle}>
            Per vehicle, {currency} per operating day
          </div>
        </div>
        <TableWithPanel
          headers={operationalCosts.headers}
          rows={operationalCosts.rows}
          actions={operationalCosts.actions}
        />
      </section>
    </>
  );
};

export default React.memo(OtherCosts);
