import {gql} from '@apollo/client';

const CREATE_PARTNER_COST_ENTRY_YEAR = gql`
  mutation createBusPartnerCostEntryYear($partnerId: ID!) {
    createBusPartnerCostEntryYear(busPartnerId: $partnerId) {
      year
      busCostEntry {
        year
        dieselCost
        adblueCost
        lngCost
        tiresCost
      }
      busTypeCostEntries {
        id
        busTypeId
        year
        leasingCost
        insuranceCost
        interestCost
        motorVehicleTax
        maintenanceCostPerMonth
        maintenanceCostPerDistanceUnit
        customIdentifier
      }
      driverCostEntry {
        year
        daytimeWeekdaysWorkingHourCost
        nighttimeWeekdaysWorkingHourCost
        daytimeWeekendsWorkingHourCost
        nighttimeWeekendsWorkingHourCost
        replacementCostPerKm
        replacementCostPerHr
        accommodationCostPerNight
      }
      otherCostEntry {
        year
        overheadCost
        legalCost
        cleaningCost
        repairCostPerDistanceUnit
        repairCostPerMonth
        otherCost
      }
      qualityChecked
      qualityCheckedLastEditedAt
    }
  }
`;

export default CREATE_PARTNER_COST_ENTRY_YEAR;
