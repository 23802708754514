import React, {useEffect, useCallback, useMemo} from 'react';
import {Select, SelectOption} from '@flixbus/honeycomb-react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import useCountryDriverConceptTypes from 'Pages/ProductionCost/components/DataImportStepTwo/hooks/useCountryDriverConceptTypes';
import {DriverConceptProps} from './DriverConcept.types';
import styles from './DriverConcept.module.scss';

const DriverConcept: React.FC<DriverConceptProps> = ({
  value,
  entryId,
  countryRulesCountryId,
  errors
}) => {
  const {fetchCountryDriverConceptTypes} = useCountryDriverConceptTypes();
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-driver-concept`;

  const countryDriverConceptTypes = useAppSelector(
    ({productionCost}) => productionCost.countryDriverConceptTypes
  );

  const driverConcepts = useMemo(
    () =>
      countryRulesCountryId && countryDriverConceptTypes[countryRulesCountryId]
        ? countryDriverConceptTypes[countryRulesCountryId]
        : [],
    [countryDriverConceptTypes, countryRulesCountryId]
  );

  const handleSelectOnDriverConceptChange = useCallback(
    (countryDriverConceptTypeId?: string) => {
      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {countryDriverConceptTypeId}
        })
      );
    },
    [entryId, dispatch]
  );

  useEffect(() => {
    if (countryRulesCountryId && driverConcepts.length === 0) {
      fetchCountryDriverConceptTypes(countryRulesCountryId);
    }
  }, [
    countryRulesCountryId,
    driverConcepts,
    fetchCountryDriverConceptTypes,
    dispatch
  ]);

  useEffect(() => {
    if (!countryRulesCountryId || driverConcepts.length === 0) return;

    let driverConceptValue: string | undefined = value;

    // reset previously selected driver concept if it's not available anymore
    if (driverConceptValue) {
      const isDriverConceptAvailable = driverConcepts.find(
        driverConceptType => driverConceptType.id === value
      );
      if (!isDriverConceptAvailable) driverConceptValue = undefined;
    }

    // preselect if only one option available
    if (driverConcepts.length === 1) {
      driverConceptValue = driverConcepts[0].id ?? undefined;
    }

    // update the value if it has changed
    const driverConceptChanged = driverConceptValue !== value;

    if (driverConceptChanged) {
      handleSelectOnDriverConceptChange(driverConceptValue);
    }
  }, [
    countryRulesCountryId,
    value,
    driverConcepts,
    handleSelectOnDriverConceptChange
  ]);

  const driverConceptOptions = useMemo(
    () =>
      driverConcepts.map(driverConceptType => (
        <SelectOption
          key={driverConceptType.id}
          value={driverConceptType.id as string}
        >
          {driverConceptType.name as string}
        </SelectOption>
      )),
    [driverConcepts]
  );

  return (
    <span className={styles.container}>
      <Select
        id={id}
        disabled={!countryRulesCountryId}
        label="Driver concept*"
        placeholder="Select a driver concept"
        value={value ?? ''}
        valid={!errors}
        infoError={errors?.optionality ?? errors?.nullable}
        onChange={e => handleSelectOnDriverConceptChange(e.target.value)}
      >
        {driverConceptOptions}
      </Select>
    </span>
  );
};

export default React.memo(DriverConcept);
