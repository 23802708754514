import constants from '../constants';

const entryKeys = {
  countries: 'countryIds'
};

export const getKey = field => entryKeys[field];

export const getQueryParamsSelector = state => {
  const {
    partners: {filters}
  } = state;

  const queryParams = Object.keys(filters).reduce((acc, curr) => {
    let key = getKey(curr);
    let param = {};

    if (curr === constants.fields.countries) {
      const ids = filters[curr].map(filter => filter.id || filter.uuid);

      param = {[key]: ids};
    }

    if (filters[curr].length === 0) return acc;
    else
      return {
        ...acc,
        ...param
      };
  }, []);

  return queryParams;
};
