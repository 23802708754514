import React from 'react';
import {TableWithPanel} from 'components/TableWithPanel';
import {DriverCostsProps} from './DriverCosts.types';
import styles from './DriverCosts.module.scss';

const DriverCosts: React.FC<DriverCostsProps> = ({
  baseSalaryCosts,
  driverChangeCosts,
  accommodationCosts
}) => (
  <>
    <section data-testid="base-salary-cost">
      <div>
        <h3 className={styles.title}>Base Salary (Cost of the Employee)</h3>
        <div className={styles.subtitle}>Per working hour</div>
      </div>
      <TableWithPanel
        headers={baseSalaryCosts.headers}
        rows={baseSalaryCosts.rows}
        actions={baseSalaryCosts.actions}
      />
    </section>

    <section data-testid="driver-change-cost">
      <div>
        <h3 className={styles.title}>Driver change cost</h3>
      </div>
      <TableWithPanel
        headers={driverChangeCosts.headers}
        rows={driverChangeCosts.rows}
        actions={driverChangeCosts.actions}
      />
    </section>

    <section data-testid="accommodation-cost">
      <div>
        <h3 className={styles.title}>Accommodation cost - Default value</h3>
      </div>
      <TableWithPanel
        headers={accommodationCosts.headers}
        rows={accommodationCosts.rows}
        actions={accommodationCosts.actions}
      />
    </section>
  </>
);

export default React.memo(DriverCosts);
