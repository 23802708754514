import {gql} from '@apollo/client';

const GET_COUNTRY_PREFILLED_EMPTY_VALUES = gql`
  query countryPrefilledEmptyValues($countryId: ID!) {
    countryPrefilledEmptyValues(countryId: $countryId) {
      prefilledEmptyKm
      prefilledEmptyHr
    }
  }
`;

export default GET_COUNTRY_PREFILLED_EMPTY_VALUES;
