import {gql} from '@apollo/client';

const SAVE_COUNTRY_SALARY_DEFINITIONS = gql`
  mutation saveCountrySalaryDefinitions(
    $countryId: ID!
    $countrySalaryDefinitions: [CountrySalaryDefinitionInput!]!
  ) {
    saveCountrySalaryDefinitions(
      countryId: $countryId
      inputs: $countrySalaryDefinitions
    ) {
      salaryType
      dayOfWeek
      startTime
      endTime
    }
  }
`;

export default SAVE_COUNTRY_SALARY_DEFINITIONS;
