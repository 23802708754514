import {PlanrScheduleType} from 'Pages/ProductionCost/store/types';
import {SchedulesListPerDay} from '../LineSchedulesSelect/LineSchedulesSelect.types';

interface SchedulesOverlapSummary {
  regular: number;
  holiday: number;
  periodGaps: number;
}

export const getSchedulesOverlapSummary = (
  schedulesListPerDay: SchedulesListPerDay
): SchedulesOverlapSummary => {
  let regular = 0;
  let holiday = 0;
  let periodGaps = 0;

  Object.keys(schedulesListPerDay).forEach(key => {
    const dateMatch = schedulesListPerDay[key];

    if (dateMatch[PlanrScheduleType.Regular].length > 1) regular++;
    if (dateMatch[PlanrScheduleType.Holiday].length > 1) holiday++;

    if (
      dateMatch[PlanrScheduleType.Regular].length === 0 &&
      dateMatch[PlanrScheduleType.Holiday].length === 0
    ) {
      periodGaps++;
    }
  });

  return {regular, holiday, periodGaps};
};
