import React, {useEffect, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Grid,
  GridCol,
  Button,
  DataTable,
  DataTableHead,
  DataTableRow,
  Infobox
} from '@flixbus/honeycomb-react';
import {
  IconEditSolid,
  IconDeleteSolid,
  IconDelete,
  Icon
} from '@flixbus/honeycomb-icons-react';
import {confirm} from '../../../Common/Confirm';
import {isNil} from 'ramda';
import {setCountryDriverConceptType} from '../../store/slice';
import {
  deleteDriverConceptType,
  fetchDriverConceptTypes
} from '../../store/thunks';
import DriverConceptTypePanel from './DriverConceptTypePanel';
import {useToast} from '../../../Common/toasts-container';
import {getScaledNumber} from '../../../Common/Utils';

const DriverConceptTypes = () => {
  const dispatch = useDispatch();
  const countryDriverConceptTypes = useSelector(
    state => state?.regionalCosts.countryDriverConceptTypes.list
  );
  const country = useSelector(state =>
    state?.countries?.list?.find(
      country => country.id === state?.regionalCosts?.country
    )
  );
  const {addToast} = useToast();
  useEffect(() => {
    if (isNil(countryDriverConceptTypes)) {
      dispatch(fetchDriverConceptTypes());
    }
  }, [country, countryDriverConceptTypes, dispatch]);

  const deleteDriverConceptTypeAction = useCallback(
    async driverConceptType => {
      if (
        await confirm({
          PopupIcon: IconDelete,
          title: 'Delete this driver concept type?',
          message: `${driverConceptType.name}`
        })
      ) {
        dispatch(deleteDriverConceptType(driverConceptType, addToast));
      }
    },
    [dispatch, addToast]
  );

  const headers = useMemo(
    () => (
      <DataTableHead>
        <th scope="col">Concept type</th>
        <th scope="col">Description</th>
        <th scope="col">Multiplication factor for working hour adjustment</th>
        <th scope="col">
          Meals/daily allowance ({country?.currency}/operating day)
        </th>
        <th scope="col"></th>
        <th scope="col"></th>
      </DataTableHead>
    ),
    [country?.currency]
  );

  const rows = useMemo(
    () =>
      (countryDriverConceptTypes ?? []).map(countryDriverConceptType => ({
        cells: [
          countryDriverConceptType.name,
          countryDriverConceptType.description,
          getScaledNumber(countryDriverConceptType.multiplicationFactor),
          getScaledNumber(countryDriverConceptType.dailyAllowance),
          <div key={'edit' + countryDriverConceptType.id}>
            <Button
              display="square"
              aria-label="Edit Country Driver Concept Type"
              onClick={() =>
                dispatch(setCountryDriverConceptType(countryDriverConceptType))
              }
            >
              <Icon InlineIcon={IconEditSolid} aria-hidden="true" />
            </Button>
          </div>,
          <div key={'delete' + countryDriverConceptType.id}>
            <Button
              display="square"
              aria-label="Delete Country Driver Concept Type"
              appearance={`danger`}
              onClick={() =>
                deleteDriverConceptTypeAction(countryDriverConceptType)
              }
            >
              <Icon InlineIcon={IconDeleteSolid} aria-hidden="true" />
            </Button>
          </div>
        ]
      })),
    [countryDriverConceptTypes, deleteDriverConceptTypeAction, dispatch]
  );

  if (isNil(countryDriverConceptTypes)) {
    return (
      <Infobox appearance="warning">
        <p>
          Select a <strong>country</strong>
        </p>
      </Infobox>
    );
  }

  return (
    <>
      <Grid>
        <GridCol size={12}>
          <DataTable headers={headers}>
            {rows.map((row, index) => (
              <DataTableRow key={index}>
                {row.cells.map((cell, index) => (
                  <td key={index}>{cell}</td>
                ))}
              </DataTableRow>
            ))}
          </DataTable>
        </GridCol>
      </Grid>
      <DriverConceptTypePanel />
    </>
  );
};

export default React.memo(DriverConceptTypes);
