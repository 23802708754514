import React, {useMemo} from 'react';
import {Divider} from '@flixbus/honeycomb-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {selectLineScheduleById} from 'Pages/ProductionCost/store/slice';
import {LineScheduleVehicleSettingsProps} from './LineScheduleVehicleSettings.types';
import LineScheduleSummary from './LineScheduleSummary/LineScheduleSummary';
import LineScheduleVehicleRelatedCosts from './LineScheduleVehicleRelatedCosts/LineScheduleVehicleRelatedCosts';
import LineScheduleVehicleDriverCosts from './LineScheduleVehicleDriverCosts/LineScheduleVehicleDriverCosts';
import styles from './LineScheduleVehicleSettings.module.scss';

const LineScheduleVehicleSettings: React.FC<
  LineScheduleVehicleSettingsProps
> = ({activeSchedule, activeVehicleEntry}) => {
  const schedule = useAppSelector(({productionCost}) =>
    selectLineScheduleById(productionCost, {scheduleId: activeSchedule})
  );

  const vehicleEntry = useMemo(() => {
    return (
      schedule?.busScheduleProvidedValuesEntries.find(
        entry => entry.id === activeVehicleEntry
      ) ?? null
    );
  }, [schedule?.busScheduleProvidedValuesEntries, activeVehicleEntry]);

  const errors = useAppSelector(
    ({productionCost}) =>
      productionCost.validationErrors.busScheduleValues[activeVehicleEntry]
  );

  if (!schedule || !vehicleEntry) return null;

  return (
    <div className={styles.container}>
      <LineScheduleSummary schedule={schedule} />
      <Divider />
      <LineScheduleVehicleRelatedCosts
        vehicleEntry={vehicleEntry}
        errors={errors}
      />
      <LineScheduleVehicleDriverCosts
        vehicleEntry={vehicleEntry}
        errors={errors}
      />
    </div>
  );
};

export default React.memo(LineScheduleVehicleSettings);
