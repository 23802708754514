import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {EmptyHoursPerOperatingDayProps} from './EmptyHoursPerOperatingDay.types';
import styles from './EmptyHoursPerOperatingDay.module.scss';

const EmptyHoursPerOperatingDay: React.FC<EmptyHoursPerOperatingDayProps> = ({
  value,
  entryId
}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-empty-hours`;

  const tooltipContent = useMemo(
    () => fieldInfoContent.emptyHoursOperatingDay,
    []
  );

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const emptyHrPerDay =
        validateInputNumber(editedValue, value ? value.toString() : '') ??
        undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {emptyHrPerDay}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="vehicle-empty-hours"
        label="Empty hours/operating day"
        inlineLabelLeft="Hrs"
        value={formattedValue}
        tooltipContent={tooltipContent}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(EmptyHoursPerOperatingDay);
