import React, {createContext, useContext, ReactNode} from 'react';
import {useLocalUnits} from './useLocalUnits';
import {UnitsSystem} from './types';

interface LocalUnitsContextType {
  localUnits: UnitsSystem;
  setLocalUnits: (units: UnitsSystem) => void;
}

interface LocalUnitsProviderProps {
  children: ReactNode;
}

const LocalUnitsContext = createContext<LocalUnitsContextType | undefined>(
  undefined
);

export const LocalUnitsProvider: React.FC<LocalUnitsProviderProps> = ({
  children
}) => {
  const [localUnits, setLocalUnits] = useLocalUnits();

  const contextValue: LocalUnitsContextType = {
    localUnits,
    setLocalUnits
  };

  return (
    <LocalUnitsContext.Provider value={contextValue}>
      {children}
    </LocalUnitsContext.Provider>
  );
};

export const useLocalUnitsContext = (): LocalUnitsContextType => {
  const context = useContext(LocalUnitsContext);
  if (!context) {
    throw new Error(
      'useLocalUnitsContext must be used within a LocalUnitsProvider'
    );
  }
  return context;
};
