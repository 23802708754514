import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {defaultTo} from 'ramda';
import {Grid, GridCol, Input} from '@flixbus/honeycomb-react';
import ContentLoader from 'Pages/Common/ContentLoader';
import CpcPanel from 'Pages/Common/CpcPanel';
import {validateInputNumber} from 'Pages/Common/Utils';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import {getValueDimensions} from 'Pages/Countries/components/index.constants';
import InputWithSelection from 'components/InputWithSelection/InputWithSelection';
import {
  OtherCostsOverheadCostsPanelData,
  OtherCostsOverheadCostsPanelProps
} from './OtherCostsOverheadCostsPanel.types';

const OtherCostsOverheadCostsPanel: React.FC<
  OtherCostsOverheadCostsPanelProps
> = ({panelData, currency, onSuccess, onCancel}) => {
  const [localData, setLocalData] =
    useState<OtherCostsOverheadCostsPanelData | null>(null);

  const {localUnits} = useLocalUnitsContext();
  const costDimensionOptions = useMemo(
    () => getValueDimensions(currency, localUnits),
    [currency, localUnits]
  );

  useEffect(() => {
    setLocalData(panelData);
  }, [panelData]);

  const handleSave = useCallback(() => {
    if (localData) onSuccess(localData);
  }, [localData, onSuccess]);

  const valueOrEmptyString = defaultTo('');

  const onChangeInput = (
    value: string,
    field: keyof OtherCostsOverheadCostsPanelData
  ) => {
    const newValue =
      field === 'repairCostUnit'
        ? value
        : validateInputNumber(
            value,
            String(localData?.[field]),
            ['repairCostValue'].includes(field) ? 7 : 6
          );

    setLocalData(
      prevData =>
        ({
          ...prevData,
          [field]: newValue ?? null
        } as OtherCostsOverheadCostsPanelData)
    );
  };

  return (
    <CpcPanel
      title="Update overhead cost"
      activeAction={!!localData}
      closeAction={onCancel}
      saveAction={handleSave}
    >
      {localData ? (
        <>
          <Grid>
            <GridCol size={12}>
              <Input
                type="number"
                name="overhead-costs-panel-overheadCost"
                id="overhead-costs-panel-overheadCost"
                label={`Overhead*`}
                inlineLabelLeft={`${currency}/month`}
                value={valueOrEmptyString(localData.overheadCost)}
                onChange={event =>
                  onChangeInput(event.target.value, 'overheadCost')
                }
              />
            </GridCol>
          </Grid>
          <Grid>
            <GridCol size={12}>
              <div className={`hcr-space-2-top`}>
                <Input
                  type="number"
                  name="overhead-costs-panel-legalCost"
                  id="overhead-costs-panel-legalCost"
                  label={`Legal`}
                  inlineLabelLeft={`${currency}/month`}
                  value={valueOrEmptyString(localData.legalCost)}
                  onChange={event =>
                    onChangeInput(event.target.value, 'legalCost')
                  }
                />
              </div>
            </GridCol>
          </Grid>
          <Grid>
            <GridCol size={12}>
              <div className={`hcr-space-2-top`}>
                <InputWithSelection
                  selectorOptions={costDimensionOptions}
                  selectorValue={valueOrEmptyString(localData.repairCostUnit)}
                  inputValue={valueOrEmptyString(localData.repairCostValue)}
                  label={`Damage repair`}
                  onChange={({inputValue, selectorValue}) => {
                    if (selectorValue !== localData.repairCostUnit) {
                      onChangeInput(selectorValue, 'repairCostUnit');
                    }

                    if (inputValue !== String(localData.repairCostValue)) {
                      onChangeInput(inputValue, 'repairCostValue');
                    }
                  }}
                />
              </div>
            </GridCol>
          </Grid>
          <Grid>
            <GridCol size={12}>
              <div className={`hcr-space-2-top`}>
                <Input
                  type="number"
                  name="overhead-costs-panel-otherCost"
                  id="overhead-costs-panel-otherCost"
                  label={`Other cost`}
                  inlineLabelLeft={`${currency}/month`}
                  value={valueOrEmptyString(localData.otherCost)}
                  onChange={event =>
                    onChangeInput(event.target.value, 'otherCost')
                  }
                />
              </div>
            </GridCol>
          </Grid>
        </>
      ) : (
        <ContentLoader />
      )}
    </CpcPanel>
  );
};

export default React.memo(OtherCostsOverheadCostsPanel);
