import {gql} from '@apollo/client';

const QUALITY_CHECK_BUS_PARTNER_COST_ENTRY_YEAR = gql`
  mutation QualityCheckBusPartnerCostEntryYear(
    $busPartnerId: ID!
    $year: Int!
    $qualityChecked: Boolean!
    $withValidation: Boolean
  ) {
    qualityCheckBusPartnerCostEntryYear(
      busPartnerId: $busPartnerId
      year: $year
      qualityChecked: $qualityChecked
      withValidation: $withValidation
    ) {
      year
      qualityChecked
      qualityCheckedLastEditedAt
    }
  }
`;

export default QUALITY_CHECK_BUS_PARTNER_COST_ENTRY_YEAR;
