import React, {useMemo} from 'react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import ProductionCostStepOne from './DataImportStepOne';
import ProductionCostStepTwo from './DataImportStepTwo';
import ProductionCostStepThree from './DataImportStepThree';
import styles from './index.module.scss';

const ProductionCost: React.FC = () => {
  const productionCostId = useAppSelector(
    ({productionCost}) => productionCost.id
  );
  const busScheduleValues = useAppSelector(
    ({productionCost}) => productionCost.busScheduleValues
  );
  const calculatedProductionCosts = useAppSelector(
    ({productionCost}) => productionCost.calculatedProductionCosts
  );

  const content = useMemo(() => {
    if (!productionCostId || !busScheduleValues)
      return <ProductionCostStepOne />;
    else if (
      productionCostId &&
      busScheduleValues &&
      !calculatedProductionCosts
    )
      return <ProductionCostStepTwo />;
    else return <ProductionCostStepThree />;
  }, [productionCostId, busScheduleValues, calculatedProductionCosts]);

  return <div className={styles.container}>{content}</div>;
};
export default React.memo(ProductionCost);
