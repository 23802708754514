import {
  PlanrSchedule,
  PlanrScheduleType
} from 'Pages/ProductionCost/store/types';
import {SchedulesListPerDay} from './LineSchedulesSelect.types';

const getDaysArray = (startDate: Date, endDate: Date) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const dates: Date[] = [];
  for (let dt = startDateObj; dt <= endDateObj; dt.setDate(dt.getDate() + 1)) {
    dates.push(new Date(dt));
  }
  return dates;
};

export const getSchedulesListPerDays = (
  startDate: Date,
  endDate: Date,
  schedules: PlanrSchedule[]
): SchedulesListPerDay => {
  const planningPeriodDates = getDaysArray(startDate, endDate);

  const schedulesListPerDay: SchedulesListPerDay = {};
  planningPeriodDates.forEach(datePeriod => {
    if (!schedulesListPerDay[datePeriod.toString()]) {
      schedulesListPerDay[datePeriod.toString()] = {
        [PlanrScheduleType.Regular]: [],
        [PlanrScheduleType.Holiday]: []
      };
    }

    schedules
      .filter(({selected}) => selected)
      .forEach(schedule => {
        const currDay = new Date(datePeriod).setHours(0, 0, 0, 0);
        const startDate = new Date(schedule.startDate).setHours(0, 0, 0, 0);
        const endDate = new Date(schedule.endDate).setHours(0, 0, 0, 0);

        // If the current day is within the schedule's start and end date
        if (startDate <= currDay && currDay <= endDate) {
          const date = schedulesListPerDay[datePeriod.toString()];
          date[schedule.type].push(schedule.uuid);
        }
      });
  });

  return schedulesListPerDay;
};
