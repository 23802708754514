import React, {useEffect, useState} from 'react';
import {isNil} from 'ramda';
import {Input} from '@flixbus/honeycomb-react';
import CpcPanel from 'Pages/Common/CpcPanel';
import {validateInputNumber} from 'Pages/Common/Utils';
import {TableHeader} from 'components/Table/Table.types';
import {TablePanelProps} from './TablePanel.types';

const TablePanel: React.FC<TablePanelProps<any>> = ({
  title,
  fields,
  data,
  onSuccess,
  onCancel
}) => {
  const [localData, setLocalData] = useState(data);
  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const save = () => {
    onSuccess(localData);
    setLocalData(null);
  };

  const reset = () => {
    onCancel();
    setLocalData(null);
  };

  const onChangeInput = (value: string, field: TableHeader) => {
    const newValue = validateInputNumber(
      value,
      localData[field.key],
      field.integerDigitsCount,
      field.fractionDigitsCount
    );

    setLocalData(prevData => ({
      ...prevData,
      [field.key]: newValue
    }));
  };

  return (
    <CpcPanel
      title={title}
      activeAction={!!localData}
      saveAction={save}
      closeAction={reset}
    >
      {fields.map(field => (
        <Input
          key={`table-panel-${field.key}`}
          name={`table-panel-${field.key}`}
          id={`table-panel-${field.key}`}
          data-testid={`table-panel-${field.key}`}
          type={`number`}
          label={field.name}
          step={'0.1'}
          value={
            !isNil(localData) && !isNil(localData[field.key])
              ? localData[field.key]
              : ''
          }
          onChange={event => onChangeInput(event.target.value, field)}
        />
      ))}
    </CpcPanel>
  );
};

export default TablePanel;
