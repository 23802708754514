import {isDevOrStagingEnvironment} from 'Pages/Common/Utils';
import {sha256} from 'js-sha256';

export const logMajorVersionMismatch = (): void => {
  const outdatedPackages: string | null =
    process.env.REACT_APP_OUTDATED_PACKAGES ?? null;
  if (!outdatedPackages || !isDevOrStagingEnvironment) return;

  // Split the outdatedPackages string into an array of parts
  const packageInfo: string[] = outdatedPackages.split(/\s+/);

  const packageName: string = packageInfo[0];
  const currentVersion: string = packageInfo[1];
  const latestVersion: string = packageInfo[3];

  // Check for major version mismatch
  if (currentVersion.split('.')[0] !== latestVersion.split('.')[0]) {
    console.warn('Outdated package with major version mismatch:');
    console.warn(`${packageName}: ${currentVersion} => ${latestVersion}`);
  }
};

export const anonymizeUsername = (username: string) => {
  return sha256(username);
};
