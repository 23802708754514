import React, {useEffect, useState, useCallback} from 'react';
import {
  Fieldset,
  Text,
  Select,
  SelectOption,
  Input,
  Button,
  Box
} from '@flixbus/honeycomb-react';
import {
  Icon,
  IconArrowLeftL,
  IconArrowRightL
} from '@flixbus/honeycomb-icons-react';
import {isNotEmpty} from 'ramda-adjunct';
import useComponentDidMount from 'Pages/Common/useComponentDidMount';
import {getProductionCostEntries} from 'Pages/Home/store/thunks';
import {selectHomePageQueryParams} from 'Pages/Home/store/slice';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';

const Pagination: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    page,
    analyticsMode,
    paginationOutput,
    recordsPerPage: stateRecordsPerPage
  } = useAppSelector(({calculatedEntries}) => calculatedEntries);
  const queryParams = useAppSelector(selectHomePageQueryParams);

  const [pageNumber, setPageNumber] = useState(page + 1);
  const [recordsPerPage, setRecordsPerPage] = useState(stateRecordsPerPage);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const componentDidMount = useComponentDidMount();

  const getCurrentTotalRecords = useCallback(
    () =>
      pageNumber === paginationOutput.totalPages
        ? Number(paginationOutput.totalRecords)
        : recordsPerPage * pageNumber,
    [
      pageNumber,
      paginationOutput.totalPages,
      paginationOutput.totalRecords,
      recordsPerPage
    ]
  );

  const getPreviousTotalRecords = useCallback(
    () => recordsPerPage * (pageNumber - 1),
    [recordsPerPage, pageNumber]
  );
  const [currentTotalRecords, setCurrentTotalRecords] =
    useState(recordsPerPage);
  const [previousTotalRecords, setPreviousTotalRecords] = useState(0);

  useEffect(() => {
    setPageNumber(page + 1);
  }, [page]);

  const clearTimer = () => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  };

  useEffect(() => {
    // Avoid making request on initial render
    if (!componentDidMount) return;

    clearTimer();
    if (pageNumber > 0 && pageNumber <= Number(paginationOutput.totalPages)) {
      const timeoutEvent = setTimeout(() => {
        if (timer || componentDidMount) makeRequestToLoadTable();
      }, 500);
      setTimer(timeoutEvent);
    }
    return clearTimer;
  }, [pageNumber, recordsPerPage, paginationOutput.totalPages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentTotalRecords(getCurrentTotalRecords());
    setPreviousTotalRecords(getPreviousTotalRecords());
  }, [getCurrentTotalRecords, getPreviousTotalRecords]);

  const makeRequestToLoadTable = () => {
    dispatch(
      getProductionCostEntries(
        pageNumber - 1,
        queryParams,
        analyticsMode,
        recordsPerPage
      )
    );
    setCurrentTotalRecords(getCurrentTotalRecords());
    setPreviousTotalRecords(getPreviousTotalRecords());
  };

  const onPageValueChange = event => {
    let pageNumberVal = event.target.value;

    if (
      pageNumberVal < 1 ||
      pageNumberVal > Number(paginationOutput.totalPages)
    )
      pageNumberVal = '';

    if (isNotEmpty(pageNumberVal)) pageNumberVal = Math.floor(pageNumberVal);

    setPageNumber(pageNumberVal);
  };

  const onArrowButtonAction = (action: 'INCREMENT' | 'DECREMENT') => {
    let pageNumberVal = 0;
    switch (action) {
      case 'INCREMENT':
        pageNumberVal = pageNumber + 1;
        break;
      case 'DECREMENT':
        pageNumberVal = pageNumber - 1;
        break;
    }

    if (pageNumberVal > 0) setPageNumber(pageNumberVal);
  };

  const onSelect = event => {
    const recordsPerPageInput = Number(event.target.value);
    setPageNumber(1);
    setRecordsPerPage(recordsPerPageInput);
  };

  return (
    <Box extraClasses="pagination">
      <Fieldset horizontal>
        <Button
          display="square"
          aria-label="Previous Page Button"
          onClick={() => onArrowButtonAction('DECREMENT')}
          disabled={pageNumber <= 1}
        >
          <Icon InlineIcon={IconArrowLeftL} aria-hidden="true" />
        </Button>
        <Input
          id="table-page-number"
          value={pageNumber}
          onInput={onPageValueChange}
          aria-label="Table Page Number"
          type="number"
        />

        <Text extraClasses="total-pages"> / {paginationOutput.totalPages}</Text>
        <Button
          display="square"
          aria-label="Next Page Button"
          onClick={() => onArrowButtonAction('INCREMENT')}
          disabled={pageNumber >= Number(paginationOutput.totalPages)}
        >
          <Icon InlineIcon={IconArrowRightL} aria-hidden="true" />
        </Button>
        <div className="records-per-page">
          <Select
            id="records-per-page"
            aria-label="Records per page"
            defaultValue={recordsPerPage}
            onChange={onSelect}
          >
            <SelectOption value={10}>10</SelectOption>
            <SelectOption value={25}>25</SelectOption>
            <SelectOption value={50}>50</SelectOption>
            <SelectOption value={75}>75</SelectOption>
            <SelectOption value={100}>100</SelectOption>
          </Select>
          <Text extraClasses="text-records">records per page</Text>
        </div>
        <Text extraClasses="text-records">
          {previousTotalRecords + 1}...
          {currentTotalRecords} of {paginationOutput.totalRecords} records
        </Text>
      </Fieldset>
    </Box>
  );
};

export default React.memo(Pagination);
