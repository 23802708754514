import React, {ReactNode, useCallback, useState} from 'react';

import {ToastsContainer} from './ToastsContainer';

export const NOOP = () => undefined;

export type Toast = {
  id: number;
  type: 'warning' | 'success' | 'danger';
  content: ReactNode;
  description?: string;
  duration?: number;
};

type Context = {
  // eslint-disable-next-line no-unused-vars
  addToast: (toast: Omit<Toast, 'id'>) => void;
};

export const ToastContext = React.createContext<Context>({
  addToast: NOOP
});
ToastContext.displayName = 'ToastContext';

let id = 0;

const ToastsProviderComp: React.FC<{children: ReactNode}> = ({children}) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const removeToast = useCallback(
    (id: number) => setToasts(toasts => toasts.filter(t => t.id !== id)),
    []
  );

  const addToast = useCallback(
    (toast: Omit<Toast, 'id'>) => {
      const payload = {
        id: id++,
        duration: 4000,
        ...toast
      };
      setToasts(toasts => [payload, ...toasts]);
      setTimeout(() => removeToast(payload.id), payload.duration);
    },
    [removeToast]
  );

  return (
    <ToastContext.Provider value={{addToast}}>
      {toasts.length > 0 && (
        <ToastsContainer toasts={toasts} removeToast={removeToast} />
      )}
      {children}
    </ToastContext.Provider>
  );
};

export const ToastsProvider = React.memo(ToastsProviderComp);
