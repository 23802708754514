import React, {useEffect, useState, useMemo} from 'react';
import {connect} from 'react-redux';

import {
  fetchCountryPrefilledEmptyValues,
  updateCountryCostEntry,
  updateCountryPrefilledEmptyValues
} from '../store/thunks';
import {
  Button,
  DataTable,
  DataTableHead,
  DataTableRow,
  Infobox,
  Input
} from '@flixbus/honeycomb-react';
import {Icon, IconEditSolid} from '@flixbus/honeycomb-icons-react';
import {isNil} from 'ramda';
import emptyDrivingDefaultsOptions from '../constants/emptyDrivingDefaultsOptions';
import CpcPanel from '../../Common/CpcPanel';
import {useLocalUnitsContext} from '../../Common/LocalUnits';
import {getScaledNumber, validateInputNumber} from '../../Common/Utils';

const RegionalCostsEmptyDrivingDefaultsTab = ({
  country,
  countryPrefilledEmptyValues,
  fetchCountryPrefilledEmptyValues,
  updateCountryPrefilledEmptyValues
}) => {
  const {localUnits} = useLocalUnitsContext();
  const [updateParam, setUpdateParam] = useState(null);
  const [updateParamValue, setUpdateParamValue] = useState(null);

  useEffect(() => {
    if (isNil(countryPrefilledEmptyValues)) {
      fetchCountryPrefilledEmptyValues();
    }
  }, [country, countryPrefilledEmptyValues, fetchCountryPrefilledEmptyValues]);

  const headers = useMemo(
    () => (
      <DataTableHead>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col"></th>
      </DataTableHead>
    ),
    []
  );

  const rows = useMemo(
    () =>
      emptyDrivingDefaultsOptions(localUnits).map(item => ({
        cells: [
          <strong key={`regional-costs-${item.key}`}>{item.name}</strong>,
          countryPrefilledEmptyValues
            ? getScaledNumber(countryPrefilledEmptyValues[item.key])
            : '',
          <>
            <Button
              display="square"
              aria-label="Edit Country Prefilled Empty Values"
              onClick={() => {
                setUpdateParam(item.key);
                setUpdateParamValue(countryPrefilledEmptyValues?.[item.key]);
              }}
            >
              <Icon InlineIcon={IconEditSolid} aria-hidden="true" />
            </Button>
          </>
        ]
      })),
    [countryPrefilledEmptyValues, localUnits]
  );

  if (isNil(countryPrefilledEmptyValues)) {
    return (
      <Infobox appearance="warning">
        <p>
          Select a <strong>country</strong>
        </p>
      </Infobox>
    );
  }

  const resetDataUpdate = () => {
    setUpdateParam(null);
    setUpdateParamValue(null);
  };

  const saveCountryCostData = () => {
    updateCountryPrefilledEmptyValues({
      ...countryPrefilledEmptyValues,
      [updateParam]: updateParamValue
    });
    resetDataUpdate();
  };

  const onChangeInput = changeEvent => {
    const {value} = changeEvent.target;
    const newValue = validateInputNumber(value, updateParamValue);
    setUpdateParamValue(newValue);
  };

  return (
    <>
      <div className={`hcr-space-1-bottom`}>Per operating day</div>
      <DataTable extraClasses={`table-hide-header`} headers={headers}>
        {rows.map((row, rowIndex) => (
          <DataTableRow key={rowIndex}>
            {row.cells.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </DataTableRow>
        ))}
      </DataTable>
      <CpcPanel
        title="Update empty driving defaults"
        activeAction={!isNil(updateParam)}
        closeAction={resetDataUpdate}
        saveAction={saveCountryCostData}
      >
        <Input
          name={`country-cost-${updateParam}`}
          id={`country-cost-${updateParam}`}
          type={'number'}
          label={
            !isNil(updateParam)
              ? emptyDrivingDefaultsOptions(localUnits).find(
                  v => v.key === updateParam
                ).name
              : 'label'
          }
          step={`0.1`}
          value={isNil(updateParamValue) ? '' : updateParamValue}
          onChange={onChangeInput}
        />
      </CpcPanel>
    </>
  );
};

export default connect(
  state => ({
    country: state.regionalCosts.country,
    countryPrefilledEmptyValues: state.regionalCosts.countryPrefilledEmptyValues
  }),
  {
    fetchCountryPrefilledEmptyValues,
    updateCountryPrefilledEmptyValues,
    updateCountryCostEntry
  }
)(RegionalCostsEmptyDrivingDefaultsTab);
