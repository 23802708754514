import React from 'react';
import {Grid, GridCol} from '@flixbus/honeycomb-react';
import {Icon, IconQuestion} from '@flixbus/honeycomb-icons-react';
import {LabelWithTooltipProps} from './LabelWithTooltip.types';

const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({
  label,
  setActiveTooltip
}) => (
  <Grid extraClasses="no-wrap">
    <GridCol inline extraClasses="ellipsis-text">
      {label}
    </GridCol>
    <GridCol inline>
      <Icon
        size={4}
        InlineIcon={IconQuestion}
        onMouseOver={() => setActiveTooltip(true)}
        onMouseOut={() => setActiveTooltip(false)}
        onBlur={() => setActiveTooltip(false)}
        style={{cursor: 'pointer', marginTop: '-3px'}}
        aria-label="Input Info Icon"
      />
    </GridCol>
  </Grid>
);

export default React.memo(LabelWithTooltip);
