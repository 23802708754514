import {isBlank} from 'ramda-adjunct';

export const convertDecimalToPercentage = value =>
  value && value !== 0 ? value * 100 : null;

export const convertPercentageToDecimal = value =>
  value && value !== 0 ? value / 100 : null;

export const displaySuccessMessage = (addToast, message) => {
  addToast({
    type: 'success',
    content: message
  });
};
export const displayErrorMessage = (addToast, message) => {
  addToast({
    type: 'danger',
    content: message
  });
};

export const isDevEnvironment = process.env.REACT_APP_ENV === 'development';
export const isStagingEnvironment = process.env.REACT_APP_ENV === 'staging';
export const isDevOrStagingEnvironment =
  isDevEnvironment || isStagingEnvironment;

export const getScaledNumber = (
  value: string,
  fractionDigitsCount = 2
): string | null => {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? null : parsedValue.toFixed(fractionDigitsCount);
};

export const validateInputNumber = (
  newValue: string | null,
  oldValue: string,
  integerDigitsCount = 6,
  fractionDigitsCount = 2,
  allowNegative = false
): number | null => {
  if (newValue === null || isBlank(newValue)) return null;

  const basePattern = `\\d{0,${integerDigitsCount}}(\\.\\d{0,${fractionDigitsCount}})?$`;
  const validPattern = allowNegative
    ? new RegExp(`^-?${basePattern}`)
    : new RegExp(`^${basePattern}`);

  const parsedValue = parseFloat(newValue);

  if (validPattern.test(newValue) || isNaN(parsedValue)) {
    return Number(newValue);
  }

  return Number(getScaledNumber(oldValue, fractionDigitsCount));
};

export const validateInputYear = value => {
  return Number(value.replace(/\D/g, '').slice(0, 4));
};

export const generateDataTestIdFromText = (text: string, suffix?: string) => {
  return text
    .split(' ')
    .filter(word => word)
    .map(word => word.toLowerCase())
    .join('-')
    .concat(suffix ? `-${suffix}` : '');
};
