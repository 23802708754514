import {gql} from '@apollo/client';

const CREATE_PARTNER = gql`
  mutation createBusPartner(
    $name: String!
    $countryId: ID!
    $backendId: String
    $depots: [BusPartnerDepotInput]
  ) {
    createBusPartner(
      input: {
        name: $name
        countryId: $countryId
        backendId: $backendId
        depots: $depots
      }
    ) {
      id
      name
      backendId
      countryId
      currency
      depots {
        city
        postCode
        street
      }
    }
  }
`;

export default CREATE_PARTNER;
