import {gql} from '@apollo/client';

const DELETE_COUNTRY = gql`
  mutation deleteCountry($id: ID!) {
    deleteCountry(id: $id) {
      id
      name
      businessRegion
      repairCostDimension
      maintenanceCostDimension
    }
  }
`;

export default DELETE_COUNTRY;
