import {
  PlanrScheduleStatus,
  PlanrScheduleType,
  ProductionCostStatus
} from '../store/types';

export const productionCostStatuses = [
  {
    name: 'In Production',
    key: ProductionCostStatus.InProduction
  },
  {
    name: 'Draft',
    key: ProductionCostStatus.Draft
  },
  {
    name: 'Planned',
    key: ProductionCostStatus.Planned
  }
];

export const productionCostLineStatuses = [
  {
    name: 'Published',
    key: PlanrScheduleStatus.Published
  },
  {
    name: 'Draft',
    key: PlanrScheduleStatus.Draft
  },
  {
    name: 'Archived',
    key: PlanrScheduleStatus.Archived
  },
  {
    name: 'Planned',
    key: PlanrScheduleStatus.Planned
  },
  {
    name: 'Concept change',
    key: PlanrScheduleStatus.ConceptChange
  },
  {
    name: 'Final',
    key: PlanrScheduleStatus.Final
  },
  {
    name: 'In generation',
    key: PlanrScheduleStatus.InGeneration
  }
];

export const planrScheduleTypes = [
  {
    name: 'Regular',
    key: PlanrScheduleType.Regular
  },
  {
    name: 'Holiday',
    key: PlanrScheduleType.Holiday
  }
];
