const emptyDrivingDefaultsOptions = localUnits => [
  {
    key: 'prefilledEmptyKm',
    name: `Pre-filled value for empty ${localUnits.distanceAbbreviation}`
  },
  {
    key: 'prefilledEmptyHr',
    name: 'Pre-filled value for empty hr'
  }
];

export default emptyDrivingDefaultsOptions;
