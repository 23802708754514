import React from 'react';
import {Link, Fieldset} from '@flixbus/honeycomb-react';
import {Icon, IconBarGraphSolid} from '@flixbus/honeycomb-icons-react';
import {PowerBiReportLinkProps} from './PowerBiReportLink.types';

const PowerBiReportLink: React.FC<PowerBiReportLinkProps> = ({href, label}) => {
  return (
    <Link target="_blank" href={href}>
      <Fieldset itemSpacing="1" horizontal>
        <Icon
          style={{fill: 'currentColor'}}
          InlineIcon={IconBarGraphSolid}
          aria-label="Opens in a New Tab"
        />
        <span>{label}</span>
      </Fieldset>
    </Link>
  );
};

export default React.memo(PowerBiReportLink);
