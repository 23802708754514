import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Button, Fieldset} from '@flixbus/honeycomb-react';
import {addLineSchedule} from '../../store/slice';
import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import {ProductionCostFilterTypeButton} from './ProductionCostFilterType';

const LineSchedulesFooter = () => {
  const dispatch = useDispatch();

  const productionCost = useSelector(state => state.productionCost);

  const getIsAllLineSchedulesValid = lineSchedules => {
    return (
      isNotNilOrEmpty(lineSchedules) &&
      !lineSchedules.filter(lineSchedule => !lineSchedule.isValid).length > 0
    );
  };
  const isAllLineSchedulesValid = useSelector(state =>
    getIsAllLineSchedulesValid(state.productionCost.lineSchedules)
  );

  const getIsAllSchedulesDataExist = productionCost => {
    return (
      productionCost.name !== null &&
      productionCost.startDate !== null &&
      productionCost.endDate !== null &&
      productionCost.status !== null &&
      productionCost.costYear !== null
    );
  };
  const isAllSchedulesDataExist = useSelector(state =>
    getIsAllSchedulesDataExist(state.productionCost)
  );

  if (isNil(productionCost.startDate) || isNil(productionCost.endDate)) {
    return null;
  }

  return (
    <div className={`line-schedules-footer`}>
      <Fieldset horizontal>
        <Button onClick={() => dispatch(addLineSchedule())}>
          Add more lines
        </Button>
        <ProductionCostFilterTypeButton
          isAllLineSchedulesValid={isAllLineSchedulesValid}
          isAllSchedulesDataExist={isAllSchedulesDataExist}
        />
      </Fieldset>
    </div>
  );
};

export default React.memo(LineSchedulesFooter);
