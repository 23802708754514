import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Fieldset, Input} from '@flixbus/honeycomb-react';
import {Icon, IconDelete} from '@flixbus/honeycomb-icons-react';
import {
  deleteCountrySalaryDefinition,
  updateCountrySalaryDefinition
} from '../../store/slice';

const DriverSalaryTime = ({
  type,
  time,
  salaryDefinition,
  updateCountrySalaryDefinition,
  deleteCountrySalaryDefinition
}) => {
  const [timeData, setTimeData] = useState(time);

  return (
    <div className={`driver-salary-periods-time-input-container`}>
      {type === `startTime` ? (
        <div
          className={`driver-salary-periods-time-input-delete`}
          onClick={() => {
            deleteCountrySalaryDefinition(salaryDefinition);
          }}
        >
          <Icon style={{fill: 'red'}} InlineIcon={IconDelete} />
        </div>
      ) : null}
      <Fieldset extraClasses={`driver-salary-periods-time-input`}>
        <Input
          id={`timeinput-driver-salary-${type}`}
          label={`${type === 'startTime' ? 'Start' : 'End'}`}
          type="time"
          defaultValue={timeData}
          onChange={({target: {value}}) => {
            setTimeData(value);
            const updatedSalaryDefinition = {
              ...salaryDefinition,
              [type]: value
            };
            updateCountrySalaryDefinition(updatedSalaryDefinition);
          }}
        />
      </Fieldset>
    </div>
  );
};

export default connect(() => ({}), {
  updateCountrySalaryDefinition,
  deleteCountrySalaryDefinition
})(DriverSalaryTime);
