import React, {useEffect, useState} from 'react';

import {Button} from '@flixbus/honeycomb-react';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import {useLazyQuery} from '@apollo/client';
import GET_PRODUCTION_COST_ANALYTICS_VIEW_SUBTOTAL_ON_SELECTION from '../graphql/getProductionCostAnalyticsViewCalculateOnSelectionQuery';
import ActionButton from './ActionButton';
import {IconCalculator} from '@flixbus/honeycomb-icons-react';

const CalculateOnSelectionButton = ({selectedRows, setCalculatedSubtotal}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [getSubtotal, {loading, data}] = useLazyQuery(
    GET_PRODUCTION_COST_ANALYTICS_VIEW_SUBTOTAL_ON_SELECTION
  );

  useEffect(() => {
    if (isNotNilOrEmpty(selectedRows)) setIsDisabled(false);
    else setIsDisabled(true);
  }, [selectedRows]);

  useEffect(() => {
    if (data) setCalculatedSubtotal(data?.productionCostAnalyticsView);
  }, [data, setCalculatedSubtotal]);

  const calculateSubtotal = rows => {
    setIsDisabled(true);

    void getSubtotal({
      variables: {
        productionCostAnalyticsViewEntryIds: rows
      },
      fetchPolicy: 'no-cache'
    });
  };

  return (
    <div>
      {loading ? (
        <Button loading>
          <span style={{fontSize: 'smaller'}}>Calculating...</span>
        </Button>
      ) : (
        <ActionButton
          onClick={() => calculateSubtotal(selectedRows)}
          disabled={isDisabled}
          name={'Calculate Subtotal'}
          buttonIcon={IconCalculator}
        />
      )}
    </div>
  );
};

export default React.memo(CalculateOnSelectionButton);
