export interface VehicleTypesState {
  list: VehicleType[] | null;
  vehicleType: VehicleType | null;
}

export interface VehicleType {
  id: string;
  name: string;
  fuelType: VehicleFuelType;
  dieselConsumption: number | null;
  adblueConsumption: number | null;
  lngConsumption: number | null;
  doubleDecker: boolean;
  numberOfTires: number;
}

export enum VehicleFuelType {
  DIESEL = 'DIESEL',
  LNG = 'LNG'
}
