import React, {useMemo, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {groupBy} from 'ramda';
import {DataTable, DataTableHead, DataTableRow} from '@flixbus/honeycomb-react';
import {getBusPartner, roundCostValues} from '../../../Home/utils';
import {CpcIconAttention} from '../../../Common/CpcIcons';
import {
  formatCostPerDistanceFieldName,
  getKmsOrMiles
} from '../../../Common/LabelUtils';
import {useLocalUnitsContext} from '../../../Common/LocalUnits';
import style from './CalculatedProductionCosts.module.scss';

const CalculatedProductionCosts = () => {
  const calculatedProductionCosts = useSelector(
    state => state.productionCost.calculatedProductionCosts
  );
  const {localUnits} = useLocalUnitsContext();
  const partners = useSelector(state => state.partners);
  const byLineNumber = groupBy(productionCost => productionCost.lineNumber);

  const headers = useMemo(
    () => (
      <DataTableHead>
        <th scope="col">Vehicle ID</th>
        <th scope="col">Bus Partner</th>
        <th scope="col">Currency</th>
        <th scope="col">Different schedules</th>
        <th scope="col">Operating days</th>
        <th scope="col">Total {getKmsOrMiles(localUnits)} (per week)</th>
        <th scope="col">Schedule hours - weekday/day (per week)</th>
        <th scope="col">Schedule hours - weekday/night (per week)</th>
        <th scope="col">Schedule hours - weekend/day (per week)</th>
        <th scope="col">Schedule hours - weekend/night (per week)</th>
        <th scope="col">Total schedule hours (per week)</th>
        <th scope="col">Total working hours (per week)</th>
        <th scope="col">Vehicle utilization in % for line</th>
        <th scope="col">
          {formatCostPerDistanceFieldName(
            localUnits,
            'Total driver salary cost'
          )}
        </th>
        <th scope="col">
          {formatCostPerDistanceFieldName(localUnits, 'Diesel cost')}
        </th>
        <th scope="col">
          {formatCostPerDistanceFieldName(localUnits, 'AdBlue cost')}
        </th>
        <th scope="col">
          {formatCostPerDistanceFieldName(localUnits, 'LNG cost')}
        </th>
        <th scope="col">
          {formatCostPerDistanceFieldName(localUnits, 'Total vehicle cost')}
        </th>
        <th scope="col">
          {formatCostPerDistanceFieldName(localUnits, 'Total other cost')}
        </th>
        <th scope="col">
          {formatCostPerDistanceFieldName(localUnits, 'Total cost')}
        </th>
      </DataTableHead>
    ),
    [localUnits]
  );

  const groupedProductionCosts = byLineNumber(calculatedProductionCosts);

  const buildRows = useCallback(
    lineNumber =>
      groupedProductionCosts[lineNumber].map(productionCost => {
        const roundedProductionCost = roundCostValues(productionCost);
        const partner = getBusPartner(
          partners,
          roundedProductionCost.busPartnerId
        );
        return {
          cells: [
            roundedProductionCost.busName,
            partner?.name,
            roundedProductionCost?.currency,
            roundedProductionCost.numSchedules,
            roundedProductionCost.numOperatingDays,
            roundedProductionCost.weeklyTotalKm,
            roundedProductionCost.weeklyDaytimeWeekdayScheduleHours,
            roundedProductionCost.weeklyNighttimeWeekdayScheduleHours,
            roundedProductionCost.weeklyDaytimeWeekendScheduleHours,
            roundedProductionCost.weeklyNighttimeWeekendScheduleHours,
            roundedProductionCost.weeklyTotalScheduleHours,
            roundedProductionCost.weeklyTotalWorkingHours,
            roundedProductionCost.lineWeightFactor * 100,
            roundedProductionCost.totalDriverSalaryCostPerKm,
            roundedProductionCost.totalDieselCostPerKm,
            roundedProductionCost.totalAdBlueCostPerKm,
            roundedProductionCost.totalLngCostPerKm,
            roundedProductionCost.busCostPerKm,
            roundedProductionCost.totalOtherCostPerKm,
            roundedProductionCost.totalCostPerKm
          ]
        };
      }),
    [groupedProductionCosts, partners]
  );

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div>
          <CpcIconAttention /> More detailed data in the “Analytics view” after
          calculation is saved
        </div>
      </div>
      {Object.keys(groupedProductionCosts).map(lineNumber => {
        const rows = buildRows(lineNumber);

        return (
          <div
            key={`line-${lineNumber}-table`}
            className={`production-cost-section-content`}
          >
            <div className={`production-cost-section-line`}>
              Line: <b>{lineNumber}</b>
            </div>
            <div className={`production-cost-section-table`}>
              <DataTable headers={headers}>
                {rows.map((row, rowIndex) => (
                  <DataTableRow key={rowIndex}>
                    {row.cells.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </DataTableRow>
                ))}
              </DataTable>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CalculatedProductionCosts;
