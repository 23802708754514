import {gql} from '@apollo/client';

const UPDATE_PARTNER = gql`
  mutation updateBusPartner(
    $id: ID!
    $name: String!
    $countryId: ID!
    $backendId: String
    $depots: [BusPartnerDepotInput]
  ) {
    updateBusPartner(
      input: {
        id: $id
        name: $name
        countryId: $countryId
        backendId: $backendId
        depots: $depots
      }
    ) {
      id
      name
      backendId
      countryId
      currency
      depots {
        city
        postCode
        street
      }
    }
  }
`;

export default UPDATE_PARTNER;
