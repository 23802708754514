import React from 'react';
import {Infobox} from '@flixbus/honeycomb-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {selectBusPartnerCountry} from '../../store/slice';
import PartnerCostsOtherCostContent from './PartnerCostsOtherCostContent/PartnerCostsOtherCostContent';

const PartnerCostsOtherCostTab: React.FC = () => {
  const partnerId = useAppSelector(({partnerCosts}) => partnerCosts.partnerId);
  const year = useAppSelector(({partnerCosts}) => partnerCosts.year);
  const partnerOtherCost = useAppSelector(
    ({partnerCosts}) => partnerCosts.otherCost
  );
  const regionalOtherCost = useAppSelector(
    ({partnerCosts}) => partnerCosts?.countryCost?.otherCost
  );
  const partnerCountry = useAppSelector(selectBusPartnerCountry);

  return !partnerId || !partnerCountry || !year || !partnerOtherCost ? (
    <Infobox appearance="warning">
      <p>
        Select a <strong>partner</strong> and a <strong>calendar year</strong>
      </p>
    </Infobox>
  ) : (
    <PartnerCostsOtherCostContent
      partnerId={partnerId}
      year={year}
      partnerOtherCost={partnerOtherCost}
      regionalOtherCost={regionalOtherCost}
      partnerCountry={partnerCountry}
    />
  );
};

export default React.memo(PartnerCostsOtherCostTab);
