import {ApolloClient, from, HttpLink, InMemoryCache} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {acquireAccessToken} from 'Pages/Auth/auth.config';
import {getUnitsSystemFromLocalStorage} from '../Pages/Common/LocalUnits';

const errorLink = onError(({graphQLErrors, networkError, operation}) => {
  if (graphQLErrors) {
    graphQLErrors.map(({message, location, path}) => {
      console.warn(`Graphql error ${message} -- ${location} -- ${path}`);
    });
  } else {
    console.error(
      'Network error: operationName:' + operation.operationName,
      operation,
      {networkError}
    );
  }
});

const withToken = setContext(async (_, {headers}) => {
  const authToken = await acquireAccessToken();
  const unitsSystem = getUnitsSystemFromLocalStorage();
  return {
    headers: {
      ...headers,
      Authorization: authToken ? `Bearer ${authToken}` : null,
      'units-system': unitsSystem
    }
  };
});

const httpLink = from([
  errorLink,
  new HttpLink({uri: process.env.REACT_APP_GRAPHQL_URL})
]);

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache'
  }
};

const graphqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([withToken, httpLink]),
  defaultOptions: defaultOptions
});

export default graphqlClient;
