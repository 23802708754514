import {gql} from '@apollo/client';

const UPDATE_PARTNER_OTHER_COST = gql`
  mutation updateBusPartnerOtherCostEntry(
    $partnerId: ID!
    $year: Int!
    $overheadCost: Float
    $legalCost: Float
    $cleaningCost: Float
    $repairCostPerDistanceUnit: CostPerDistance
    $repairCostPerMonth: Float
    $otherCost: Float
  ) {
    updateBusPartnerOtherCostEntry(
      input: {
        busPartnerId: $partnerId
        year: $year
        overheadCost: $overheadCost
        legalCost: $legalCost
        cleaningCost: $cleaningCost
        repairCostPerDistanceUnit: $repairCostPerDistanceUnit
        repairCostPerMonth: $repairCostPerMonth
        otherCost: $otherCost
      }
    ) {
      year
      overheadCost
      legalCost
      cleaningCost
      repairCostPerDistanceUnit
      repairCostPerMonth
      otherCost
    }
  }
`;

export default UPDATE_PARTNER_OTHER_COST;
