export default {
  fields: {
    countries: 'countries'
  },
  filterInputFields: [
    [
      {
        key: 'countryIds',
        title: 'Countries',
        field: 'countries'
      }
    ]
  ]
};
