import {gql} from '@apollo/client';

const GET_COUNTRY_COST_ENTRY = gql`
  query countryCostEntry($countryId: ID!, $year: Int!) {
    countryCostEntry(countryId: $countryId, year: $year) {
      year
      busCostEntry {
        adblueCost
        flixStandardCost
        dieselCost
        lngCost
        tiresCost
        currency
      }
      busTypeCostEntries {
        id
        busTypeId
        year
        customIdentifier
        insuranceCost
        interestCost
        leasingCost
        maintenanceCostPerMonth
        maintenanceCostPerDistanceUnit
        motorVehicleTax
        vehicleProductionYear
      }
      otherCostEntry {
        year
        overheadCost
        legalCost
        cleaningCost
        repairCostPerDistanceUnit
        repairCostPerMonth
        otherCost
      }
      driverCostEntry {
        year
        daytimeWeekdaysWorkingHourCost
        daytimeWeekendsWorkingHourCost
        nighttimeWeekdaysWorkingHourCost
        nighttimeWeekendsWorkingHourCost
        replacementCostPerKm
        replacementCostPerHr
        accommodationCostPerNight
      }

      qualityChecked
    }
  }
`;

export default GET_COUNTRY_COST_ENTRY;
