import React from 'react';
import {Text} from '@flixbus/honeycomb-react';
import {Icon, IconBus} from '@flixbus/honeycomb-icons-react';
import {LineVehicleIdProps} from './LineVehicleId.types';
import style from './LineVehicleId.module.scss';

const LineVehicleId: React.FC<LineVehicleIdProps> = ({vehicleName}) => {
  return (
    <div className={style.container} data-testid={`line-vehicle-id`}>
      <Icon InlineIcon={IconBus} extraClasses="content-secondary-color" />
      <Text extraClasses="font-bold">Vehicle ID: {vehicleName ?? ''}</Text>
    </div>
  );
};

export default React.memo(LineVehicleId);
