import React, {useEffect} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {isNil} from 'ramda';
import {Infobox} from '@flixbus/honeycomb-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {useCountryCurrency} from 'Pages/RegionalCosts/hooks/useCountryCurrency';
import {fetchCountrySpecificCost} from '../../store/thunks';
import RegionalCostsDriverCostContent from './RegionalCostsDriverCostContent/RegionalCostsDriverCostContent';

const RegionalCostsDriverCostTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const countryId = useAppSelector(({regionalCosts}) => regionalCosts.country);
  const year = useAppSelector(({regionalCosts}) => regionalCosts.year);
  const driverCost = useAppSelector(
    ({regionalCosts}) => regionalCosts.driverCost
  );

  useEffect(() => {
    if (isNil(driverCost)) {
      dispatch(fetchCountrySpecificCost(countryId, year));
    }
  }, [driverCost, countryId, year, dispatch]);

  const currency = useCountryCurrency(countryId);

  return countryId === null || year === null || driverCost === null ? (
    <Infobox appearance="warning">
      <p>
        Select <strong>country</strong> and <strong>calendar year</strong>
      </p>
    </Infobox>
  ) : (
    <RegionalCostsDriverCostContent
      countryId={countryId}
      year={year}
      driverCost={driverCost}
      currency={currency}
    />
  );
};

export default React.memo(RegionalCostsDriverCostTab);
