import {gql} from '@apollo/client';

const UPDATE_COUNTRY_BUS_COST_ENTRY = gql`
  mutation updateCountryBusCostEntry(
    $countryId: ID!
    $year: Int!
    $dieselCost: FuelCost
    $adblueCost: FuelCost
    $lngCost: Float
    $flixStandardCost: Float
    $tiresCost: TiresCost
  ) {
    updateCountryBusCostEntry(
      input: {
        countryId: $countryId
        year: $year
        dieselCost: $dieselCost
        adblueCost: $adblueCost
        lngCost: $lngCost
        flixStandardCost: $flixStandardCost
        tiresCost: $tiresCost
      }
    ) {
      adblueCost
      flixStandardCost
      dieselCost
      lngCost
      year
      currency
      tiresCost
    }
  }
`;

export default UPDATE_COUNTRY_BUS_COST_ENTRY;
