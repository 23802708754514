export const getPlanningPeriodDate = (
  startDate: string | null,
  endDate: string | null
) =>
  !startDate || !endDate
    ? ''
    : `${formateDate(startDate)} - ${formateDate(endDate)}`;

const formateDate = (dateString: string) => {
  const dateObj = new Date(dateString);

  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);

  return `${day}.${month}.${dateObj.getFullYear()}`;
};
