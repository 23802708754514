import {
  Notification,
  NotificationContainer,
  Text
} from '@flixbus/honeycomb-react';
import React from 'react';

import {Toast} from './ToastsProvider';

type Props = {
  toasts: Toast[];
  // eslint-disable-next-line no-unused-vars
  removeToast: (id: number) => void;
};

const ToastsContainerComp: React.FC<Props> = ({removeToast, toasts}) => {
  return (
    <NotificationContainer position={'top-right'}>
      {toasts.map(toast => {
        const onCloseClick = () => removeToast(toast.id);
        const closeProps = {
          onClick: onCloseClick,
          'aria-label': `${toast.type}-notification-${toast.id}`
        };

        return (
          <Notification
            key={toast.id}
            toast={false}
            appearance={toast?.type}
            closeProps={closeProps}
          >
            <strong style={{fontWeight: 'bold'}}>{toast.content}</strong>
            {toast.description && <Text>{toast.description}</Text>}
          </Notification>
        );
      })}
    </NotificationContainer>
  );
};

export const ToastsContainer = React.memo(ToastsContainerComp);
