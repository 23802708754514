import {useEffect, useState} from 'react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';

export const useCountryCurrency = (countryId: string | null) => {
  const [currency, setCurrency] = useState<string>('');

  const country = useAppSelector(({countries}) =>
    countries?.list?.find(country => country.id === countryId)
  );

  useEffect(() => {
    setCurrency(country?.currency || '???');
  }, [country]);

  return currency;
};
