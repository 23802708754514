import {gql} from '@apollo/client';

const GET_PRODUCTION_COST_ENTRIES = gql`
  query productionCostEntries(
    $page: Int!
    $size: Int!
    $name: String
    $busPartnerIds: [ID]
    $lineNumbers: [ID]
    $countryIds: [ID]
    $startDate: Date
    $endDate: Date
    $statuses: [ProductionCostEntryStatus]
  ) {
    productionCostEntriesView(
      pagination: {page: $page, size: $size}
      queryParams: {
        name: $name
        busPartners: $busPartnerIds
        lines: $lineNumbers
        countries: $countryIds
        startDate: $startDate
        endDate: $endDate
        statuses: $statuses
      }
    ) {
      paginationOutput {
        totalRecords
        totalPages
      }
      productionCostEntries {
        id
        name
        status
        publishingStatus
        startDate
        endDate
        lineNumbers
        countryIds
        busPartnerIds
        lineCount
        vehicleCount
        operatingDays
        totalKm
        totalHr
        totalWorkingHr
        productionCostPerKm
        calculationVersion
        createdAt
        lastEditedAt
        publishedAt
        usedCurrencies
        planrDataVersion
      }
    }
  }
`;

export default GET_PRODUCTION_COST_ENTRIES;
