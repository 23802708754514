import {ValueDimension} from '../components/index.constants';
import {CountriesState} from './types';

export const initCountryObject = {
  id: null,
  name: '',
  businessRegion: '',
  currency: 'EUR',
  errorMessage: null,
  repairCostDimension: ValueDimension.PER_MONTH,
  maintenanceCostDimension: ValueDimension.PER_MONTH
};

export const countriesInitialState: CountriesState = {
  list: null,
  country: null,
  currencies: null,
  repairCostDimension: null,
  maintenanceCostDimension: null
};
