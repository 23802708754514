import React from 'react';
import {Link} from '@flixbus/honeycomb-react';
import {Icon, IconAttention, IconNewTab} from '@flixbus/honeycomb-icons-react';
import {PlanrScheduleLinkProps} from './PlanrScheduleLinkProps.types';
import classNames from 'classnames';
import styles from './LineSchedulesSelectTable.module.scss';

const PlanrScheduleLink: React.FC<PlanrScheduleLinkProps> = ({
  schedule,
  hasOverlap
}) => {
  // Construct the deep link URL
  const baseUrl =
    process.env.REACT_APP_PLANR_URL || 'https://planr-dev.flixbus.com';
  const planrLink = `${baseUrl}/lines/${schedule.lineUuid}/schedules/${
    schedule.uuid
  }/${schedule.type.toLowerCase()}/timetable`;

  return (
    <Link
      target="_blank"
      href={planrLink}
      className={classNames({
        [styles.nameCell]: true,
        [styles.warning]: hasOverlap
      })}
    >
      {schedule.name}
      <Icon
        style={{fill: 'currentColor'}}
        InlineIcon={IconNewTab}
        size={3}
        aria-label="Opens in a New Tab"
      />
      {hasOverlap && (
        <Icon
          InlineIcon={IconAttention}
          size={3}
          className={styles.icon}
          aria-label="Attention"
        />
      )}
    </Link>
  );
};

export default React.memo(PlanrScheduleLink);
