import React, {useCallback, useMemo} from 'react';
import {Button, Fieldset} from '@flixbus/honeycomb-react';
import {Icon, IconCopy} from '@flixbus/honeycomb-icons-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {useToast} from 'Pages/Common/toasts-container';
import {confirm} from 'Pages/Common/Confirm';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {
  copyValuesToAllVehicles,
  copyValuesToSameVehicle,
  copyValuesToSameVehicleAndScheduleSize,
  selectLineScheduleById,
  selectVehicleEntryById
} from 'Pages/ProductionCost/store/slice';
import {CopyVehicleProps} from './CopyVehicle.types';
import styles from './CopyVehicle.module.scss';

const CopyVehicle: React.FC<CopyVehicleProps> = ({
  lineNumber,
  scheduleId,
  vehicleEntryId
}) => {
  const busValues = useAppSelector(
    ({productionCost}) => productionCost.busValues
  );
  const calculationUserJourneyMode = useAppSelector(
    ({productionCost}) => productionCost.calculationUserJourneyMode
  );
  const schedule = useAppSelector(({productionCost}) =>
    selectLineScheduleById(productionCost, {scheduleId})
  );
  const vehicleEntry = useAppSelector(({productionCost}) =>
    selectVehicleEntryById(productionCost, {vehicleEntryId})
  );

  const vehicleIdToCountryRulesCountryId = useMemo(
    () =>
      (busValues ?? []).reduce((acc, busValue) => {
        acc[busValue.vehicleId] = busValue.countryRulesCountryId;
        return acc;
      }, {}),
    [busValues]
  );

  const dispatch = useAppDispatch();
  const {addToast} = useToast();

  const successToastMessage = useCallback(
    (message: string) =>
      addToast({
        type: 'success',
        content: message
      }),
    [addToast]
  );

  const onClickCopyToSameVehicle = useCallback(() => {
    if (!vehicleEntry) return;

    dispatch(
      copyValuesToSameVehicle({
        lineNumber,
        sourceVehicleEntry: vehicleEntry
      })
    );
    successToastMessage(
      `Vehicle values have been copied to same vehicles successfully`
    );
  }, [lineNumber, vehicleEntry, dispatch, successToastMessage]);

  const onClickCopyToSameVehicleAndScheduleSize = useCallback(() => {
    if (!vehicleEntry || !schedule) return;

    dispatch(
      copyValuesToSameVehicleAndScheduleSize({
        lineNumber,
        scheduleSize: schedule.size,
        sourceVehicleEntry: vehicleEntry
      })
    );
    successToastMessage(
      `Vehicle values have been copied to schedules with size ${schedule.size} successfully`
    );
  }, [lineNumber, schedule, vehicleEntry, dispatch, successToastMessage]);

  const onClickCopyToAllVehicles = useCallback(async () => {
    if (!vehicleEntry) return;

    if (
      await confirm({
        PopupIcon: IconCopy,
        title: `Copy those vehicle values to all vehicles and schedules of line ${lineNumber}?`,
        message: `Please be cautious, all values will be overwritten.`
      })
    ) {
      dispatch(
        copyValuesToAllVehicles({
          lineNumber,
          sourceVehicleEntry: vehicleEntry,
          vehicleIdToCountryRulesCountryId
        })
      );
      successToastMessage(
        `Vehicle values have been copied to all successfully`
      );
    }
  }, [
    lineNumber,
    vehicleEntry,
    vehicleIdToCountryRulesCountryId,
    dispatch,
    successToastMessage
  ]);

  if (!schedule || !vehicleEntry) return null;

  return (
    <div className={styles.container}>
      <Fieldset horizontal itemSpacing="2" extraClasses="flex-right">
        <Button appearance="secondary" onClick={onClickCopyToSameVehicle}>
          <Icon InlineIcon={IconCopy} aria-hidden="true" />
          Copy to same vehicle
        </Button>

        {schedule.size && (
          <Button
            appearance="secondary"
            onClick={onClickCopyToSameVehicleAndScheduleSize}
          >
            <Icon InlineIcon={IconCopy} aria-hidden="true" />
            Copy to same vehicle in all {schedule.size} schedules
          </Button>
        )}

        {CalculationUserJourneyMode.EditCalculation !==
          calculationUserJourneyMode && (
          <Button appearance="secondary" onClick={onClickCopyToAllVehicles}>
            <Icon InlineIcon={IconCopy} aria-hidden="true" />
            Copy to all
          </Button>
        )}
      </Fieldset>
    </div>
  );
};

export default React.memo(CopyVehicle);
