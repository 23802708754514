import React, {useCallback, useState} from 'react';
import {Button} from '@flixbus/honeycomb-react';
import {Icon, IconArrowRight} from '@flixbus/honeycomb-icons-react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {
  fetchPlanrBusPartners,
  fetchPlanrVehicles
} from 'Pages/ProductionCost/store/thunks';
import ProductionCostFilterTypePopup from '../ProductionCostFilterTypePopup';
import {ProductionCostFilterTypeProps} from './index.types';

const ProductionCostFilterTypeButton: React.FC<
  ProductionCostFilterTypeProps
> = ({isAllLineSchedulesValid, isAllSchedulesDataExist}) => {
  const dispatch = useAppDispatch();

  const [popupActive, setPopupActive] = useState(false);
  const handleButtonClick = useCallback(() => {
    dispatch(fetchPlanrBusPartners());
    dispatch(fetchPlanrVehicles());

    setPopupActive(true);
  }, [dispatch]);

  return (
    <div>
      <Button
        appearance="primary"
        onClick={handleButtonClick}
        disabled={!isAllLineSchedulesValid || !isAllSchedulesDataExist}
        aria-haspopup="dialog"
      >
        Next
        <Icon InlineIcon={IconArrowRight} aria-hidden="true" />
      </Button>
      {popupActive && (
        <ProductionCostFilterTypePopup
          popupActive={popupActive}
          setPopupActive={setPopupActive}
        />
      )}
    </div>
  );
};

export default React.memo(ProductionCostFilterTypeButton);
