// Polyfill for TextEncoder, which's needed for the cuid2 package
import 'fast-text-encoding';
import {createId} from '@paralleldrive/cuid2';
import {isNil} from 'ramda';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  regionalCostsInitialState,
  countryCostYearData,
  countryCostData,
  initCountrySalaryDefinition,
  initCountryDriverConceptType
} from './data';
import {
  RegionalCostsBusTypeCost,
  RegionalCostsDriverCost,
  RegionalCostsOtherCost,
  RegionalCostsVehicleCost
} from './types';

const regionalCostsSlice = createSlice({
  name: 'regional-costs',
  initialState: regionalCostsInitialState,
  reducers: {
    selectCountry(state, {payload}: PayloadAction<string>) {
      return {
        ...state,
        ...countryCostYearData,
        ...countryCostData,
        country: payload
      };
    },
    selectYear(state, {payload}: PayloadAction<number>) {
      return {
        ...state,
        ...countryCostData,
        year: payload
      };
    },
    addedNewYear: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        ...countryCostData,
        year: payload.year,
        years: [...state.years, ...[payload.year]].sort(),
        vehicleCost: payload.busCostEntry,
        vehicleTypeCosts: {
          list: payload.busTypeCostEntries,
          vehicleTypeCost: null
        },
        driverCost: payload.driverCostEntry,
        otherCost: payload.otherCostEntry,
        qualityChecked: payload.qualityChecked
      };
    },
    deletedNewYear: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        ...countryCostData,
        year: null,
        years: state.years
          .filter(year => year.toString() !== payload.year.toString())
          .sort()
      };
    },
    qualityCheckedCountryCostEntryYear(
      state,
      {payload: {qualityChecked}}: PayloadAction<{qualityChecked: boolean}>
    ) {
      return {
        ...state,
        qualityChecked
      };
    },

    // Country Cost Entry
    receivedcountryCostEntry: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        vehicleCost: payload?.busCostEntry,
        vehicleTypeCosts: {
          list: payload?.busTypeCostEntries
        },
        otherCost: payload?.otherCostEntry,
        driverCost: payload?.driverCostEntry,
        qualityChecked: payload?.qualityChecked
      };
    },
    receivedCountryVehicleCostAction: (
      state,
      {payload}: PayloadAction<RegionalCostsVehicleCost>
    ) => {
      return {
        ...state,
        vehicleCost: payload
      };
    },
    receivedCountryDriverCost: (
      state,
      {payload}: PayloadAction<RegionalCostsDriverCost>
    ) => {
      return {
        ...state,
        driverCost: payload
      };
    },
    addToCountryVehicleTypeCostAction: (
      state,
      {payload}: PayloadAction<RegionalCostsBusTypeCost>
    ) => {
      const list = [...(state.vehicleTypeCosts.list ?? []), payload];
      return {
        ...state,
        vehicleTypeCosts: {
          ...state.vehicleTypeCosts,
          list
        }
      };
    },
    updateVehicleTypeCostAction: (
      state,
      {payload}: PayloadAction<RegionalCostsBusTypeCost>
    ) => {
      const list =
        state.vehicleTypeCosts.list?.map(vehicleTypeCost =>
          payload.id === vehicleTypeCost.id ? payload : vehicleTypeCost
        ) ?? [];

      return {
        ...state,
        vehicleTypeCosts: {
          ...state.vehicleTypeCosts,
          list
        }
      };
    },
    deleteFromVehicleTypeCostAction: (
      state,
      {payload}: PayloadAction<RegionalCostsBusTypeCost>
    ) => {
      const list =
        state.vehicleTypeCosts?.list?.filter(vehicleTypeCost => {
          return payload.id !== vehicleTypeCost.id;
        }) ?? [];

      return {
        ...state,
        vehicleTypeCosts: {
          ...state.vehicleTypeCosts,
          list
        }
      };
    },
    receivedCountryOtherCost: (
      state,
      {payload}: PayloadAction<RegionalCostsOtherCost>
    ) => {
      return {
        ...state,
        otherCost: payload
      };
    },
    receivedCountryCostYears: (state, {payload}: PayloadAction<number[]>) => {
      return {
        ...state,
        vehicleCost: null,
        otherCost: null,
        driverCost: null,
        years: [...payload].sort()
      };
    },

    // Country Prefilled Empty Value
    receivedCountryPrefilledEmptyValues: (
      state,
      {payload}: PayloadAction<any>
    ) => {
      return {
        ...state,
        countryPrefilledEmptyValues: payload
      };
    },

    // Country Driver Concept Type
    setCountryDriverConceptType: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        countryDriverConceptTypes: {
          ...state.countryDriverConceptTypes,
          countryDriverConceptType: isNil(payload)
            ? initCountryDriverConceptType
            : payload
        }
      };
    },
    resetCountryDriverConceptType: state => {
      return {
        ...state,
        countryDriverConceptTypes: {
          ...state.countryDriverConceptTypes,
          countryDriverConceptType: null
        }
      };
    },
    receivedCountryDriverConceptTypes: (
      state,
      {payload}: PayloadAction<any>
    ) => {
      return {
        ...state,
        countryDriverConceptTypes: {
          ...state.countryDriverConceptTypes,
          list: payload
        }
      };
    },
    deleteFromCountryDriverConceptTypes: (
      state,
      {payload}: PayloadAction<any>
    ) => {
      const list =
        state.countryDriverConceptTypes?.list?.filter(
          countryDriverConceptType => payload.id !== countryDriverConceptType.id
        ) ?? [];

      return {
        ...state,
        countryDriverConceptTypes: {
          ...state.countryDriverConceptTypes,
          list
        }
      };
    },
    updateCountryDriverConceptTypes: (state, {payload}: PayloadAction<any>) => {
      const list =
        state.countryDriverConceptTypes?.list?.map(countryDriverConceptType => {
          return payload.id === countryDriverConceptType.id
            ? payload
            : countryDriverConceptType;
        }) ?? [];

      return {
        ...state,
        countryDriverConceptTypes: {
          ...state.countryDriverConceptTypes,
          list
        }
      };
    },
    addToCountryDriverConceptTypes: (state, {payload}: PayloadAction<any>) => {
      const list = [...(state.countryDriverConceptTypes?.list ?? []), payload];

      return {
        ...state,
        countryDriverConceptTypes: {
          ...state.countryDriverConceptTypes,
          list
        }
      };
    },

    // Country Salary Definition
    addCountrySalaryDefinition: {
      reducer: (state, {payload}: PayloadAction<any>) => {
        return {
          ...state,
          countrySalaryDefinition: {
            ...state.countrySalaryDefinition,
            list: [
              ...state.countrySalaryDefinition.list,
              ...[
                {
                  ...initCountrySalaryDefinition,
                  salaryType: payload.salaryType,
                  dayOfWeek: payload.dayOfWeek,
                  id: createId()
                }
              ]
            ]
          }
        };
      },
      prepare: (salaryType, dayOfWeek) => ({
        payload: {
          salaryType,
          dayOfWeek
        }
      })
    },
    updateCountrySalaryDefinition: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        countrySalaryDefinition: {
          ...state.countrySalaryDefinition,
          list: [...state.countrySalaryDefinition.list].map(salaryDefinition =>
            salaryDefinition.id === payload.id ? payload : salaryDefinition
          )
        }
      };
    },
    deleteCountrySalaryDefinition: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        countrySalaryDefinition: {
          ...state.countrySalaryDefinition,
          list: [...state.countrySalaryDefinition.list].filter(
            salaryDefinition => salaryDefinition.id !== payload.id
          )
        }
      };
    },

    receivedCountrySalaryDefinitions: (
      state,
      {payload}: PayloadAction<any[]>
    ) => {
      return {
        ...state,
        countrySalaryDefinition: {
          ...state.countrySalaryDefinition,
          list: payload.map(salaryDefinition => ({
            ...salaryDefinition,
            id: createId()
          }))
        }
      };
    },
    driverSalaryDefinitionsSaved: state => {
      return {
        ...state,
        countrySalaryDefinition: {
          ...state.countrySalaryDefinition,
          messages: ['Saved']
        }
      };
    },
    driverSalaryDefinitionsErrors: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        countrySalaryDefinition: {
          ...state.countrySalaryDefinition,
          messages: payload
        }
      };
    }
  }
});

export const {
  selectCountry,
  selectYear,
  addedNewYear,
  deletedNewYear,
  qualityCheckedCountryCostEntryYear,

  // Country Cost Entry
  receivedcountryCostEntry,
  receivedCountryVehicleCostAction,
  receivedCountryDriverCost,
  addToCountryVehicleTypeCostAction,
  updateVehicleTypeCostAction,
  deleteFromVehicleTypeCostAction,
  receivedCountryOtherCost,
  receivedCountryCostYears,

  // Country Prefilled Empty Value
  receivedCountryPrefilledEmptyValues,

  // Country Driver Concept Type
  setCountryDriverConceptType,
  resetCountryDriverConceptType,
  receivedCountryDriverConceptTypes,
  deleteFromCountryDriverConceptTypes,
  updateCountryDriverConceptTypes,
  addToCountryDriverConceptTypes,

  // Country Salary Definition
  addCountrySalaryDefinition,
  updateCountrySalaryDefinition,
  deleteCountrySalaryDefinition,
  receivedCountrySalaryDefinitions,
  driverSalaryDefinitionsSaved,
  driverSalaryDefinitionsErrors
} = regionalCostsSlice.actions;

export default regionalCostsSlice.reducer;
