import {
  TableWithPanelAction,
  TableWithPanelHeader,
  TableWithPanelRow
} from 'components/TableWithPanel';

export type DriverCostsTableWithPanelConfig = {
  headers: TableWithPanelHeader[];
  rows: TableWithPanelRow[];
  actions: TableWithPanelAction[];
};

export type DriverCostsProps = {
  baseSalaryCosts: DriverCostsTableWithPanelConfig;
  driverChangeCosts: DriverCostsTableWithPanelConfig;
  accommodationCosts: DriverCostsTableWithPanelConfig;
  currency?: string;
};

export enum DriverCostsType {
  BaseSalary = 'Base Salary',
  DriverChange = 'Driver Change',
  Accommodation = 'Accommodation'
}
