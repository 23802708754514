import React from 'react';
import {connect} from 'react-redux';

import {saveDriverSalaryDefinitions} from '../../store/thunks';

import {Button, Grid, GridCol, PageContainer} from '@flixbus/honeycomb-react';
import {Icon, IconSaveSolid} from '@flixbus/honeycomb-icons-react';
import {isEmpty} from 'ramda';
import {CpcIconAttention} from '../../../Common/CpcIcons';

const DriverSalaryFooter = ({messages, saveDriverSalaryDefinitions}) => {
  return (
    <div className={`driver-salary-periods-footer`}>
      <PageContainer>
        <Grid>
          <GridCol size={11}>
            <div className={`driver-salary-periods-validation-message`}>
              {!isEmpty(messages) ? (
                <>
                  <CpcIconAttention /> <span>{messages.join(' ')}</span>
                </>
              ) : null}
            </div>
          </GridCol>
          <GridCol size={1}>
            <Button appearance="primary" onClick={saveDriverSalaryDefinitions}>
              <Icon InlineIcon={IconSaveSolid} aria-hidden="true" />
              Save
            </Button>
          </GridCol>
        </Grid>
      </PageContainer>
    </div>
  );
};

export default connect(
  state => ({
    messages: state.regionalCosts.countrySalaryDefinition.messages
  }),
  {
    saveDriverSalaryDefinitions
  }
)(DriverSalaryFooter);
