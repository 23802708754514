import {isNil} from 'ramda';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
  initPartnerDepotObject,
  initPartnerObject,
  partnersInitialState
} from './data';
import {getFilters} from './utils';

const partnersSlice = createSlice({
  name: 'partners',
  initialState: partnersInitialState,
  reducers: {
    receivedPartners: (state, {payload}: PayloadAction<any>) => {
      return {...state, list: payload};
    },
    setPartner: (state, {payload}: PayloadAction<any | null>) => {
      return {
        ...state,
        partner: isNil(payload) ? initPartnerObject : payload
      };
    },
    resetPartner: state => {
      return {...state, partner: null};
    },
    addToPartners: (state, {payload}: PayloadAction<any>) => {
      return {...state, list: [...(state.list ?? []), payload]};
    },
    updatePartners: (state, {payload}: PayloadAction<any>) => {
      const list = [...(state.list ?? [])].map(partner => {
        return payload.id === partner.id ? payload : partner;
      });

      return {...state, list};
    },
    deleteFromPartners: (state, {payload}: PayloadAction<any>) => {
      const list = [...(state.list ?? [])].filter(
        partner => payload.id !== partner.id
      );

      return {...state, list};
    },
    addPartnerDepot: state => {
      const depots = [...state.partner.depots, initPartnerDepotObject];

      return {
        ...state,
        partner: {
          ...state.partner,
          depots
        }
      };
    },
    deletePartnerDepot: (state, {payload}: PayloadAction<any>) => {
      const depots = [...state.partner.depots].filter(
        (depot, index) => index !== payload
      );

      return {
        ...state,
        partner: {
          ...state.partner,
          depots
        }
      };
    },
    updatePartnerDepot: {
      reducer(state, {payload}: PayloadAction<{index: number; depot: any}>) {
        const depots = [...state.partner.depots].map((depot, index) =>
          index === payload.index ? payload.depot : depot
        );

        return {
          ...state,
          partner: {
            ...state.partner,
            depots
          }
        };
      },
      prepare(index: number, depot: any) {
        return {
          payload: {index, depot}
        };
      }
    },
    updateOptions: {
      reducer(
        state,
        {payload}: PayloadAction<{field: string; options: any[]}>
      ) {
        return {
          ...state,
          options: {
            [payload.field]: payload.options
          }
        };
      },
      prepare(field: string, options: any) {
        return {
          payload: {field, options}
        };
      }
    },
    updateFilter: {
      reducer(state, {payload}: PayloadAction<{field: string; value: any}>) {
        const filters = getFilters(state.filters[payload.field], payload.value);

        return {
          ...state,
          filters: {
            ...state.filters,
            [payload.field]: filters
          }
        };
      },
      prepare(field: string, value: any) {
        return {
          payload: {field, value}
        };
      }
    },
    deleteFilter: {
      reducer(state, {payload}: PayloadAction<{field: string; value: any}>) {
        const newFilters = state.filters[payload.field].filter(
          filter => filter.title !== payload.value.title
        );

        return {
          ...state,
          filters: {
            ...state.filters,
            [payload.field]: newFilters
          }
        };
      },
      prepare(field: string, value: any) {
        return {
          payload: {field, value}
        };
      }
    },
    resetFilters: {
      reducer(state, {payload}: PayloadAction<{field: string}>) {
        return {
          ...state,
          filters: {
            ...state.filters,
            [payload.field]: []
          }
        };
      },
      prepare(field: string) {
        return {
          payload: {field}
        };
      }
    }
  }
});

export const {
  receivedPartners,
  setPartner,
  resetPartner,
  addToPartners,
  updatePartners,
  deleteFromPartners,
  addPartnerDepot,
  deletePartnerDepot,
  updatePartnerDepot,
  updateOptions,
  updateFilter,
  deleteFilter,
  resetFilters
} = partnersSlice.actions;

export default partnersSlice.reducer;
