import React, {useMemo} from 'react';
import {ProgressTracker, ProgressTrackerItem} from '@flixbus/honeycomb-react';
import {ProductionCostHeaderStepsProps} from './ProductionCostHeaderSteps.types';

const steps = [
  {text: 'Select schedules'},
  {text: 'Data Entry'},
  {text: 'Summary'}
];

const ProductionCostHeaderSteps: React.FC<ProductionCostHeaderStepsProps> = ({
  step
}) => {
  const productionCostSteps = useMemo(
    () =>
      steps.map((stepData, index) => {
        const isCompleted = index < step - 1;
        const isCurrent = index === step - 1;
        const dataTestId = stepData.text.replace(' ', '-').toLowerCase();
        const props: {completedSrText?: string} = {};
        if (isCompleted) {
          props.completedSrText = 'completed';
        }
        return (
          <ProgressTrackerItem
            key={stepData.text}
            completed={isCompleted}
            current={isCurrent}
            data-testid={dataTestId}
            extraClasses="progress-tracker-item"
            {...props}
          >
            {stepData.text}
          </ProgressTrackerItem>
        );
      }),
    [step]
  );

  return (
    <ProgressTracker extraClasses="progress-tracker">
      {productionCostSteps}
    </ProgressTracker>
  );
};

export default React.memo(ProductionCostHeaderSteps);
