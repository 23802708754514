import {gql} from '@apollo/client';

const DELETE_VEHICLE_TYPE = gql`
  mutation deleteBusType($id: ID!) {
    deleteBusType(busTypeId: $id) {
      id
      name
    }
  }
`;

export default DELETE_VEHICLE_TYPE;
