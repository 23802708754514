import {
  Button,
  Heading,
  Popup,
  PopupSection,
  Input,
  ButtonGroup
} from '@flixbus/honeycomb-react';
import {Icon, IconCopy} from '@flixbus/honeycomb-icons-react';
import {isNil} from 'ramda';
import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {cloneProductionCostEntry} from '../store/thunks';
import {isBlank} from 'ramda-adjunct';

const CloneConfirmationPopup = ({setEntryToClone, entryToClone, addToast}) => {
  const dispatch = useDispatch();

  const [cloneName, setCloneName] = useState('');

  const getDefaultCloneName = useCallback(() => {
    return (
      entryToClone?.name +
      '_' +
      new Date().toISOString().slice(-24).replace(/\D/g, '').slice(0, 14) +
      '_cloned'
    );
  }, [entryToClone?.name]);

  useEffect(() => {
    setCloneName(getDefaultCloneName());
  }, [getDefaultCloneName]);

  const handleClone = () => {
    if (!isBlank(cloneName)) {
      dispatch(
        cloneProductionCostEntry(
          entryToClone.id,
          cloneName,
          entryToClone?.name,
          addToast
        )
      );
      setEntryToClone(null);
    }
  };

  return (
    <Popup
      id="clone-entry-popup"
      active={!isNil(entryToClone)}
      onOverlayClick={() => setEntryToClone(null)}
    >
      <PopupSection type="icon">
        <Icon size={6} style={{fill: 'black'}} InlineIcon={IconCopy} />
      </PopupSection>

      <PopupSection type="title">
        <Heading size={2}>Clone Production Cost Calculation</Heading>
        <Input
          name="clone-production-cost-name"
          id="clone-production-cost-id"
          label="New production cost calculation clone name*"
          value={cloneName}
          valid={!isBlank(cloneName)}
          required
          onChange={e => setCloneName(e.target.value)}
          infoError="Field is mandatory"
        />
      </PopupSection>

      <PopupSection type="actions">
        <ButtonGroup alignment="center">
          <Button appearance="secondary" onClick={() => setEntryToClone(null)}>
            Cancel
          </Button>
          <Button appearance={'primary'} onClick={handleClone}>
            Clone
          </Button>
        </ButtonGroup>
      </PopupSection>
    </Popup>
  );
};

export default React.memo(CloneConfirmationPopup);
