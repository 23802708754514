import React from 'react';
import {Button} from '@flixbus/honeycomb-react';
import {Icon, IconCalculator} from '@flixbus/honeycomb-icons-react';
import {NavLink} from 'react-router-dom';
import HomeFilters from './HomeFilters';
import Calculations from './Calculations';
import Footer from './Footer';

const Home = () => {
  return (
    <div className="hcr-space-4-top">
      <NavLink
        to={`/production-cost-calculation`}
        style={{textDecoration: 'none'}}
      >
        <Button extraClasses="home-button" appearance="primary">
          <Icon InlineIcon={IconCalculator} aria-hidden="true" />
          New calculation
        </Button>
      </NavLink>
      <HomeFilters />
      <Calculations />
      <Footer />
    </div>
  );
};

export default Home;
