import {gql} from '@apollo/client';

const DELETE_PARTNER_VEHICLE_TYPE_COST = gql`
  mutation deleteBusPartnerBusTypeCostEntry($id: ID!) {
    deleteBusPartnerBusTypeCostEntry(id: $id) {
      id
    }
  }
`;

export default DELETE_PARTNER_VEHICLE_TYPE_COST;
