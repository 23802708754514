import React from 'react';
import {
  Icon,
  IconCheckmarkStrong,
  IconCrossed
} from '@flixbus/honeycomb-icons-react';
import {CpcIconAttention} from 'Pages/Common/CpcIcons';
import {getSchedulesOverlapSummary} from './LineSchedulesSelectSummary.utils';
import {LineSchedulesSelectSummaryProps} from './LineSchedulesSelectSummary.types';
import styles from './LineSchedulesSelectSummary.module.scss';

const LineSchedulesSelectSummary: React.FC<LineSchedulesSelectSummaryProps> = ({
  schedulesListPerDay
}) => {
  const overlaps = getSchedulesOverlapSummary(schedulesListPerDay);

  const getValidationIconForRegularHolidayCases = (num: number) =>
    num > 0 ? (
      <Icon
        InlineIcon={IconCrossed}
        style={{fill: 'red'}}
        size={4}
        extraClasses="hcr-space-half-right"
      />
    ) : (
      <Icon
        InlineIcon={IconCheckmarkStrong}
        style={{fill: 'green'}}
        size={4}
        extraClasses="hcr-space-half-right"
      />
    );

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <strong>Please check your selected schedules:</strong>
      </div>

      <div className={styles.section}>
        {getValidationIconForRegularHolidayCases(overlaps.regular)}
        <strong>{overlaps.regular} days</strong> with more than one schedule
        (regular)
      </div>

      <div className={styles.section}>
        {getValidationIconForRegularHolidayCases(overlaps.holiday)}
        <strong>{overlaps.holiday} days</strong> with more than one schedule
        (holiday)
      </div>

      {overlaps.periodGaps > 0 ? (
        <div className={styles.section}>
          <CpcIconAttention extraClasses="hcr-space-half-right" />
          <strong>{overlaps.periodGaps} days</strong> are not covered yet
        </div>
      ) : (
        <div className={styles.section}>
          <Icon
            InlineIcon={IconCheckmarkStrong}
            style={{fill: 'green'}}
            size={4}
            extraClasses="hcr-space-half-right"
          />
          All days are covered
        </div>
      )}
    </div>
  );
};
export default React.memo(LineSchedulesSelectSummary);
