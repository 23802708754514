import {UnitsSystem} from './types';

export const UnitsSystems: UnitsSystem[] = [
  {
    unitsSystem: 'METRIC',
    name: 'Metric',
    distanceAbbreviation: 'km',
    volumeAbbreviation: 'l',
    weightAbbreviation: 'kg',
    dieselConsumption: 'per l per 100 km',
    adBlueConsumption: 'parts per 100 parts Diesel',
    lngConsumption: 'per kg per 100 km'
  },
  {
    unitsSystem: 'UK',
    name: 'UK',
    distanceAbbreviation: 'mi',
    volumeAbbreviation: 'l',
    weightAbbreviation: 'kg',
    dieselConsumption: 'mi per imp gal',
    adBlueConsumption: 'parts per 100 parts Diesel',
    lngConsumption: 'per kg per 100 km'
  },
  {
    unitsSystem: 'US',
    name: 'USA',
    distanceAbbreviation: 'mi',
    volumeAbbreviation: 'US gal',
    weightAbbreviation: 'kg',
    dieselConsumption: 'mi per US gal',
    adBlueConsumption: 'parts per 100 parts Diesel',
    lngConsumption: 'per kg per 100 km'
  },
  {
    unitsSystem: 'CANADA',
    name: 'Canada',
    distanceAbbreviation: 'mi',
    volumeAbbreviation: 'imp gal',
    weightAbbreviation: 'kg',
    dieselConsumption: 'mi per imp gal',
    adBlueConsumption: 'parts per 100 parts Diesel',
    lngConsumption: 'per kg per 100 km'
  }
];

export const getUnitsSystemOrDefault = (
  unitsSystemValue: string | null | undefined
): UnitsSystem =>
  UnitsSystems.find(
    unitsSystem => unitsSystem.unitsSystem === unitsSystemValue
  ) ?? UnitsSystems[0];
