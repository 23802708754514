import {gql} from '@apollo/client';

const UPDATE_VEHICLE_TYPE = gql`
  mutation updateBusType(
    $id: ID!
    $name: String!
    $fuelType: FuelType!
    $dieselConsumption: FuelConsumption
    $adblueConsumption: Float
    $lngConsumption: Float
    $doubleDecker: Boolean
    $numberOfTires: Int!
  ) {
    updateBusType(
      input: {
        id: $id
        name: $name
        fuelType: $fuelType
        dieselConsumption: $dieselConsumption
        adblueConsumption: $adblueConsumption
        lngConsumption: $lngConsumption
        doubleDecker: $doubleDecker
        numberOfTires: $numberOfTires
      }
    ) {
      id
      name
      fuelType
      dieselConsumption
      adblueConsumption
      lngConsumption
      doubleDecker
      numberOfTires
    }
  }
`;

export default UPDATE_VEHICLE_TYPE;
