import React, {useCallback, useMemo} from 'react';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {ProductionCostBusScheduleProvidedValueEntry} from 'Pages/ProductionCost/store/types';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import {VehicleUtilizationFactorProps} from './VehicleUtilizationFactor.types';
import styles from './VehicleUtilizationFactor.module.scss';

const VehicleUtilizationFactor: React.FC<VehicleUtilizationFactorProps> = ({
  value,
  entryId,
  errors
}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-bus-utilization-factor`;

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const updates = {
        busUtilizationFactor: validateInputNumber(
          editedValue,
          value ? value.toString() : ''
        )
      } as Partial<ProductionCostBusScheduleProvidedValueEntry>;

      // If the busUtilizationFactor is empty, we should remove the flixStandardCostOverwrite
      if (!updates.busUtilizationFactor) {
        updates.flixStandardCostOverwrite = undefined;
      }

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="bus-utilization-factor"
        label={`Vehicle utilisation factor`}
        value={formattedValue}
        tooltipContent={fieldInfoContent.busUtilizationFactor}
        valid={!errors?.invalidRange}
        infoError={errors?.invalidRange}
        handleInputChange={handleInputChange}
        inlineLabelLeft="%"
      />
    </span>
  );
};

export default React.memo(VehicleUtilizationFactor);
