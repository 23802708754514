import {
  formatCostPerDistanceFieldName as format,
  getKmsOrMiles
} from '../../Common/LabelUtils';
import {ProductionCostEntryStatus} from 'Pages/Home/store/types';

export default {
  fields: {
    countries: 'countries',
    partners: 'partners',
    lineIds: 'lineIds',
    planningPeriods: 'planningPeriods',
    costCalculationName: 'costCalculationName',
    calculationStatus: 'calculationStatus'
  },
  keys: {
    countries: 'countryIds',
    partners: 'busPartnerIds',
    lineIds: 'lineNumbers',
    planningPeriods: 'date',
    costCalculationName: 'name'
  },
  statuses: {
    inProduction: ProductionCostEntryStatus.InProduction,
    draft: ProductionCostEntryStatus.Draft,
    planned: ProductionCostEntryStatus.Planned
  },
  filterInputFields: [
    [
      {
        key: 'busPartnerIds',
        title: 'Partner(s)',
        field: 'partners'
      },
      {
        key: 'lineNumbers',
        title: 'Line ID(s)',
        field: 'lineIds'
      },
      {
        key: 'date',
        title: 'Planning period(s)',
        field: 'planningPeriods'
      },
      {
        key: 'status',
        title: 'Calculation status',
        field: 'calculationStatus'
      }
    ],
    [
      {
        key: 'countryIds',
        title: 'Countries',
        field: 'countries'
      },
      {
        key: 'name',
        title: 'Cost calculation name',
        field: 'costCalculationName'
      }
    ]
  ],
  calculationStatusOptions: [
    {
      id: 'inProduction',
      value: ProductionCostEntryStatus.InProduction,
      name: 'calculationStatus',
      label: 'In production'
    },
    {
      id: 'draft',
      value: ProductionCostEntryStatus.Draft,
      name: 'calculationStatus',
      label: 'Draft'
    },
    {
      id: 'planned',
      value: ProductionCostEntryStatus.Planned,
      name: 'calculationStatus',
      label: 'Planned'
    }
  ],
  requestSize: 20,
  headers: localUnits => {
    return {
      standardView: [
        {key: 'name', title: 'Cost calculation name'},
        {key: 'countries', title: 'Countries'},
        {key: 'partners', title: 'Partner(s)'},
        {key: 'status', title: 'Calculation status'},
        {key: 'planningPeriod', title: 'Planning period'},
        {key: 'lineNumbers', title: 'Line ID(s)'},
        {key: 'vehicleCount', title: 'No. of vehicles'},
        {key: 'operatingDays', title: 'No. operating days'},
        {key: 'totalKm', title: `Total ${getKmsOrMiles(localUnits)}`},
        {key: 'totalHr', title: 'Total schedule hours'},
        {key: 'totalWorkingHr', title: 'Total working hours'},
        {
          key: 'productionCostPerKm',
          title: format(localUnits, 'Total production costs')
        },
        {key: 'currency', title: 'Currency'},
        {key: 'version', title: 'Version'},
        {key: 'createdAt', title: 'Created'},
        {key: 'lastEditedAt', title: 'Last edited'},
        {key: 'editButton', title: ''},
        {key: 'copyButton', title: ''},
        {key: 'deleteButton', title: ''}
      ],
      analyticsView: [
        {key: 'viewButton', title: 'Input Cost Factors'},
        {key: 'lineNumbers', title: 'Line ID(s)'},
        {key: 'partnerName', title: 'Bus Partner'},
        {key: 'planningPeriod', title: 'Planning period'},
        {key: 'productionCostEntryName', title: 'Cost calculation name'},
        {key: 'productionCostEntryStatus', title: 'Calculation status'},
        {key: 'totalKms', title: `Total ${getKmsOrMiles(localUnits)}`},
        {key: 'currency', title: 'Currency'},
        {key: 'totalProductionCosts', title: 'Total production costs'},
        {
          key: 'totalProductionCostsPerKm',
          title: format(localUnits, 'Total production costs')
        },
        {
          key: 'leasingCostsPerKm',
          title: format(localUnits, 'Vehicle costs')
        },
        {
          key: 'interestCostsPerKm',
          title: format(localUnits, 'Interest')
        },
        {
          key: 'maintenanceCostsPerKm',
          title: format(localUnits, 'Service & Maintenance')
        },
        {
          key: 'insuranceCostsPerKm',
          title: format(localUnits, 'Insurance')
        },
        {
          key: 'motorVehicleTaxCostsPerKm',
          title: format(localUnits, 'Motor vehicle tax')
        },
        {
          key: 'driverSalaryCostsPerKm',
          title: format(localUnits, 'Drivers costs')
        },
        {
          key: 'accommodationCostsPerKm',
          title: format(localUnits, 'Accomodation')
        },
        {
          key: 'dailyAllowanceCostsPerKm',
          title: format(localUnits, 'Meals/daily allowance')
        },
        {
          key: 'driverChangeCostsPerKm',
          title: format(localUnits, 'Driver change transp.')
        },
        {
          key: 'dieselCostsPerKm',
          title: format(localUnits, 'Diesel costs')
        },
        {
          key: 'adblueCostsPerKm',
          title: format(localUnits, 'AdBlue costs')
        },
        {
          key: 'lngCostsPerKm',
          title: format(localUnits, 'LNG costs')
        },
        {
          key: 'totalFuelCostsPerKm',
          title: format(localUnits, 'Total fuel costs')
        },
        {
          key: 'tiresCostsPerKm',
          title: format(localUnits, 'Tires costs')
        },
        {
          key: 'cleaningCostsPerKm',
          title: format(localUnits, 'Cleaning')
        },
        {
          key: 'overheadCostsPerKm',
          title: format(localUnits, 'Overhead')
        },
        {
          key: 'flixStandardCostsPerKm',
          title: format(localUnits, 'Flix standards')
        },
        {
          key: 'legalCostsPerKm',
          title: format(localUnits, 'Legal / Litigation')
        },
        {
          key: 'damageRepairCostsPerKm',
          title: format(localUnits, 'Damage repair')
        },
        {
          key: 'otherCostsPerKm',
          title: format(localUnits, 'Other')
        },
        {
          key: 'infrastructureCostsPerKm',
          title: format(localUnits, 'Infrastructure')
        }
      ],
      analyticsAbsoluteView: [
        {key: 'viewButton', title: 'Input Cost Factors'},
        {key: 'lineNumbers', title: 'Line ID(s)'},
        {key: 'partnerName', title: 'Bus Partner'},
        {key: 'planningPeriod', title: 'Planning period'},
        {key: 'productionCostEntryName', title: 'Cost calculation name'},
        {key: 'productionCostEntryStatus', title: 'Calculation status'},
        {key: 'totalKms', title: `Total ${getKmsOrMiles(localUnits)}`},
        {key: 'currency', title: 'Currency'},
        {key: 'totalProductionCosts', title: 'Total production costs'},
        {key: 'leasingCosts', title: 'Vehicle costs'},
        {key: 'interestCosts', title: 'Interest'},
        {key: 'maintenanceCosts', title: 'Service & Maintenance'},
        {key: 'insuranceCosts', title: 'Insurance'},
        {key: 'motorVehicleTaxCosts', title: 'Motor vehicle tax'},
        {key: 'driverSalaryCosts', title: 'Drivers costs'},
        {key: 'accommodationCosts', title: 'Accomodation'},
        {key: 'dailyAllowanceCosts', title: 'Meals/daily allowance'},
        {key: 'driverChangeCosts', title: 'Driver change transp.'},
        {key: 'dieselCosts', title: 'Diesel costs'},
        {key: 'adblueCosts', title: 'AdBlue costs'},
        {key: 'lngCosts', title: 'LNG costs'},
        {key: 'totalFuelCosts', title: 'Total fuel costs'},
        {key: 'tiresCosts', title: 'Tires costs'},
        {key: 'cleaningCosts', title: 'Cleaning'},
        {key: 'overheadCosts', title: 'Overhead'},
        {key: 'flixStandardCosts', title: 'Flix standards'},
        {key: 'legalCosts', title: 'Legal / Litigation'},
        {key: 'damageRepairCosts', title: 'Damage repair'},
        {key: 'otherCosts', title: 'Other'},
        {key: 'infrastructureCosts', title: 'Infrastructure'}
      ]
    };
  },

  analyticsModeOptions: localUnits => [
    {
      id: 'overview',
      value: 'OVERVIEW',
      name: 'analyticsModeType',
      label: 'Overview',
      defaultChecked: true
    },
    {
      id: 'analytics',
      value: 'ANALYTICS',
      name: 'analyticsModeType',
      label: `Analytics / ${localUnits.distanceAbbreviation}`
    },
    {
      id: 'analytics-absolute',
      value: 'ANALYTICS-ABSOLUTE',
      name: 'analyticsModeType',
      label: 'Analytics / absolute'
    }
  ],
  // param present only in result from productionCostAnalyticsView query
  uniqueAnalyticsEntriesIdentifier: 'dieselCostsPerKm',
  // param present only in result from productionCostEntries query
  uniqueStandardEntriesIdentifier: 'countryIds',
  tableHeight: 750
};
