import styles from './PartnerCostsExplanationNotice.module.scss';
import React from 'react';
import {CpcIconAttention} from 'Pages/Common/CpcIcons';

const PartnerCostsExplanationNotice: React.FC = () => {
  return (
    <span className={styles.container}>
      <CpcIconAttention />{' '}
      <span className={styles.label}>
        Cost factors specified on partner level override regional values.
        <br />
        Regional values are shown in brackets (if available).
        <br />
        Fields marked with an * are mandatory and values must be
        <br />
        specified at either regional or partner level.
      </span>
    </span>
  );
};

export default React.memo(PartnerCostsExplanationNotice);
