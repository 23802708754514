import React, {useMemo} from 'react';
import {Infobox, Text} from '@flixbus/honeycomb-react';
import {LineErrorsProps} from './LineErrors.types';
import style from './LineErrors.module.scss';

const LineErrors: React.FC<LineErrorsProps> = ({errors}) => {
  const errorsList = useMemo(
    () =>
      errors.map(error => {
        const key = `${error.providedValuesEntryId}_${error.errorCode}`;
        const message = `${error.errorMessage} for schedule: ${error.schedule.name}, bus: ${error.bus.name}`;
        return (
          <Text key={key} extraClasses="no-margin">
            {message}
          </Text>
        );
      }),
    [errors]
  );

  return (
    <div className={style.container}>
      <Infobox aria-label="Some critical errors occurred" appearance="danger">
        <Text Elem="div" extraClasses="danger-color">
          <h3>Errors found</h3>
        </Text>
        {errorsList}
      </Infobox>
    </div>
  );
};

export default React.memo(LineErrors);
