import React, {useEffect} from 'react';
import {isNil} from 'ramda';
import {Infobox} from '@flixbus/honeycomb-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {useCountryCurrency} from 'Pages/RegionalCosts/hooks/useCountryCurrency';
import {getVehicleTypes} from 'Pages/VehicleTypes/store/thunks';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {fetchCountrySpecificCost} from 'Pages/RegionalCosts/store/thunks';
import RegionalCostsVehicleCostContent from './RegionalCostsVehicleCostContent/RegionalCostsVehicleCostContent';

const RegionalCostsVehicleCostTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const year = useAppSelector(({regionalCosts}) => regionalCosts.year);
  const countryId = useAppSelector(({regionalCosts}) => regionalCosts.country);
  const country =
    useAppSelector(({countries}) =>
      countries.list?.find(({id}) => id === countryId)
    ) ?? null;
  const currency = useCountryCurrency(countryId);
  const vehicleCost = useAppSelector(
    ({regionalCosts}) => regionalCosts.vehicleCost
  );
  const vehicleTypes = useAppSelector(({vehicleTypes}) => vehicleTypes.list);
  const vehicleTypeCosts = useAppSelector(
    ({regionalCosts}) => regionalCosts.vehicleTypeCosts?.list
  );

  useEffect(() => {
    if (isNil(vehicleCost)) {
      dispatch(fetchCountrySpecificCost(countryId, year));
    }
  }, [vehicleCost, countryId, year, dispatch]);

  useEffect(() => {
    if (isNil(vehicleTypes)) {
      dispatch(getVehicleTypes());
    }
  }, [vehicleTypes, countryId, year, dispatch]);

  return countryId === null ||
    country === null ||
    year === null ||
    vehicleCost === null ||
    vehicleTypeCosts === null ||
    vehicleTypes === null ? (
    <Infobox appearance="warning">
      <p>
        Select <strong>country</strong> and <strong>calendar year</strong>
      </p>
    </Infobox>
  ) : (
    <RegionalCostsVehicleCostContent
      country={country}
      year={year}
      vehicleCost={vehicleCost}
      vehicleTypes={vehicleTypes}
      vehicleTypeCosts={vehicleTypeCosts}
      currency={currency}
    />
  );
};

export default React.memo(RegionalCostsVehicleCostTab);
