import React from 'react';
import IconButton from '../../Common/IconButton';
import {IconEye} from '@flixbus/honeycomb-icons-react';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import {
  getPlanningPeriod,
  roundCostValues,
  getAnalyticsRowId,
  calculateSubtotalRowId
} from './index';

export const getAnalyticsAbsoluteRows = (
  analyticsEntriesAreReady,
  entries,
  calculatedSubtotal,
  partners,
  setEntryToView
) => {
  if (!analyticsEntriesAreReady) return [];

  const rows = entries.map(entry => {
    const viewButton = (
      <IconButton
        title={'View Input Cost Factors'}
        aria-label="view-entry"
        onClick={() => setEntryToView(entry)}
        InlineIcon={IconEye}
      />
    );
    const roundedEntry = roundCostValues(entry);
    const partner = partners.list.find(
      partner => partner.id === roundedEntry.busPartnerId
    );
    const partnerName = partner && partner.name;

    const rowId = getAnalyticsRowId(roundedEntry);

    return {
      rowId,
      rowIdStringified: JSON.stringify(rowId),
      viewButton,
      lineNumbers: roundedEntry.lineNumber,
      partnerName,
      planningPeriod: getPlanningPeriod(
        roundedEntry.startDate,
        roundedEntry.endDate
      ),
      productionCostEntryName: roundedEntry.productionCostEntryName,
      productionCostEntryStatus: roundedEntry.productionCostEntryStatus,
      totalKms: roundedEntry.totalKms,
      currency: roundedEntry.currency,
      totalProductionCosts: roundedEntry.totalProductionCosts,
      leasingCosts: roundedEntry.leasingCosts,
      interestCosts: roundedEntry.interestCosts,
      maintenanceCosts: roundedEntry.maintenanceCosts,
      insuranceCosts: roundedEntry.insuranceCosts,
      motorVehicleTaxCosts: roundedEntry.motorVehicleTaxCosts,
      driverSalaryCosts: roundedEntry.driverSalaryCosts,
      accommodationCosts: roundedEntry.accommodationCosts,
      dailyAllowanceCosts: roundedEntry.dailyAllowanceCosts,
      driverChangeCosts: roundedEntry.driverChangeCosts,
      dieselCosts: roundedEntry.dieselCosts,
      adblueCosts: roundedEntry.adblueCosts,
      lngCosts: roundedEntry.lngCosts,
      totalFuelCosts: roundedEntry.totalFuelCosts,
      tiresCosts: roundedEntry.tiresCosts,
      cleaningCosts: roundedEntry.cleaningCosts,
      overheadCosts: roundedEntry.overheadCosts,
      flixStandardCosts: roundedEntry.flixStandardCosts,
      legalCosts: roundedEntry.legalCosts,
      damageRepairCosts: roundedEntry.damageRepairCosts,
      otherCosts: roundedEntry.otherCosts,
      infrastructureCosts: roundedEntry.infrastructureCosts
    };
  });

  if (isNotNilOrEmpty(calculatedSubtotal)) {
    const roundedCalculatedSubtotalRows = getCalculatedAbsoluteSubtotalRows(
      calculatedSubtotal.absoluteValuesSubtotals
    );
    rows.unshift(...roundedCalculatedSubtotalRows);
  }

  return rows;
};

const getCalculatedAbsoluteSubtotalRows = calculatedSubtotal => {
  const roundedCalculatedSubtotals = calculatedSubtotal.map(subtotals =>
    roundCostValues(subtotals)
  );

  return roundedCalculatedSubtotals.map((roundedSubtotals, index) => ({
    rowId: `${calculateSubtotalRowId}-${index}`,
    rowIdStringified: `${calculateSubtotalRowId}-${index}`,
    viewButton: '',
    lineNumbers: index === 0 ? 'CALCULATED SUBTOTAL' : '',
    partnerName: '',
    planningPeriod: '',
    productionCostEntryName: '',
    productionCostEntryStatus: '',
    totalKms: roundedSubtotals.totalKms,
    currency: roundedSubtotals.currency,
    totalProductionCosts: roundedSubtotals.totalProductionCosts,
    leasingCosts: roundedSubtotals.leasingCosts,
    interestCosts: roundedSubtotals.interestCosts,
    maintenanceCosts: roundedSubtotals.maintenanceCosts,
    insuranceCosts: roundedSubtotals.insuranceCosts,
    motorVehicleTaxCosts: roundedSubtotals.motorVehicleTaxCosts,
    driverSalaryCosts: roundedSubtotals.driverSalaryCosts,
    accommodationCosts: roundedSubtotals.accommodationCosts,
    dailyAllowanceCosts: roundedSubtotals.dailyAllowanceCosts,
    driverChangeCosts: roundedSubtotals.driverChangeCosts,
    dieselCosts: roundedSubtotals.dieselCosts,
    adblueCosts: roundedSubtotals.adblueCosts,
    lngCosts: roundedSubtotals.lngCosts,
    totalFuelCosts: roundedSubtotals.totalFuelCosts,
    tiresCosts: roundedSubtotals.tiresCosts,
    cleaningCosts: roundedSubtotals.cleaningCosts,
    overheadCosts: roundedSubtotals.overheadCosts,
    flixStandardCosts: roundedSubtotals.flixStandardCosts,
    legalCosts: roundedSubtotals.legalCosts,
    damageRepairCosts: roundedSubtotals.damageRepairCosts,
    otherCosts: roundedSubtotals.otherCosts,
    infrastructureCosts: roundedSubtotals.infrastructureCosts
  }));
};
