import React from 'react';
import {Icon, IconAttention} from '@flixbus/honeycomb-icons-react';

export const CpcIconAttention = extraProps => (
  <Icon
    style={{fill: 'orange'}}
    InlineIcon={IconAttention}
    size={4}
    {...extraProps}
  />
);
