import React from 'react';
import Pagination from './Pagination/Pagination';
import {useSelector} from 'react-redux';
import {Spinner} from '@flixbus/honeycomb-react';

const Footer = () => {
  const totalRecords = useSelector(
    ({calculatedEntries}) => calculatedEntries?.paginationOutput?.totalRecords
  );

  return (
    <div className="home-footer">
      {totalRecords === null ? (
        <div>
          <Spinner />
          <div>Loading......</div>
        </div>
      ) : totalRecords === 0 ? (
        <h2 style={{marginTop: '5%'}}>No records found</h2>
      ) : (
        <Pagination />
      )}
    </div>
  );
};

export default React.memo(Footer);
