import React, {useMemo} from 'react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {ProductionCostScheduleWithProvidedValues} from 'Pages/ProductionCost/store/types';
import LineErrors from './LineErrors/LineErrors';
import LineSettings from './LineSettings/LineSettings';
import style from './Lines.module.scss';

const Lines: React.FC = () => {
  const busValues =
    useAppSelector(({productionCost}) => productionCost.busValues) ?? [];
  const busScheduleValues = useAppSelector(
    ({productionCost}) => productionCost.busScheduleValues
  );
  const driverCostCalculationType = useAppSelector(
    ({productionCost}) => productionCost.driverCostCalculationType
  );
  const validationErrors = useAppSelector(
    ({productionCost}) => productionCost.validationErrors
  );
  const calculateProductionCostErrors = useAppSelector(
    ({productionCost}) => productionCost.serverErrors.calculateProductionCost
  );

  const lines: {
    [lineNumber: string]: ProductionCostScheduleWithProvidedValues[];
  } = useMemo(
    () =>
      (busScheduleValues ?? []).reduce((lineNumberToSchedules, schedule) => {
        if (!lineNumberToSchedules[schedule.lineNumber]) {
          lineNumberToSchedules[schedule.lineNumber] = [];
        }
        lineNumberToSchedules[schedule.lineNumber].push(schedule);
        return lineNumberToSchedules;
      }, {}),
    [busScheduleValues]
  );

  const lineNumberToServerErrors = useMemo(() => {
    if (!calculateProductionCostErrors?.invalidProvidedValues) {
      return null;
    }

    const {invalidProvidedValues} = calculateProductionCostErrors;
    const lineNumberToErrors = {};

    for (const err of invalidProvidedValues) {
      const {line} = err.schedule;

      if (!lineNumberToErrors[line]) lineNumberToErrors[line] = [];
      lineNumberToErrors[line].push(err);
    }

    return lineNumberToErrors;
  }, [calculateProductionCostErrors]);

  return (
    <div className={style.container}>
      {Object.entries(lines).map(([lineNumber, schedules]) => (
        <div key={lineNumber} data-testid={`${lineNumber}-container`}>
          {lineNumberToServerErrors?.[lineNumber] && (
            <LineErrors errors={lineNumberToServerErrors[lineNumber]} />
          )}

          <LineSettings
            lineNumber={lineNumber}
            schedules={schedules}
            busValues={busValues}
            driverCostCalculationType={driverCostCalculationType}
            errors={validationErrors}
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(Lines);
