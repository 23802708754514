import React from 'react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {Infobox} from '@flixbus/honeycomb-react';
import {useBusPartner} from 'Pages/PartnerCosts/hooks/useBusPartner';
import PartnerCostsDriverCostContent from './PartnerCostsDriverCostContent/PartnerCostsDriverCostContent';

const PartnerCostsDriverCostTab: React.FC = () => {
  const partnerId = useAppSelector(({partnerCosts}) => partnerCosts.partnerId);
  const year = useAppSelector(({partnerCosts}) => partnerCosts.year);

  const partnerDriverCost = useAppSelector(
    ({partnerCosts}) => partnerCosts.driverCost
  );
  const regionalDriverCost = useAppSelector(
    ({partnerCosts}) => partnerCosts?.countryCost?.driverCost
  );
  const partner = useBusPartner(partnerId);

  return partnerId === null || year === null || partnerDriverCost === null ? (
    <Infobox appearance="warning">
      <p>
        Select a <strong>partner</strong> and a <strong>calendar year</strong>
      </p>
    </Infobox>
  ) : (
    <PartnerCostsDriverCostContent
      partnerId={partnerId}
      year={year}
      partnerDriverCost={partnerDriverCost}
      regionalDriverCost={regionalDriverCost}
      currency={partner?.currency}
    />
  );
};

export default React.memo(PartnerCostsDriverCostTab);
