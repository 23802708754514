import {createSlice, createSelector} from '@reduxjs/toolkit';
import {calculatedEntriesInitialState, resetEntriesData} from './data';
import {getFilters} from './utils';
import {getQueryParamsSelector} from '../utils';

const calculatedEntriesSlice = createSlice({
  name: 'calculated-entries',
  initialState: calculatedEntriesInitialState,
  reducers: {
    receivedProductionCostEntries: (state, {payload}) => {
      const {
        page,
        paginationOutput,
        entries,
        subtotals,
        absoluteValuesSubtotals,
        absoluteValuesEntries
      } = payload;

      return {
        ...state,
        subtotals,
        absoluteValuesSubtotals,
        page: page,
        paginationOutput,
        entries,
        isReloadData: false,
        absoluteValuesEntries: absoluteValuesEntries
          ? absoluteValuesEntries
          : []
      };
    },
    productionCostEntryWasRemoved: state => {
      return {
        ...state,
        page:
          state.entries.length === 1 && state.page > 0 // delete when only one entry exists on page > 0
            ? state.page - 1
            : state.page,
        isReloadData: true
      };
    },
    productionCostEntryWasCloned: state => {
      return {
        ...state,
        isReloadData: true,
        page: 0
      };
    },
    productionCostEntryWasUpdated: state => {
      return {
        ...state,
        ...resetEntriesData,
        isReloadData: true,
        page: 0
      };
    },
    updateFilter: (state, {payload}) => {
      const filters = getFilters(
        state.filters[payload.field],
        payload.value,
        payload.field
      );

      return {
        ...state,
        ...resetEntriesData,
        filters: {
          ...state.filters,
          [payload.field]: filters
        }
      };
    },
    deleteFilter: (state, {payload}) => {
      const newFilters = state.filters[payload.field].filter(
        filter => filter.title !== payload.value.title
      );

      return {
        ...state,
        ...resetEntriesData,
        filters: {
          ...state.filters,
          [payload.field]: newFilters
        }
      };
    },
    resetFilters: (state, {payload: field}) => {
      return {
        ...state,
        ...resetEntriesData,
        filters: {
          ...state.filters,
          [field]: []
        }
      };
    },
    updateOptions: (state, {payload}) => {
      return {
        ...state,
        options: {
          [payload.field]: payload.options
        }
      };
    },
    toggleAnalyticsMode: (state, {payload: analyticsModeType}) => {
      if (analyticsModeType === state.analyticsModeType) return state;
      return {
        ...state,
        analyticsMode: analyticsModeType !== 'OVERVIEW',
        analyticsModeType: analyticsModeType
      };
    },
    resetDataTableEntries: state => {
      return {
        ...state,
        ...resetEntriesData
      };
    }
  }
});

export const selectHomePageQueryParams = createSelector(
  [({calculatedEntries}) => calculatedEntries.filters],
  filters => getQueryParamsSelector(filters)
);

export const {
  receivedProductionCostEntries,
  productionCostEntryWasRemoved,
  productionCostEntryWasCloned,
  productionCostEntryWasUpdated,
  updateFilter,
  deleteFilter,
  resetFilters,
  updateOptions,
  toggleAnalyticsMode,
  resetDataTableEntries
} = calculatedEntriesSlice.actions;

export default calculatedEntriesSlice.reducer;
