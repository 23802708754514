import {configureStore} from '@reduxjs/toolkit';
import countriesReducer from '../Pages/Countries/store/slice';
import vehicleTypesReducer from '../Pages/VehicleTypes/store/slice';
import partnersReducer from '../Pages/Partners/store/slice';
import regionalCostsReducer from '../Pages/RegionalCosts/store/slice';
import partnerCostsReducer from '../Pages/PartnerCosts/store/slice';
import productionCostReducer from '../Pages/ProductionCost/store/slice';
import entriesReducer from '../Pages/Home/store/slice';

export const store = configureStore({
  reducer: {
    productionCost: productionCostReducer,
    calculatedEntries: entriesReducer,
    countries: countriesReducer,
    vehicleTypes: vehicleTypesReducer,
    partners: partnersReducer,
    regionalCosts: regionalCostsReducer,
    partnerCosts: partnerCostsReducer
  },
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
