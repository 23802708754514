import React, {useCallback, useMemo, useState} from 'react';
import {SelectGroup, Tooltip} from '@flixbus/honeycomb-react';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {DriverCostCalculationType} from 'Pages/ProductionCost/store/types';
import {productionCostBaseUpdate} from 'Pages/ProductionCost/store/slice';
import {DriverCostCalculationTypeSelectorOptions} from './DriverCostCalculationType.constants';

const DriverCostCalculationTypeSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const driverCostCalculationType = useAppSelector(
    ({productionCost}) => productionCost.driverCostCalculationType
  );
  const driverShiftsCalculationTypeAllowed = useAppSelector(
    ({productionCost}) => productionCost.driverShiftsCalculationTypeAllowed
  );

  const [activeTooltip, setActiveTooltip] = useState(false);

  const onClickOption = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        productionCostBaseUpdate({
          driverCostCalculationType: event.target
            .value as DriverCostCalculationType
        })
      );
    },
    [dispatch]
  );

  const optionsWithDefaultSelection = useMemo(
    () =>
      DriverCostCalculationTypeSelectorOptions.map(option => ({
        ...option,
        defaultChecked: option.value === driverCostCalculationType,
        disabled:
          option.value === DriverCostCalculationType.DriverShifts &&
          !driverShiftsCalculationTypeAllowed,
        onClick: onClickOption
      })),
    [
      driverCostCalculationType,
      driverShiftsCalculationTypeAllowed,
      onClickOption
    ]
  );

  return (
    <Tooltip
      id={`driver-cost-calculation-type-tooltip`}
      content="
        Here you can select the approach how driver costs are calculated.
        Depending on the chosen approach different input fields will be shown to you.
        Please refer to the CPC documentation for further information.
      "
      position="top"
      active={activeTooltip}
      onMouseOut={() => setActiveTooltip(false)}
    >
      <SelectGroup
        label={
          <LabelWithTooltip
            label="Driver cost calculation logic*"
            setActiveTooltip={setActiveTooltip}
          />
        }
        options={optionsWithDefaultSelection}
        id="driver-cost-calculation-type-select-group"
        info={
          driverShiftsCalculationTypeAllowed
            ? null
            : `'Planr Driver Shifts' is not available due to incomplete driver shifts in Planr.`
        }
      />
    </Tooltip>
  );
};

export default React.memo(DriverCostCalculationTypeSelector);
