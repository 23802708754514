import {PartnersState} from './types';

export const initPartnerObject = {
  id: null,
  name: '',
  backendId: null,
  countryId: '',
  depots: []
};
export const initPartnerDepotObject = {
  city: '',
  postCode: '',
  street: ''
};

export const initFilters = {
  countries: []
};

export const partnersInitialState: PartnersState = {
  filters: initFilters,
  list: null,
  partner: null,
  options: {}
};
