import {isNil} from 'ramda';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {initVehicleTypeObject, initialVehicleTypesState} from './data';

const vehicleTypesSlice = createSlice({
  name: 'vehicle-types',
  initialState: initialVehicleTypesState,
  reducers: {
    receivedVehicleTypes: (state, {payload}: PayloadAction<any>) => {
      return {...state, list: payload};
    },
    setVehicleType: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        vehicleType: isNil(payload) ? initVehicleTypeObject : payload
      };
    },
    resetVehicleType: state => {
      return {...state, vehicleType: null};
    },
    addToVehicleTypes: (state, {payload}: PayloadAction<any>) => {
      return {...state, list: [...(state.list ?? []), payload]};
    },
    updateVehicleTypes: (state, {payload}: PayloadAction<any>) => {
      const list = [...(state.list ?? [])].map(vehicleType => {
        return payload.id === vehicleType.id ? payload : vehicleType;
      });

      return {...state, list};
    },
    deleteFromVehicleTypes: (state, {payload}: PayloadAction<any>) => {
      const list =
        state.list?.filter(vehicleType => payload.id !== vehicleType.id) ?? [];

      return {...state, list};
    }
  }
});

export const {
  receivedVehicleTypes,
  setVehicleType,
  resetVehicleType,
  addToVehicleTypes,
  updateVehicleTypes,
  deleteFromVehicleTypes
} = vehicleTypesSlice.actions;

export default vehicleTypesSlice.reducer;
