import {isNil} from 'ramda';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {countriesInitialState, initCountryObject} from './data';
import {Country} from './types';

const countriesSlice = createSlice({
  name: 'countries',
  initialState: countriesInitialState,
  reducers: {
    receivedCountries: (state, {payload}: PayloadAction<Country[]>) => {
      return {...state, list: payload};
    },
    receivedCountryCurrencies: (state, {payload}: PayloadAction<string[]>) => {
      return {...state, currencies: payload};
    },
    setCountry: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        country: isNil(payload) ? initCountryObject : payload
      };
    },
    resetCountry: state => {
      return {...state, country: null};
    },
    receivedError: (state, {payload}: PayloadAction<any>) => {
      return {
        ...state,
        country: {...state.country, errorMessage: payload} as any
      };
    },
    addToCountries: state => {
      return {...state, list: null};
    },
    updateCountries: state => {
      return {...state, list: null};
    },
    deleteFromCountries: (state, {payload}: PayloadAction<{id: string}>) => {
      const list = [...(state.list ?? [])].filter(
        country => payload.id !== country.id
      );

      return {...state, list};
    }
  }
});

export const {
  receivedCountries,
  receivedCountryCurrencies,
  setCountry,
  resetCountry,
  receivedError,
  addToCountries,
  updateCountries,
  deleteFromCountries
} = countriesSlice.actions;

export default countriesSlice.reducer;
