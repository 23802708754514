import React, {useState, useEffect} from 'react';
import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOptions
} from '@flixbus/honeycomb-react';

import {isNil} from 'ramda';
import {useSelector} from 'react-redux';
import {sortOptions} from '../../utils/sortOptions';

const LineAutocompleteField = ({lineScheduleKey, line, onSelected, valid}) => {
  const [name, setName] = useState(isNil(line) ? '' : line);
  const lineSchedules = useSelector(
    state => state?.productionCost?.lineSchedules
  );
  const lines = useSelector(state => state?.productionCost?.lines);

  useEffect(() => {
    setName(isNil(line) ? '' : line);
  }, [line]);

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const lineOptions = lines
    .filter(
      line =>
        !lineSchedules.some(
          lineSchedule =>
            line.uuid === lineSchedule.lineUuid &&
            lineSchedule.lineNumber !== name
        )
    )
    .map(line => ({
      title: line.number,
      value: line.uuid,
      key: line.uuid
    }));

  const filterAutocompleteData = (searchQuery, data) =>
    new Promise(resolve => {
      setTimeout(() => {
        const res = data.filter(item =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        resolve(res);
      }, 200);
    });

  return (
    <Autocomplete
      key={`line-autocomplete-key-${lineScheduleKey}`}
      options={sortOptions(data) ?? []}
      value={name}
      onDebounce={e => {
        setLoading(true);
        filterAutocompleteData(e.target.value, lineOptions).then(options => {
          setData(options);
          setLoading(false);
        });
      }}
      onChange={() => {
        setLoading(true);
        // onSelected(e.target.value);
      }}
      onSelect={item => {
        setData(null);
        setName(item.title);
        onSelected(item);
      }}
    >
      <AutocompleteInput
        id={`line-autocomplete-${lineScheduleKey}`}
        placeholder=""
        label="Line number*"
        infoError={`Cannot be empty`}
        loading={loading}
        valid={valid}
      />
      <AutocompleteOptions label="Line number" optionsToDisplay={5} />
    </Autocomplete>
  );
};

export default React.memo(LineAutocompleteField);
