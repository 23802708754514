import {gql} from '@apollo/client';

const QUALITY_CHECK_COUNTRY_COST_ENTRY_YEAR = gql`
  mutation qualityCheckCountryCostEntryYear(
    $countryId: ID!
    $year: Int!
    $qualityChecked: Boolean!
    $withValidation: Boolean
  ) {
    qualityCheckCountryCostEntryYear(
      countryId: $countryId
      year: $year
      qualityChecked: $qualityChecked
      withValidation: $withValidation
    ) {
      year
      qualityChecked
      qualityCheckedLastEditedAt
    }
  }
`;

export default QUALITY_CHECK_COUNTRY_COST_ENTRY_YEAR;
