const WEEK_DAY = 'WEEK_DAY';
const WEEK_NIGHT = 'WEEK_NIGHT';
const WEEKEND_DAY = 'WEEKEND_DAY';
const WEEKEND_NIGHT = 'WEEKEND_NIGHT';

export const driverSalaryTypeOptions = [
  WEEK_DAY,
  WEEK_NIGHT,
  WEEKEND_DAY,
  WEEKEND_NIGHT
];

export const driverSalaryTypeTitles = {
  WEEK_DAY: 'Salary week day',
  WEEK_NIGHT: 'Salary week night',
  WEEKEND_DAY: 'Salary weekend day',
  WEEKEND_NIGHT: 'Salary weekend night'
};
