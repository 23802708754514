import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import {getKmsOrMiles} from 'Pages/Common/LabelUtils';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {EmptyDistancePerOperatingDayProps} from './EmptyDistancePerOperatingDay.types';
import styles from './EmptyDistancePerOperatingDay.module.scss';

const EmptyDistancePerOperatingDay: React.FC<
  EmptyDistancePerOperatingDayProps
> = ({value, entryId}) => {
  const dispatch = useAppDispatch();

  const {localUnits} = useLocalUnitsContext();
  const id = `entry-${entryId}-vehicle-empty-km`;

  const tooltipContent = useMemo(
    () => fieldInfoContent.emptyKmsOperatingDay(localUnits),
    [localUnits]
  );

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const emptyKmPerDay =
        validateInputNumber(editedValue, value ? value.toString() : '') ??
        undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {emptyKmPerDay}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="vehicle-empty-km"
        label={`Empty ${getKmsOrMiles(localUnits)}/operating day`}
        value={formattedValue}
        tooltipContent={tooltipContent}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(EmptyDistancePerOperatingDay);
