import {gql} from '@apollo/client';

const DELETE_PARTNER = gql`
  mutation deleteBusPartner($id: ID!) {
    deleteBusPartner(id: $id) {
      id
      name
    }
  }
`;

export default DELETE_PARTNER;
