import {ValidationError} from 'yup';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {updateBusScheduleValuesValidationErrors} from 'Pages/ProductionCost/store/slice';
import {validateBusScheduleValues} from 'Pages/ProductionCost/utils/validateVehicles';
import {
  DriverCostCalculationType,
  ProductionCostBusScheduleProvidedValueEntry,
  ProductionCostBusScheduleProvidedValueEntryErrors,
  ProductionCostBusScheduleValuesErrors,
  ProductionCostScheduleWithProvidedValues
} from 'Pages/ProductionCost/store/types';

const useValidateBusScheduleValues = () => {
  const dispatch = useDispatch();

  const getValidationErrors = useCallback(
    (
      vehicleEntry: ProductionCostBusScheduleProvidedValueEntry,
      driverCostCalculationType: DriverCostCalculationType
    ) => {
      const validationErrors: ProductionCostBusScheduleProvidedValueEntryErrors =
        {};

      try {
        validateBusScheduleValues(vehicleEntry, driverCostCalculationType);
        return null; // No validation errors
      } catch (error) {
        if (error instanceof ValidationError) {
          error.inner.forEach(({path, type, message}) => {
            const key = path || 'default';

            if (!validationErrors[key]) {
              validationErrors[key] = {};
            }
            validationErrors[key][type] = message;
          });
        }
      }

      return validationErrors;
    },
    []
  );

  const validateForms = useCallback(
    (
      schedules: ProductionCostScheduleWithProvidedValues[],
      driverCostCalculationType: DriverCostCalculationType
    ) => {
      const validationErrors: ProductionCostBusScheduleValuesErrors = {};

      for (const schedule of schedules) {
        for (const vehicleEntry of schedule.busScheduleProvidedValuesEntries) {
          validationErrors[vehicleEntry.id] =
            getValidationErrors(vehicleEntry, driverCostCalculationType) ??
            undefined;
        }
      }

      dispatch(updateBusScheduleValuesValidationErrors({validationErrors}));
    },
    [dispatch, getValidationErrors]
  );

  return validateForms;
};

export default useValidateBusScheduleValues;
