import styles from './TableComparisonCell.module.scss';
import React from 'react';
import {Tooltip} from '@flixbus/honeycomb-react';
import {TableComparisonCellProps} from './TableComparisonCell.types';

const TableComparisonCell: React.FC<TableComparisonCellProps> = ({
  originalValue,
  referenceValue,
  header,
  tooltipContent
}) => {
  return (
    <>
      {originalValue && (
        <span data-testid="original-value">{originalValue.toString()}</span>
      )}
      {referenceValue && (
        <>
          {' '}
          <Tooltip
            id={`table-comparison-cell-${header}-tooltip`}
            content={tooltipContent}
            size={'content-fit'}
            openOnHover
          >
            <span
              data-testid="reference-value"
              className={styles.referenceValue}
            >
              ({referenceValue})
            </span>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default React.memo(TableComparisonCell);
