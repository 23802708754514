import {useAccount} from '@azure/msal-react';
import {HeaderUserWidget} from '@flixbus/honeycomb-react';
import React from 'react';

export const LoginWidget = () => {
  const account = useAccount();
  return (
    <div className="flex-center" aria-label="Login widget">
      {account ? (
        <HeaderUserWidget extraClasses="header-user-widget">
          {account?.name || ''}
        </HeaderUserWidget>
      ) : (
        <HeaderUserWidget />
      )}
    </div>
  );
};
