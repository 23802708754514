import React from 'react';
import DownloadAnalyticsViewButton from './DownloadAnalytics/DownloadAnalyticsViewButton';
import {Fieldset, Grid, GridCol, SelectGroup} from '@flixbus/honeycomb-react';
import {useDispatch, useSelector} from 'react-redux';
import {toggleAnalyticsMode} from '../store/slice';
import constants from '../constants';
import CalculateOnSelectionButton from './CalculateOnSelectionButton';
import {RecalculateCostsButton} from './RecalculateCosts';
import {useLocalUnitsContext} from '../../Common/LocalUnits';
import {
  POWER_BI_REPORT_HOME_SHOW_BREAKDOWN_VISUALISATION_URL,
  POWER_BI_REPORT_HOME_SHOW_VISUALISATION_URL
} from 'constants/PowerBiReportUrls';
import PowerBiReportLink from 'components/PowerBiReportLink/PowerBiReportLink';
import {AnalyticsModeType} from 'Pages/Home/store/types';

const DataTableActionButtons = ({selectedRows, setCalculatedSubtotal}) => {
  const dispatch = useDispatch();
  const {localUnits} = useLocalUnitsContext();
  const analyticsMode = useSelector(
    state => state?.calculatedEntries.analyticsMode
  );
  const analyticsModeType = useSelector(
    state => state?.calculatedEntries.analyticsModeType
  );
  const filters = useSelector(state => state?.calculatedEntries.filters);

  const getOptions = () => {
    return constants.analyticsModeOptions(localUnits).map(option => {
      return {
        ...option,
        onClick: e => dispatch(toggleAnalyticsMode(e.target.value))
      };
    });
  };

  const powerBiLinks = {
    [AnalyticsModeType.Overview]: (
      <PowerBiReportLink
        href={POWER_BI_REPORT_HOME_SHOW_VISUALISATION_URL}
        label="Show visualisation"
      />
    ),
    [AnalyticsModeType.Analytics]: (
      <PowerBiReportLink
        href={POWER_BI_REPORT_HOME_SHOW_BREAKDOWN_VISUALISATION_URL}
        label="View results in PowerBI"
      />
    )
  };

  return (
    <Grid extraClasses={'data-table-action-buttons'}>
      <GridCol size={8}>
        <Fieldset horizontal>
          <div key="analytics-switch">
            <SelectGroup
              aria-label="Analytics switch select group"
              options={getOptions()}
              id="select-group-analytics-switch"
            />
          </div>
          {analyticsMode ? (
            <>
              <CalculateOnSelectionButton
                selectedRows={selectedRows}
                setCalculatedSubtotal={setCalculatedSubtotal}
              />
              <DownloadAnalyticsViewButton
                name={'Download analytics view'}
                searchFilters={filters}
                endPointName={'analyticsViewEntries'}
                fileName={'analyticsViewExport'}
                selectedRows={selectedRows}
              />
              <DownloadAnalyticsViewButton
                name={'Download monthly analytics view'}
                searchFilters={filters}
                endPointName={'monthlyAnalyticsViewEntries'}
                fileName={'monthlyAnalyticsViewExport'}
                selectedRows={selectedRows}
              />
            </>
          ) : (
            <RecalculateCostsButton selectedRows={selectedRows} />
          )}
        </Fieldset>
      </GridCol>
      <GridCol size={4} extraClasses={'power-bi-links'}>
        {powerBiLinks[analyticsModeType]}
      </GridCol>
    </Grid>
  );
};

export default React.memo(DataTableActionButtons);
