export interface CalculatedEntriesState {
  filters: any;
  entries: ProductionCostEntry[];
  options: any;

  page: number;
  paginationOutput: {
    totalPages: number | null;
    totalRecords: number | null;
  };
  recordsPerPage: number;
  isReloadData: boolean;

  analyticsMode: boolean;
  analyticsModeType: AnalyticsModeType;
  subtotals: any;
  absoluteValuesSubtotals: any;
  absoluteValuesEntries: any[];
}

export interface ProductionCostEntry {
  busPartnerIds?: string[];
  calculationVersion: string;
  countryIds?: string[];
  createdAt: string;
  endDate: string[];
  id: string;
  lastEditedAt: string;
  lineCount?: number;
  lineNumbers?: string[];
  name: string;
  operatingDays?: number;
  planrDataVersion: string; // "1" - rides-based calculation, otherwise - trips-based calculation
  productionCostPerKm?: number;
  publishedAt?: string;
  publishingStatus: ProductionCostPublishingStatus;
  startDate: string;
  status: ProductionCostEntryStatus;
  totalHr?: number;
  totalKm?: number;
  totalWorkingHr?: number;
  usedCurrencies: string[];
  vehicleCount?: number;
}

export enum ProductionCostPublishingStatus {
  NotPublished = 'NOT_PUBLISHED',
  Published = 'PUBLISHED',
  PublishedOutdated = 'PUBLISHED_OUTDATED'
}

export enum ProductionCostEntryStatus {
  Draft = 'DRAFT',
  InProduction = 'IN_PRODUCTION',
  Planned = 'PLANNED'
}

export enum AnalyticsModeType {
  Overview = 'OVERVIEW',
  Analytics = 'ANALYTICS',
  AnalyticsAbsolute = 'ANALYTICS-ABSOLUTE'
}
