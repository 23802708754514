import styles from './PartnerCostsVehicleCostContent.module.scss';
import React, {useCallback, useState} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {
  Icon,
  IconDelete,
  IconDeleteSolid,
  IconEditSolid,
  IconOfferSolid,
  IconPlus
} from '@flixbus/honeycomb-icons-react';
import {Button, Fieldset} from '@flixbus/honeycomb-react';
import {confirm} from 'Pages/Common/Confirm';
import {useToast} from 'Pages/Common/toasts-container';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import {
  deleteVehicleTypeCost,
  updateVehicleCost
} from 'Pages/PartnerCosts/store/thunks';
import {getScaledNumber} from 'Pages/Common/Utils';
import {Nullable} from 'Pages/Common/types';
import {Table, TableAction, TableRow} from 'components/Table';
import {TableWithPanel, TableWithPanelAction} from 'components/TableWithPanel';
import {
  PartnerCostsBusCost,
  PartnerCostsBusTypeCost
} from 'Pages/PartnerCosts/store/types';
import {
  RegionalCostsBusTypeCost,
  RegionalCostsVehicleCost
} from 'Pages/RegionalCosts/store/types';
import {initVehicleTypeCost} from 'components/VehicleCosts/VehicleCosts.data';
import TableComparisonCell from 'components/TableWithPanel/TableComparisonCell/TableComparisonCell';
import PartnerCostsExplanationNotice from 'components/PartnerCostsExplanationNotice/PartnerCostsExplanationNotice';
import {VehicleCostsPanelState} from 'components/VehicleCosts/VehicleCostsPanel/VehicleCostsPanel.types';
import {getBaseCostsPerVehicleTypeTableConfig} from 'components/VehicleCosts/VehicleCosts.utils';
import PartnerCostsVehicleCostPanel from 'Pages/PartnerCosts/components/PartnerCostsVehicleCostTab/PartnerCostsVehicleCostPanel/PartnerCostsVehicleCostPanel';
import {PartnerCostsVehicleCostsContentProps} from './PartnerCostsVehicleCostContent.types';
import {
  getCostByKmHeaders,
  getCostPerVehicleTypeHeaders
} from './PartnerCostsVehicleCostContent.data';

const PartnerCostsVehicleCostsContent: React.FC<
  PartnerCostsVehicleCostsContentProps
> = ({
  partnerId,
  year,
  partnerVehicleCost,
  regionalVehicleCost,
  vehicleTypes,
  partnerVehicleTypeCosts,
  regionalVehicleTypeCosts,
  currency,
  partnerCountry
}) => {
  const dispatch = useAppDispatch();
  const {localUnits} = useLocalUnitsContext();
  const {addToast} = useToast();

  const [panelState, setPanelState] = useState<VehicleCostsPanelState>({
    data: null,
    loading: false,
    error: null
  });

  const onUpdateCostsByKm = useCallback(
    (row: TableRow) => {
      const updatedCost = {...partnerVehicleCost, ...row};
      dispatch(updateVehicleCost(updatedCost));
    },
    [partnerVehicleCost, dispatch]
  );

  const getBaseCostsTableConfig = useCallback(
    (
      partnerCost: PartnerCostsBusCost,
      regionalCost: RegionalCostsVehicleCost | undefined
    ) => {
      const headers = getCostByKmHeaders(currency, localUnits);
      const row = headers.map(({key, fractionDigitsCount}) => {
        const displayValue = (
          <TableComparisonCell
            originalValue={getScaledNumber(
              partnerCost[key],
              fractionDigitsCount
            )}
            referenceValue={
              regionalCost &&
              getScaledNumber(regionalCost[key], fractionDigitsCount)
            }
            header={key}
            tooltipContent={`Regional value`}
          />
        );
        return {
          key,
          value: partnerCost[key],
          displayValue
        };
      });
      const actions: TableWithPanelAction[] = [
        {
          type: 'panel',
          icon: IconEditSolid,
          panel: {
            title: 'Update country specific cost',
            onSuccess: onUpdateCostsByKm
          }
        }
      ];
      return {headers, rows: [row], actions};
    },
    [currency, localUnits, onUpdateCostsByKm]
  );

  const getCostsPerVehicleTypeTableConfig = useCallback(
    (
      vehicleTypeCosts:
        | PartnerCostsBusTypeCost[]
        | RegionalCostsBusTypeCost[]
        | undefined,
      actions: TableAction[]
    ) => {
      const {headers, rows} = getBaseCostsPerVehicleTypeTableConfig(
        vehicleTypes,
        vehicleTypeCosts,
        partnerCountry,
        getCostPerVehicleTypeHeaders(currency, localUnits)
      );

      return {headers, rows, actions};
    },
    [partnerCountry, vehicleTypes, currency, localUnits]
  );

  const baseCosts = getBaseCostsTableConfig(
    partnerVehicleCost,
    regionalVehicleCost
  );

  const regionalCostsPerVehicleTypeActions: TableAction[] = [
    {
      icon: IconPlus,
      onClick: row => {
        setPanelState({
          data: {...row, id: null} as Nullable<PartnerCostsBusTypeCost>,
          loading: false,
          error: null
        });
      }
    }
  ];

  const regionalCostsPerVehicleType = getCostsPerVehicleTypeTableConfig(
    regionalVehicleTypeCosts,
    regionalCostsPerVehicleTypeActions
  );

  const partnerCostsPerVehicleTypeActions: TableAction[] = [
    {
      icon: IconEditSolid,
      onClick: row => {
        setPanelState({
          data: row as Nullable<PartnerCostsBusTypeCost>,
          loading: false,
          error: null
        });
      }
    },
    {
      icon: IconDeleteSolid,
      appearance: 'danger',
      onClick: row => {
        confirm({
          PopupIcon: IconDelete,
          title: 'Delete this vehicle type cost?',
          message: ``
        }).then(confirmed => {
          if (confirmed) {
            dispatch(deleteVehicleTypeCost(row.id, addToast));
          }
        });
      }
    }
  ];

  const partnerCostsPerVehicleType = getCostsPerVehicleTypeTableConfig(
    partnerVehicleTypeCosts,
    partnerCostsPerVehicleTypeActions
  );

  return (
    <Fieldset>
      <div className={styles.headerInfo}>
        <PartnerCostsExplanationNotice />
      </div>

      <div>
        <section data-testid="base-costs">
          <TableWithPanel
            headers={baseCosts.headers}
            rows={baseCosts.rows}
            actions={baseCosts.actions}
          />
        </section>

        <section data-testid="regional-costs-per-vehicle-type">
          <div className={styles.title}>
            <h3 className={styles.leftSide}>
              Costs per vehicle type (country-level defaults, read-only)
            </h3>
          </div>

          <Table
            headers={regionalCostsPerVehicleType.headers}
            rows={regionalCostsPerVehicleType.rows}
            actions={regionalCostsPerVehicleType.actions}
            caption={
              regionalCostsPerVehicleType.rows.length === 0
                ? 'No entries specified on country level.'
                : undefined
            }
          />
        </section>

        <section data-testid="partner-costs-per-vehicle-type">
          <div className={styles.title}>
            <h3 className={styles.leftSide}>
              Costs per vehicle type (partner-specific entries)
            </h3>
            <Button
              onClick={() => {
                setPanelState({
                  data: initVehicleTypeCost,
                  loading: false,
                  error: null
                });
              }}
            >
              <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
              Add costs per vehicle type
            </Button>
          </div>

          <Table
            headers={partnerCostsPerVehicleType.headers}
            rows={partnerCostsPerVehicleType.rows}
            actions={partnerCostsPerVehicleType.actions}
            caption={
              partnerCostsPerVehicleType.rows.length === 0
                ? 'No entries specified on partner level.'
                : undefined
            }
          />

          {/* Is used for both create and update logic */}
          <PartnerCostsVehicleCostPanel
            partnerId={partnerId}
            year={year}
            currency={currency}
            vehicleTypes={vehicleTypes}
            localUnits={localUnits}
            panelState={panelState}
            setPanelState={setPanelState}
            partnerCountry={partnerCountry}
          />
        </section>
      </div>
    </Fieldset>
  );
};

export default React.memo(PartnerCostsVehicleCostsContent);
