import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {Notification} from '@flixbus/honeycomb-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {removeEditScreenError} from 'Pages/ProductionCost/store/slice';

const ProductionCostErrorNotification: React.FC = () => {
  const loading = useAppSelector(({productionCost}) => productionCost.loading);
  const calculateProductionCostErrors = useAppSelector(
    ({productionCost}) => productionCost.serverErrors.calculateProductionCost
  );
  const dispatch = useAppDispatch();

  const errorsList = useMemo(() => {
    if (!calculateProductionCostErrors) return [];
    return calculateProductionCostErrors.other;
  }, [calculateProductionCostErrors]);

  // Scroll to the top only when loading switches from true to false and errors exist
  const previousLoading = useRef<boolean>(loading);
  useEffect(() => {
    if (previousLoading.current && !loading && errorsList.length > 0) {
      window.scrollTo(0, 0);
    }

    // Update the ref value after comparison
    previousLoading.current = loading;
  }, [loading, errorsList]);

  const removeError = useCallback(
    (errorId: string) => dispatch(removeEditScreenError(errorId)),
    [dispatch]
  );

  if (!errorsList.length) return null;

  return (
    <>
      {errorsList.map(error => (
        <Notification
          key={`calculation-error-${error.id}`}
          appearance="danger"
          closeProps={{
            'aria-label': 'Close',
            onClick: () => removeError(error.id)
          }}
        >
          {error.errorMessage}
        </Notification>
      ))}
    </>
  );
};

export default React.memo(ProductionCostErrorNotification);
