import React, {useState} from 'react';
import {Input, Fieldset} from '@flixbus/honeycomb-react';
import {
  IconCheckmark,
  IconClose,
  Icon,
  IconEditSolid
} from '@flixbus/honeycomb-icons-react';
import {isBlank} from 'ramda-adjunct';
import {useToast} from '../../Common/toasts-container';
import {useDispatch} from 'react-redux';
import {updateProductionCostEntry} from '../store/thunks';

const InlineEdit = ({id, value}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(value);
  const [isEdit, setIsEdit] = useState(false);
  const {addToast} = useToast();

  const onSave = () => {
    if (!isBlank(inputValue)) {
      const newName = inputValue.trim();
      if (newName !== value) {
        setIsEdit(false);
        dispatch(updateProductionCostEntry(id, newName, addToast));
      } else {
        setIsEdit(false);
      }
      setInputValue(newName);
    }
  };
  const onClose = () => {
    setIsEdit(false);
    setInputValue(value);
  };
  return (
    <div className={'inline-edit'}>
      {isEdit ? (
        <div className={'inline-edit-save'}>
          <Fieldset horizontal>
            <Input
              name="name-inline-edit-input"
              id="id-inline-edit-input"
              value={inputValue}
              aria-label={'Inline edit'}
              onInput={event => setInputValue(event.target.value)}
              valid={!isBlank(inputValue)}
              type={'text'}
              autoFocus
            />
            <div>
              <Icon
                aria-label={'Inline edit save'}
                title={'save'}
                size={4}
                onClick={onSave}
                InlineIcon={IconCheckmark}
              />
              <Icon
                aria-label={'Inline edit close'}
                title={'close'}
                size={4}
                InlineIcon={IconClose}
                onClick={onClose}
              />
            </div>
          </Fieldset>
        </div>
      ) : (
        <div className={'inline-edit-button'}>
          <div>{value}</div>
          <div>
            <Icon
              aria-label={'Inline edit button'}
              title={'Edit calculation name'}
              size={3}
              InlineIcon={IconEditSolid}
              onClick={() => setIsEdit(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(InlineEdit);
