export const POWER_BI_REPORT_REGIONAL_YOY_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=79952b11-2b45-4c5b-98bc-73d8778d1fdd&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=ReportSection2de4d5432ad046c5196c';

export const POWER_BI_REPORT_BUS_PARTNER_YOY_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=79952b11-2b45-4c5b-98bc-73d8778d1fdd&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=ReportSection2de4d5432ad046c5196c';

export const POWER_BI_REPORT_BUS_PARTNER_COMPARE_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=79952b11-2b45-4c5b-98bc-73d8778d1fdd&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=ReportSection3c4c464027d02356e710';

export const POWER_BI_REPORT_HOME_SHOW_VISUALISATION_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=28396355-ef06-4501-b791-e4dfa2e0a15a&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=ReportSection';

export const POWER_BI_REPORT_HOME_SHOW_BREAKDOWN_VISUALISATION_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=07807caf-5479-48ee-8778-5a03f0e74c70&reportObjectId=28396355-ef06-4501-b791-e4dfa2e0a15a&ctid=d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5&reportPage=ReportSection4cec968db7ee8a1a187b';
