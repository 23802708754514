import styles from './PartnerCostsDriverCostContent.module.scss';
import React, {useCallback, useMemo} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {IconEditSolid} from '@flixbus/honeycomb-icons-react';
import {PartnerCostsDriverCost} from 'Pages/PartnerCosts/store/types';
import {updateDriverCost} from 'Pages/PartnerCosts/store/thunks';
import {getScaledNumber} from 'Pages/Common/Utils';
import {RegionalCostsDriverCost} from 'Pages/RegionalCosts/store/types';
import {DriverCosts, DriverCostsType} from 'components/DriverCosts';
import {TableRow} from 'components/Table';
import {
  baseSalaryHeaders,
  driverChangeCostHeaders,
  accommodationCostHeaders
} from './PartnerCostsDriverCostContent.data';
import {
  TableWithPanelAction,
  TableWithPanelHeader,
  TableWithPanelRow
} from 'components/TableWithPanel';
import PartnerCostsExplanationNotice from 'components/PartnerCostsExplanationNotice/PartnerCostsExplanationNotice';
import {PartnerCostsDriverCostContentProps} from './PartnerCostsDriverCostContent.types';
import {useLocalUnitsContext} from '../../../../Common/LocalUnits';
import TableComparisonCell from '../../../../../components/TableWithPanel/TableComparisonCell/TableComparisonCell';

const PartnerCostsDriverCostContent: React.FC<
  PartnerCostsDriverCostContentProps
> = ({partnerId, year, partnerDriverCost, regionalDriverCost, currency}) => {
  const dispatch = useAppDispatch();
  const {localUnits} = useLocalUnitsContext();

  const onUpdateDriverCosts = useCallback(
    (row: TableRow) => {
      const updatedDriverCost = {...partnerDriverCost, ...row};
      dispatch(updateDriverCost(updatedDriverCost, partnerId, year));
    },
    [partnerDriverCost, partnerId, year, dispatch]
  );

  const getTableConfig = useCallback(
    (
      type: DriverCostsType,
      headers: TableWithPanelHeader[],
      partnerDriverCost: PartnerCostsDriverCost,
      regionalDriverCost: RegionalCostsDriverCost | undefined
    ) => {
      const row: TableWithPanelRow = partnerDriverCost
        ? headers.map(({key}) => {
            const displayValue = (
              <TableComparisonCell
                originalValue={getScaledNumber(partnerDriverCost[key])}
                referenceValue={
                  regionalDriverCost && getScaledNumber(regionalDriverCost[key])
                }
                header={key}
                tooltipContent={`Regional value`}
              />
            );
            return {
              key,
              value: partnerDriverCost[key],
              displayValue
            };
          })
        : [];

      const actions: TableWithPanelAction[] = [
        {
          type: 'panel',
          icon: IconEditSolid,
          panel: {
            title: `Update ${type.toLowerCase()} cost`,
            onSuccess: onUpdateDriverCosts
          }
        }
      ];

      return {headers, rows: [row], actions};
    },
    [onUpdateDriverCosts]
  );

  const baseSalaryCosts = useMemo(
    () =>
      getTableConfig(
        DriverCostsType.BaseSalary,
        baseSalaryHeaders(currency),
        partnerDriverCost,
        regionalDriverCost
      ),
    [getTableConfig, partnerDriverCost, regionalDriverCost, currency]
  );

  const driverChangeCosts = useMemo(
    () =>
      getTableConfig(
        DriverCostsType.DriverChange,
        driverChangeCostHeaders(currency, localUnits),
        partnerDriverCost,
        regionalDriverCost
      ),
    [
      getTableConfig,
      partnerDriverCost,
      regionalDriverCost,
      currency,
      localUnits
    ]
  );

  const accommodationCosts = useMemo(
    () =>
      getTableConfig(
        DriverCostsType.Accommodation,
        accommodationCostHeaders(currency),
        partnerDriverCost,
        regionalDriverCost
      ),
    [getTableConfig, partnerDriverCost, regionalDriverCost, currency]
  );

  return (
    <>
      <div className={styles.alignRight}>
        <PartnerCostsExplanationNotice />
      </div>
      <DriverCosts
        baseSalaryCosts={baseSalaryCosts}
        driverChangeCosts={driverChangeCosts}
        accommodationCosts={accommodationCosts}
        currency={currency}
      />
    </>
  );
};

export default React.memo(PartnerCostsDriverCostContent);
