import constants from '../constants';

export const getFilters = (oldState, newFilter, field) => {
  const filterExists =
    oldState.length > 0 &&
    oldState.some(filter => newFilter.title === filter.title);

  if (field === constants.fields.calculationStatus) {
    if (filterExists)
      return oldState.filter(filter => filter.title !== newFilter.title);
    else return [...oldState, newFilter];
  } else {
    if (
      field === constants.fields.costCalculationName ||
      field === constants.fields.planningPeriods
    ) {
      return [newFilter];
    } else if (!filterExists) {
      return [...oldState, newFilter];
    } else {
      return oldState;
    }
  }
};
