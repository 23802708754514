import {gql} from '@apollo/client';

const CLONE_PRODUCTION_COST_ENTRY = gql`
  mutation cloneProductionCostEntry(
    $productionCostEntryId: ID!
    $clonedProductionCostEntryName: String!
  ) {
    cloneProductionCostEntry(
      productionCostEntryId: $productionCostEntryId
      clonedProductionCostEntryName: $clonedProductionCostEntryName
    ) {
      id
      name
      status
      publishingStatus
      startDate
      endDate
      lineNumbers
      countryIds
      busPartnerIds
      lineCount
      vehicleCount
      operatingDays
      totalKm
      totalHr
      totalWorkingHr
      productionCostPerKm
      calculationVersion
      createdAt
      lastEditedAt
      publishedAt
      usedCurrencies
      planrDataVersion
    }
  }
`;

export default CLONE_PRODUCTION_COST_ENTRY;
