import React, {useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  IconArrowLeft,
  IconClose,
  IconSaveSolid
} from '@flixbus/honeycomb-icons-react';
import {resetDataTableEntries} from 'Pages/Home/store/slice';
import ProductionCostHeader from '../ProductionCostHeader';
import {ProductionCostHeaderStepOption} from '../ProductionCostHeader/ProductionCostHeaderSteps/ProductionCostHeaderSteps.types';
import ProductionCostFooter from '../ProductionCostFooter';
import {
  backToSecondDataImportScreen,
  resetProductionCostState
} from 'Pages/ProductionCost/store/slice';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {releaseProductionCost} from 'Pages/ProductionCost/store/thunks';
import CalculatedProductionCosts from './CalculatedProductionCosts';
import styles from './index.module.scss';

const ProductionCostStepThree = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const calculationUserJourneyMode = useSelector(
    ({productionCost}) => productionCost.calculationUserJourneyMode
  );

  const releaseAndResetDataTable = useCallback(() => {
    dispatch(resetProductionCostState());
    dispatch(resetDataTableEntries());
    navigate('/');
  }, [dispatch, navigate]);

  const backButton = useMemo(
    () => ({
      label: 'Back',
      leftSideIcon: IconArrowLeft,
      onClick: () => dispatch(backToSecondDataImportScreen())
    }),
    [dispatch]
  );

  const closeButton = useMemo(
    () => ({
      label: 'Close',
      leftSideIcon: IconClose,
      onClick: releaseAndResetDataTable,
      appearance: 'primary',
      dataActionName: 'click-close'
    }),
    [releaseAndResetDataTable]
  );

  const saveButton = useMemo(
    () => ({
      label: 'Save calculation',
      leftSideIcon: IconSaveSolid,
      onClick: () => dispatch(releaseProductionCost(releaseAndResetDataTable)),
      appearance: 'primary',
      dataActionName: 'click-save-calculation'
    }),
    [dispatch, releaseAndResetDataTable]
  );

  return (
    <>
      <div className={styles.header}>
        <ProductionCostHeader
          mode={calculationUserJourneyMode}
          step={ProductionCostHeaderStepOption.Summary}
        />
      </div>

      <CalculatedProductionCosts />

      <ProductionCostFooter
        leftSideButtons={[backButton]}
        rightSideButtons={
          calculationUserJourneyMode ===
          CalculationUserJourneyMode.EditCalculation
            ? [closeButton]
            : [saveButton]
        }
      />
    </>
  );
};

export default React.memo(ProductionCostStepThree);
