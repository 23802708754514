import React, {useState, useCallback, useMemo} from 'react';
import {Input, Calendar, Grid, GridCol} from '@flixbus/honeycomb-react';
import {
  Icon,
  IconCalendarSolid,
  IconClose
} from '@flixbus/honeycomb-icons-react';
import constants from 'Pages/Home/constants';
import {formatDate} from 'Pages/Home/utils';
import {useDocumentEvent} from 'Pages/Common/hooks/useDocumentEvent';
import {getPlanningPeriodDate} from 'Pages/ProductionCost/utils/formatePlanningPeriodDate';
import {PlanningPeriodProps} from './PlanningPeriod.types';
import styles from './PlanningPeriod.module.scss';

const initialStartDate = new Date(2019, 0, 1);
const endYear = new Date().getFullYear() + 10;
const endDate = new Date(endYear, 11, 31);

const PlanningPeriod: React.FC<PlanningPeriodProps> = ({
  planningPeriods,
  clearSelection,
  handleSelect
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = React.useState(initialStartDate);

  const handleEvent = e => {
    if (e.type === 'click' || e.key === 'Escape') setShowCalendar(false);
  };

  useDocumentEvent([
    {type: 'click', callback: handleEvent},
    {type: 'keydown', callback: handleEvent}
  ]);

  const handleDateSelect = useCallback(
    (date: Date) => {
      const period = planningPeriods[0];

      const isStartDateSelection = !period?.startDate;
      const isEndDateSelection = !period?.endDate;

      // date range is already selected and user wants to change the start date
      const isConsecutiveSelection = period?.endDate;

      if (isStartDateSelection) {
        handleSelect(constants.fields.planningPeriods, {
          startDate: date,
          title: formatDate(date),
          key: formatDate(date)
        });
        setStartDate(date);
      } else if (isEndDateSelection) {
        handleSelect(constants.fields.planningPeriods, {
          startDate: planningPeriods[0].startDate,
          endDate: date,
          title:
            formatDate(planningPeriods[0].startDate) + ' - ' + formatDate(date),
          key:
            formatDate(planningPeriods[0].startDate) + ' - ' + formatDate(date)
        });
        setStartDate(initialStartDate);
        setShowCalendar(false);
      } else if (isConsecutiveSelection) {
        handleSelect(constants.fields.planningPeriods, {
          startDate: date
        });
        setStartDate(date);
      }
    },
    [planningPeriods, handleSelect]
  );

  const planningPeriodValue = useMemo(() => {
    if (!planningPeriods[0]) return getPlanningPeriodDate(null, null);

    const {startDate, endDate} = planningPeriods[0];
    return getPlanningPeriodDate(
      startDate ? startDate.toString() : null,
      endDate ? endDate.toString() : null
    );
  }, [planningPeriods]);

  return (
    <div style={{position: 'relative'}} onClick={e => e.stopPropagation()}>
      <Grid>
        <GridCol>
          <Input
            iconLeft={
              <Icon
                InlineIcon={IconCalendarSolid}
                aria-label={'Calendar icon'}
              />
            }
            label="Planning period"
            id="planning-period-input"
            onFocus={() => setShowCalendar(true)}
            value={planningPeriodValue}
            readOnly
          />
          {planningPeriods[0]?.endDate && (
            <Icon
              InlineIcon={IconClose}
              className={styles.clearSelectionIcon}
              onClick={() => clearSelection(constants.fields.planningPeriods)}
              aria-label={'Clear selection'}
            />
          )}
        </GridCol>
      </Grid>

      <Calendar
        appearance={'birthday'}
        hidden={!showCalendar}
        extraClasses={'planning-period-calendar'}
        id="home-page-planning-period-calender"
        startDate={startDate}
        endDate={endDate}
        defaultMonth={planningPeriods[0]?.startDate ?? new Date()}
        startSelected={planningPeriods[0]?.startDate ?? undefined}
        endSelected={planningPeriods[0]?.endDate ?? undefined}
        handleSelect={handleDateSelect}
      />
    </div>
  );
};

export default React.memo(PlanningPeriod);
