import {useMemo} from 'react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {PlanrBusPartner} from 'Pages/ProductionCost/store/types';

export interface BusPartnerWithVehicles extends PlanrBusPartner {
  vehicles: Array<{
    id: string;
    name: string;
  }>;
}

const useBusPartnersWithVehicles = () => {
  const planrVehicles = useAppSelector(
    ({productionCost}) => productionCost.planrVehicles
  );

  const planrBusPartners = useAppSelector(
    ({productionCost}) => productionCost.planrBusPartners
  );

  const busPartnersWithVehicles = useMemo(() => {
    const partnerMap = new Map<string, BusPartnerWithVehicles>();

    for (const planrBusPartner of planrBusPartners) {
      partnerMap.set(planrBusPartner.busPartnerBackendId, {
        ...planrBusPartner,
        vehicles: []
      });
    }

    for (const planrVehicle of planrVehicles) {
      const {busPartnerBackendId} = planrVehicle.planrBusPartner;

      const partner = partnerMap.get(busPartnerBackendId);
      partner?.vehicles.push({
        id: planrVehicle.id,
        name: planrVehicle.name
      });
    }

    return Array.from(partnerMap.values());
  }, [planrBusPartners, planrVehicles]);

  return busPartnersWithVehicles;
};

export default useBusPartnersWithVehicles;
