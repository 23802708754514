import styles from './PartnerCostsOtherCostContent.module.scss';
import React, {useCallback, useMemo} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {IconEditSolid} from '@flixbus/honeycomb-icons-react';
import {RegionalCostsOtherCost} from 'Pages/RegionalCosts/store/types';
import {PartnerCostsOtherCost} from 'Pages/PartnerCosts/store/types';
import {updateOtherCost} from 'Pages/PartnerCosts/store/thunks';
import {getScaledNumber} from 'Pages/Common/Utils';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import {OtherCosts} from 'components/OtherCosts';
import {TableHeader, TableRow} from 'components/Table';
import {
  TableWithPanelAction,
  TableWithPanelRow
} from 'components/TableWithPanel';
import TableComparisonCell from 'components/TableWithPanel/TableComparisonCell/TableComparisonCell';
import PartnerCostsExplanationNotice from 'components/PartnerCostsExplanationNotice/PartnerCostsExplanationNotice';
import PartnerCostsRepairCostTableCell from 'Pages/PartnerCosts/components/PartnerCostsOtherCostTab/PartnerCostsRepairCostTableCell/PartnerCostsRepairCostTableCell';
import {PartnerCostsOtherCostContentProps} from './PartnerCostsOtherCostContent.types';
import {
  operationalCostHeaders,
  overheadCostHeaders
} from './PartnerCostsOtherCostContent.data';

const PartnerCostsOtherCostContent: React.FC<
  PartnerCostsOtherCostContentProps
> = ({
  partnerId,
  year,
  partnerOtherCost,
  regionalOtherCost,
  partnerCountry
}) => {
  const dispatch = useAppDispatch();
  const {localUnits} = useLocalUnitsContext();

  const updateOtherCosts = useCallback(
    (updatedCosts: RegionalCostsOtherCost) => {
      const updatedOtherCost = {...partnerOtherCost, ...updatedCosts};
      dispatch(updateOtherCost(updatedOtherCost, partnerId, year));
    },
    [partnerOtherCost, partnerId, year, dispatch]
  );

  const basicCellRenderer = useCallback(
    (key: keyof PartnerCostsOtherCost) => (
      <TableComparisonCell
        originalValue={getScaledNumber(String(partnerOtherCost[key]))}
        referenceValue={
          regionalOtherCost && getScaledNumber(String(regionalOtherCost[key]))
        }
        header={key}
        tooltipContent={`Regional value`}
      />
    ),
    [partnerOtherCost, regionalOtherCost]
  );

  const repairCostCellRenderer = useCallback(
    (partnerRepairCostKey: keyof PartnerCostsOtherCost) => (
      <PartnerCostsRepairCostTableCell
        partnerOtherCost={partnerOtherCost}
        partnerRepairCostKey={partnerRepairCostKey}
        regionalOtherCost={regionalOtherCost}
        country={partnerCountry}
      />
    ),
    [partnerOtherCost, regionalOtherCost, partnerCountry]
  );

  const overheadCosts = useMemo(() => {
    const headers: TableHeader[] = overheadCostHeaders(
      partnerOtherCost,
      partnerCountry,
      localUnits
    );

    const row = {
      overheadCost: basicCellRenderer('overheadCost'),
      legalCost: basicCellRenderer('legalCost'),
      repairCostPerDistanceUnit: repairCostCellRenderer(
        'repairCostPerDistanceUnit'
      ),
      repairCostPerMonth: repairCostCellRenderer('repairCostPerMonth'),
      otherCost: basicCellRenderer('otherCost')
    } as TableRow;

    return {headers, rows: [row], data: [partnerOtherCost]};
  }, [
    partnerOtherCost,
    localUnits,
    partnerCountry,
    basicCellRenderer,
    repairCostCellRenderer
  ]);

  const operationalCosts = useMemo(() => {
    const headers = operationalCostHeaders;
    const row: TableWithPanelRow = partnerOtherCost
      ? headers.map(({key}) => {
          return {
            key,
            value: partnerOtherCost[key],
            displayValue: basicCellRenderer(key as keyof PartnerCostsOtherCost)
          };
        })
      : [];

    const actions: TableWithPanelAction[] = [
      {
        type: 'panel',
        icon: IconEditSolid,
        panel: {
          title: `Update operational cost`,
          onSuccess: (row: TableRow) => {
            return updateOtherCosts(row as unknown as PartnerCostsOtherCost);
          }
        }
      }
    ];

    return {headers, rows: [row], actions};
  }, [partnerOtherCost, basicCellRenderer, updateOtherCosts]);

  return (
    <>
      <div className={styles.alignRight}>
        <PartnerCostsExplanationNotice />
      </div>
      <OtherCosts
        overheadCosts={overheadCosts}
        operationalCosts={operationalCosts}
        currency={partnerCountry.currency}
        updateOtherCosts={updateOtherCosts}
      />
    </>
  );
};

export default React.memo(PartnerCostsOtherCostContent);
