import {UnitsSystem} from 'Pages/Common/LocalUnits/types';
import {ValueDimension} from 'Pages/Countries/components/index.constants';
import {Country} from 'Pages/Countries/store/types';
import {PartnerCostsOtherCost} from 'Pages/PartnerCosts/store/types';
import {RegionalCostsOtherCost} from 'Pages/RegionalCosts/store/types';
import {TableHeader} from 'components/Table';

export const overheadCostHeaders: (
  otherCost: PartnerCostsOtherCost,
  country: Country,
  localUnits: UnitsSystem
) => TableHeader[] = (otherCost, country, localUnits) => [
  {
    name: `Overhead (${country.currency}/month)*`,
    key: 'overheadCost'
  },
  {
    name: `Legal (${country.currency}/month)`,
    key: 'legalCost'
  },
  getRepairCostHeader(otherCost, country, localUnits),
  {
    name: `Other costs (${country.currency}/month)`,
    key: 'otherCost'
  }
];

export const getRepairCostDimensionOrDefault: (
  otherCost: PartnerCostsOtherCost | RegionalCostsOtherCost,
  defaultValue?: ValueDimension | null
) => ValueDimension | null = (otherCost, defaultValue = null) => {
  return otherCost.repairCostPerDistanceUnit
    ? ValueDimension.PER_DISTANCE_UNIT
    : otherCost.repairCostPerMonth
    ? ValueDimension.PER_MONTH
    : defaultValue;
};

export const getRepairCostAbbreviation: (
  repairCostDimension: ValueDimension,
  country: Country,
  localUnits: UnitsSystem
) => string | null = (repairCostDimension, country, localUnits) => {
  switch (repairCostDimension) {
    case ValueDimension.PER_DISTANCE_UNIT:
      return `${country.currency}/${localUnits.distanceAbbreviation}`;
    case ValueDimension.PER_MONTH:
      return `${country.currency}/month`;
    default:
      return null;
  }
};

const getRepairCostHeader: (
  otherCost: PartnerCostsOtherCost,
  country: Country,
  localUnits: UnitsSystem
) => TableHeader = (otherCost, country, localUnits) => {
  const repairCostDimension = getRepairCostDimensionOrDefault(
    otherCost,
    country.repairCostDimension
  ) as ValueDimension;
  const repairCostAbbreviation = getRepairCostAbbreviation(
    repairCostDimension,
    country,
    localUnits
  );

  return {
    name: `Damage repair (${repairCostAbbreviation})`,
    key:
      repairCostDimension === ValueDimension.PER_DISTANCE_UNIT
        ? 'repairCostPerDistanceUnit'
        : 'repairCostPerMonth'
  };
};

export const operationalCostHeaders = [
  {
    name: 'Cleaning*',
    key: 'cleaningCost'
  }
];
