import graphqlClient from '../../../services/graphqlClient';
import getPartnerCostEntryYearsQuery from '../graphql/getPartnerCostEntryYearsQuery';
import {isNil} from 'ramda';
import createPartnerCostEntryYearMutation from '../graphql/createPartnerCostEntryYearMutation';
import clonePartnerCostEntryYearMutation from '../graphql/clonePartnerCostEntryYearMutation';
import deletePartnerCostEntryYearMutation from '../graphql/deletePartnerCostEntryYearMutation';
import updateBusPartnerOtherCostMutation from '../graphql/updateBusPartnerOtherCostMutation';
import updateBusPartnerDriverCostMutation from '../graphql/updateBusPartnerDriverCostMutation';
import getBusPartnerAndCountryCostEntryYearQuery from '../graphql/getBusPartnerAndCountryCostEntryYearQuery';
import updateBusPartnerVehicleCostMutation from '../graphql/updateBusPartnerVehicleCostMutation';
import createPartnerVehicleTypeCostMutation from '../graphql/createPartnerVehicleTypeCostMutation';
import deletePartnerVehicleTypeCostMutation from '../graphql/deletePartnerVehicleTypeCostMutation';
import updatePartnerVehicleTypeCostMutation from '../graphql/updatePartnerVehicleTypeCostMutation';
import {displayErrorMessage, displaySuccessMessage} from '../../Common/Utils';
import {
  addedNewYear,
  deletedNewYear,
  receivedPartnerCostYears,
  receivedBusPartnerCostEntryYear,
  receivedPartnerOtherCost,
  receivedPartnerDriverCost,
  receivedPartnerVehicleCostAction,
  addToVehicleTypeCostAction,
  updateVehicleTypeCostAction,
  deleteFromVehicleTypeCostAction
} from './slice';

export const fetchPartnerCostYearsById = partnerId => {
  return dispatch => {
    graphqlClient
      .query({
        query: getPartnerCostEntryYearsQuery,
        variables: {partnerId},
        fetchPolicy: 'no-cache'
      })
      .then(result => {
        let {
          data: {busPartnerCostEntryYears}
        } = result;
        dispatch(receivedPartnerCostYears(busPartnerCostEntryYears));
      });
  };
};

export const addPartnerCostEntryYear = (partnerId, addToast) => {
  return dispatch => {
    if (isNil(partnerId)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: createPartnerCostEntryYearMutation,
        variables: {partnerId}
      })
      .then(result => {
        let {
          data: {createBusPartnerCostEntryYear}
        } = result;
        dispatch(addedNewYear(createBusPartnerCostEntryYear));
        displaySuccessMessage(
          addToast,
          `${createBusPartnerCostEntryYear.year} partner costs database with blank values created successfully`
        );
      });
  };
};

export const clonePartnerCostEntryYear = (partnerId, addToast) => {
  return dispatch => {
    if (isNil(partnerId)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: clonePartnerCostEntryYearMutation,
        variables: {partnerId}
      })
      .then(result => {
        let {
          data: {cloneBusPartnerCostEntryYear}
        } = result;
        dispatch(addedNewYear(cloneBusPartnerCostEntryYear));
        displaySuccessMessage(
          addToast,
          `${cloneBusPartnerCostEntryYear.year} partner costs database with values from previous year created successfully`
        );
      });
  };
};

export const deletePartnerCostEntryYear = (year, addToast) => {
  return (dispatch, getState) => {
    const {partnerCosts} = getState();

    const partnerId = partnerCosts.partnerId;

    if (isNil(partnerId)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: deletePartnerCostEntryYearMutation,
        variables: {partnerId, year},
        errorPolicy: 'all'
      })
      .then(result => {
        let {
          data: {deleteBusPartnerCostEntryYear},
          errors
        } = result;
        if (errors) {
          displayErrorMessage(
            addToast,
            errors[0]?.extensions?.errorMessage || errors[0].message
          );
        } else {
          const year = deleteBusPartnerCostEntryYear?.year;
          dispatch(deletedNewYear(year));
          displaySuccessMessage(
            addToast,
            `Bus partner cost year ${year} deleted successfully`
          );
        }
      });
  };
};

//
// Partner costs - Other cost
//
export const updateOtherCost = (otherCost, partnerId, year) => {
  return dispatch => {
    if (isNil(partnerId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: updateBusPartnerOtherCostMutation,
        variables: {...otherCost, partnerId, year}
      })
      .then(result => {
        let {
          data: {updateBusPartnerOtherCostEntry}
        } = result;
        dispatch(receivedPartnerOtherCost(updateBusPartnerOtherCostEntry));
      });
  };
};

//
// Partner costs - Driver cost
//
export const updateDriverCost = (driverCost, partnerId, year) => {
  return dispatch => {
    if (isNil(partnerId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: updateBusPartnerDriverCostMutation,
        variables: {...driverCost, partnerId, year}
      })
      .then(result => {
        let {
          data: {updateBusPartnerDriverCostEntry}
        } = result;
        dispatch(receivedPartnerDriverCost(updateBusPartnerDriverCostEntry));
      });
  };
};

//
// Partner costs - Vehicle cost
//

export const fetchBusPartnerAndCountryCostEntryYear = (
  busPartnerId,
  year,
  countryId
) => {
  return dispatch => {
    if (isNil(busPartnerId) || isNil(year) || isNil(countryId)) {
      return;
    }

    graphqlClient
      .query({
        query: getBusPartnerAndCountryCostEntryYearQuery,
        variables: {busPartnerId, year, countryId},
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      })
      .then(
        ({data: {getBusPartnerCostEntryYear, countryCostEntry}, errors}) => {
          if (
            errors &&
            !(
              errors[0].extensions.errorType === 'ResourceNotFound' &&
              errors[0].extensions.resourceName === 'Country Year Cost'
            )
          ) {
            console.error('Error fetching cost entry, Errors:', errors);
          }

          dispatch(
            receivedBusPartnerCostEntryYear({
              ...getBusPartnerCostEntryYear,
              countryCostEntry
            })
          );
        }
      );
  };
};

export const updateVehicleCost = vehicleCost => {
  return (dispatch, getState) => {
    const {partnerCosts} = getState();

    const partnerId = partnerCosts.partnerId;
    const year = partnerCosts.year;

    if (isNil(partnerId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: updateBusPartnerVehicleCostMutation,
        variables: {...vehicleCost, partnerId, year}
      })
      .then(result => {
        let {
          data: {updateBusPartnerBusCostEntry}
        } = result;
        dispatch(
          receivedPartnerVehicleCostAction(updateBusPartnerBusCostEntry)
        );
      });
  };
};

export const createVehicleTypeCost = (
  vehicleTypeCost,
  partnerId,
  year,
  setCustomIdentifierErrorMessage
) => {
  return dispatch => {
    if (isNil(partnerId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: createPartnerVehicleTypeCostMutation,
        variables: {
          partnerId,
          year,
          busTypeId: vehicleTypeCost.busTypeId,
          leasingCost: vehicleTypeCost.leasingCost,
          insuranceCost: vehicleTypeCost.insuranceCost,
          interestCost: vehicleTypeCost.interestCost,
          motorVehicleTax: vehicleTypeCost.motorVehicleTax,
          maintenanceCostPerMonth: vehicleTypeCost.maintenanceCostPerMonth,
          maintenanceCostPerDistanceUnit:
            vehicleTypeCost.maintenanceCostPerDistanceUnit,
          vehicleRegistrationCost: vehicleTypeCost.vehicleRegistrationCost,
          customIdentifier: vehicleTypeCost.customIdentifier,
          vehicleProductionYear: vehicleTypeCost.vehicleProductionYear
        },
        errorPolicy: 'all'
      })
      .then(result => {
        const {
          data: {createBusPartnerBusTypeCostEntry},
          errors
        } = result;
        if (errors) {
          setCustomIdentifierErrorMessage(
            errors[0]?.extensions?.errorMessage || errors[0].message
          );
        } else {
          setCustomIdentifierErrorMessage(null);
          dispatch(
            addToVehicleTypeCostAction(createBusPartnerBusTypeCostEntry)
          );
        }
      });
  };
};

export const updateVehicleTypeCost = (
  vehicleTypeCost,
  partnerId,
  year,
  setCustomIdentifierErrorMessage
) => {
  return dispatch => {
    if (isNil(partnerId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: updatePartnerVehicleTypeCostMutation,
        variables: vehicleTypeCost,
        errorPolicy: 'all'
      })
      .then(result => {
        const {
          data: {updateBusPartnerBusTypeCostEntry},
          errors
        } = result;
        if (errors) {
          setCustomIdentifierErrorMessage(
            errors[0]?.extensions?.errorMessage || errors[0].message
          );
        } else {
          setCustomIdentifierErrorMessage(null);
          dispatch(
            updateVehicleTypeCostAction(updateBusPartnerBusTypeCostEntry)
          );
        }
      });
  };
};

export const deleteVehicleTypeCost = (vehicleTypeCostId, addToast) => {
  return dispatch => {
    graphqlClient
      .mutate({
        mutation: deletePartnerVehicleTypeCostMutation,
        variables: {id: vehicleTypeCostId},
        errorPolicy: 'all'
      })
      .then(result => {
        let {
          data: {deleteBusPartnerBusTypeCostEntry},
          errors
        } = result;
        if (errors) {
          displayErrorMessage(
            addToast,
            errors[0]?.extensions?.errorMessage || errors[0].message
          );
        } else {
          displaySuccessMessage(
            addToast,
            'Vehicle type cost deleted successfully'
          );
        }
        dispatch(
          deleteFromVehicleTypeCostAction(deleteBusPartnerBusTypeCostEntry)
        );
      });
  };
};
