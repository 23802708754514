import {UnitsSystem} from 'Pages/Common/LocalUnits/types';

export enum ValueDimension {
  PER_MONTH = 'PER_MONTH',
  PER_DISTANCE_UNIT = 'PER_DISTANCE_UNIT'
}

export const getValueDimensions: (
  currency: string,
  localUnits: UnitsSystem
) => Array<{key: ValueDimension; value: string}> = (currency, localUnits) => [
  {
    key: ValueDimension.PER_MONTH,
    value: `${currency}/month`
  },
  {
    key: ValueDimension.PER_DISTANCE_UNIT,
    value: `${currency}/${localUnits.distanceAbbreviation}`
  }
];
