import React from 'react';
import {Spinner, Text} from '@flixbus/honeycomb-react';
import useBusPartnersWithVehicles from '../../hooks/useBusPartnersWithVehicles';
import baseStyles from '../index.module.scss';

const AllVehiclesFilter: React.FC = () => {
  const busPartnersWithVehicles = useBusPartnersWithVehicles();

  if (busPartnersWithVehicles.length === 0)
    return <Spinner size="md" aria-label="Spinner" />;

  return (
    <div
      className={baseStyles.accordionContent}
      data-testid="all-vehicles-filter"
    >
      {busPartnersWithVehicles.map(partner => (
        <div
          className={baseStyles.partnerDetails}
          key={partner.busPartnerBackendId}
          data-testid={`partner-details-${partner.busPartnerBackendId}`}
        >
          <Text small className={baseStyles.noMargin}>
            <b>{partner.busPartnerName}</b>
          </Text>
          {partner.vehicles.map(vehicle => (
            <Text small key={vehicle.id} className={baseStyles.noMargin}>
              {vehicle.name}
            </Text>
          ))}
        </div>
      ))}
    </div>
  );
};

export default React.memo(AllVehiclesFilter);
