import {gql} from '@apollo/client';

const DELETE_COUNTRY_BUS_TYPE_COST = gql`
  mutation deleteCountryBusTypeCostEntry($id: ID!) {
    deleteCountryBusTypeCostEntry(id: $id) {
      id
    }
  }
`;

export default DELETE_COUNTRY_BUS_TYPE_COST;
