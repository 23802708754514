import React, {ReactNode, useEffect, useState} from 'react';
import ActionButton from './../ActionButton';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import DataTableActionPopup from './DataTableActionPopup';
import {OverviewSelectedValue} from '../types';
import {DocumentNode} from '@apollo/client';
import {IconComponent} from '@flixbus/honeycomb-icons-react';

type PropsType = {
  name: string;
  buttonIcon: IconComponent;
  selectedRows: OverviewSelectedValue[];
  popupHeaderMessage: string;
  popupActionButtonName: string;
  tableSummary: React.ReactNode;
  tableHeaders: {key: string; title: string | ReactNode}[];
  getTableRowFields: (selectedRow: OverviewSelectedValue) => {
    [key: string]: string;
  };
  getProcessedResultFields: (resultRow: OverviewSelectedValue) => object;
  getMutationQuery: (selectedRows: OverviewSelectedValue[]) => DocumentNode;
  errorMessage: string;
};

const DataTableActionButton = (props: PropsType) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [popupActive, setPopupActive] = React.useState(false);

  useEffect(() => {
    if (isNotNilOrEmpty(props.selectedRows)) setIsDisabled(false);
    else setIsDisabled(true);
  }, [props.selectedRows]);

  const onClickPopupActive = () => {
    setPopupActive(true);
  };

  return (
    <div>
      <ActionButton
        onClick={() => onClickPopupActive()}
        disabled={isDisabled}
        name={props.name}
        buttonIcon={props.buttonIcon}
      />
      {popupActive && (
        <DataTableActionPopup
          selectedRows={props.selectedRows}
          popupActive={popupActive}
          setPopupActive={setPopupActive}
          popupHeaderMessage={props.popupHeaderMessage}
          popupActionButtonName={props.popupActionButtonName}
          tableSummary={props.tableSummary}
          tableHeaders={props.tableHeaders}
          getTableRowFields={props.getTableRowFields}
          getProcessedResultFields={props.getProcessedResultFields}
          getMutationQuery={props.getMutationQuery}
          errorMessage={props.errorMessage}
          popupIcon={props.buttonIcon}
        />
      )}
    </div>
  );
};

export default React.memo(DataTableActionButton);
