import React, {useCallback} from 'react';
import {Checkbox, Spinner, Text} from '@flixbus/honeycomb-react';
import useBusPartnersWithVehicles, {
  BusPartnerWithVehicles
} from '../../hooks/useBusPartnersWithVehicles';
import {PartnersFilterProps} from './PartnersFilter.types';
import baseStyles from '../index.module.scss';
import styles from './PartnersFilter.module.scss';

const PartnersFilter: React.FC<PartnersFilterProps> = ({
  selectedPartnerIds,
  setSelectedPartnerIds
}) => {
  const busPartnersWithVehicles = useBusPartnersWithVehicles();

  const handlePartnerSelect = useCallback(
    (partner: BusPartnerWithVehicles) => {
      const wasSelected = selectedPartnerIds.includes(
        partner.busPartnerBackendId
      );
      const updatedSelectedPartnerIds = wasSelected
        ? selectedPartnerIds.filter(id => id !== partner.busPartnerBackendId)
        : [...selectedPartnerIds, partner.busPartnerBackendId];

      setSelectedPartnerIds(updatedSelectedPartnerIds);
    },
    [selectedPartnerIds, setSelectedPartnerIds]
  );

  if (busPartnersWithVehicles.length === 0)
    return <Spinner size="md" aria-label="Spinner" />;

  return (
    <div className={baseStyles.accordionContent} data-testid="partners-filter">
      {busPartnersWithVehicles.map(partner => (
        <div
          className={baseStyles.partnerDetails}
          key={partner.busPartnerBackendId}
          data-testid={`partner-details-${partner.busPartnerBackendId}`}
        >
          <Checkbox
            label={
              <Text small className={baseStyles.noMargin}>
                <b>{partner.busPartnerName}</b>
              </Text>
            }
            id={`partners-filter-${partner.busPartnerBackendId}-select`}
            value={partner.busPartnerBackendId}
            checked={selectedPartnerIds.includes(partner.busPartnerBackendId)}
            onChange={() => handlePartnerSelect(partner)}
          />

          {partner.vehicles.map(vehicle => (
            <Text small key={vehicle.id} className={styles.vehicleName}>
              {vehicle.name}
            </Text>
          ))}
        </div>
      ))}
    </div>
  );
};

export default React.memo(PartnersFilter);
