import {gql} from '@apollo/client';

const RELEASE_PRODUCTION_COST_ENTRY = gql`
  mutation releaseProductionCostEntry($productionCostEntryId: ID!) {
    releaseProductionCostEntry(productionCostEntryId: $productionCostEntryId) {
      id
    }
  }
`;

export default RELEASE_PRODUCTION_COST_ENTRY;
