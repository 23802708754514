import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {addCountrySalaryDefinition} from '../../store/slice';
import {fetchCountrySalaryDefinitions} from '../../store/thunks';
import {Button, Grid, GridCol, Infobox} from '@flixbus/honeycomb-react';
import {Icon, IconPlus} from '@flixbus/honeycomb-icons-react';
import * as R from 'ramda';
import {
  driverSalaryTypeOptions,
  driverSalaryTypeTitles
} from '../../constants/driverSalaryTypes';
import {daysOfWeekOptions, daysOfWeekTitles} from '../../constants/daysOfWeek';
import DriverSalaryTime from './DriverSalaryTime';
import DriverSalaryFooter from './DriverSalaryFooter';

const DriverSalaryPeriods = ({
  country,
  countrySalaryDefinitions,
  fetchCountrySalaryDefinitions,
  addCountrySalaryDefinition
}) => {
  useEffect(() => {
    if (R.isNil(countrySalaryDefinitions) && !R.isNil(country)) {
      fetchCountrySalaryDefinitions();
    }
  }, [countrySalaryDefinitions, country, fetchCountrySalaryDefinitions]);

  if (R.isNil(countrySalaryDefinitions)) {
    return (
      <Infobox appearance="warning">
        <p>
          Select a <strong>country</strong>
        </p>
      </Infobox>
    );
  }

  return (
    <>
      <div className={`hcr-space-2-bottom`}>
        <i>{`Please enter periods like this: "10:00" – "21:59"`}</i>
      </div>

      {driverSalaryTypeOptions.map((driverSalaryType, index) => (
        <Grid key={`driver-salary-type-${driverSalaryType}`}>
          <GridCol size={1}>
            <strong>{driverSalaryTypeTitles[driverSalaryType]}</strong>
          </GridCol>
          <GridCol size={11}>
            <div
              className={`driver-salary-periods-container ${
                index === driverSalaryTypeOptions.length - 1
                  ? `driver-salary-periods-latest`
                  : ``
              }`}
            >
              {daysOfWeekOptions.map(dayOfWeek => {
                let salaryDefinitions = R.filter(
                  R.allPass([
                    R.propEq('salaryType', driverSalaryType),
                    R.propEq('dayOfWeek', dayOfWeek)
                  ])
                )(countrySalaryDefinitions);

                return (
                  <div
                    className={`driver-salary-periods-week-day`}
                    key={`driver-salary-periods-${dayOfWeek}`}
                  >
                    <div className={`hcr-space-2-bottom`}>
                      <strong>{daysOfWeekTitles[dayOfWeek]}</strong>
                    </div>
                    {salaryDefinitions.map(salaryDefinition => (
                      <div key={`driver-salary-time-${salaryDefinition.id}`}>
                        <DriverSalaryTime
                          type={`startTime`}
                          time={salaryDefinition.startTime}
                          salaryDefinition={salaryDefinition}
                        />
                        <DriverSalaryTime
                          type={`endTime`}
                          time={salaryDefinition.endTime}
                          salaryDefinition={salaryDefinition}
                        />
                      </div>
                    ))}

                    <div>
                      <Button
                        display="square"
                        aria-label="Add Country Salary Definition"
                        onClick={() =>
                          addCountrySalaryDefinition(
                            driverSalaryType,
                            dayOfWeek
                          )
                        }
                      >
                        <Icon InlineIcon={IconPlus} aria-hidden="true" />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </GridCol>
        </Grid>
      ))}
      <DriverSalaryFooter />
    </>
  );
};

export default connect(
  state => ({
    country: state.regionalCosts.country,
    countrySalaryDefinitions: state.regionalCosts.countrySalaryDefinition.list
  }),
  {
    fetchCountrySalaryDefinitions,
    addCountrySalaryDefinition
  }
)(DriverSalaryPeriods);
